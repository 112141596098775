/* React */
import React from 'react'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import { Tabs, TabsAlt } from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Parts */
import Statistics from 'components/Statistics'
import ListTable from './ListTable'

/* Constants */
import { subjectTypes } from 'constants/config'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { getDataSetTabsItems } from 'helpers'

const List = ({
  /* router */
  history,
  computedMatch,
  /* props */
  filteredSummarises,
  filteredSubjects,
  subjectType
}) => {
  const routerProps = {
    history,
    computedMatch
  }

  return (
    <ContentWrapper withSidebar>
      <Container>
        <Row>
          <Col>
            <Tabs
              items={[
                {
                  text: 'Análise de risco',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(computedMatch)}/${subjectType}/dashboard`
                    )
                },
                {
                  text: 'Listagem',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(
                        computedMatch
                      )}/${subjectType}/individual`
                    ),
                  isActive: true
                }
              ]}
            />

            <TabsAlt
              items={getDataSetTabsItems({
                subjectTypes,
                history,
                computedMatch,
                screenPath: 'individual'
              })}
              mb="xlarge"
            />
          </Col>

          <Statistics
            filteredSummarises={filteredSummarises}
            filteredSubjects={filteredSubjects}
            subjectType={subjectType}
          />

          <ListTable
            {...routerProps}
            filteredSubjects={filteredSubjects}
            subjectType={subjectType}
          />
        </Row>
      </Container>

      {/* Footer */}
      <Footer />
    </ContentWrapper>
  )
}

export default List
