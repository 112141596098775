/* React */
import React from 'react'
// import PropTypes from 'prop-types'

/* Components */
// import ReactTooltip from 'react-tooltip'

import {
  CustomBlock,
  CustomText,
  // Chip,
  Icon,
  Flex,
  ProgressChart,
  FlexAlignCenter,
  FlexSpaceBetween,
  defaultAvatar,
  theme
} from '@zeta/ui/src'

/* Styled */
import {
  Wrapper,
  StyledFlexSpaceBetween,
  StyledFlexAlignCenter,
  Thumbnail,
  SocialIconLink,
  ScoreCard,
  OfficeButton
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { getPathPrefix, formatDate, logAmplitudeEvent } from '@zeta/helpers'
import { getPicture, getScoreColor } from 'helpers'

/* Constants */
// const trustLevelColors = {
//   'Altíssimo': theme.colors.feedback.green.darkness
// }

const scoreCardLabels = {
  // person: 'Score AAI',
  // office: 'Score escritório',
  // employees: 'Total score PFs',
  global: 'Score'
}

// const scoreCardTooltips = {
//   person: 'person tooltip',
//   office: 'office tooltip',
//   employees: 'employees tooltip',
//   global: 'score tooltip'
// }

const SocialIcon = ({ icon }) => (
  <Icon
    icon={icon}
    size="medium"
    fill={theme.colors.surface.secondary.lightness}
  />
)

const InfoText = ({ children }) => (
  <CustomText mb="medium" size="0.875rem">
    {children}
  </CustomText>
)

const ProfileHeader = ({
  /* router */
  history,
  computedMatch,
  /* props */
  individual,
  subjectType
}) => {
  const content = {
    name: individual.name || individual.company_name,
    thumbnail: !individual.social_midia
      ? defaultAvatar
      : getPicture(individual.social_midia.linkedin_image) ||
        getPicture(individual.social_midia.twitter_image) ||
        defaultAvatar,
    aaiId: subjectType === 'people' && individual.code,
    formatted_cpf: subjectType === 'people' && individual.formatted_cpf,
    cnpj: subjectType === 'company' && individual.cnpj,
    company: individual.company,
    companyId: individual.legal_entity_id,
    bondDate: individual.bond_date,
    warning: true,
    socials: individual.social_midia
      ? {
          facebook:
            individual.social_midia.facebook !== 'NA' &&
            individual.social_midia.facebook,
          linkedin:
            individual.social_midia.linkedin !== 'NA' &&
            individual.social_midia.linkedin,
          instagram:
            individual.social_midia.instagram !== 'NA' &&
            individual.social_midia.instagram,
          twitter:
            individual.social_midia.twitter !== 'NA' &&
            individual.social_midia.twitter
        }
      : null,
    site:
      subjectType === 'company' &&
      individual.social_midia &&
      individual.social_midia.site !== 'NA' &&
      individual.social_midia.site,
    trustLevel: individual.trust_data,
    situation: individual.situation,
    // emails: {
    //   main: 'xxx@refinariadedados.com.br',
    //   partner: 'xxx@refinariadedados.com.br',
    //   manager: 'xxx@refinariadedados.com.br'
    // },
    // phone: 'xxxxxxxxx',
    scores: {
      global: individual.score
      // person: subjectType === 'people' && individual.score && Number((individual.score).toFixed(2)),
      // office: individual.score_office ? subjectType === 'people' ? Number((individual.score_office).toFixed(2)) : Number((individual.score).toFixed(2)) : null,
      // employees: subjectType === 'company' && individual.average_score_aai && Number((individual.average_score_aai).toFixed(2))
    }
  }

  return (
    <Wrapper>
      {/* Foto */}
      <Thumbnail src={content.thumbnail} alt={content.name} />

      <Flex
        override={`
        flex-wrap: wrap;

        @media (min-width: 992px) {
          width: calc(100% - 120px);
        }
      `}
      >
        <StyledFlexSpaceBetween mb="xlarge">
          {/* Nome */}
          <StyledFlexAlignCenter>
            <CustomText size="1.5rem" weight="700" mr="medium">
              {content.name}
            </CustomText>

            {/* {content.warning &&
              <Chip
                bg={theme.colors.feedback.red.darkness}
                override={`@media (max-width: 991px) { margin-bottom: ${theme.spacing.medium}; }`}
              >
                Gerou alerta
              </Chip>} */}
          </StyledFlexAlignCenter>

          {/* Redes Sociais */}
          <FlexAlignCenter>
            {content.site && (
              <SocialIconLink
                href={content.site}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logAmplitudeEvent({
                    event: 'Clique em rede social no perfil',
                    eventProperties: {
                      'Rede social': 'site'
                    }
                  })
                }}
              >
                <SocialIcon icon="globe" />
              </SocialIconLink>
            )}

            {content.socials &&
              Object.keys(content.socials)
                .filter((social) => content.socials[social])
                .map((social) => (
                  <SocialIconLink
                    href={content.socials[social]}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logAmplitudeEvent({
                        event: 'Clique em rede social no perfil',
                        eventProperties: {
                          'Rede social': social
                        }
                      })
                    }}
                    key={uuidv4()}
                  >
                    <SocialIcon icon={`fa-${social}`} />
                  </SocialIconLink>
                ))}
          </FlexAlignCenter>
        </StyledFlexSpaceBetween>

        {/* Infos */}
        <StyledFlexSpaceBetween override="align-items: flex-start;">
          <CustomBlock>
            {content.aaiId && (
              <InfoText>
                A: <strong>{content.aaiId}</strong>
              </InfoText>
            )}

            {content.formatted_cpf && (
              <InfoText>
                CPF: <strong>{content.formatted_cpf}</strong>
              </InfoText>
            )}
            {content.cnpj && (
              <InfoText>
                CNPJ: <strong>{content.cnpj}</strong>
              </InfoText>
            )}

            {content.company && (
              <InfoText>
                Empresa:{' '}
                {content.companyId ? (
                  <OfficeButton
                    onClick={() => {
                      history.push(
                        `${getPathPrefix(computedMatch)}/company/individual/${
                          content.companyId
                        }`
                      )

                      logAmplitudeEvent({
                        event: 'Clique em escritório a partir do perfil'
                      })
                    }}
                  >
                    {content.company}
                  </OfficeButton>
                ) : (
                  <strong>{content.company}</strong>
                )}
              </InfoText>
            )}

            {content.bondDate && (
              <InfoText>
                Data de vínculo: <strong>{formatDate(content.bondDate)}</strong>
              </InfoText>
            )}

            <InfoText>
              Situação:{' '}
              <strong>{content.situation ? 'Ativo' : 'Inativo'}</strong>
            </InfoText>

            {/* <StyledFlexAlignCenter mb='xlarge'>
              <CustomText size='0.875rem' weight='700' mr='small'>Nível de confiança dos dados:</CustomText>

              <Chip
                bg={trustLevelColors[content.trustLevel]}
                override={`@media (max-width: 991px) { margin-top: ${theme.spacing.xsmall}; }`}
              >
                {content.trustLevel}
              </Chip>
            </StyledFlexAlignCenter> */}

            {/* {content.emails.main && <InfoText>E-mail: <strong>{content.emails.main}</strong></InfoText>}
            {content.emails.partner && <InfoText>E-mail sócio responsável: <strong>{content.emails.partner}</strong></InfoText>}
            {content.emails.manager && <InfoText>E-mail gerente estratégico: <strong>{content.emails.manager}</strong></InfoText>}
            {content.phone && <CustomText size='0.875rem'>Telefone de contato: <strong>{content.phone}</strong></CustomText>} */}
          </CustomBlock>

          {/* Scores */}
          <StyledFlexAlignCenter>
            {Object.keys(content.scores).map((score) =>
              content.scores[score] ? (
                <ScoreCard key={uuidv4()}>
                  <FlexSpaceBetween mb="medium">
                    <CustomText size="0.75rem">
                      {scoreCardLabels[score]}
                    </CustomText>

                    {/* <Icon
                      icon='alert-circle-outline'
                      size='medium'
                      fill={theme.colors.brand.tertiary.lightness}
                      data-tip={scoreCardTooltips[score]}
                      data-for='scoreTooltip'
                    /> */}
                  </FlexSpaceBetween>

                  <CustomBlock mx="large" mb="small">
                    <ProgressChart
                      percentage={content.scores[score] / 10}
                      numberDisplay={content.scores[score]}
                      hidePercent
                      fill={
                        theme.colors.feedback[
                          getScoreColor(content.scores[score])
                        ].darkness
                      }
                    />
                  </CustomBlock>
                </ScoreCard>
              ) : null
            )}

            {/* <ReactTooltip id='scoreTooltip' /> */}
          </StyledFlexAlignCenter>
        </StyledFlexSpaceBetween>
      </Flex>
    </Wrapper>
  )
}

ProfileHeader.propTypes = {}

export default ProfileHeader
