/* React */
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Action Creators */
import { getAndSetMainData } from 'store/reducers/data/action-creators'

export const MainDataLoader = ({ children }) => {
  const dispatch = useDispatch()

  const token = useSelector((state) => state.user.token)

  useEffect(() => {
    if (token) {
      dispatch(getAndSetMainData())
    }
  }, [token])

  return children
}

export default MainDataLoader
