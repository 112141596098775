import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  ${defaultProps}
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};

  tr {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
      color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
      transition: all 0.3s;
    }
  }

  td {
    padding: ${({ theme: { spacing } }) => spacing.small};
  }
`

export const ThumbnailTd = styled.td`
  width: 48px;
  text-align: center;
`

export const NameTd = styled.td`
  min-width: 225px;
  font-size: 0.875rem;
  font-weight: 700;
`

export const ChipTd = styled.td`
  width: 140px;
`

export const StatusTd = styled.td`
  width: 230px;
`

export const Thumbnail = styled.img`
  width: ${({ theme: { spacing } }) => spacing.xlarge};
  height: ${({ theme: { spacing } }) => spacing.xlarge};
  border-radius: 50%;
  display: block;
  margin: auto;
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
