import styled from 'styled-components'

export const Overlay = styled.div`
  background: rgba(57, 61, 64, 0.7);
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;

  @media (min-width: 1200px) {
    left: 280px;
  }
`

export const Tab = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  width: 100%;
  height: calc(100vh - ${({ theme: { spacing } }) => spacing.largest});
  position: fixed;
  left: 0;
  top: ${({ theme: { spacing } }) => spacing.largest};
  z-index: 10;
  padding: ${({ theme: { spacing } }) => `${spacing.medium} 0`};

  @media (min-width: 1200px) {
    width: calc(90% - 280px);
    left: 280px;
    z-index: 9;
    padding: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: ${({ theme: { spacing } }) => spacing.medium} !important;

    @media (max-width: 767px) {
      &:first-child {
        margin-left: 0 !important;
      }
    }
  }
`

export const TabButton = styled.button`
  color: ${({ cancel, theme: { colors } }) => cancel ? `${colors.brand.primary.medium}` : `${colors.surface.primary.darkness}`};
  font-size: 1rem;
`

export const TabContent = styled.div`
  width: 100%;
  max-height: calc(100vh - 168px);
  padding: ${({ theme: { spacing } }) => `0 ${spacing.medium}`};
  overflow-y: auto;
  overflow-x: hidden;
`
