/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  SearchForm,
  CustomBlock,
  CustomText,
  Button,
  Icon,
  theme
} from '@zeta/ui/src'

/* Helpers */
import { logAmplitudeEvent } from '@zeta/helpers'

/* Action Creators */
import { setSearchQuery } from 'store/reducers/data/action-creators'

const placeholder = {
  people: 'Busque por nome, código do AAI ou CPF',
  company: 'Busque por nome do escritório ou CNPJ (sem pontuação)'
}

export const ListSearch = ({ subjectType }) => {
  const dispatch = useDispatch()

  const searchQuery = useSelector((state) => state.data.searchQuery)

  const [formValue, setFormValue] = useState('')

  return (
    <>
      <SearchForm
        onSubmit={() => {
          dispatch(setSearchQuery(formValue.trim(), subjectType))
          setFormValue('')

          logAmplitudeEvent({
            event: 'Campo de busca acionado na listagem',
            eventProperties: {
              Valor: formValue.trim()
            }
          })
        }}
        onChange={(e) => setFormValue(e.target.value)}
        value={formValue}
        placeholder={placeholder[subjectType]}
        bg={theme.colors.surface.primary.lightness}
        override={`
          @media (min-width: 768px) {
            width: 420px;
            max-width: 100%;
            margin-right: ${theme.spacing.medium};
          }
        `}
      />

      {searchQuery[subjectType] && (
        <CustomBlock
          override="display: flex; align-items: center;"
          mt="small"
          mr="small"
        >
          <CustomText size="0.75rem" mr="medium">
            Exibindo resultados de busca para{' '}
            <strong>"{searchQuery[subjectType]}"</strong>
          </CustomText>

          <Button
            onClick={() => {
              dispatch(setSearchQuery(null, subjectType))
              setFormValue('')
            }}
            small
            red
          >
            <Icon icon="close-outline" />
            <span>Limpar busca</span>
          </Button>
        </CustomBlock>
      )}
    </>
  )
}

export default ListSearch
