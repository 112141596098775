/* React */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

/* Components */
import {
  Card,
  HorizontalScroll,
  TableStriped,
  CustomText,
  SectionHeadline,
  theme
} from '@zeta/ui/src'

import OccurrenceCategoryCard from '../../OccurrenceCategoryCard'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { uniq } from 'ramda'
import { filterSummarisesByCategory, getSubjectsStatus } from '../../helpers'

/* Constants */
import { visibleOccurrenceClass } from 'constants/dictionary'
import { categories } from '../../constants'

const Subjects = ({
  /* router */
  history,
  /* props */
  filteredSummarises,
  subjectType,
  activeTab
}) => {
  const [subjectsByCategoryAndStatus, setSubjectsByCategoryAndStatus] = useState(null)

  useEffect(() => {
    if (filteredSummarises) {
      const obsByCategory = categories.reduce((acc, category) => {
        return {
          ...acc,
          [category]: filterSummarisesByCategory(filteredSummarises, category)
        }
      }, {})

      const subjectsStatus = categories.reduce((acc, category) => {
        return {
          ...acc,
          [category]: getSubjectsStatus(obsByCategory[category])
        }
      }, {})

      setSubjectsByCategoryAndStatus(
        categories.reduce((acc, category) => {
          return {
            ...acc,
            [category]: {
              total: uniq(obsByCategory[category].map(summarise => summarise.subject_id)).length,
              totalTrue: subjectsStatus[category].length,
              status0: subjectsStatus[category].filter(subject => subject.status === 0).length,
              status2: subjectsStatus[category].filter(subject => subject.status === 2).length,
              status3: subjectsStatus[category].filter(subject => subject.status === 3).length,
              status4: subjectsStatus[category].filter(subject => subject.status === 4).length,
              status5: subjectsStatus[category].filter(subject => subject.status === 5).length
            }
          }
        }, {})
      )
    }
  }, [filteredSummarises])

  useEffect(() => {
    if (subjectsByCategoryAndStatus) {
      window.print()
      history.goBack()
    }
  }, [subjectsByCategoryAndStatus])

  const TableStripedNumber = ({ children }) =>
    <CustomText
      weight='700'
      color={subjectType === 'people' ? theme.colors.brand.primary.dark : theme.colors.brand.secondary.dark}>
      {children}
    </CustomText>

  return filteredSummarises && subjectsByCategoryAndStatus
    ? (
      <div style={{ padding: '16px' }}>
        <SectionHeadline>Visão Geral</SectionHeadline>

        {categories.map(category => (
          <OccurrenceCategoryCard
            title={visibleOccurrenceClass[category]}
            totals={{
              totalListed: subjectsByCategoryAndStatus[category].total,
              totalUnclassified: subjectsByCategoryAndStatus[category].status0
            }}
            status={{
              totals: {
                underAnalysis: subjectsByCategoryAndStatus[category].status2,
                waiting: subjectsByCategoryAndStatus[category].status3,
                committee: subjectsByCategoryAndStatus[category].status4,
                solved: subjectsByCategoryAndStatus[category].status5
              }
            }}
            subjectType={subjectType}
            activeTab={activeTab}
            key={uuidv4()}
          />
        ))}

        <Card title='Evolução de análises'>
          <HorizontalScroll mx={-16} override='width: calc(100% + 32px);'>
            <TableStriped
              headers={[
                { text: <strong>Categoria</strong> },
                { text: 'Nova identificação', textAlign: 'center' },
                { text: 'Em análise', textAlign: 'center' },
                { text: 'Aguardando retorno', textAlign: 'center' },
                { text: 'Comitê', textAlign: 'center' },
                { text: 'Solucionado', textAlign: 'center' },
                { text: <strong>TOTAL</strong>, textAlign: 'center' }
              ]}
              rows={
                categories.map(category => ({
                  cells: [
                    { text: visibleOccurrenceClass[category] },
                    { text: <TableStripedNumber>{subjectsByCategoryAndStatus[category].status0}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{subjectsByCategoryAndStatus[category].status2}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{subjectsByCategoryAndStatus[category].status3}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{subjectsByCategoryAndStatus[category].status4}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{subjectsByCategoryAndStatus[category].status5}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{subjectsByCategoryAndStatus[category].totalTrue}</TableStripedNumber>, textAlign: 'center' }
                  ]
                }))
              }
            />
          </HorizontalScroll>
        </Card>
      </div>
    )
    : null
}

export default withRouter(Subjects)
