/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import LoadingSpinner from '../LoadingSpinner'
import { FlexCentered } from '../helpers/Flex'

/* Styled */
import { Wrapper, HeaderRow, HeaderCell, BodyRow, BodyCell } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const TableInfo = ({ headers, rows, isLoadingRows, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <thead>
        <HeaderRow>
          {headers &&
            headers.map((header) => (
              <HeaderCell key={uuidv4()} textAlign={header.textAlign}>
                {header.text}
              </HeaderCell>
            ))}
        </HeaderRow>
      </thead>

      <tbody>
        {!isLoadingRows &&
          rows &&
          rows.map((row) =>
            row ? (
              <BodyRow key={uuidv4()}>
                {row.cells.map((cell, i) => (
                  <BodyCell
                    key={uuidv4()}
                    colSpan={cell.colSpan}
                    textAlign={cell.textAlign}
                  >
                    {cell.text}
                  </BodyCell>
                ))}
              </BodyRow>
            ) : null
          )}

        {isLoadingRows && (
          <BodyRow>
            <BodyCell colSpan={headers.length}>
              <FlexCentered>
                <LoadingSpinner size='40' />
              </FlexCentered>
            </BodyCell>
          </BodyRow>
        )}
      </tbody>
    </Wrapper>
  )
}

TableInfo.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      textAlign: PropTypes.oneOf(['left', 'center', 'right'])
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
            .isRequired,
          colSpan: PropTypes.number,
          textAlign: PropTypes.oneOf(['left', 'center', 'right'])
        })
      ).isRequired
    })
  ),
  isLoadingRows: PropTypes.bool
}

export default TableInfo
