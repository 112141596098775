import React from 'react'
import { TooltipIcon } from 'components/pages/Individual/OccurrencesTabs/OccurrenceClassTab/OccurrenceCard'
import { defaultAvatar } from '@zeta/ui/src'

import { visibleStatus } from 'constants/dictionary'

import ReactHtmlParser from 'react-html-parser'
import { sanitize } from 'dompurify'
import { update, reverse } from 'ramda'
import { formatDate } from '@zeta/helpers'
import { toCurrency, getPicture } from 'helpers'

/**
 *
 * Métodos utilitários relacionados a tela individual (perfil).
 *
 */
const helpers = {
  /**
   *
   * Retorna os subjects vinculados a um escritório formatados para exibição no componente EmployeesTable
   * @param {Array[Number]} ids
   * @param {Array[Object]} subjects
   * @return {Array[Object]}
   *
   */
  formatEmployees: (ids, subjects) =>
    ids.map((id) => {
      const subject = subjects.find((subject) => subject.id === id)

      return {
        id: subject.id,
        thumbnail: !subject.social_midia
          ? defaultAvatar
          : getPicture(subject.social_midia.linkedin_image) ||
            getPicture(subject.social_midia.twitter_image) ||
            defaultAvatar,
        name: subject.name,
        // score: Number((subject.score).toFixed(2)),
        status: visibleStatus[subject.actual_status]
      }
    }),

  /**
   *
   * Retorna as rows do card de ocorrência
   * @param {String} occurrenceType
   * @param {String} occurrenceClass
   * @param {Object} content
   * @param {String} subjectType 'people' / 'company'
   * @return {Array[Object]}
   *
   */
  formateOccurrenceRows: (
    occurrenceType,
    occurrenceClass,
    content,
    subjectType
  ) => {
    // CADASTRAIS
    if (occurrenceClass === 'beneficio_social') {
      return [
        {
          cells: [
            { text: 'País' },
            { text: content['Pais'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Valor' },
            { text: toCurrency(content['Valo_Beneficio']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Número de Parcelas' },
            { text: content['Numero_Parcelas'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Estado' },
            { text: content['Estado'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Cidade' },
            { text: content['Cidade'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Nome do Benefício' },
            { text: content['Nome_do_Beneficio'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data de Início' },
            { text: formatDate(content['Data_Inicio_Beneficio']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data de Término' },
            { text: formatDate(content['Data_Termino_Beneficio']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }

    if (occurrenceClass === 'midia') {
      return [
        {
          cells: [
            { text: 'Categorias' },
            { text: content['Categorias'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data de Captura' },
            { text: formatDate(content['Data_Captura']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data de Publicação' },
            { text: formatDate(content['Data_Publicacao']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Fonte' },
            { text: content['Fonte'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Título' },
            { text: content['Titulo'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'URL' },
            { text: content['URL'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }

    // RELAÇÕES SOCIETÁRIAS
    if (occurrenceType === 'quadro_societario') {
      return [
        {
          cells: [
            { text: 'Razão Social Sócio' },
            { text: content['Nome_Razao_Social_Socio'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Qualificação Sócio' },
            { text: content['Qualificacao_Socio'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'CPF / CNPJ Sócio' },
            { text: content['CPF_CNPJ_Socio'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data Entrada' },
            { text: formatDate(content['Data_Entrada']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data Saída' },
            { text: formatDate(content['Data_Saida']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }

    // FINANCEIRO
    if (occurrenceType === 'dividas_e_pendencias') {
      return [
        {
          cells: [
            { text: 'Valor' },
            { text: toCurrency(content['Valor']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Credor' },
            { text: content['Credor'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Informações Adicionais' },
            { text: content['Informacoes_Adicionais'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Modalidade' },
            { text: content['Modalidade'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }

    if (occurrenceType === 'protestos') {
      return [
        {
          cells: [
            { text: 'Valor' },
            { text: toCurrency(content['Valor_Protesto']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data' },
            { text: formatDate(content['Data_Protesto']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Cidade' },
            { text: content['Cidade'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'UF' },
            { text: content['UF'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Assertiva"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }

    if (occurrenceType === 'certidao_de_debito') {
      return [
        {
          cells: [
            { text: 'Status' },
            { text: content['Status'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Fonte' },
            { text: content['Fonte'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Protocolo' },
            { text: content['Protocolo'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data da Consulta' },
            { text: formatDate(content['Data_Consulta']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Validade' },
            { text: formatDate(content['Validade']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Ressalvas' },
            { text: content['Ressalvas'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Ressalvas (1)' },
            { text: content['Ressalvas_1'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Ressalvas (2)' },
            { text: content['Ressalvas_2'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }

    // PPE
    if (occurrenceType === 'ppe') {
      if (subjectType === 'people') {
        return [
          {
            cells: [
              { text: 'Cargo' },
              { text: content['Cargo'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          // { cells: [{ text: 'Data de Início' }, { text: formatDate(content['Data de Inicio']) }, { text: <TooltipIcon dataTip='Bigdatacorp' dataFor='occurrenceTableTooltip' /> }] },
          {
            cells: [
              { text: 'Data de Término' },
              { text: formatDate(content['Data_de_Termino']) },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Departamento' },
              { text: content['Departamento'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          // { cells: [{ text: 'Fonte' }, { text: content['Fonte'] }, { text: <TooltipIcon dataTip='Bigdatacorp' dataFor='occurrenceTableTooltip' /> }] },
          {
            cells: [
              { text: 'Motivo' },
              { text: content['Motivo'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Nível' },
              { text: content['Nivel'] == 1 ? 'Direto' : 'Indireto' },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'CPF' },
              { text: content['CPF_Relacionado'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          }
        ]
      }
      if (subjectType === 'company') {
        return [
          {
            cells: [
              { text: 'Cargo 1' },
              { text: content['Cargo_1'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Cargo 2' },
              { text: content['Cargo_2'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Cargo 3' },
              { text: content['Cargo_3'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          // { cells: [{ text: 'Data de Início 1' }, { text: formatDate(content['Data_de_Inicio_1']) }, { text: <TooltipIcon dataTip='Bigdatacorp' dataFor='occurrenceTableTooltip' /> }] },
          // { cells: [{ text: 'Data de Início 2' }, { text: formatDate(content['Data_de_Inicio_2']) }, { text: <TooltipIcon dataTip='Bigdatacorp' dataFor='occurrenceTableTooltip' /> }] },
          // { cells: [{ text: 'Data de Inicio3' }, { text: formatDate(content['Data_de_Inicio_3']) }, { text: <TooltipIcon dataTip='Bigdatacorp' dataFor='occurrenceTableTooltip' /> }] },
          {
            cells: [
              { text: 'Data de Término 1' },
              { text: formatDate(content['Data_de_Termino_1']) },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Data de Término 2' },
              { text: formatDate(content['Data_de_Termino_2']) },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Data de Término 3' },
              { text: formatDate(content['Data_de_Termino_3']) },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Departamento 1' },
              { text: content['Departamento_1'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Departamento 2' },
              { text: content['Departamento_2'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Departamento 3' },
              { text: content['Departamento_3'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Motivo 1' },
              { text: content['Motivo_1'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Motivo 2' },
              { text: content['Motivo_2'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Motivo 3' },
              { text: content['Motivo_3'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Nível 1' },
              { text: content['Nivel_1'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Nível 2' },
              { text: content['Nivel_2'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          },
          {
            cells: [
              { text: 'Nível 3' },
              { text: content['Nivel_3'] },
              {
                text: (
                  <TooltipIcon
                    dataTip="Bigdatacorp"
                    dataFor="occurrenceTableTooltip"
                  />
                )
              }
            ]
          }
        ]
      }
    }

    // LISTAS RESTRITIVAS
    if (occurrenceType === 'cac') {
      return [
        {
          cells: [
            { text: 'Status' },
            { text: content['Status'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Validade' },
            { text: content['Validade'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Dado Bruto' },
            { text: content['Dado_Bruto'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Número de ID' },
            { text: content['Numero_Id'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data de Emissão' },
            { text: formatDate(content['Data_Emissao']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Prazo de Validade' },
            { text: formatDate(content['Prazo_Validade']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Texto do Certificado' },
            { text: content['Texto_Certificado'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Número do Certificado' },
            { text: content['Numero_Certificado'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }

    if (occurrenceType === 'sancoes') {
      return [
        {
          cells: [
            { text: 'Tipo' },
            { text: content['Tipo'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Fonte' },
            { text: content['Fonte'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Detalhes' },
            { text: content['Detalhes'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Data de Início' },
            { text: formatDate(content['Data_de_Inicio']) },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        // { cells: [{ text: 'Data de Término' }, { text: formatDate(content['Data de Termino']) }, { text: <TooltipIcon dataTip='Bigdatacorp' dataFor='occurrenceTableTooltip' /> }] },
        {
          cells: [
            { text: 'Nível de Match do Nome' },
            { text: content['Nivel_Match_Nome'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        },
        {
          cells: [
            { text: 'Nível de Unicidade do Nome' },
            { text: content['Nivel_Unicidade_Nome'] },
            {
              text: (
                <TooltipIcon
                  dataTip="Bigdatacorp"
                  dataFor="occurrenceTableTooltip"
                />
              )
            }
          ]
        }
      ]
    }
  },

  /**
   *
   * Formata o objeto do histórico de ações, organizando por categoria e por ocorrência
   * @param {Array[Object]} timeline
   * @return {Object}
   *
   */
  formatTimeline: (timeline) => {
    return timeline.reduce((acc, timelineItem) => {
      const occurrenceLogsIndex =
        acc[timelineItem.occurrence_class] &&
        acc[timelineItem.occurrence_class].findIndex(
          (occurrenceLogs) =>
            occurrenceLogs[0].occurrence_id === timelineItem.occurrence_id
        )

      return {
        ...acc,
        [timelineItem.occurrence_class]: acc[timelineItem.occurrence_class]
          ? occurrenceLogsIndex === -1
            ? acc[timelineItem.occurrence_class].concat([[timelineItem]])
            : update(
                occurrenceLogsIndex,
                acc[timelineItem.occurrence_class][occurrenceLogsIndex].concat(
                  timelineItem
                ),
                acc[timelineItem.occurrence_class]
              )
          : [[timelineItem]]
      }
    }, {})
  },

  /**
   *
   * Formata as rows do histórico de observations
   * @param {Array[Object]} observations
   * @return {Object}
   *
   */
  formatObservationsRows: (observations) =>
    reverse(
      observations.map((observation) => ({
        cells: [
          { text: observation.who_created },
          { text: ReactHtmlParser(sanitize(observation.annotation)) },
          { text: formatDate(observation.created_at) }
        ]
      }))
    )
}

export const {
  formatEmployees,
  formateOccurrenceRows,
  formatTimeline,
  formatObservationsRows
} = helpers

export default helpers
