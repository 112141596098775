import styled from 'styled-components'

import { Card, FlexSpaceBetween, FlexAlignCenter } from '@zeta/ui/src'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.medium};

  @media (max-width: 991px) {
    flex-direction: column;
  }
`

export const Thumbnail = styled.img`
  width: 104px;
  max-width: 100%;

  @media (max-width: 991px) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
  }
  @media (min-width: 992px) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const SocialIconLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  width: ${({ theme: { spacing } }) => spacing.large};
  height: ${({ theme: { spacing } }) => spacing.large};
  margin-left: ${({ theme: { spacing } }) => spacing.medium};

  @media (max-width: 991px) {
    &:first-child {
      margin-left: 0;
    }
  }
`

export const ScoreCard = styled(Card)`
  padding: ${({ theme: { spacing } }) => spacing.small};

  @media (max-width: 991px) {
    margin-top: ${({ theme: { spacing } }) => spacing.medium};
  }

  @media (min-width: 992px) {
    margin-left: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const StyledFlexSpaceBetween = styled(FlexSpaceBetween)`
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledFlexAlignCenter = styled(FlexAlignCenter)`
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const OfficeButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  font-weight: 700;
  text-align: left;
`
