/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import { CustomBlock, SearchForm, theme } from '@zeta/ui/src'

import FilterTabs from 'components/filters/FilterTabs'
import Selection from 'components/filters/Selection'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

const CompaniesFilters = ({ ...props }) => {
  const dynamicFiltersItems = useSelector(
    (state) => state.data.dynamicFiltersItems
  )

  const [activeView, setActiveView] = useState('A')
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <CustomBlock mt="medium">
      <Row>
        <Col>
          <SearchForm
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            placeholder="Pesquise pelo nome do escritório"
            border={theme.colors.surface.secondary.darkness}
            override={`
              margin-bottom: ${theme.spacing.medium};

              @media (min-width: 768px) {
                width: 420px;
                max-width: 100%;
                margin-right: ${theme.spacing.medium};
              }
            `}
          />

          {searchQuery && (
            <Selection
              display="column"
              columnWidth="25%"
              selectionType="checkbox"
              name="office"
              items={dynamicFiltersItems['office'].filter((item) =>
                item.visibleValue
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase().trim())
              )}
              mt="large"
              key={uuidv4()}
              {...props}
            />
          )}

          {!searchQuery && (
            <>
              <FilterTabs
                items={[...letters, 'Número'].map((letter) => ({
                  text: letter,
                  onClickFn: () => setActiveView(letter),
                  isActive: activeView === letter
                }))}
              />

              {letters.map((letter) =>
                activeView === letter ? (
                  <Selection
                    display="column"
                    columnWidth="25%"
                    selectionType="checkbox"
                    name="office"
                    items={dynamicFiltersItems['office'].filter(
                      (item) =>
                        item.visibleValue.charAt(0).toLowerCase() ===
                        letter.toLowerCase()
                    )}
                    mt="large"
                    key={uuidv4()}
                    {...props}
                  />
                ) : null
              )}

              {activeView === 'Número' && (
                <Selection
                  display="column"
                  columnWidth="25%"
                  selectionType="checkbox"
                  name="office"
                  items={dynamicFiltersItems['office'].filter(
                    (item) => !isNaN(Number(item.visibleValue.charAt(0)))
                  )}
                  mt="large"
                  {...props}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default CompaniesFilters
