import styled from 'styled-components'

export const MiniTabs = styled.div`
  display: flex;
  align-items: center;
`

export const MiniTabsButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  font-size: 0.875rem;
  color: ${({ isActive, theme: { colors } }) => isActive ? colors.surface.secondary.lightness : colors.brand.primary.medium};
  ${({ isActive, theme: { colors } }) => isActive && `background-color: ${colors.brand.primary.medium}`};
  border: 1px solid ${({ theme: { colors } }) => colors.brand.primary.medium};
  border-left: none;
  padding:  ${({ theme: { spacing } }) => `${spacing.xsmall} ${spacing.small} ${spacing.xsmall} ${spacing.small}`};

  &:first-child {
    border-radius: ${({ theme: { radius } }) => `${radius.medium} 0 0 ${radius.medium}`};
    border-left: 1px solid ${({ theme: { colors } }) => colors.brand.primary.medium};
    padding:  ${({ theme: { spacing } }) => `${spacing.xsmall} ${spacing.small} ${spacing.xsmall} ${spacing.medium}`};
  }
  &:last-child {
    border-radius: ${({ theme: { radius } }) => `0 ${radius.medium} ${radius.medium} 0`};
    padding:  ${({ theme: { spacing } }) => `${spacing.xsmall} ${spacing.medium} ${spacing.xsmall} ${spacing.small}`};
  }
`
