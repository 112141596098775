/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Tabs, FlexCentered, LoadingSpinner } from '@zeta/ui/src'

import CurrentTab from './CurrentTab'
import HistoryTab from './HistoryTab'

const Posture = ({ ...props }) => {
  const [activeTab, setActiveTab] = useState('CURRENT')

  const users = useSelector((state) => state.data.users)
  const posture = useSelector((state) => state.individual.posture)
  const isLoadingPosture = useSelector(
    (state) => state.individual.isLoadingPosture
  )

  // REVISAR CONDIÇÕES
  if (!posture || !users || isLoadingPosture) {
    return (
      <FlexCentered>
        <LoadingSpinner size="40" my="large" />
      </FlexCentered>
    )
  }

  // REVISAR CONDIÇÕES
  if (posture && users) {
    return (
      <>
        <Tabs
          items={[
            {
              text: 'Análise',
              onClickFn: () => setActiveTab('CURRENT'),
              isActive: activeTab === 'CURRENT'
            },
            {
              text: 'Histórico',
              onClickFn: () => setActiveTab('HISTORY'),
              isActive: activeTab === 'HISTORY'
            }
          ]}
          mt="large"
          mb="medium"
        />

        {activeTab === 'CURRENT' ? (
          <CurrentTab {...props} />
        ) : (
          <HistoryTab {...props} />
        )}
      </>
    )
  }

  return null
}

export default Posture
