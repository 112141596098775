/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { CustomText } from '@zeta/ui/src'

/* Styled */
import {
  Wrapper,
  Table,
  NameTd,
  ThumbnailTd,
  // ChipTd,
  StatusTd,
  Thumbnail
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
// import { logAmplitudeEvent } from '@zeta/helpers'
// import { getScoreColor } from 'helpers'

const EmployeesTable = ({ employees, onClickFn, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Table>
        <tbody>
          {employees.map((employee) => (
            <Employee
              onClickFn={() => {
                onClickFn(employee.id)

                // logAmplitudeEvent({
                //   event: 'Clique em perfil',
                //   eventProperties: {
                //     'ID do perfil': employee.id,
                //     'Nome': employee.name,
                //     'Base': 'people',
                //     'Local': `Listagem do escritório (${computedMatch.path})`
                //   }
                // })
              }}
              thumbnail={employee.thumbnail}
              name={employee.name}
              // score={employee.score}
              status={employee.status}
              key={uuidv4()}
            />
          ))}
        </tbody>
      </Table>
    </Wrapper>
  )
}

const Employee = ({ onClickFn, thumbnail, name, status }) => {
  return (
    <tr onClick={onClickFn}>
      <ThumbnailTd>
        <Thumbnail src={thumbnail} />
      </ThumbnailTd>

      <NameTd colSpan={status ? 1 : 2}>{name}</NameTd>

      {/* <ChipTd>
        <CustomText size='0.875rem' override='white-space: nowrap;'>Score: <Chip bg={theme.colors.feedback[getScoreColor(score)].darkness}>{score}</Chip></CustomText>
      </ChipTd> */}

      {status && (
        <StatusTd>
          <CustomText size="0.875rem" override="white-space: nowrap;">
            Status: <strong>{status}</strong>
          </CustomText>
        </StatusTd>
      )}
    </tr>
  )
}

EmployeesTable.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      // score: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  onClickFn: PropTypes.func.isRequired
}

Employee.propTypes = {
  onClickFn: PropTypes.func.isRequired,
  thumbnail: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // score: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired
}

export default EmployeesTable
