/* React */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import { Col } from 'styled-bootstrap-grid'

import {
  FlexCentered,
  LoadingSpinner,
  Card,
  HorizontalScroll,
  TableStriped,
  CustomText,
  SectionHeadline,
  GroupBarChart,
  theme
} from '@zeta/ui/src'

import OccurrenceCategoryCard from '../OccurrenceCategoryCard'
import GraphCard from '../GraphCard'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { uniq } from 'ramda'
import { filterSummarisesByCategory, getSubjectsStatus } from '../helpers'

/* Action Creators */
import { setFilters } from 'store/reducers/data/action-creators'

/* Constants */
import { visibleOccurrenceClass } from 'constants/dictionary'
import { categories, filtersByCategory } from '../constants'

const Subjects = ({ filteredSummarises, subjectType, activeTab }) => {
  const dispatch = useDispatch()

  // const [obsByCategoryAndStatus, setObsByCategoryAndStatus] = useState(null)
  const [
    subjectsByCategoryAndStatus,
    setSubjectsByCategoryAndStatus
  ] = useState(null)

  useEffect(() => {
    if (filteredSummarises) {
      const obsByCategory = categories.reduce((acc, category) => {
        return {
          ...acc,
          [category]: filterSummarisesByCategory(filteredSummarises, category)
        }
      }, {})

      // setObsByCategoryAndStatus(
      //   categories.reduce((acc, category) => {
      //     return {
      //       ...acc,
      //       [category]: {
      //         total: filterSummarisesByExcludingStatus(obsByCategory[category], [7]).length,
      //         status0: filterSummarisesByStatus(obsByCategory[category], 0).length,
      //         status1: filterSummarisesByStatus(obsByCategory[category], 1).length,
      //         status2: filterSummarisesByStatus(obsByCategory[category], 2).length,
      //         status3: filterSummarisesByStatus(obsByCategory[category], 3).length,
      //         status4: filterSummarisesByStatus(obsByCategory[category], 4).length,
      //         status5: filterSummarisesByStatus(obsByCategory[category], 5).length,
      //         status7: filterSummarisesByStatus(obsByCategory[category], 7).length
      //       }
      //     }
      //   }, {})
      // )

      const subjectsStatus = categories.reduce((acc, category) => {
        return {
          ...acc,
          [category]: getSubjectsStatus(obsByCategory[category])
        }
      }, {})

      setSubjectsByCategoryAndStatus(
        categories.reduce((acc, category) => {
          return {
            ...acc,
            [category]: {
              total: uniq(
                obsByCategory[category].map((summarise) => summarise.subject_id)
              ).length,
              totalTrue: subjectsStatus[category].length,
              status0: subjectsStatus[category].filter(
                (subject) => subject.status === 0
              ).length,
              status2: subjectsStatus[category].filter(
                (subject) => subject.status === 2
              ).length,
              status3: subjectsStatus[category].filter(
                (subject) => subject.status === 3
              ).length,
              status4: subjectsStatus[category].filter(
                (subject) => subject.status === 4
              ).length,
              status5: subjectsStatus[category].filter(
                (subject) => subject.status === 5
              ).length
            }
          }
        }, {})
      )
    }
  }, [filteredSummarises])

  const TableStripedNumber = ({ children }) => (
    <CustomText
      weight="700"
      color={
        subjectType === 'people'
          ? theme.colors.brand.primary.dark
          : theme.colors.brand.secondary.dark
      }
    >
      {children}
    </CustomText>
  )

  return filteredSummarises && subjectsByCategoryAndStatus ? (
    <>
      <Col>
        <SectionHeadline>Visão Geral</SectionHeadline>
      </Col>

      {categories.map((category) => (
        <Col lg={4} md={6} key={uuidv4()}>
          <OccurrenceCategoryCard
            title={visibleOccurrenceClass[category]}
            totals={{
              totalListed: subjectsByCategoryAndStatus[category].total,
              // total: obsByCategoryAndStatus[category].total,
              totalUnclassified: subjectsByCategoryAndStatus[category].status0
            }}
            status={{
              totals: {
                underAnalysis: subjectsByCategoryAndStatus[category].status2,
                waiting: subjectsByCategoryAndStatus[category].status3,
                committee: subjectsByCategoryAndStatus[category].status4,
                solved: subjectsByCategoryAndStatus[category].status5
              }
            }}
            onClickFn={() => dispatch(setFilters(filtersByCategory[category]))}
            subjectType={subjectType}
            activeTab={activeTab}
          />
        </Col>
      ))}

      <Col>
        <Card title="Evolução de análises">
          <HorizontalScroll mx={-16} override="width: calc(100% + 32px);">
            <TableStriped
              headers={[
                { text: <strong>Categoria</strong> },
                { text: 'Nova identificação', textAlign: 'center' },
                { text: 'Em análise', textAlign: 'center' },
                { text: 'Aguardando retorno', textAlign: 'center' },
                { text: 'Comitê', textAlign: 'center' },
                { text: 'Solucionado', textAlign: 'center' },
                { text: <strong>TOTAL</strong>, textAlign: 'center' }
              ]}
              rows={categories.map((category) => ({
                cells: [
                  { text: visibleOccurrenceClass[category] },
                  {
                    text: (
                      <TableStripedNumber>
                        {subjectsByCategoryAndStatus[category].status0}
                      </TableStripedNumber>
                    ),
                    textAlign: 'center'
                  },
                  {
                    text: (
                      <TableStripedNumber>
                        {subjectsByCategoryAndStatus[category].status2}
                      </TableStripedNumber>
                    ),
                    textAlign: 'center'
                  },
                  {
                    text: (
                      <TableStripedNumber>
                        {subjectsByCategoryAndStatus[category].status3}
                      </TableStripedNumber>
                    ),
                    textAlign: 'center'
                  },
                  {
                    text: (
                      <TableStripedNumber>
                        {subjectsByCategoryAndStatus[category].status4}
                      </TableStripedNumber>
                    ),
                    textAlign: 'center'
                  },
                  {
                    text: (
                      <TableStripedNumber>
                        {subjectsByCategoryAndStatus[category].status5}
                      </TableStripedNumber>
                    ),
                    textAlign: 'center'
                  },
                  {
                    text: (
                      <TableStripedNumber>
                        {subjectsByCategoryAndStatus[category].totalTrue}
                      </TableStripedNumber>
                    ),
                    textAlign: 'center'
                  }
                ]
              }))}
              oddRowBg={
                subjectType === 'people'
                  ? theme.colors.brand.primary.lightness
                  : theme.colors.brand.secondary.lightness
              }
            />
          </HorizontalScroll>
        </Card>

        <SectionHeadline mt="xlarge">Volumetria</SectionHeadline>

        <GraphCard
          headline={'VOLUMETRIA POR CATEGORIA E STATUS'}
          id={'graphCard1'}
          mb="xlarge"
        >
          <GroupBarChart
            data={categories.map((category) => ({
              name: visibleOccurrenceClass[category],
              'Nova identificação':
                subjectsByCategoryAndStatus[category].status0,
              'Em análise': subjectsByCategoryAndStatus[category].status2,
              'Aguardando retorno':
                subjectsByCategoryAndStatus[category].status3,
              Comitê: subjectsByCategoryAndStatus[category].status4,
              Solucionado: subjectsByCategoryAndStatus[category].status5
            }))}
            colors={
              subjectType === 'people'
                ? [
                    theme.colors.brand.primary.light,
                    theme.colors.brand.primary.dark,
                    theme.colors.brand.primary.darkness
                  ]
                : [
                    theme.colors.brand.secondary.lightness,
                    theme.colors.brand.secondary.medium,
                    theme.colors.brand.secondary.darkness
                  ]
            }
            margin={{ top: 24, right: 12, left: -12, bottom: 0 }}
            isResponsive
            barGap={0}
          />
        </GraphCard>
      </Col>
    </>
  ) : (
    <Col>
      <FlexCentered>
        <LoadingSpinner size="40" my="large" />
      </FlexCentered>
    </Col>
  )
}

Subjects.propTypes = {}

export default Subjects
