/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  Button,
  LoadingSpinner,
  Icon
} from '@zeta/ui/src'

/* Helpers */
import * as Yup from 'yup'

import { editBoard } from 'api'

const EditBoardModal = ({ isModalOpen, setIsModalOpen, proceedFn }) => {
  const board = useSelector((state) => state.data.board)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFn = (values) => {
    setIsSubmitting(true)

    editBoard({
      boardId: board.id,
      name: values.name
    }).then((res) => {
      setIsSubmitting(false)
      setIsModalOpen(false)

      proceedFn(res)
    })
  }

  return (
    <Modal
      title={'Editar nome do painel'}
      isVisible={isModalOpen}
      closeFn={() => setIsModalOpen(false)}
    >
      <Formik
        initialValues={{ name: board.name }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('É necessário nomear o painel.')
        })}
        onSubmit={(values) => submitFn(values)}
      >
        {(props) => {
          const { handleSubmit } = props

          return (
            <Form onSubmit={handleSubmit}>
              <ModalContent>
                <Field
                  inputElement={'input'}
                  type={'text'}
                  name={'name'}
                  label={'Nome do painel'}
                  placeholder={'Digite o nome do seu painel'}
                  component={FieldGroup}
                />
              </ModalContent>

              <ModalActions>
                <Button
                  type={'button'}
                  onClick={() => setIsModalOpen(false)}
                  red
                >
                  <Icon icon={'close-outline'} />
                  <span>Cancelar</span>
                </Button>

                <Button type="submit" disabled={isSubmitting}>
                  <Icon icon={'save-outline'} />
                  <span>Salvar alterações</span>
                </Button>

                {isSubmitting && (
                  <LoadingSpinner size={'xlarge'} ml={'small'} />
                )}
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default EditBoardModal
