/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  LoadingSpinner,
  Icon,
  CustomText
} from '@zeta/ui/src'

/* Action Creators */
import { deleteRiskRating } from 'store/reducers/action-creators'

const ExcludeModal = ({ setModal, riskRatingToExclude }) => {
  const dispatch = useDispatch()

  const isDeletingRiskRating = useSelector(state => state.data.isDeletingRiskRating)

  return (
    <Modal
      title='Excluir classificação de risco'
      isVisible={true}
      closeFn={() => setModal(null)}
    >
      <ModalContent>
        <CustomText size='0.875rem'>Confirma a exclusão do risco <strong>{riskRatingToExclude.description}</strong>?</CustomText>
      </ModalContent>

      <ModalActions>
        <Button
          onClick={() => setModal(null)}
          red
        >
          <Icon icon='close-outline' />
          <span>Cancelar</span>
        </Button>

        <Button
          onClick={() => {
            dispatch(
              deleteRiskRating({
                riskRatingId: riskRatingToExclude.id,
                onSuccess: () => setModal(null)
              })
            )
          }}
          isDisabled={isDeletingRiskRating}
        >
          <Icon icon='checkmark-outline' />
          <span>Confirmar</span>
        </Button>

        {isDeletingRiskRating &&
          <LoadingSpinner size='xlarge' ml='small' />}
      </ModalActions>
    </Modal>
  )
}

export default ExcludeModal
