import { connect } from 'react-redux'

import styled from 'styled-components'

const ContentWrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 80px 0 118px 0;

  ${({ withSidebar, theme: { spacing } }) =>
    withSidebar &&
    `
    width: calc(100% - 28px);
    padding: 80px ${spacing.medium} 118px ${spacing.medium};
  `}

  @media (min-width: 768px) {
    ${({ withSidebar }) =>
      withSidebar ? 'width: calc(100% - 48px);' : 'width: 100%;'}
  }

  @media (min-width: 1200px) {
    ${({ withSidebar, isSidebarOpen }) =>
      withSidebar
        ? isSidebarOpen
          ? 'width: calc(100% - 280px);'
          : 'width: calc(100% - 48px);'
        : 'width: 100%;'}
  }
`

export default connect((state) => ({
  isSidebarOpen: state.data.isSidebarOpen
}))(ContentWrapper)
