import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  padding: ${({ theme: { spacing } }) => spacing.medium};
`

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme: { spacing } }) => spacing.medium};
`

export const SubjectName = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: ${({ theme: { spacing } }) => spacing.small};
`

export const ItemWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`
