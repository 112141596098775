/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { Formik, Form, Field } from 'formik'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  CustomText,
  FieldGroup,
  DropdownSelect,
  Button,
  LoadingSpinner
} from '@zeta/ui/src'

/* Constants */
import { visibleOccurrenceType } from 'constants/dictionary'

/* Helpers */
import { logAmplitudeEvent } from '@zeta/helpers'

/* Action Creators */
import { editOccurrenceStatus } from 'store/reducers/action-creators'
import { setOccurrenceToClassify } from 'store/reducers/individual/action-creators'

const getDropdownSelectActiveIndex = (status) => {
  if (status === 7) return 3
  if (status >= 2) return 2
  return status
}

export const EditOccurenceModal = ({ subjectType, subjectId }) => {
  const dispatch = useDispatch()

  const occurrenceToClassify = useSelector(
    (state) => state.individual.occurrenceToClassify
  )
  const isClassifyingOccurrence = useSelector(
    (state) => state.individual.isClassifyingOccurrence
  )

  const [notes, setNotes] = useState(occurrenceToClassify.notes || '')

  return (
    <Modal
      isVisible={occurrenceToClassify}
      closeFn={() => dispatch(setOccurrenceToClassify(null))}
      title="Status da ocorrência"
      bodyWidth={661}
    >
      <Formik initialValues={{ status: occurrenceToClassify.status }}>
        {(props) => {
          const { values, setFieldValue } = props

          return (
            <Form>
              <ModalContent data-testid="editOccurrenceModal">
                <CustomText
                  size="0.75rem"
                  mb="medium"
                  data-testid="occurrenceToClassifyText"
                >
                  Ocorrência a ser classificada:{' '}
                  <strong>{`${
                    visibleOccurrenceType[occurrenceToClassify.occurrenceType]
                  } - ID: ${occurrenceToClassify.id}`}</strong>
                </CustomText>

                <DropdownSelect
                  items={[
                    {
                      text: 'Nova identificação',
                      fn: () => setFieldValue('status', 0)
                    },
                    {
                      text: 'Improcedente',
                      fn: () => setFieldValue('status', 1)
                    },
                    {
                      text: 'Procedente',
                      fn: () => setFieldValue('status', 'procedente')
                    },
                    {
                      text: 'Não elegível',
                      fn: () => setFieldValue('status', 7)
                    }
                  ]}
                  activeIndex={getDropdownSelectActiveIndex(values.status)}
                  label="Essa ocorrência identificada é procedente? *"
                  displayText="Selecione uma opção"
                  mb={'large'}
                  data-testid="statusDropdown"
                />

                {(values.status === 'procedente' ||
                  (values.status >= 2 && values.status !== 7)) && (
                  <span data-testid="actionRadioFields">
                    <Field
                      inputElement="radio"
                      type="radio"
                      name="status"
                      label="Status *"
                      options={[
                        {
                          label: 'Em análise',
                          value: 2
                        },
                        {
                          label: 'Aguardando retorno',
                          value: 3
                        },
                        {
                          label: 'Comitê',
                          value: 4
                        },
                        {
                          label: 'Solucionado',
                          value: 5
                        }
                      ]}
                      component={FieldGroup}
                      data-testid="actionRadioField"
                    />
                  </span>
                )}

                <CustomText size="0.875rem" weight="700" mb="xsmall">
                  Comentário
                </CustomText>
                <ReactQuill
                  theme="snow"
                  value={notes}
                  onChange={setNotes}
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link']
                    ]
                  }}
                />
              </ModalContent>

              <ModalActions>
                <Button
                  onClick={() => dispatch(setOccurrenceToClassify(null))}
                  red
                >
                  <Icon icon="close-outline" />
                  <span>Cancelar</span>
                </Button>

                <Button
                  onClick={() => {
                    dispatch(
                      editOccurrenceStatus({
                        status: Number(values.status),
                        notes,
                        subjectType,
                        subjectId
                      })
                    )

                    logAmplitudeEvent({
                      event: 'Status de ocorrência editado',
                      eventProperties: {
                        Status: Number(values.status)
                      }
                    })
                  }}
                  disabled={
                    isClassifyingOccurrence ||
                    values.status === '' ||
                    values.status === 'procedente'
                  }
                  data-testid="editOccurrenceBtn"
                >
                  <Icon icon="save-outline" />
                  <span>Salvar</span>
                </Button>

                {isClassifyingOccurrence && (
                  <LoadingSpinner size="xlarge" ml="small" />
                )}
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

EditOccurenceModal.propTypes = {}

export default EditOccurenceModal
