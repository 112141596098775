/* React */
import React, { useState } from 'react'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import { Tabs } from '@zeta/ui/src'

import Menu from 'components/Menu'
import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

import ManageRiskRating from './ManageRiskRating'
import ManageDossier from './ManageDossier'

/* Constants */
const MANAGE_RISK_RATING = 'MANAGE_RISK_RATING'
const MANAGE_DOSSIER = 'MANAGE_DOSSIER'

export const ProductConfigurations = () => {
  const [activeTab, setActiveTab] = useState(MANAGE_RISK_RATING)

  return (
    <>
      <Menu />

      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <Tabs
                items={[
                  { text: 'Classificações de risco', onClickFn: () => setActiveTab(MANAGE_RISK_RATING), isActive: activeTab === MANAGE_RISK_RATING },
                  { text: 'Dossiê', onClickFn: () => setActiveTab(MANAGE_DOSSIER), isActive: activeTab === MANAGE_DOSSIER }
                ]}
                override={`
                  @media (min-width: 1200px) {
                    margin-top: 48px;
                    margin-bottom: 64px;
                  }
                  @media (max-width: 1199px) {
                    margin-bottom: 24px;
                  }
                `}
              />

              {activeTab === MANAGE_RISK_RATING && <ManageRiskRating />}

              {activeTab === MANAGE_DOSSIER && <ManageDossier />}
            </Col>
          </Row>
        </Container>

        {/* Footer */}
        <Footer />
      </ContentWrapper >
    </>
  )
}

export default ProductConfigurations
