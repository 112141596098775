import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ first, theme: { spacing } }) =>
    first && `margin-bottom: ${spacing.small}`}
`

export const Input = styled.input`
  width: ${({ theme: { spacing } }) => spacing.largest};
  height: ${({ theme: { spacing } }) => spacing.large};
  line-height: ${({ theme: { spacing } }) => spacing.large};
  font-size: 0.75rem;
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  padding-left: ${({ theme: { spacing } }) => spacing.xsmall};
`

export const Prefix = styled.span`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  margin-right: ${({ theme: { spacing } }) => spacing.small};

  &:last-child {
    margin-right: 0;
    margin-left: ${({ theme: { spacing } }) => spacing.small};
  }
`

export const ItemCheckbox = styled.div`
  width: ${({ theme: { spacing } }) => spacing.medium};
  height: ${({ theme: { spacing } }) => spacing.medium};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
`

export const ItemLabel = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  width: calc(100% - 24px);
  margin-left: ${({ theme: { spacing } }) => spacing.small};
`

export const Item = styled.button`
  display: flex;
  align-items: center;
  outline: 0;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.small};
  }

  ${ItemCheckbox} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ selected, theme: { colors } }) =>
    selected &&
    `
    ${ItemCheckbox} {
      background-color: ${colors.brand.primary.dark};
      border-color: ${colors.brand.primary.dark};

      svg {
        fill: ${colors.surface.secondary.lightness};
      }
    }

    ${ItemLabel} {
      color: ${colors.brand.tertiary.dark};
    }
  `}
`
