/* React */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  FlexCentered,
  CustomText,
  Button,
  LoadingSpinner,
  Tabs,
  HeadlineCard,
  Icon
} from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

import BoardCard from './BoardCard'

/* Parts */
import Menu from 'components/Menu'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { logAmplitudeEvent, formatDate } from '@zeta/helpers'
import { getBoardsList } from 'api'

export const BoardsList = ({
  /* router */
  history
}) => {
  const [activeStatusTab, setActiveStatusTab] = useState(1)

  const [boardsList, setBoardsList] = useState(null)
  const [isLoadingBoards, setIsLoadingBoards] = useState(false)

  useEffect(() => {
    let mounted = true

    /* boards */
    setIsLoadingBoards(true)

    getBoardsList().then((res) => {
      if (mounted) {
        setIsLoadingBoards(false)
        setBoardsList(res.data.reverse())
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  const hasBoard = () => !isLoadingBoards && boardsList && boardsList.length > 0

  return (
    <>
      <Menu />

      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <Tabs
                items={[
                  {
                    text: 'Visão geral',
                    onClickFn: () => {},
                    isActive: true
                  }
                ]}
                mb={'large'}
                override={`
                  @media (min-width: 1200px) {
                    margin-top: 48px;
                  }
                `}
              />

              <HeadlineCard
                headline="Painéis"
                text={
                  <>
                    Os painéis são classificados em{' '}
                    <strong>Em Investigação</strong> e{' '}
                    <strong>Finalizados</strong>
                  </>
                }
                icon="clipboard-outline"
                mb="xlarge"
              >
                <Button
                  type={'button'}
                  onClick={() => {
                    history.push('/dataset/people/dashboard')

                    logAmplitudeEvent({
                      event: '[Paineis] Criar painel',
                      eventProperties: { Local: 'Página "Paineis"' }
                    })
                  }}
                >
                  <Icon icon="plus-circle-outline" />
                  <span>Novo painel</span>
                </Button>
              </HeadlineCard>

              {isLoadingBoards && (
                <FlexCentered>
                  <LoadingSpinner size={'40'} />
                </FlexCentered>
              )}

              {!isLoadingBoards && boardsList && boardsList.length === 0 && (
                <CustomText size="0.875rem" mb="xlarge">
                  Nenhum painel encontrado.
                </CustomText>
              )}

              {hasBoard() && (
                <Tabs
                  items={[
                    {
                      text: 'Em investigação',
                      onClickFn: () => {
                        setActiveStatusTab(1)
                      },
                      isActive: activeStatusTab === 1,
                      count:
                        boardsList.filter(
                          (board) =>
                            !board.config_body.status ||
                            board.config_body.status === 1
                        ).length || false
                    },
                    {
                      text: 'Finalizados',
                      onClickFn: () => {
                        setActiveStatusTab(2)
                      },
                      isActive: activeStatusTab === 2,
                      count:
                        boardsList.filter(
                          (board) => board.config_body.status === 2
                        ).length || false
                    }
                  ]}
                  mb={'large'}
                />
              )}

              <Row>
                {hasBoard() &&
                  boardsList
                    .filter((board) =>
                      activeStatusTab === 1
                        ? !board.config_body.status ||
                          board.config_body.status === 1
                        : board.config_body.status === 2
                    )
                    .map((board) => (
                      <Col lg={4} md={6} key={uuidv4()}>
                        <BoardCard
                          status={board.config_body.status || 1}
                          id={board.id}
                          name={board.name}
                          createdAt={formatDate(board.created_at)}
                          owner={board.user.email}
                          onClick={() =>
                            history.push(`/painel/${board.id}/people/dashboard`)
                          }
                          mb="large"
                        />
                      </Col>
                    ))}
              </Row>

              {hasBoard() &&
                ((activeStatusTab === 1 &&
                  boardsList.filter(
                    (board) =>
                      !board.config_body.status ||
                      board.config_body.status === 1
                  ).length === 0) ||
                  (activeStatusTab === 2 &&
                    boardsList.filter((board) => board.config_body.status === 2)
                      .length === 0)) && (
                  <CustomText size="0.875rem">
                    Nenhum painel encontrado.
                  </CustomText>
                )}
            </Col>
          </Row>
        </Container>

        {/* Footer */}
        <Footer />
      </ContentWrapper>
    </>
  )
}

export default withRouter(BoardsList)
