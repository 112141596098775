export const categories = [
  'beneficio_social',
  'midia',
  'relacao_societaria',
  'financeiro',
  'publico_exposta',
  'lista_restritiva',
  'processos'
]

export const filtersByCategory = {
  'beneficio_social': {
    occurrence_class: ['beneficio_social']
  },
  'midia': {
    occurrence_class: ['midia']
  },
  'relacao_societaria': {
    occurrence_class: ['relacao_societaria']
  },
  'financeiro': {
    occurrence_class: ['financeiro']
  },
  'publico_exposta': {
    occurrence_class: ['publico_exposta']
  },
  'lista_restritiva': {
    occurrence_class: ['lista_restritiva']
  },
  'processos': {
    processes_involvement: ['AUTHOR', 'DEFENDANT']
  }
}

export const states = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
  'EX',
  null
]
