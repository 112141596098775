/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  FlexSpaceBetween,
  SVGLogoWhite,
  IconWrapper,
  Icon,
  DropdownBtns,
  theme
} from '@zeta/ui/src'

import LinkWithSaveBoard from 'components/routeHelpers/LinkWithSaveBoard'

/* Styled */
import {
  Wrapper,
  InnerWrapper,
  LogoWrapper,
  NavWrapper,
  Nav,
  NavItem,
  NavWrapperDivider,
  UserNav,
  Username,
  DropdownBtn,
  DropdownBtnSpan
} from './styled'

/* Constants */
import { paths as userPaths } from '@zeta/user/src/constants/config'
import { paths } from 'constants/config'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { getSubjectType } from 'helpers'

const Menu = ({
  /* router */
  history,
  computedMatch,
  /* props */
  withSidebar,
  withBoardWrapperLinks
}) => {
  const userEmail = useSelector((state) => state.user.email)
  const isSidebarOpen = useSelector((state) => state.data.isSidebarOpen)

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const subjectType = withBoardWrapperLinks && getSubjectType(computedMatch.url)

  return (
    <Wrapper>
      <InnerWrapper withSidebar={withSidebar} isSidebarOpen={isSidebarOpen}>
        <Container>
          <Row>
            <Col>
              <FlexSpaceBetween>
                <LogoWrapper>
                  <SVGLogoWhite />
                </LogoWrapper>

                <NavWrapper>
                  <Nav>
                    <LinkWithSaveBoard component={NavItem} to={'/paineis'}>
                      <p>Painéis</p>
                    </LinkWithSaveBoard>

                    {withBoardWrapperLinks && (
                      <>
                        <NavItem
                          onClick={() =>
                            history.push(
                              `${getPathPrefix(
                                computedMatch
                              )}/${subjectType}/dashboard`
                            )
                          }
                        >
                          <p>Análise de risco</p>
                        </NavItem>

                        <NavItem
                          onClick={() =>
                            history.push(
                              `${getPathPrefix(
                                computedMatch
                              )}/${subjectType}/individual`
                            )
                          }
                        >
                          <p>Listagem</p>
                        </NavItem>
                      </>
                    )}

                    {/* {!history.location.pathname.includes('/dataset/') &&
                      <LinkWithSaveBoard component={NavItem} to={'/dataset/people/dashboard'}>
                        <p>Novo Painel</p>
                      </LinkWithSaveBoard>} */}
                  </Nav>

                  <NavWrapperDivider />

                  <UserNav>
                    <Username>{userEmail}</Username>

                    <IconWrapper
                      size="xlarge"
                      bg={theme.colors.surface.secondary.lightness}
                      ml="small"
                      mr="xsmall"
                    >
                      <Icon
                        icon="person-outline"
                        size="medium"
                        fill={theme.colors.brand.tertiary.medium}
                      />
                    </IconWrapper>

                    <button
                      type="button"
                      onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                    >
                      {isDropdownVisible ? (
                        <Icon
                          icon="arrow-ios-upward-outline"
                          size="medium"
                          fill={theme.colors.surface.secondary.lightness}
                        />
                      ) : (
                        <Icon
                          icon="arrow-ios-downward-outline"
                          size="medium"
                          fill={theme.colors.surface.secondary.lightness}
                        />
                      )}
                    </button>

                    {isDropdownVisible && (
                      <DropdownBtns
                        items={[
                          {
                            text: (
                              <LinkWithSaveBoard
                                component={DropdownBtn}
                                to={userPaths.userConfigurations}
                              >
                                <Icon
                                  icon="person-outline"
                                  size="medium"
                                  mr="xsmall"
                                />
                                <DropdownBtnSpan>
                                  Configurações de Usuário
                                </DropdownBtnSpan>
                              </LinkWithSaveBoard>
                            ),
                            asDiv: true
                          },
                          {
                            text: (
                              <LinkWithSaveBoard
                                component={DropdownBtn}
                                to={paths.productConfigurations}
                              >
                                <Icon
                                  icon="settings-outline"
                                  size="medium"
                                  mr="xsmall"
                                />
                                <DropdownBtnSpan>
                                  Configurações da Plataforma
                                </DropdownBtnSpan>
                              </LinkWithSaveBoard>
                            ),
                            asDiv: true
                          },
                          {
                            text: (
                              <LinkWithSaveBoard
                                component={DropdownBtn}
                                to={userPaths.signOut}
                              >
                                <Icon
                                  icon="log-out-outline"
                                  size="medium"
                                  mr="xsmall"
                                />
                                <DropdownBtnSpan>Sair</DropdownBtnSpan>
                              </LinkWithSaveBoard>
                            ),
                            asDiv: true
                          }
                        ]}
                        secondary
                        override="right: 0; top: 100%; width: 100%;"
                      />
                    )}
                  </UserNav>
                </NavWrapper>
              </FlexSpaceBetween>
            </Col>
          </Row>
        </Container>
      </InnerWrapper>
    </Wrapper>
  )
}

export default withRouter(Menu)
