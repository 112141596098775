/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import { Col } from 'styled-bootstrap-grid'

import {
  FlexAlignCenter,
  FlexCentered,
  LoadingSpinner,
  CustomText,
  CustomBlock,
  CustomScrollbars,
  theme
} from '@zeta/ui/src'

import OccurrenceCard, {
  InnerCard,
  InnerCardRow,
  InnerCardHeadline,
  InnerCardColumnWrapper,
  InnerCardColumn,
  InnerCardTable,
  InnerCardLink,
  OccurrenceCardContentModal,
  PopUpIcon,
  WarningTag
} from '../OccurrenceCard'

import HotCnaesIcon from 'img/cnae-hot.png'

/* Helpers */
import { uniq } from 'ramda'
import { getOccurrenceCnaes, getOccurrenceSubsidiaries } from 'api'
import { toCurrency } from 'helpers'

const CnaeOccurrenceCard = ({ occurrence, subjectName, subjectThumbnail }) => {
  return (
    <OccurrenceCard
      occurrence={occurrence}
      title={occurrence.content.Razao_social}
      subtitle={`CNPJ: ${occurrence.content.CNPJ}`}
      contentAfterStatus={() => (
        <FlexAlignCenter override="flex-wrap: wrap;">
          {occurrence.content.Classificacao === 'H' && (
            <WarningTag text="CNAE" icon={() => <img src={HotCnaesIcon} />} />
          )}

          {occurrence.content.Similaridade_endereco === '1' && (
            <WarningTag text="Endereço Similar" />
          )}

          {occurrence.content.Similaridade_Cnae === '1' && (
            <WarningTag text="CNAE Similar" />
          )}
        </FlexAlignCenter>
      )}
    >
      <Content
        occurrence={occurrence}
        subjectName={subjectName}
        subjectThumbnail={subjectThumbnail}
      />
    </OccurrenceCard>
  )
}

const Content = ({ occurrence, subjectName, subjectThumbnail }) => {
  const [modal, setModal] = useState(null)
  const [activeView, setActiveView] = useState({ view: 'MAIN' })
  const [activeCnaesView, setActiveCnaesView] = useState('HOT')
  const [aditionalData, setAditionalData] = useState(null)

  useEffect(() => {
    Promise.all([
      getOccurrenceCnaes({ occurrenceId: occurrence.id }),
      getOccurrenceSubsidiaries({ occurrenceId: occurrence.id })
    ]).then((res) => {
      setAditionalData({
        cnaes: res[0].data,
        subsidiaries: res[1].data
      })
    })
  }, [])

  const getSubsidiary = (subsidiaryId) =>
    aditionalData.subsidiaries.find(
      (subsidiary) => subsidiary.id === subsidiaryId
    )

  const getModalContent = () => {
    if (modal) {
      if (modal.subType === 'MATRIZ') {
        const { content } = occurrence

        switch (modal.type) {
          case 'ADDRESS':
            return (
              <>
                <InnerCardHeadline>Endereço</InnerCardHeadline>
                <CustomText size="0.75rem" mt="xsmall">
                  {(
                    content.Descricao_Tipo_Logradouro +
                    ' ' +
                    content.Logradouro +
                    ', ' +
                    Number(content.Numero).toFixed(0) +
                    ', ' +
                    content.Complemento +
                    ', ' +
                    content.Bairro +
                    ', ' +
                    content.Municipio +
                    ', ' +
                    content.UF +
                    ', ' +
                    content.Cep
                  )
                    .replace(/, null/g, '')
                    .replace(/null/g, '')
                    .trim()}
                </CustomText>
              </>
            )

          case 'PHONE':
            return (
              <>
                {content.ddd_telefone_1 && (
                  <>
                    <InnerCardHeadline>Telefone 1</InnerCardHeadline>
                    <CustomText size="0.875rem">
                      {content.ddd_telefone_1}
                    </CustomText>
                  </>
                )}

                {content.ddd_telefone_2 && (
                  <>
                    <InnerCardHeadline mt="small">Telefone 2</InnerCardHeadline>
                    <CustomText size="0.875rem">
                      {content.ddd_telefone_2}
                    </CustomText>
                  </>
                )}
              </>
            )
        }
      }

      if (modal.subType === 'FILIAL') {
        const { content } = getSubsidiary(activeView.filialId)

        switch (modal.type) {
          case 'ADDRESS':
            return (
              <>
                <InnerCardHeadline>Endereço</InnerCardHeadline>
                <CustomText size="0.75rem" mt="xsmall">
                  {(
                    content.Descricao_Tipo_Logradouro +
                    ' ' +
                    content.Logradouro +
                    ', ' +
                    Number(content.Numero).toFixed(0) +
                    ', ' +
                    content.Complemento +
                    ', ' +
                    content.Bairro +
                    ', ' +
                    content.Municipio +
                    ', ' +
                    content.UF +
                    ', ' +
                    content.Cep
                  )
                    .replace(/, null/g, '')
                    .replace(/null/g, '')
                    .trim()}
                </CustomText>
              </>
            )

          case 'PHONE':
            return (
              <>
                {content.ddd_telefone_1 && (
                  <>
                    <InnerCardHeadline>Telefone 1</InnerCardHeadline>
                    <CustomText size="0.875rem">
                      {content.ddd_telefone_1}
                    </CustomText>
                  </>
                )}

                {content.ddd_telefone_2 && (
                  <>
                    <InnerCardHeadline mt="small">Telefone 2</InnerCardHeadline>
                    <CustomText size="0.875rem">
                      {content.ddd_telefone_2}
                    </CustomText>
                  </>
                )}
              </>
            )
        }
      }
    }
  }

  return aditionalData ? (
    <>
      {activeView.view === 'MAIN' && (
        <>
          <InnerCardRow>
            <Col>
              <InnerCard
                title="CNAE's"
                onClickMore={() => setActiveView({ view: 'CNAES' })}
                moreText="ver mais"
              >
                <InnerCardColumnWrapper>
                  <InnerCardColumn>
                    <InnerCardHeadline override="color: #F4753D;" mb="xsmall">
                      HOT
                    </InnerCardHeadline>
                    <CustomText weight="700">
                      {
                        uniq(
                          aditionalData.cnaes
                            .filter((cnae) => cnae.warning)
                            .map((cnae) => cnae.code_cnae)
                        ).length
                      }
                    </CustomText>
                  </InnerCardColumn>

                  <InnerCardColumn>
                    <InnerCardHeadline mb="xsmall">COLD</InnerCardHeadline>
                    <CustomText weight="700">
                      {
                        uniq(
                          aditionalData.cnaes
                            .filter((cnae) => !cnae.warning)
                            .map((cnae) => cnae.code_cnae)
                        ).length
                      }
                    </CustomText>
                  </InnerCardColumn>
                </InnerCardColumnWrapper>
              </InnerCard>
            </Col>
          </InnerCardRow>

          <InnerCardRow>
            <Col>
              <InnerCard
                title="Outras Informações (Matriz)"
                headerContent={() => (
                  <FlexAlignCenter>
                    {occurrence.content.Logradouro && (
                      <PopUpIcon
                        icon="pin-outline"
                        onClickFn={() =>
                          setModal({
                            title: 'Endereços',
                            type: 'ADDRESS',
                            subType: 'MATRIZ'
                          })
                        }
                      />
                    )}

                    {(occurrence.content.ddd_telefone_1 ||
                      occurrence.content.ddd_telefone_2) && (
                      <PopUpIcon
                        icon="phone-outline"
                        onClickFn={() =>
                          setModal({
                            title: 'Telefones',
                            type: 'PHONE',
                            subType: 'MATRIZ'
                          })
                        }
                      />
                    )}

                    {/* <PopUpIcon icon='smartphone-outline' onClickFn={() => setModal(true)} />
                      <PopUpIcon icon='email-outline' onClickFn={() => setModal(true)} /> */}
                  </FlexAlignCenter>
                )}
                mt="xlarge"
              >
                <InnerCardRow>
                  <Col sm={4}>
                    <InnerCardHeadline>SITUAÇÃO CADASTRAL</InnerCardHeadline>
                    <CustomText size="0.75rem" weight="700" mb="small">
                      {occurrence.content.Situacao_Cadastral}
                    </CustomText>
                  </Col>

                  <Col sm={4}>
                    <InnerCardHeadline>PORTE</InnerCardHeadline>
                    <CustomText size="0.75rem" weight="700" mb="small">
                      {occurrence.content.Porte_Empresa}
                    </CustomText>
                  </Col>

                  <Col sm={4}>
                    <InnerCardHeadline>DATA ABERTURA</InnerCardHeadline>
                    <CustomText size="0.75rem" weight="700" mb="small">
                      {occurrence.content.Data_Abertura}
                    </CustomText>
                  </Col>
                </InnerCardRow>

                <InnerCardRow>
                  <Col sm={4}>
                    <InnerCardHeadline>CAPITAL SOCIAL</InnerCardHeadline>
                    <CustomText size="0.75rem" weight="700" mb="small">
                      {toCurrency(occurrence.content.Capital_Social)}
                    </CustomText>
                  </Col>
                </InnerCardRow>
              </InnerCard>
            </Col>
          </InnerCardRow>

          {/* <InnerCardRow>
              <Col sm={6}>
                <InnerCard
                  title='Quadro Societário'
                  onClickMore={() => setActiveView({ view: 'QUADRO_SOCIETARIO' })}
                  moreText='ver todos'
                  override='height: 250px;'
                  mt='xlarge'
                >
                  <Scrollbars
                    style={{ height: '170px', width: 'calc(100% + 16px)' }}
                    renderView={props => <div style={{ paddingRight: '16px', ...props.style }} />}
                  >
                    <InnerCardTable
                      headers={[
                        <InnerCardHeadline>NOME</InnerCardHeadline>,
                        <InnerCardHeadline>TIPO</InnerCardHeadline>
                      ]}
                      rows={[
                        [
                          <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                          <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                        ],
                        [
                          <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                          <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                        ],
                        [
                          <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                          <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                        ],
                        [
                          <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                          <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                        ],
                        [
                          <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                          <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                        ],
                        [
                          <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                          <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                        ],
                        [
                          <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                          <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                        ]
                      ]}
                    />
                  </Scrollbars>
                </InnerCard>
              </Col> */}

          <InnerCardRow>
            <Col sm={6}>
              <InnerCard
                title="Sociedade"
                override="height: 250px;"
                mt="xlarge"
              >
                <FlexCentered
                  override={`
                      height: 170px;
                      flex-direction: column;
                      text-align: center;

                      > img {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                      }
                    `}
                >
                  <img src={subjectThumbnail} />
                  <CustomText size="0.875rem" mt="medium" mb="xsmall">
                    {subjectName}
                  </CustomText>
                  <CustomText size="0.75rem">
                    {occurrence.content.Qualificacao_Socio}
                  </CustomText>
                </FlexCentered>
              </InnerCard>
            </Col>

            <Col sm={6}>
              <InnerCard
                title="Filiais"
                onClickMore={
                  aditionalData.subsidiaries.length > 0
                    ? () => setActiveView({ view: 'FILIAIS' })
                    : null
                }
                moreText="ver todos"
                override="height: 250px;"
                mt="xlarge"
              >
                <CustomBlock override="text-align: center;" mb="large">
                  <InnerCardHeadline mb="xsmall">TOTAL</InnerCardHeadline>
                  <CustomText weight="700">
                    {aditionalData.subsidiaries.length}
                  </CustomText>
                </CustomBlock>

                {aditionalData.subsidiaries.length > 0 && (
                  <CustomScrollbars
                    style={{ height: '113px', width: 'calc(100% + 16px)' }}
                    renderViewStyle={{
                      paddingRight: '12px',
                      paddingBottom: '12px'
                    }}
                  >
                    {aditionalData.subsidiaries.map((subsidiary) => (
                      <InnerCardLink
                        onClickFn={() =>
                          setActiveView({
                            view: 'FILIAL',
                            filialId: subsidiary.id
                          })
                        }
                        override="display: block;"
                        mb="small"
                      >
                        {subsidiary.content.Razao_social},{' '}
                        {subsidiary.content.Municipio}, {subsidiary.content.UF}
                      </InnerCardLink>
                    ))}
                  </CustomScrollbars>
                )}
              </InnerCard>
            </Col>
          </InnerCardRow>
        </>
      )}

      {activeView.view === 'CNAES' && (
        <InnerCardRow>
          <Col>
            <InnerCard
              tabs={[
                {
                  text: (
                    <FlexAlignCenter
                      override={`
                          > img {
                            width: 12px;
                            margin-left: ${theme.spacing.xsmall};
                            transform: translateY(-1px);
                          }
                        `}
                    >
                      <span>CNAE's</span>
                      <img src={HotCnaesIcon} />
                    </FlexAlignCenter>
                  ),
                  onClickFn: () => setActiveCnaesView('HOT'),
                  isActive: activeCnaesView === 'HOT'
                },
                {
                  text: (
                    <FlexAlignCenter>
                      <span>CNAE's Cold</span>
                    </FlexAlignCenter>
                  ),
                  onClickFn: () => setActiveCnaesView('COLD'),
                  isActive: activeCnaesView === 'COLD'
                }
              ]}
              onClickBack={() => setActiveView({ view: 'MAIN' })}
            >
              {/* HOT */}
              {activeCnaesView === 'HOT' &&
                uniq(
                  aditionalData.cnaes
                    .filter((cnae) => cnae.warning)
                    .map((cnae) => ({
                      code_cnae: cnae.code_cnae,
                      description: cnae.description
                    }))
                ).map((cnae) => (
                  <>
                    <CustomText
                      size="0.875rem"
                      weight="700"
                      mt="large"
                      mb="xsmall"
                    >
                      {cnae.code_cnae}
                    </CustomText>
                    <CustomText size="0.75rem" mb="xsmall">
                      {cnae.description}
                    </CustomText>

                    {aditionalData.cnaes
                      .filter(
                        (allCnaes) => allCnaes.code_cnae === cnae.code_cnae
                      )
                      .map((cnae) => (
                        <InnerCardLink
                          onClickFn={() =>
                            !cnae.subsidiary_id
                              ? setActiveView({ view: 'MAIN' })
                              : setActiveView({
                                  view: 'FILIAL',
                                  filialId: cnae.subsidiary_id
                                })
                          }
                        >
                          {!cnae.subsidiary_id
                            ? `${occurrence.content.Razao_social} (Matriz)`
                            : `${
                                aditionalData.subsidiaries.find(
                                  (subsidiary) =>
                                    subsidiary.id === cnae.subsidiary_id
                                ).content.Razao_social
                              }, ${
                                aditionalData.subsidiaries.find(
                                  (subsidiary) =>
                                    subsidiary.id === cnae.subsidiary_id
                                ).content.Municipio
                              } - ${
                                aditionalData.subsidiaries.find(
                                  (subsidiary) =>
                                    subsidiary.id === cnae.subsidiary_id
                                ).content.UF
                              }`}
                        </InnerCardLink>
                      ))}
                  </>
                ))}

              {/* COLD */}
              {activeCnaesView === 'COLD' &&
                uniq(
                  aditionalData.cnaes
                    .filter((cnae) => !cnae.warning)
                    .map((cnae) => ({
                      code_cnae: cnae.code_cnae,
                      description: cnae.description
                    }))
                ).map((cnae) => (
                  <>
                    <CustomText
                      size="0.875rem"
                      weight="700"
                      mt="large"
                      mb="xsmall"
                    >
                      {cnae.code_cnae}
                    </CustomText>
                    <CustomText size="0.75rem" mb="xsmall">
                      {cnae.description}
                    </CustomText>

                    {aditionalData.cnaes
                      .filter(
                        (allCnaes) => allCnaes.code_cnae === cnae.code_cnae
                      )
                      .map((cnae) => (
                        <InnerCardLink
                          onClickFn={() =>
                            !cnae.subsidiary_id
                              ? setActiveView({ view: 'MAIN' })
                              : setActiveView({
                                  view: 'FILIAL',
                                  filialId: cnae.subsidiary_id
                                })
                          }
                        >
                          {!cnae.subsidiary_id
                            ? `${occurrence.content.Razao_social} (Matriz)`
                            : `${
                                aditionalData.subsidiaries.find(
                                  (subsidiary) =>
                                    subsidiary.id === cnae.subsidiary_id
                                ).content.Razao_social
                              }, ${
                                aditionalData.subsidiaries.find(
                                  (subsidiary) =>
                                    subsidiary.id === cnae.subsidiary_id
                                ).content.Municipio
                              } - ${
                                aditionalData.subsidiaries.find(
                                  (subsidiary) =>
                                    subsidiary.id === cnae.subsidiary_id
                                ).content.UF
                              }`}
                        </InnerCardLink>
                      ))}
                  </>
                ))}
            </InnerCard>
          </Col>
        </InnerCardRow>
      )}

      {/* {activeView.view === 'QUADRO_SOCIETARIO' &&
          <InnerCardRow>
            <Col>
              <InnerCard
                title='Quadro Societário'
                onClickBack={() => setActiveView({ view: 'MAIN' })}
              >
                <InnerCardTable
                  headers={[
                    <InnerCardHeadline>NOME</InnerCardHeadline>,
                    <InnerCardHeadline>TIPO</InnerCardHeadline>
                  ]}
                  rows={[
                    [
                      <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                      <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                    ],
                    [
                      <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                      <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                    ],
                    [
                      <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                      <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                    ],
                    [
                      <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                      <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                    ],
                    [
                      <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                      <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                    ],
                    [
                      <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                      <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                    ],
                    [
                      <ProfileCell image={defaultAvatar} name='Luis Augusto Luis Augusto Luis Augusto' onClickFn={() => {}} />,
                      <CustomText size='0.625rem'>Sócio Administrador</CustomText>
                    ]
                  ]}
                />
              </InnerCard>
            </Col>
          </InnerCardRow>} */}

      {activeView.view === 'FILIAIS' && (
        <InnerCardRow>
          <Col>
            <InnerCard
              title="Filiais"
              onClickBack={() => setActiveView({ view: 'MAIN' })}
            >
              <InnerCardTable
                headers={[
                  <InnerCardHeadline>RAZÃO SOCIAL</InnerCardHeadline>,
                  <InnerCardHeadline>CIDADE / ESTADO</InnerCardHeadline>
                ]}
                rows={aditionalData.subsidiaries.map((subsidiary) => [
                  <InnerCardLink
                    onClickFn={() =>
                      setActiveView({ view: 'FILIAL', filialId: subsidiary.id })
                    }
                  >
                    {subsidiary.content.Razao_social}
                  </InnerCardLink>,
                  <CustomText size="0.625rem">
                    {subsidiary.content.Municipio}, {subsidiary.content.UF}
                  </CustomText>
                ])}
              />
            </InnerCard>
          </Col>
        </InnerCardRow>
      )}

      {activeView.view === 'FILIAL' && (
        <InnerCardRow>
          <Col>
            <InnerCard
              title="Filial"
              onClickBack={() => setActiveView({ view: 'MAIN' })}
              onClickMore={() => setActiveView({ view: 'FILIAIS' })}
              moreText="Lista de filiais"
              disableMoreArrow
            >
              <CustomText
                size="0.875rem"
                weight="700"
                color={theme.colors.brand.primary.medium}
                mb="small"
              >
                {getSubsidiary(activeView.filialId).content.Razao_social},{' '}
                {getSubsidiary(activeView.filialId).content.Municipio},{' '}
                {getSubsidiary(activeView.filialId).content.UF}
              </CustomText>
              <CustomText size="0.75rem" mb="xlarge">
                CNPJ: {getSubsidiary(activeView.filialId).content.CNPJ}
              </CustomText>

              <InnerCardRow>
                <Col sm={4}>
                  <InnerCardHeadline>SITUAÇÃO CADASTRAL</InnerCardHeadline>
                  <CustomText size="0.75rem" weight="700" mb="small">
                    {
                      getSubsidiary(activeView.filialId).content
                        .Situacao_Cadastral
                    }
                  </CustomText>
                </Col>

                <Col sm={4}>
                  <InnerCardHeadline>PORTE</InnerCardHeadline>
                  <CustomText size="0.75rem" weight="700" mb="small">
                    {getSubsidiary(activeView.filialId).content.Porte_Empresa}
                  </CustomText>
                </Col>

                <Col sm={4}>
                  <InnerCardHeadline>DATA ABERTURA</InnerCardHeadline>
                  <CustomText size="0.75rem" weight="700" mb="small">
                    {getSubsidiary(activeView.filialId).content.Data_Abertura}
                  </CustomText>
                </Col>
              </InnerCardRow>

              <InnerCardRow>
                <Col sm={4}>
                  <InnerCardHeadline>CAPITAL SOCIAL</InnerCardHeadline>
                  <CustomText size="0.75rem" weight="700" mb="small">
                    {getSubsidiary(activeView.filialId).content.Capital_Social}
                  </CustomText>
                </Col>
              </InnerCardRow>

              <FlexAlignCenter mt="large">
                {getSubsidiary(activeView.filialId).content.Logradouro && (
                  <PopUpIcon
                    icon="pin-outline"
                    onClickFn={() =>
                      setModal({
                        title: 'Endereços',
                        type: 'ADDRESS',
                        subType: 'FILIAL',
                        filialId: activeView.filialId
                      })
                    }
                  />
                )}

                {(getSubsidiary(activeView.filialId).content.ddd_telefone_1 ||
                  getSubsidiary(activeView.filialId).content
                    .ddd_telefone_2) && (
                  <PopUpIcon
                    icon="phone-outline"
                    onClickFn={() =>
                      setModal({
                        title: 'Telefones',
                        type: 'PHONE',
                        subType: 'FILIAL',
                        filialId: activeView.filialId
                      })
                    }
                  />
                )}

                {/* <PopUpIcon icon='smartphone-outline' onClickFn={() => setModal(true)} />
                  <PopUpIcon icon='email-outline' onClickFn={() => setModal(true)} /> */}
              </FlexAlignCenter>
            </InnerCard>
          </Col>
        </InnerCardRow>
      )}

      <OccurrenceCardContentModal
        title={modal && modal.title}
        isOpen={modal}
        closeFn={() => setModal(null)}
      >
        {getModalContent()}
      </OccurrenceCardContentModal>
    </>
  ) : (
    <FlexCentered override="width: 100%;">
      <LoadingSpinner size="xlarge" />
    </FlexCentered>
  )
}

export default CnaeOccurrenceCard
