/* Constants */
import {
  SUBJECT_REQUESTED,
  SUBJECT_SUCCEEDED,
  POSTURE_REQUESTED,
  POSTURE_SUCCEEDED,
  SET_OCCURRENCES_BY_CLASS,
  SET_OCCURRENCE_TO_CLASSIFY,
  TOGGLE_OPENED_OCCURRENCE,
  SET_VIEW_FILTERED_PUBLICATIONS,
  RESET_INDIVIDUAL
} from './action-types'
import types from 'store/reducers/action-types'

export const initialState = {
  individual: null,
  occurrencesByClass: null,
  isLoadingIndividual: false,
  posture: null,
  isLoadingPosture: false,
  isEditingSubjectRiskRating: false,
  occurrenceToClassify: null,
  isClassifyingOccurrence: false,
  openedOccurrences: {
    registrationInfo: true
  },
  viewFilteredPublications: true,
  isEditingSituation: false,
  observations: null,
  isCreatingObservation: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBJECT_REQUESTED:
      return {
        ...state,
        isLoadingIndividual: true
      }

    case SUBJECT_SUCCEEDED:
      return {
        ...state,
        individual: action.individual,
        occurrencesByClass: action.occurrencesByClass,
        isLoadingIndividual: false,
        openedOccurrences: {
          ...state.openedOccurrences,
          ...action.openedOccurrences
        },
        posture: action.posture,
        observations: action.observations
      }

    case SET_OCCURRENCES_BY_CLASS:
      return {
        ...state,
        occurrencesByClass: action.occurrencesByClass
      }

    case SET_OCCURRENCE_TO_CLASSIFY:
      return {
        ...state,
        occurrenceToClassify: action.occurrenceToClassify
      }

    case types.EDIT_OCCURRENCE_REQUESTED:
      return {
        ...state,
        isClassifyingOccurrence: true
      }

    case types.EDIT_OCCURRENCE_SUCCEEDED:
      return {
        ...state,
        isClassifyingOccurrence: false,
        occurrenceToClassify: null,
        occurrencesByClass: action.occurrencesByClass
      }

    case POSTURE_REQUESTED:
      return {
        ...state,
        isLoadingPosture: true
      }

    case POSTURE_SUCCEEDED:
      return {
        ...state,
        posture: action.payload,
        isLoadingPosture: false
      }

    case types.EDIT_POSTURE_REQUESTED:
      return {
        ...state,
        isLoadingPosture: true
      }

    case types.EDIT_POSTURE_SUCCEEDED:
      return {
        ...state,
        posture: action.posture,
        isLoadingPosture: false
      }

    case types.EDIT_SUBJECT_RISK_RATING_REQ:
      return {
        ...state,
        isEditingSubjectRiskRating: true
      }

    case types.EDIT_SUBJECT_RISK_RATING_SUC:
      return {
        ...state,
        posture: action.posture,
        isEditingSubjectRiskRating: false
      }

    case TOGGLE_OPENED_OCCURRENCE:
      return {
        ...state,
        openedOccurrences: {
          ...state.openedOccurrences,
          [action.occurrenceId]: !state.openedOccurrences[action.occurrenceId]
        }
      }

    case SET_VIEW_FILTERED_PUBLICATIONS:
      return {
        ...state,
        viewFilteredPublications: action.bool
      }

    case types.TOGGLE_SUBJECT_SITUATION_REQUESTED:
      return {
        ...state,
        isEditingSituation: true
      }

    case types.TOGGLE_SUBJECT_SITUATION_SUCCEEDED:
      return {
        ...state,
        isEditingSituation: false,
        individual: action.individual
      }

    case types.CREATE_SUBJECT_OBSERVATION_REQUESTED:
      return {
        ...state,
        isCreatingObservation: true
      }

    case types.CREATE_SUBJECT_OBSERVATION_SUCCEEDED:
      return {
        ...state,
        isCreatingObservation: false,
        observations: action.observations
      }

    case RESET_INDIVIDUAL:
      return initialState

    default:
      return state
  }
}
