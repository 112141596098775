import { uniq } from 'ramda'

const helpers = {
  /**
   *
   * Filtra os subjects a serem listados a partir do searchQuery (filtra por name ou document)
   * @param {Array[Object]} subjects
   * @param {String} searchQuery
   * @return {Object}
   *
   */
  filterSubjectsBySearchQuery: (subjects, searchQuery) =>
    subjects.filter(subject =>
      (subject.name && subject.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (subject.code && subject.code.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (subject.cpf && subject.cpf.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (subject.formatted_cpf && subject.formatted_cpf.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (subject.fantasy_name && subject.fantasy_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (subject.company_name && subject.company_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (subject.cnpj && subject.cnpj.toLowerCase().includes(searchQuery.toLowerCase()))
    ),

  /**
   *
   * Retorna os subjects a serem listados
   * @param {Array[Object]} summarises
   * @param {Array[Object]} subjects
   * @return {Array[Object]}
   *
   */
  getSubjectsToList: (summarises, subjects) =>
    uniq(summarises.map(summarise => summarise.subject_id))
      .map(id => subjects.find(subject => subject.id === id)),

  /**
   *
   * Ordena uma lista baseado na key especificada e na lógica de ordenação (crescente ou decrescente)
   * @param {Array[Object]} arr
   * @param {String} key
   * @param {Boolean} dscOrder
   * @return {Array[Object]}
   *
   */
  orderList: (arr, key, dscOrder, type) =>
    type === 'number'
      ? dscOrder ? arr.sort((a, b) => (b[key] || -1) - (a[key] || -1)) : arr.sort((a, b) => (a[key] || -1) - (b[key] || -1))
      : dscOrder ? arr.sort((a, b) => (b[key] || '').localeCompare((a[key] || ''))) : arr.sort((a, b) => (a[key] || '').localeCompare((b[key] || '')))
}

export const {
  filterSubjectsBySearchQuery,
  getSubjectsToList,
  orderList
} = helpers

export default helpers
