import { all, call, put, takeLatest } from 'redux-saga/effects'

/* Constants */
import {
  SUBJECT_REQUESTED,
  SUBJECT_SUCCEEDED,
  POSTURE_SUCCEEDED,
  POSTURE_REQUESTED
} from './action-types'
import {
  getSubject,
  getSubjectOccurrences,
  getPosture,
  getSubjectObservations
} from 'api'

/* Workers */
function* getAndSetSubjectWorker({ subjectId, subjectType }) {
  try {
    const [
      subjectPayload,
      occurrencesPayload,
      posturePayload,
      observationsPayload
    ] = yield all([
      call(getSubject, { subjectId, subjectType }),
      call(getSubjectOccurrences, { subjectId, subjectType }),
      call(getPosture, { subjectId, subjectType }),
      call(getSubjectObservations, { subjectId, subjectType })
    ])

    yield put({
      type: SUBJECT_SUCCEEDED,
      individual: subjectPayload.data,
      occurrencesByClass: occurrencesPayload.data,
      openedOccurrences: Object.keys(occurrencesPayload.data)
        .reduce(
          (acc, curKey) => acc.concat(occurrencesPayload.data[curKey]),
          []
        )
        .reduce(
          (acc, curOccurrence) => ({ ...acc, [curOccurrence.id]: false }),
          {}
        ),
      posture: posturePayload.data,
      observations: observationsPayload.data
    })
  } catch (error) {
    console.log(error)
  }
}

function* getAndSetPostureWorker({ subjectId, subjectType }) {
  try {
    const payload = yield call(getPosture, { subjectId, subjectType })

    yield put({
      type: POSTURE_SUCCEEDED,
      payload: payload.data
    })
  } catch (error) {
    console.log(error)
  }
}

/* Watchers */
export function* getAndSetSubjectWatcher() {
  yield takeLatest(SUBJECT_REQUESTED, getAndSetSubjectWorker)
}

export function* getAndSetPostureWatcher() {
  yield takeLatest(POSTURE_REQUESTED, getAndSetPostureWorker)
}
