import { call, put, takeLatest, all, select } from 'redux-saga/effects'

/* Types */
import {
  BOARD_REQUESTED,
  BOARD_SUCCEEDED,
  MAIN_DATA_REQUESTED,
  MAIN_DATA_SUCCEEDED,
  PEOPLE_REQUESTED,
  COMPANIES_REQUESTED,
  PEOPLE_SUCCEEDED,
  COMPANIES_SUCCEEDED,
  CREATE_RISK_RATING_REQ,
  CREATE_RISK_RATING_SUC
} from './action-types'

/* Constants */
import { getUsers } from '@zeta/user/src/api'
import {
  getBoard,
  getSummarises,
  getPeople,
  getCompanies,
  getRiskRatings,
  createRiskRating
} from 'api'
import { dynamicFilters } from 'constants/filters'

/* Helpers */
import { uniq } from 'ramda'
import { formatSummarisesArray, formatSubjectsArray } from 'helpers'

/* Workers */
function* getAndSetBoardWorker({ boardType, boardId }) {
  try {
    const payload = yield call(getBoard, { boardId: boardId })

    yield put({ type: BOARD_SUCCEEDED, boardType, payload: payload.data })
  } catch (error) {
    console.log(error)
  }
}

const orderRiskRatings = (riskRatings) =>
  riskRatings.sort((a, b) => a.periodicity_in_months - b.periodicity_in_months)

const formatRiskRatingsSelectionOptions = (riskRatings) =>
  riskRatings
    .map((riskRating) => ({
      visibleValue: riskRating.description,
      dbValue: riskRating.id
    }))
    .concat({
      visibleValue: 'Não classificado',
      dbValue: null
    })

function* getAndSetMainDataWorker() {
  try {
    const [
      summarisesPayload,
      peoplePayload,
      companiesPayload,
      usersPayload,
      riskRatingsPayload
    ] = yield all([
      call(getSummarises),
      call(getPeople),
      call(getCompanies),
      call(getUsers),
      call(getRiskRatings)
    ])

    const selectionOptions = yield select(
      (state) => state.data.selectionOptions
    )

    const riskRatings = orderRiskRatings(riskRatingsPayload.data.result)

    yield put({
      type: MAIN_DATA_SUCCEEDED,
      summarises: {
        people: formatSummarisesArray(
          summarisesPayload.data.result,
          'PrivatePerson'
        ),
        company: formatSummarisesArray(
          summarisesPayload.data.result,
          'LegalEntity'
        )
      },
      subjects: {
        people: formatSubjectsArray(peoplePayload.data, 'PrivatePerson'),
        company: formatSubjectsArray(companiesPayload.data, 'LegalEntity')
      },
      dynamicFiltersItems: dynamicFilters.reduce((acc, filterKey) => {
        return {
          ...acc,
          [filterKey]: uniq(
            summarisesPayload.data.result.map(
              (summarise) => summarise[filterKey]
            )
          )
            .filter((value) => value)
            .map((value) => ({ visibleValue: value, dbValue: value }))
        }
      }, {}),
      users: usersPayload.data.map(
        (user) => `${user.first_name} ${user.last_name}`
      ),
      riskRatings,
      selectionOptions: {
        ...selectionOptions,
        risk_rating_id: formatRiskRatingsSelectionOptions(riskRatings)
      }
    })
  } catch (error) {
    console.log(error)
  }
}

function* getAndSetPeopleWorker({ subjectsIds }) {
  try {
    const people = yield select((state) => state.data.subjects.people)
    const peoplePayload = yield call(getPeople, subjectsIds)

    const peopleSummarises = yield select(
      (state) => state.data.summarises.people
    )
    const companiesSummarises = yield select(
      (state) => state.data.summarises.company
    )

    const peopleSummarisesPayload = yield call(
      getSummarises,
      subjectsIds,
      'PrivatePerson'
    )

    yield put({
      type: PEOPLE_SUCCEEDED,
      people: people
        .filter(
          (person) => !subjectsIds.some((subjectId) => subjectId === person.id)
        )
        .concat(formatSubjectsArray(peoplePayload.data, 'PrivatePerson')),
      summarises: {
        people: peopleSummarises
          .filter(
            (summarise) =>
              !subjectsIds.some(
                (subjectId) => subjectId === summarise.subject_id
              )
          )
          .concat(
            formatSummarisesArray(
              peopleSummarisesPayload.data.result,
              'PrivatePerson'
            )
          ),
        company: companiesSummarises
      }
    })
  } catch (error) {
    console.log(error)
  }
}

function* getAndSetCompaniesWorker({ subjectsIds }) {
  try {
    const companies = yield select((state) => state.data.subjects.company)
    const companiesPayload = yield call(getCompanies, subjectsIds)

    const peopleSummarises = yield select(
      (state) => state.data.summarises.people
    )
    const companiesSummarises = yield select(
      (state) => state.data.summarises.company
    )

    const companiesSummarisesPayload = yield call(
      getSummarises,
      subjectsIds,
      'LegalEntity'
    )

    yield put({
      type: COMPANIES_SUCCEEDED,
      companies: companies
        .filter(
          (company) =>
            !subjectsIds.some((subjectId) => subjectId === company.id)
        )
        .concat(formatSubjectsArray(companiesPayload.data, 'LegalEntity')),
      summarises: {
        people: peopleSummarises,
        company: companiesSummarises
          .filter(
            (summarise) =>
              !subjectsIds.some(
                (subjectId) => subjectId === summarise.subject_id
              )
          )
          .concat(
            formatSummarisesArray(
              companiesSummarisesPayload.data.result,
              'LegalEntity'
            )
          )
      }
    })
  } catch (error) {
    console.log(error)
  }
}

function* createRiskRatingWorker({ description, periodicity, onSuccess }) {
  try {
    const payload = yield call(createRiskRating, { description, periodicity })

    const riskRatings = yield select((state) => state.data.riskRatings)
    const selectionOptions = yield select(
      (state) => state.data.selectionOptions
    )

    const orderedRiskRatings = orderRiskRatings(
      riskRatings.concat(payload.data)
    )

    yield put({
      type: CREATE_RISK_RATING_SUC,
      riskRatings: orderedRiskRatings,
      selectionOptions: {
        ...selectionOptions,
        risk_rating_id: formatRiskRatingsSelectionOptions(orderedRiskRatings)
      }
    })

    onSuccess()
  } catch (error) {
    console.log(error)
  }
}

/* Watchers */
export function* getAndSetBoardWatcher() {
  yield takeLatest(BOARD_REQUESTED, getAndSetBoardWorker)
}

export function* getAndSetMainDataWatcher() {
  yield takeLatest(MAIN_DATA_REQUESTED, getAndSetMainDataWorker)
}

export function* getAndSetPeopleWatcher() {
  yield takeLatest(PEOPLE_REQUESTED, getAndSetPeopleWorker)
}

export function* getAndSetCompaniesWatcher() {
  yield takeLatest(COMPANIES_REQUESTED, getAndSetCompaniesWorker)
}

export function* createRiskRatingWatcher() {
  yield takeLatest(CREATE_RISK_RATING_REQ, createRiskRatingWorker)
}
