export const SUBJECT_REQUESTED = 'SUBJECT_REQUESTED'
export const SUBJECT_SUCCEEDED = 'SUBJECT_SUCCEEDED'
// export const SUBJECT_OCCURRENCES_REQUESTED = 'SUBJECT_OCCURRENCES_REQUESTED'
// export const SUBJECT_OCCURRENCES_SUCCEEDED = 'SUBJECT_OCCURRENCES_SUCCEEDED'
export const POSTURE_REQUESTED = 'POSTURE_REQUESTED'
export const POSTURE_SUCCEEDED = 'POSTURE_SUCCEEDED'
export const SET_OCCURRENCES_BY_CLASS = 'SET_OCCURRENCES_BY_CLASS'
export const SET_OCCURRENCE_TO_CLASSIFY = 'SET_OCCURRENCE_TO_CLASSIFY'
export const TOGGLE_OPENED_OCCURRENCE = 'TOGGLE_OPENED_OCCURRENCE'
export const SET_VIEW_FILTERED_PUBLICATIONS = 'SET_VIEW_FILTERED_PUBLICATIONS'
export const RESET_INDIVIDUAL = 'RESET_INDIVIDUAL'
