/* Types */
import types from './action-types'

export const editOccurrenceStatus = ({
  status, notes, subjectType, subjectId
}) => ({
  type: types.EDIT_OCCURRENCE_REQUESTED,
  status,
  notes,
  subjectType,
  subjectId
})

export const editPosture = ({
  body, key, subjectType, subjectId
}) => ({
  type: types.EDIT_POSTURE_REQUESTED,
  body,
  key,
  subjectType,
  subjectId
})

export const toggleSubjectSituation = (onSuccess) => ({
  type: types.TOGGLE_SUBJECT_SITUATION_REQUESTED,
  onSuccess
})

export const createSubjectObservation = ({ observation, onSuccess }) => ({
  type: types.CREATE_SUBJECT_OBSERVATION_REQUESTED,
  observation,
  onSuccess
})

export const editRiskRating = ({
  description,
  periodicity,
  riskRatingId,
  onSuccess
}) => ({
  type: types.EDIT_RISK_RATING_REQ,
  description,
  periodicity,
  riskRatingId,
  onSuccess
})

export const deleteRiskRating = ({
  riskRatingId,
  onSuccess
}) => ({
  type: types.DELETE_RISK_RATING_REQ,
  riskRatingId,
  onSuccess
})

export const editSubjectRiskRating = ({
  riskRatingId,
  subjectType,
  onSuccess
}) => ({
  type: types.EDIT_SUBJECT_RISK_RATING_REQ,
  riskRatingId,
  subjectType,
  onSuccess
})

/* Reseta uma ou mais prop's > array de string's */
export const resetProps = props => ({
  type: types.RESET_PROPS,
  props
})

/* Reseta tudo para o state inicial */
export const resetState = () => ({ type: types.RESET_STATE })
