import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  color: #000;
`

// export const ContentBlock = styled.div`
//   page-break-inside: avoid;
//   margin-bottom: ${({ theme: { spacing } }) => spacing.largest};
// `

export const PageBreak = styled.div`
  page-break-before: always;
`

// export const Table = styled.table`
//   border-collapse: collapse;
//   width: 100%;
//   color: ${({ theme: { colors } }) => colors.type.medium};
//   font-size: 0.875rem;
//   list-style-position: inside;

//   thead {
//     tr {
//       border: 1px solid ${({ theme: { colors } }) => colors.type.medium};
//     }
//     th {
//       white-space: nowrap;
//     }
//   }

//   tbody {
//     td {
//       border-bottom: 1px solid ${({ theme: { colors } }) => colors.type.medium};
//     }
//   }

//   th, td {
//     padding: ${({ theme: { spacing } }) => `${spacing.medium} ${spacing.medium}`};
//     text-align: left;
//   }
// `
