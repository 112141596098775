/* React */
import React, { useState } from 'react'

/* Components */
import { ButtonAction, FlexSpaceBetween } from '@zeta/ui/src'

import ArchiveModal from 'components/pages/Individual/ArchiveModal'

/* Styled */
import { Wrapper } from './styled'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { getSubjectType } from 'helpers'

const ActionsBar = ({
  /* router */
  history,
  computedMatch,
  /* props */
  individual
}) => {
  const subjectType = getSubjectType(computedMatch.url)

  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false)

  const archiveModalProps = {
    individual,
    isArchiveModalVisible,
    setIsArchiveModalVisible
  }

  return (
    <>
      <Wrapper>
        <FlexSpaceBetween>
          <ButtonAction
            onClick={() =>
              history.push(
                `${getPathPrefix(computedMatch)}/${subjectType}/individual`
              )
            }
          >
            <span>Ir para a lista de perfis</span>
          </ButtonAction>

          <ButtonAction onClick={() => setIsArchiveModalVisible(true)}>
            <span>
              {individual.situation ? 'Inativar perfil' : 'Ativar perfil'}
            </span>
          </ButtonAction>
        </FlexSpaceBetween>
      </Wrapper>

      {isArchiveModalVisible && <ArchiveModal {...archiveModalProps} />}
    </>
  )
}

export default ActionsBar
