export const BOARD_REQUESTED = 'BOARD_REQUESTED'
export const BOARD_SUCCEEDED = 'BOARD_SUCCEEDED'
export const SET_BOARD_INFO = 'SET_BOARD_INFO'

export const MAIN_DATA_REQUESTED = 'MAIN_DATA_REQUESTED'
export const MAIN_DATA_SUCCEEDED = 'MAIN_DATA_SUCCEEDED'

export const PEOPLE_REQUESTED = 'PEOPLE_REQUESTED'
export const COMPANIES_REQUESTED = 'COMPANIES_REQUESTED'
export const PEOPLE_SUCCEEDED = 'PEOPLE_SUCCEEDED'
export const COMPANIES_SUCCEEDED = 'COMPANIES_SUCCEEDED'

export const SET_USERS = 'SET_USERS'

export const CREATE_RISK_RATING_REQ = 'CREATE_RISK_RATING_REQ'
export const CREATE_RISK_RATING_SUC = 'CREATE_RISK_RATING_SUC'

export const EDIT_OCCURRENCE_STATUS = 'EDIT_OCCURRENCE_STATUS'
export const UPDATE_SUMMARISES = 'UPDATE_SUMMARISES'
export const UPDATE_SUBJECTS = 'UPDATE_SUBJECTS'

export const SET_FILTERS = 'SET_FILTERS'

export const SET_LIST_PAGE = 'SET_LIST_PAGE'
export const SET_LIST_PER_PAGE = 'SET_LIST_PER_PAGE'
export const SET_LIST_ORDER = 'SET_LIST_ORDER'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

export const SET_SAVE_REPORT_MODAL = 'SET_SAVE_REPORT_MODAL'

export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN'

export const RESET_PROPS = 'RESET_PROPS'
export const RESET_STATE = 'RESET_STATE'
