import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-size: 0.875rem;
  font-weight: 700;
`

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  padding: 0 ${({ theme }) => theme.spacing.small};
  font-size: 0.875rem;

  &:focus {
    border-color: ${({ theme }) => theme.colors.brand.primary.medium};
    outline: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.surface.primary.lightness};
    color: ${({ theme }) => theme.colors.type.medium};
  }
`

export const RiskRating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.small} 0`};

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.surface.primary.dark};
  }

  > div:first-child {
    width: calc(100% - 48px);
  }
`

export const RemoveBtn = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: ${({ theme }) => theme.spacing.medium};
  height: ${({ theme }) => theme.spacing.medium};
  border: 2px solid ${({ theme }) => theme.colors.feedback.red.medium};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
