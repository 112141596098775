/* React */
import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CustomText,
  ButtonAlt,
  Icon,
  FlexSpaceBetween
} from '@zeta/ui/src'

/* Helpers */
import domtoimage from 'dom-to-image'
import { logAmplitudeEvent } from '@zeta/helpers'

export const GraphCard = ({
  /* props */
  headline,
  children,
  id,
  ...rest
}) => {
  return (
    <Card id={id} {...rest}>
      <FlexSpaceBetween mb='small'>
        <CustomText size='0.875rem'>{headline}</CustomText>

        {id &&
          <ButtonAlt
            onClick={async () => {
              const dataUrl = await domtoimage.toJpeg(document.getElementById(id))
              const link = document.createElement('a')
              link.download = `${headline}.jpeg`
              link.href = dataUrl
              link.click()

              logAmplitudeEvent({
                event: 'Download de gráfico da análise de risco',
                eventProperties: {
                  'Gráfico': headline
                }
              })
            }}
            isSquare
          >
            <Icon icon='download-outline' />
          </ButtonAlt>}
      </FlexSpaceBetween>

      {children}
    </Card>
  )
}

GraphCard.propTypes = {
  headline: PropTypes.string.isRequired,
  graph: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
}

export default GraphCard
