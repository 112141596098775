import { call, put, takeLatest, select } from 'redux-saga/effects'

/* Types */
import types from './action-types'
import { POSTURE_REQUESTED } from '../reducers/individual/action-types'

/* Constants */
import {
  editOccurrence,
  editPosture,
  editSubjectSituation,
  createSubjectObservation,
  editRiskRating,
  deleteRiskRating,
  editSubjectRiskRating
} from 'api'

/* Helpers */
import { update } from 'ramda'
import {
  getDateString,
  getSubjectStatus,
  getMiscOccurrenceClass
} from './helpers'

/* Workers */
function* editOccurrenceStatusWorker({
  status,
  notes,
  subjectType,
  subjectId
}) {
  try {
    const individual = yield select((state) => state.individual)
    const occurrenceToClassify = individual.occurrenceToClassify
    const occurrenceId = occurrenceToClassify.id

    const payload = yield call(editOccurrence, { occurrenceId, status, notes })

    /* atualiza status e notes na ocorrência dentro das ocorrências do subject (individual) */
    const occurrencesByClass = individual.occurrencesByClass
    const occurrenceClass = occurrenceToClassify.occurrenceClass

    const occurrenceIndex = occurrencesByClass[occurrenceClass].findIndex(
      (occurrence) => occurrence.id == occurrenceId
    )

    const updatedOccurrence = {
      ...occurrencesByClass[occurrenceClass][occurrenceIndex],
      occurrence_status: {
        ...occurrencesByClass[occurrenceClass][occurrenceIndex]
          .occurrence_status,
        status,
        notes
      }
    }

    const updatedOccurrencesByClass = {
      ...occurrencesByClass,
      [occurrenceClass]: update(
        occurrenceIndex,
        updatedOccurrence,
        occurrencesByClass[occurrenceClass]
      )
    }

    /* atualiza o status da ocorrência no respectivo summarise e,
    se necessário, as datas de início da análise, data de resolução
    e responsável nos summarises pertencentes ao subject (data) */
    const summarises = yield select((state) => state.data.summarises)
    const summariseIndex = summarises[subjectType].findIndex(
      (summarise) => summarise.occurrence_id == occurrenceId
    )

    const subjects = yield select((state) => state.data.subjects)
    const subjectIndex = subjects[subjectType].findIndex(
      (subject) => subject.id == subjectId
    )
    const subject = subjects[subjectType][subjectIndex]

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const resolutionDate = new Date()
    resolutionDate.setHours(0, 0, 0, 0)
    resolutionDate.setDate(resolutionDate.getDate() + 15)

    const updatedSummarise = {
      ...summarises[subjectType][summariseIndex],
      occurrence_status: status
    }

    const user = yield select((state) => state.user)
    const responsable = `${user.first_name} ${user.last_name}`

    const updateSummarisesDatesIfNeeded = (summarises) => {
      /* caso o subject passe a ter status 5 (solucionado),
      retorna os summarises pertencentes ao mesmo atualizados,
      configurando data de início da análise, data de resolução e responsável pela análise  */
      if (getSubjectStatus(updatedOccurrencesByClass) === 5) {
        return summarises.map((summarise) =>
          summarise.subject_id == subjectId
            ? {
                ...summarise,
                analyse_start: summarise.analyse_start || getDateString(today),
                resolution_date: null,
                responsable: summarise.responsable || responsable
              }
            : summarise
        )
      }

      /* caso o subject não tenha data de início da análise,
      significando que esta é a primeira ocorrência do mesmo que foi classificada,
      retorna os summarises pertencentes ao mesmo atualizados,
      configurando data de início da análise, data de resolução e responsável pela análise */
      if (!subject.analise_start) {
        return summarises.map((summarise) =>
          summarise.subject_id == subjectId
            ? {
                ...summarise,
                analyse_start: getDateString(today),
                resolution_date: getDateString(resolutionDate),
                responsable
              }
            : summarise
        )
      }

      return summarises
    }

    const updatedSummarises = {
      ...summarises,
      [subjectType]: updateSummarisesDatesIfNeeded(
        update(summariseIndex, updatedSummarise, summarises[subjectType])
      )
    }

    /* atualiza o subject dentro dos subjects (data) */
    const updatedSubject = {
      ...subject,
      analise_update: getDateString(today),
      data_resolucao:
        getSubjectStatus(updatedOccurrencesByClass) === 5
          ? null
          : !subject.analise_start
          ? getDateString(resolutionDate)
          : subject.data_resolucao,
      analise_start: !subject.analise_start
        ? getDateString(today)
        : subject.analise_start,
      actual_status: getSubjectStatus(updatedOccurrencesByClass),
      [getMiscOccurrenceClass(occurrenceClass)]:
        occurrencesByClass[occurrenceClass][occurrenceIndex].occurrence_status
          .status === 7 && status != 7
          ? subject[getMiscOccurrenceClass(occurrenceClass)] + 1
          : occurrencesByClass[occurrenceClass][occurrenceIndex]
              .occurrence_status.status !== 7 && status == 7
          ? subject[getMiscOccurrenceClass(occurrenceClass)] - 1
          : subject[getMiscOccurrenceClass(occurrenceClass)],
      total_occurrences:
        occurrencesByClass[occurrenceClass][occurrenceIndex].occurrence_status
          .status === 7 && status != 7
          ? subject.total_occurrences + 1
          : occurrencesByClass[occurrenceClass][occurrenceIndex]
              .occurrence_status.status !== 7 && status == 7
          ? subject.total_occurrences - 1
          : subject.total_occurrences
    }

    const updatedSubjects = {
      ...subjects,
      [subjectType]: update(subjectIndex, updatedSubject, subjects[subjectType])
    }

    /* adiciona o "responsable" dentro dos "dynamicFiltersItems"
    caso seja a primeira análise do usuário atual (data) */
    const dynamicFiltersItems = yield select(
      (state) => state.data.dynamicFiltersItems
    )

    const responsables = yield select(
      (state) => state.data.dynamicFiltersItems.responsable
    )

    let updatedResponsables

    if (
      !responsables.find(
        (responsablesItem) => responsablesItem.dbValue === responsable
      )
    ) {
      updatedResponsables = responsables.concat({
        visibleValue: responsable,
        dbValue: responsable
      })
    }

    yield put({
      type: types.EDIT_OCCURRENCE_SUCCEEDED,
      occurrencesByClass: updatedOccurrencesByClass,
      summarises: updatedSummarises,
      subjects: updatedSubjects,
      dynamicFiltersItems: {
        ...dynamicFiltersItems,
        responsable: updatedResponsables || responsables
      }
    })

    yield put({
      type: POSTURE_REQUESTED,
      subjectId,
      subjectType
    })
  } catch (error) {
    console.log(error)
  }
}

function* editPostureWorker({ body, key, subjectType, subjectId }) {
  try {
    const payload = yield call(editPosture, { body, subjectType, subjectId })

    /* atualiza os summarises dentro dos summarises (data) */
    const summariseKey = () => {
      if (key === 'responsible') return 'responsable'
      if (key === 'conflictTerm') return 'conflit_term'
      if (key === 'contact') return 'pendent_contact'
      if (key === 'resolution_date') return 'resolution_date'
    }

    const bodyKey = () => {
      if (key === 'responsible') return 'responsible'
      if (key === 'conflictTerm') return 'conflict_term'
      if (key === 'contact') return 'contact'
    }

    const occurrencesByClass = yield select(
      (state) => state.individual.occurrencesByClass
    )
    const summarises = yield select((state) => state.data.summarises)

    const occurrencesIds = Object.keys(occurrencesByClass)
      .reduce((acc, curKey) => acc.concat(occurrencesByClass[curKey]), [])
      .map((occurrence) => occurrence.id)
    const subjectSummarises = summarises[subjectType].filter((summarise) =>
      occurrencesIds.some(
        (occurrenceId) => occurrenceId == summarise.occurrence_id
      )
    )
    const notSubjectSummarises = summarises[subjectType].filter((summarise) =>
      occurrencesIds.every(
        (occurrenceId) => occurrenceId != summarise.occurrence_id
      )
    )

    const updatedSubjectSummarises = subjectSummarises.map(
      (subjectSummarise) => ({
        ...subjectSummarise,
        [summariseKey()]:
          key === 'resolution_date'
            ? getDateString(body.resolution_date)
            : body[bodyKey()]
      })
    )

    const updatedSummarises = {
      ...summarises,
      [subjectType]: notSubjectSummarises.concat(updatedSubjectSummarises)
    }

    /* atualiza o subject dentro dos subjects (data) */
    const subjects = yield select((state) => state.data.subjects)

    let updatedSubjects

    if (key === 'resolution_date') {
      const subjectIndex = subjects[subjectType].findIndex(
        (subject) => subject.id == subjectId
      )

      const updatedSubject = {
        ...subjects[subjectType][subjectIndex],
        data_resolucao: getDateString(body.resolution_date)
      }

      updatedSubjects = {
        ...subjects,
        [subjectType]: update(
          subjectIndex,
          updatedSubject,
          subjects[subjectType]
        )
      }
    }

    /* adiciona o "responsable" dentro dos "dynamicFiltersItems" caso seja a primeira análise do usuário ao qual a análise foi atribuída (data) */
    const dynamicFiltersItems = yield select(
      (state) => state.data.dynamicFiltersItems
    )
    const responsables = yield select(
      (state) => state.data.dynamicFiltersItems.responsable
    )
    let updatedResponsables

    if (key === 'responsible') {
      const responsable = body.responsible

      if (
        !responsables.find(
          (responsablesItem) => responsablesItem.dbValue === responsable
        )
      ) {
        updatedResponsables = responsables.concat({
          visibleValue: responsable,
          dbValue: responsable
        })
      }
    }

    yield put({
      type: types.EDIT_POSTURE_SUCCEEDED,
      summarises: updatedSummarises,
      subjects: updatedSubjects || subjects,
      dynamicFiltersItems: {
        ...dynamicFiltersItems,
        responsable: updatedResponsables || responsables
      },
      posture: payload.data
    })
  } catch (error) {
    console.log(error)
  }
}

function* toggleSubjectSituationWorker({ onSuccess }) {
  try {
    const individual = yield select((state) => state.individual)
    const subjects = yield select((state) => state.data.subjects)

    const subjectType =
      individual.posture.subject_type === 'PrivatePerson' ? 'people' : 'company'
    const situation = !individual.individual.situation

    yield call(editSubjectSituation, {
      subjectId: individual.individual.id,
      subjectType,
      situation
    })

    const i = subjects[subjectType].findIndex(
      (subject) => subject.id === individual.individual.id
    )

    yield put({
      type: types.TOGGLE_SUBJECT_SITUATION_SUCCEEDED,
      individual: {
        ...individual.individual,
        situation
      },
      subjects: {
        ...subjects,
        [subjectType]: update(
          i,
          { ...subjects[subjectType][i], situation },
          subjects[subjectType]
        )
      }
    })

    onSuccess()
  } catch (error) {
    console.log(error)
  }
}

function* createSubjectObservationWorker({ observation, onSuccess }) {
  try {
    const individual = yield select((state) => state.individual)
    const subjects = yield select((state) => state.data.subjects)
    const subjectType =
      individual.posture.subject_type === 'PrivatePerson' ? 'people' : 'company'

    const payload = yield call(createSubjectObservation, {
      subjectId: individual.individual.id,
      subjectType,
      observation
    })

    const i = subjects[subjectType].findIndex(
      (subject) => subject.id === individual.individual.id
    )

    yield put({
      type: types.CREATE_SUBJECT_OBSERVATION_SUCCEEDED,
      subjects: individual.has_observation
        ? subjects
        : {
            ...subjects,
            [subjectType]: update(
              i,
              { ...subjects[subjectType][i], has_observation: true },
              subjects[subjectType]
            )
          },
      observations: individual.observations.concat(payload.data)
    })

    onSuccess()
  } catch (error) {
    console.log(error)
  }
}

function* editRiskRatingWorker({
  description,
  periodicity,
  riskRatingId,
  onSuccess
}) {
  try {
    const payload = yield call(editRiskRating, {
      description,
      periodicity,
      riskRatingId
    })

    const riskRatings = yield select((state) => state.data.riskRatings)

    const riskRatingsIndex = riskRatings.findIndex(
      (riskRating) => riskRating.id === riskRatingId
    )

    // const getUpdatedNextAnalysisDate = (y, m, d, diff) => {
    //   const date = new Date(y, `${Number(m) - 1}`, d)
    //   date.setMonth(date.getMonth() + diff)
    //   return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    // }

    // const diff = periodicity - riskRatings[riskRatingsIndex].periodicity_in_months

    // const getUpdatedSubjects = (subjects) =>
    //   subjects.map(subject => {
    //     if (subject.risk_rating_id === riskRatingId) {
    //       return {
    //         ...subject,
    //         next_risk_rating_review: getUpdatedNextAnalysisDate(...subject.next_risk_rating_review.split('-'), diff)
    //       }
    //     }

    //     return subject
    //   })

    // const subjects = yield select(state => state.data.subjects)

    const selectionOptions = yield select(
      (state) => state.data.selectionOptions
    )

    const selectionOptionsIndex = selectionOptions['risk_rating_id'].findIndex(
      (selectionOption) => selectionOption.dbValue === riskRatingId
    )

    yield put({
      type: types.EDIT_RISK_RATING_SUC,
      riskRatings: update(riskRatingsIndex, payload.data, riskRatings),
      selectionOptions: {
        ...selectionOptions,
        risk_rating_id: update(
          selectionOptionsIndex,
          { visibleValue: payload.data.description, dbValue: payload.data.id },
          selectionOptions['risk_rating_id']
        )
      }
      // subjects: {
      //   people: getUpdatedSubjects(subjects.people),
      //   company: getUpdatedSubjects(subjects.company)
      // }
    })

    onSuccess()
  } catch (error) {
    console.log(error)
  }
}

function* deleteRiskRatingWorker({ riskRatingId, onSuccess }) {
  try {
    yield call(deleteRiskRating, { riskRatingId })

    const riskRatings = yield select((state) => state.data.riskRatings)

    const getUpdatedSubjects = (subjects) =>
      subjects.map((subject) => {
        if (subject.risk_rating_id === riskRatingId) {
          return {
            ...subject,
            risk_rating_id: null,
            next_risk_rating_review: null
          }
        }

        return subject
      })

    const subjects = yield select((state) => state.data.subjects)

    const selectionOptions = yield select(
      (state) => state.data.selectionOptions
    )

    yield put({
      type: types.DELETE_RISK_RATING_SUC,
      riskRatings: riskRatings.filter(
        (riskRating) => riskRating.id !== riskRatingId
      ),
      selectionOptions: {
        ...selectionOptions,
        risk_rating_id: selectionOptions['risk_rating_id'].filter(
          (selectionOption) => selectionOption.dbValue !== riskRatingId
        )
      },
      subjects: {
        people: getUpdatedSubjects(subjects.people),
        company: getUpdatedSubjects(subjects.company)
      }
    })

    onSuccess()
  } catch (error) {
    console.log(error)
  }
}

function* editSubjectRiskRatingWorker({
  riskRatingId,
  subjectType,
  onSuccess
}) {
  try {
    const individual = yield select((state) => state.individual)

    const payload = yield call(editSubjectRiskRating, {
      postureId: individual.posture.id,
      riskRatingId
    })

    const subjects = yield select((state) => state.data.subjects)

    const i = subjects[subjectType].findIndex(
      (subject) => subject.id === individual.individual.id
    )

    const updatedSubject = {
      ...subjects[subjectType][i],
      risk_rating_id: payload.data.risk_rating_id,
      next_risk_rating_review: payload.data.next_risk_rating_review
    }

    yield put({
      type: types.EDIT_SUBJECT_RISK_RATING_SUC,
      posture: payload.data,
      subjects: {
        ...subjects,
        [subjectType]: update(i, updatedSubject, subjects[subjectType])
      }
    })

    onSuccess()
  } catch (error) {
    console.log(error)
  }
}

/* Watchers */
export function* editOccurrenceStatusWatcher() {
  yield takeLatest(types.EDIT_OCCURRENCE_REQUESTED, editOccurrenceStatusWorker)
}

export function* editPostureWatcher() {
  yield takeLatest(types.EDIT_POSTURE_REQUESTED, editPostureWorker)
}

export function* toggleSubjectSituationWatcher() {
  yield takeLatest(
    types.TOGGLE_SUBJECT_SITUATION_REQUESTED,
    toggleSubjectSituationWorker
  )
}

export function* createSubjectObservationWatcher() {
  yield takeLatest(
    types.CREATE_SUBJECT_OBSERVATION_REQUESTED,
    createSubjectObservationWorker
  )
}

export function* editRiskRatingWatcher() {
  yield takeLatest(types.EDIT_RISK_RATING_REQ, editRiskRatingWorker)
}

export function* deleteRiskRatingWatcher() {
  yield takeLatest(types.DELETE_RISK_RATING_REQ, deleteRiskRatingWorker)
}

export function* editSubjectRiskRatingWatcher() {
  yield takeLatest(
    types.EDIT_SUBJECT_RISK_RATING_REQ,
    editSubjectRiskRatingWorker
  )
}
