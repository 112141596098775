/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  CustomBlock
} from '@zeta/ui/src'

import Selection from 'components/filters/Selection'

/* Constants */
import { visibleLabels } from 'constants/filters'

const LawsuitFilters = ({
  ...props
}) => {
  const selectionOptions = useSelector(state => state.data.selectionOptions)

  return (
    <CustomBlock mt='medium'>
      <Row>
        <Col lg={4}>
          <Selection
            display='group'
            selectionType='checkbox'
            label={visibleLabels['processes_involvement']}
            name='processes_involvement'
            items={selectionOptions['processes_involvement']}
            {...props}
            mb='xlarge'
          />
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default LawsuitFilters
