/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Action Creators */
import { setSaveBoardModal } from 'store/reducers/data/action-creators'

const LinkWithSaveBoard = ({
  /* router */
  history,
  /* props */
  component: Component,
  to,
  children
}) => {
  const dispatch = useDispatch()

  const board = useSelector((state) => state.data.board)
  const userId = useSelector((state) => state.user.id)

  const isBoard = () => board.type === 'board'
  const isOwner = () => board.user_id === userId || board.user.id === userId

  return (
    <Component
      onClick={() => {
        if (!board) {
          history.push(to)
          return
        }

        if (isBoard() && isOwner()) {
          dispatch(
            setSaveBoardModal({
              isOpen: true,
              proceedFn: () => history.push(to),
              enableProceedWithoutSaving: true
            })
          )

          return
        }

        if (isBoard() && !isOwner()) {
          history.push(to)
          return
        }

        dispatch(
          setSaveBoardModal({
            isOpen: true,
            proceedFn: () => history.push(to),
            enableProceedWithoutSaving: true
          })
        )
      }}
    >
      {children}
    </Component>
  )
}

export default withRouter(LinkWithSaveBoard)
