/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import ReactTooltip from 'react-tooltip'

import {
  FlexCentered,
  FlexAlignCenter,
  LoadingSpinner,
  HorizontalScroll,
  TableInfo,
  Icon,
  CustomText,
  theme
} from '@zeta/ui/src'

import { TooltipIcon } from '../OccurrenceCard'

/* Styled */
import { Count, SeeMoreButton } from './styled'

/* Parts */
import MovementsModal from './MovementsModal'

/* Helpers */
import { formatDate, logAmplitudeEvent } from '@zeta/helpers'
import { getLawsuitParts, getLawsuitMovements, getLawsuitDecisions } from 'api'

/* Constants */
import { visibleInvolvement, visiblePolarity } from 'constants/dictionary'

const TableLabel = ({ children }) => (
  <CustomText size="0.75rem" weight="700" mr="small">
    {children}
  </CustomText>
)

const SeeMoreIcon = () => (
  <Icon
    icon="plus-outline"
    size="medium"
    fill={theme.colors.brand.primary.medium}
    ml="small"
  />
)

export const Movements = ({
  /* props */
  occurrenceId
}) => {
  const [data, setData] = useState(null)
  const [isLoadingData, setIsLoadingData] = useState(null)

  const [movementsModal, setMovementsModal] = useState(null)
  const movementsModalProps = { movementsModal, setMovementsModal }

  useEffect(() => {
    setIsLoadingData(true)

    Promise.all([
      getLawsuitParts({ occurrenceId }),
      getLawsuitMovements({ occurrenceId }),
      getLawsuitDecisions({ occurrenceId })
    ]).then((res) => {
      setData({
        parts: res[0].data,
        movements: res[1].data,
        decisions: res[2].data
      })

      setIsLoadingData(false)
    })
  }, [])

  return isLoadingData ? (
    <FlexCentered override="width: 100%;">
      <LoadingSpinner size="40" my="large" />
    </FlexCentered>
  ) : (
    <>
      {data && data.decisions.result && data.decisions.result.length > 0 && (
        <>
          <FlexAlignCenter mt="large" mb="medium" override="width: 100%;">
            <TableLabel>DECISÕES</TableLabel>
            <Count>
              <span>{data.decisions.total_listed}</span>
            </Count>
          </FlexAlignCenter>

          <HorizontalScroll>
            <TableInfo
              headers={[
                { text: 'Data da Decisão' },
                { text: 'Conteúdo da Decisão' },
                { text: 'Fonte' }
              ]}
              rows={data.decisions.result.map((decision) => ({
                cells: [
                  { text: formatDate(decision.decision_date) },
                  { text: decision.content },
                  {
                    text: (
                      <TooltipIcon
                        dataTip="Bigdatacorp"
                        dataFor="movementsTooltip"
                      />
                    )
                  }
                ]
              }))}
              mb="small"
            />
          </HorizontalScroll>

          <ReactTooltip id="movementsTooltip" />

          <SeeMoreButton
            onClick={() => {
              setMovementsModal({ type: 'decisions', occurrenceId })

              logAmplitudeEvent({
                event: 'Abriu modal de decisões de um processo'
              })
            }}
          >
            <span>Ver mais</span>
            <SeeMoreIcon />
          </SeeMoreButton>
        </>
      )}

      {data && data.parts.result && data.parts.result.length > 0 && (
        <>
          <FlexAlignCenter mt="large" mb="medium" override="width: 100%;">
            <TableLabel>PARTES</TableLabel>
            <Count>
              <span>{data.parts.total_listed}</span>
            </Count>
          </FlexAlignCenter>

          <HorizontalScroll>
            <TableInfo
              headers={[
                { text: 'Tipo' },
                { text: 'Polaridade' },
                { text: 'Nome' },
                { text: 'Documento' },
                { text: 'Fonte' }
              ]}
              rows={data.parts.result.map((part) => ({
                cells: [
                  {
                    text:
                      visibleInvolvement[part.involvement] || part.involvement
                  },
                  { text: visiblePolarity[part.polarity] || part.polarity },
                  { text: part.name },
                  { text: part.document },
                  {
                    text: (
                      <TooltipIcon
                        dataTip="Bigdatacorp"
                        dataFor="movementsTooltip"
                      />
                    )
                  }
                ]
              }))}
              mb="small"
            />
          </HorizontalScroll>

          <ReactTooltip id="movementsTooltip" />

          <SeeMoreButton
            onClick={() => {
              setMovementsModal({ type: 'parts', occurrenceId })

              logAmplitudeEvent({
                event: 'Abriu modal de partes de um processo'
              })
            }}
          >
            <span>Ver mais</span>
            <SeeMoreIcon />
          </SeeMoreButton>
        </>
      )}

      {data && data.movements.result && data.movements.result.length > 0 && (
        <>
          <FlexAlignCenter mt="large" mb="medium" override="width: 100%;">
            <TableLabel>MOVIMENTAÇÕES</TableLabel>
            <Count>
              <span>{data.movements.total_listed}</span>
            </Count>
          </FlexAlignCenter>

          <HorizontalScroll>
            <TableInfo
              headers={[
                { text: 'Data da Publicação' },
                { text: 'Conteúdo' },
                { text: 'Data da Captura' },
                { text: 'Fonte' }
              ]}
              rows={data.movements.result.map((movement) => ({
                cells: [
                  { text: formatDate(movement.publication_date) },
                  { text: movement.content },
                  { text: formatDate(movement.publication_catch) },
                  {
                    text: (
                      <TooltipIcon
                        dataTip="Bigdatacorp"
                        dataFor="movementsTooltip"
                      />
                    )
                  }
                ]
              }))}
              mb="small"
            />
          </HorizontalScroll>

          <ReactTooltip id="movementsTooltip" />

          <SeeMoreButton
            onClick={() => {
              setMovementsModal({ type: 'movements', occurrenceId })

              logAmplitudeEvent({
                event: 'Abriu modal de movimentações de um processo'
              })
            }}
          >
            <span>Ver mais</span>
            <SeeMoreIcon />
          </SeeMoreButton>
        </>
      )}

      {movementsModal && <MovementsModal {...movementsModalProps} />}
    </>
  )
}

export default Movements
