/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import {
  Wrapper,
  Line,
  ItemsWrapper,
  Item,
  ItemCheck,
  ItemLabel
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const Stepline = ({
  items,
  activeItem,
  itemCheckBg,
  orientationBreakpoint,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <Line orientationBreakpoint={orientationBreakpoint} />
      <ItemsWrapper orientationBreakpoint={orientationBreakpoint}>
        {items.map((item, i) => (
          <Item
            orientationBreakpoint={orientationBreakpoint}
            key={uuidv4()}
          >
            <ItemCheck
              itemCheckBg={itemCheckBg}
              isActive={activeItem === 0 && i === 0 ? true : activeItem >= i + 1}
              data-testid='steplineCheck'
            />
            <ItemLabel
              orientationBreakpoint={orientationBreakpoint}
              isActive={activeItem === 0 && i === 0 ? true : activeItem >= i + 1}
            >
              {item.label}
            </ItemLabel>
          </Item>
        ))}
      </ItemsWrapper>
    </Wrapper>
  )
}

Stepline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired })
  ).isRequired,
  activeItem: PropTypes.number.isRequired,
  itemCheckBg: PropTypes.string.isRequired,
  orientationBreakpoint: PropTypes.number.isRequired
}

export default Stepline
