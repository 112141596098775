/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  CustomBlock
} from '@zeta/ui/src'

import Selection from 'components/filters/Selection'
import Range from 'components/filters/Range'

/* Constants */
import { visibleLabels } from 'constants/filters'

const ProfileFilters = ({
  ...props
}) => {
  const selectionOptions = useSelector(state => state.data.selectionOptions)

  return (
    <CustomBlock mt='medium'>
      <Row>
        {/* <Col lg={4}>
          <Range
            name='bond_time'
            label={visibleLabels['bond_time']}
            sufix='anos'
            {...props}
            mb='xlarge'
          />
        </Col> */}

        <Col lg={4}>
          <Range
            name='score'
            label={visibleLabels['score']}
            initialRange={[0, 1000]}
            items={[
              { visibleValue: 'Baixo', start: '0', end: '333.33' },
              { visibleValue: 'Médio', start: '333.34', end: '666.66' },
              { visibleValue: 'Alto', start: '666.67', end: '1000' }
            ]}
            {...props}
            mb='xlarge'
          />
        </Col>

        <Col lg={4}>
          <Selection
            display='group'
            selectionType='checkbox'
            label={visibleLabels['situation']}
            name='situation'
            items={selectionOptions['situation']}
            {...props}
            mb='xlarge'
          />
        </Col>

        {/* <Col lg={4}>
          <Selection
            display='group'
            selectionType='checkbox'
            label={visibleLabels['alert']}
            name='alert'
            items={selectionOptions['alert']}
            {...props}
            mb='xlarge'
          />
        </Col>

        <Col lg={4}>
          <Range
            name='score'
            label={visibleLabels['score']}
            initialRange={[0, 100]}
            {...props}
            mb='xlarge'
          />
        </Col> */}

        <Col lg={4}>
          <Selection
            display='group'
            selectionType='checkbox'
            label={visibleLabels['gender']}
            name='gender'
            items={selectionOptions['gender']}
            {...props}
            mb='xlarge'
          />
        </Col>

        <Col lg={4}>
          <Selection
            display='dropdown'
            dropdownVisible={8}
            selectionType='checkbox'
            label={visibleLabels['state']}
            name='state'
            items={selectionOptions['state']}
            {...props}
            mb='xlarge'
          />
        </Col>

        <Col lg={4}>
          <Range
            name='age'
            label={visibleLabels['age']}
            sufix='anos'
            {...props}
            mb='xlarge'
          />
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default ProfileFilters
