/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import { CustomBlock, CustomText } from '@zeta/ui/src'

import Selection from 'components/filters/Selection'
import DateRange from 'components/filters/DateRange'

/* Constants */
import { visibleLabels } from 'constants/filters'

const AnalysisFilters = ({ ...props }) => {
  const selectionOptions = useSelector((state) => state.data.selectionOptions)
  const dynamicFiltersItems = useSelector(
    (state) => state.data.dynamicFiltersItems
  )

  return (
    <CustomBlock mt="medium">
      <Row>
        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['responsable']}
            name="responsable"
            items={dynamicFiltersItems['responsable']}
            {...props}
            mb="xlarge"
          />
        </Col>

        {/* <Col lg={4}>
          <Selection
            display='group'
            selectionType='checkbox'
            label={visibleLabels['pendent_contact']}
            name='pendent_contact'
            items={selectionOptions['pendent_contact']}
            {...props}
            mb='xlarge'
          />
        </Col> */}

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['conflit_term']}
            name="conflit_term"
            items={selectionOptions['conflit_term']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['occurrence_status']}
            name="occurrence_status"
            items={selectionOptions['occurrence_status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['actual_status']}
            name="actual_status"
            items={selectionOptions['actual_status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['collection_id']}
            name="collection_id"
            items={selectionOptions['collection_id']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <CustomBlock mb="xlarge">
            <CustomText size="0.75rem" mb="medium">
              {visibleLabels['analyse_start']}
            </CustomText>

            <DateRange name="analyse_start" {...props} />
          </CustomBlock>
        </Col>

        <Col lg={4}>
          <CustomBlock mb="xlarge">
            <CustomText size="0.75rem" mb="medium">
              {visibleLabels['resolution_date']}
            </CustomText>

            <DateRange name="resolution_date" {...props} />
          </CustomBlock>
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['has_observation']}
            name="has_observation"
            items={selectionOptions['has_observation']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['risk_rating_id']}
            name="risk_rating_id"
            items={selectionOptions['risk_rating_id']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <CustomBlock mb="xlarge">
            <CustomText size="0.75rem" mb="medium">
              {visibleLabels['next_risk_rating_review']}
            </CustomText>

            <DateRange name="next_risk_rating_review" {...props} />
          </CustomBlock>
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default AnalysisFilters
