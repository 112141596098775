/* React */
import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

/* Router */
import { Redirect } from 'react-router'

/* Components */
import {
  FlexAlignCenter,
  FlexCentered,
  CustomText,
  CustomBlock,
  LoadingSpinner,
  theme
} from '@zeta/ui/src'

/* Styled */
import { Wrapper, PageBreak } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { uniq, reverse } from 'ramda'
import ReactHtmlParser from 'react-html-parser'
import { sanitize } from 'dompurify'
import { formatDate, getPathPrefix } from '@zeta/helpers'
import { getSubjectRelates, getDossierMsg, getSubjectObservations } from 'api'
import { getRiskRating } from 'helpers'
import {
  getHeaders,
  getCells,
  filterOccurrences,
  filterRelates
} from './helpers'

/* Constants */
import {
  visibleOccurrenceClass,
  visibleOccurrenceType,
  visibleStatus
} from 'constants/dictionary'

const RenderDossier = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType,
  subjectId
}) => {
  const individual = useSelector((state) => state.individual.individual)

  return individual ? (
    <Dossier
      history={history}
      computedMatch={computedMatch}
      subjectType={subjectType}
      subjectId={subjectId}
    />
  ) : (
    <Redirect
      to={`${getPathPrefix(
        computedMatch
      )}/${subjectType}/individual/${subjectId}`}
    />
  )
}

const Dossier = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType,
  subjectId
}) => {
  const individual = useSelector((state) => state.individual.individual)
  const occurrencesByClass = useSelector(
    (state) => state.individual.occurrencesByClass
  )
  const posture = useSelector((state) => state.individual.posture)
  const riskRatings = useSelector((state) => state.data.riskRatings)

  const {
    includeNonElegible,
    includePastCollections,
    withRelates,
    occurrenceClasses,
    includeNotes,
    includeScore,
    includeRelatesScore,
    includeObservations,
    includeRiskRating
  } = history.location.state.config

  const filteredOccurrencesByClass = filterOccurrences(
    occurrencesByClass,
    includeNonElegible,
    occurrenceClasses,
    posture.collection_id,
    includePastCollections
  )

  const printAndGoBackToProfile = () => {
    window.print()

    history.push({
      pathname: `${getPathPrefix(
        computedMatch
      )}/${subjectType}/individual/${subjectId}`,
      state: { preventGetSubject: true }
    })
  }

  const [dossierMsg, setDossierMsg] = useState('')
  const [observations, setObservations] = useState(null)
  const [relates, setRelates] = useState(null)

  const getAndSetRelatesIfNeeded = (mounted) => {
    if (!withRelates) return printAndGoBackToProfile()

    getSubjectRelates({
      subjectId,
      collectionId: includePastCollections ? null : posture.collection_id
    }).then((res) => {
      if (mounted) {
        setRelates(
          filterRelates(
            res.data,
            includeNonElegible,
            occurrenceClasses,
            posture.collection_id,
            includePastCollections
          )
        )
        printAndGoBackToProfile()
      }
    })
  }

  useEffect(() => {
    let mounted = true

    getDossierMsg().then((res) => {
      if (mounted) {
        setDossierMsg(res.data.result[0])

        if (includeObservations) {
          getSubjectObservations({ subjectId, subjectType }).then((res) => {
            if (mounted) {
              setObservations(res.data)
              getAndSetRelatesIfNeeded(mounted)
            }
          })

          return
        }

        getAndSetRelatesIfNeeded(mounted)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  const CategoryGroups = ({ filteredOccurrencesByClass, subjectType }) => {
    return Object.keys(filteredOccurrencesByClass)
      .filter(
        (occurrenceClass) =>
          filteredOccurrencesByClass[occurrenceClass].length > 0
      )
      .map((occurrenceClass) => (
        <Fragment key={uuidv4()}>
          {uniq(
            filteredOccurrencesByClass[occurrenceClass].map(
              (occurrence) => occurrence.occurrence_type
            )
          )
            .filter(
              (occurrenceType) =>
                // occurrenceType !== 'quadro_societario' &&
                !(occurrenceType === 'ppe' && subjectType === 'company')
            )
            .map((occurrenceType, i) => (
              <CustomBlock mb="largest" key={uuidv4()}>
                {i === 0 && (
                  <CustomText
                    size="1.375rem"
                    weight="700"
                    mb="xlarge"
                    override="text-transform: uppercase; padding-top: 4px;"
                  >
                    Categoria: {visibleOccurrenceClass[occurrenceClass]}
                  </CustomText>
                )}

                <CustomText
                  size="1.125rem"
                  weight="700"
                  mb="medium"
                  override="text-transform: uppercase; padding-top: 4px;"
                  data-testid={`occurrenceTypeHeadline-${occurrenceType}`}
                >
                  Tipo: {visibleOccurrenceType[occurrenceType]}
                </CustomText>

                {filteredOccurrencesByClass[occurrenceClass]
                  .filter(
                    (occurrence) =>
                      occurrence.occurrence_type === occurrenceType
                  )
                  .map((occurrence) => (
                    <CustomBlock
                      mb="medium"
                      pb="medium"
                      override={`border-bottom: 1px solid ${theme.colors.type.medium};`}
                      key={uuidv4()}
                    >
                      {getCells(occurrence, subjectType).map((cell, i) => (
                        <CustomText size="0.875rem" mb="xsmall" key={uuidv4()}>
                          <strong>
                            {
                              getHeaders(
                                occurrenceClass,
                                occurrenceType,
                                subjectType
                              )[i]
                            }
                            :
                          </strong>{' '}
                          {cell}
                        </CustomText>
                      ))}
                      <CustomText size="0.875rem" mb="xsmall">
                        <strong>Status:</strong>{' '}
                        {visibleStatus[occurrence.occurrence_status.status]}
                      </CustomText>
                      <CustomText size="0.875rem" mb="xsmall">
                        <strong>ID:</strong> {occurrence.id}
                      </CustomText>
                      {includeNotes && occurrence.occurrence_status.notes && (
                        <CustomText size="0.875rem">
                          <strong
                            style={{
                              marginBottom: theme.spacing.xsmall,
                              display: 'block'
                            }}
                          >
                            Comentário Analista:
                          </strong>
                          {ReactHtmlParser(
                            sanitize(occurrence.occurrence_status.notes)
                          )}
                        </CustomText>
                      )}
                    </CustomBlock>
                  ))}
              </CustomBlock>
            ))}

          <PageBreak />
        </Fragment>
      ))
  }

  return (
    <Wrapper>
      {individual &&
      (!withRelates || relates) &&
      (!includeObservations || observations) ? (
        <>
          <CustomText size="2rem" weight="700" mb="xlarge">
            Relatório - Dossiê
          </CustomText>

          {/* Informações de Perfil */}
          <CustomBlock>
            <CustomText mb="xsmall">
              <strong>{individual.name || individual.company_name}</strong>
            </CustomText>
            {subjectType === 'people' && individual.code && (
              <CustomText mb="xsmall">
                A: <strong>{individual.code}</strong>
              </CustomText>
            )}
            {subjectType === 'people' && (
              <CustomText mb="xsmall">
                CPF: <strong>{individual.cpf}</strong>
              </CustomText>
            )}
            {subjectType === 'company' && (
              <CustomText mb="xsmall">
                CNPJ: <strong>{individual.cnpj}</strong>
              </CustomText>
            )}
            {includeScore && (
              <CustomText mb="xsmall">
                Score: <strong>{individual.score || 'NA'}</strong>
              </CustomText>
            )}
            {includeRiskRating && (
              <>
                <CustomText mb="xsmall">
                  Classificação de risco:{' '}
                  <strong>
                    {getRiskRating(riskRatings, posture.risk_rating_id)
                      ? getRiskRating(riskRatings, posture.risk_rating_id)
                          .description
                      : 'NA'}
                  </strong>
                </CustomText>
                <CustomText mb="xsmall">
                  Próxima análise:{' '}
                  <strong>{formatDate(posture.next_risk_rating_review)}</strong>
                </CustomText>
              </>
            )}
            <CustomText mb="xsmall">
              Status:{' '}
              <strong>{individual.situation ? 'Ativo' : 'Inativo'}</strong>
            </CustomText>
            {subjectType === 'people' && individual.company && (
              <CustomText mb="xsmall">
                Escritório: <strong>{individual.company}</strong>
              </CustomText>
            )}
          </CustomBlock>

          {/* Aviso Legal */}
          <CustomBlock
            p="large"
            mt="xlarge"
            mb="largest"
            override={`border: 1px solid ${theme.colors.type.medium};`}
          >
            <CustomText weight="700" mb="medium">
              Aviso Legal
            </CustomText>
            <CustomText override="line-height: 1.5;">
              Este documento pode conter informações confidenciais, caso não
              seja o destinatário ou a pessoa autorizada a recebê-lo, está
              formalmente notificado que qualquer utilizaçào, cópia ou
              divulgação dos dados aqui contidos é proibido.
            </CustomText>
          </CustomBlock>

          {/* Mensagem padrão */}
          {dossierMsg && dossierMsg.body && (
            <>
              <PageBreak />

              <CustomBlock
                p="large"
                mt="xlarge"
                mb="largest"
                override={`border: 1px solid ${theme.colors.type.medium};`}
              >
                <CustomText override="line-height: 1.5;">
                  {ReactHtmlParser(sanitize(dossierMsg.body))}
                </CustomText>
              </CustomBlock>
            </>
          )}

          {/* Mensagem customizada */}
          {history.location.state.customMsg && (
            <CustomText mb="largest" override="line-height: 1.5;">
              {ReactHtmlParser(sanitize(history.location.state.customMsg))}
            </CustomText>
          )}

          {/* Observações */}
          {observations && observations.length > 0 && (
            <>
              <PageBreak />

              <CustomText
                size="1.375rem"
                weight="700"
                mb="xlarge"
                override="text-transform: uppercase; padding-top: 4px;"
              >
                Observações
              </CustomText>

              {reverse(observations).map((observation) => (
                <CustomBlock
                  key={uuidv4()}
                  mb="large"
                  pb="large"
                  override={`border-bottom: 1px solid ${theme.colors.type.medium};`}
                >
                  <FlexAlignCenter>
                    <CustomText size="0.875rem" mb="medium" mr="medium">
                      <strong>Responsável:</strong> {observation.who_created}
                    </CustomText>
                    <CustomText size="0.875rem" mb="medium">
                      <strong>Data:</strong>{' '}
                      {formatDate(observation.created_at)}
                    </CustomText>
                  </FlexAlignCenter>
                  <CustomText
                    size="0.875rem"
                    override="list-style-position: inside;"
                  >
                    {ReactHtmlParser(sanitize(observation.annotation))}
                  </CustomText>
                </CustomBlock>
              ))}
            </>
          )}

          {/* Ocorrências */}
          <PageBreak />
          <CategoryGroups
            filteredOccurrencesByClass={filteredOccurrencesByClass}
            subjectType={subjectType}
          />

          {/* PF's */}
          {relates &&
            relates.map((relate, i) => (
              <Fragment key={uuidv4()}>
                {i === 0 && (
                  <CustomText
                    size="2rem"
                    weight="700"
                    mb="largest"
                    override="padding-top: 4px;"
                  >
                    Ocorrências AAIs
                  </CustomText>
                )}

                <FlexAlignCenter
                  wrap
                  mb="xlarge"
                  p="small"
                  pb="xsmall"
                  override={`border: 1px solid ${theme.colors.type.medium};`}
                >
                  <CustomText mr="large" mb="xsmall">
                    <strong>{relate.profile.name}</strong>
                  </CustomText>
                  {relate.profile.code && (
                    <CustomText mr="large" mb="xsmall">
                      A: <strong>{relate.profile.code}</strong>
                    </CustomText>
                  )}
                  <CustomText mr="large" mb="xsmall">
                    CPF: <strong>{relate.profile.cpf}</strong>
                  </CustomText>
                  {includeRelatesScore && (
                    <CustomText mr="large" mb="xsmall">
                      Score: <strong>{relate.profile.score || 'NA'}</strong>
                    </CustomText>
                  )}
                  {includeRiskRating && (
                    <>
                      <CustomText mr="large" mb="xsmall">
                        Classificação de risco:{' '}
                        <strong>
                          {getRiskRating(
                            riskRatings,
                            relate.profile.posture.risk_rating_id
                          )
                            ? getRiskRating(
                                riskRatings,
                                relate.profile.posture.risk_rating_id
                              ).description
                            : 'NA'}
                        </strong>
                      </CustomText>
                      <CustomText mr="large" mb="xsmall">
                        Próxima análise:{' '}
                        <strong>
                          {formatDate(
                            relate.profile.posture.next_risk_rating_review
                          )}
                        </strong>
                      </CustomText>
                    </>
                  )}
                </FlexAlignCenter>

                <CategoryGroups
                  filteredOccurrencesByClass={filterOccurrences(
                    relate.occurrences,
                    includeNonElegible,
                    occurrenceClasses,
                    posture.collection_id,
                    includePastCollections
                  )}
                  subjectType="people"
                />

                <PageBreak />
              </Fragment>
            ))}
        </>
      ) : (
        <FlexCentered>
          <LoadingSpinner size="40" my="large" />
        </FlexCentered>
      )}
    </Wrapper>
  )
}

export default RenderDossier
