/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  CustomText,
  Button,
  LoadingSpinner,
  Icon
} from '@zeta/ui/src'

import { deleteBoard } from 'api'

const DeleteBoardModal = ({
  /* router */
  history,
  /* props */
  isModalOpen,
  setIsModalOpen
}) => {
  const board = useSelector((state) => state.data.board)

  const [isDeleting, setIsDeleting] = useState(false)

  const deleteFn = () => {
    setIsDeleting(true)

    deleteBoard({ boardId: board.id }).then(() => history.push('/paineis'))
  }

  return (
    <Modal
      title={'Excluir painel'}
      isVisible={isModalOpen}
      closeFn={() => setIsModalOpen(false)}
    >
      <ModalContent>
        <CustomText size={'0.875rem'} mb={'large'}>
          Desejar excluir o painel?
        </CustomText>
      </ModalContent>

      <ModalActions>
        <Button type={'button'} onClick={() => setIsModalOpen(false)} red>
          <Icon icon={'close-outline'} />
          <span>Cancelar</span>
        </Button>

        <Button
          type={'button'}
          onClick={() => deleteFn()}
          disabled={isDeleting}
        >
          <Icon icon={'save-outline'} />
          <span>Confirmar</span>
        </Button>

        {isDeleting && <LoadingSpinner size={'xlarge'} ml={'small'} />}
      </ModalActions>
    </Modal>
  )
}

export default withRouter(DeleteBoardModal)
