/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  Tabs,
  TabsAlt,
  SectionHeadline,
  ButtonAction,
  Icon,
  FlexSpaceBetween
} from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Parts */
import Statistics from 'components/Statistics'
import Occurrences from './Occurrences'
import Subjects from './Subjects'
import Score from './Score'

/* Styled */
import { MiniTabs, MiniTabsButton } from './styled'

/* Constants */
import { subjectTypes } from 'constants/config'

/* Helpers */
import { getPathPrefix, logAmplitudeEvent } from '@zeta/helpers'
import { getDataSetTabsItems } from 'helpers'

const Dashboard = ({
  /* router */
  history,
  computedMatch,
  /* props */
  filteredSummarises,
  filteredSubjects,
  subjectType
}) => {
  const board = useSelector((state) => state.data.board)

  const [activeTab, setActiveTab] = useState('summarises')

  return (
    <ContentWrapper withSidebar>
      <Container>
        <Row>
          <Col>
            <Tabs
              items={[
                {
                  text: 'Análise de risco',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(computedMatch)}/${subjectType}/dashboard`
                    ),
                  isActive: true
                },
                {
                  text: 'Listagem',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(
                        computedMatch
                      )}/${subjectType}/individual`
                    )
                }
              ]}
            />

            <TabsAlt
              items={getDataSetTabsItems({
                subjectTypes,
                history,
                computedMatch,
                screenPath: 'dashboard'
              })}
              mb="xlarge"
            />
          </Col>

          <Statistics
            filteredSummarises={filteredSummarises}
            filteredSubjects={filteredSubjects}
            subjectType={subjectType}
          />

          <Col>
            <FlexSpaceBetween
              override="flex-wrap: wrap; align-items: flex-end;"
              mb="xlarge"
            >
              <div>
                <SectionHeadline mb="small">Resumo por</SectionHeadline>

                <MiniTabs>
                  <MiniTabsButton
                    isActive={activeTab === 'summarises'}
                    onClick={() => setActiveTab('summarises')}
                  >
                    Ocorrências
                  </MiniTabsButton>

                  <MiniTabsButton
                    isActive={activeTab === 'subjects'}
                    onClick={() => {
                      setActiveTab('subjects')
                      logAmplitudeEvent({
                        event:
                          'Ativou visualização por totais de CPFs na análise de risco'
                      })
                    }}
                  >
                    {subjectType === 'people' ? "CPF's" : "CNPJ's"}
                  </MiniTabsButton>

                  <MiniTabsButton
                    isActive={activeTab === 'score'}
                    onClick={() => {
                      setActiveTab('score')
                      logAmplitudeEvent({
                        event: 'Ativou visualização por score análise de risco'
                      })
                    }}
                  >
                    Score
                  </MiniTabsButton>
                </MiniTabs>
              </div>

              <ButtonAction
                onClick={() => {
                  if (board.type === 'board') {
                    history.push(
                      `/painel/${board.id}/${subjectType}/dashboard/print/${activeTab}`
                    )
                    return
                  }

                  history.push(
                    `/dataset/${subjectType}/dashboard/print/${activeTab}`
                  )
                }}
                mt="medium"
              >
                <span>Gerar PDF</span>
                <Icon icon="file-text-outline" />
              </ButtonAction>
            </FlexSpaceBetween>
          </Col>

          {activeTab === 'summarises' && (
            <Occurrences
              filteredSummarises={filteredSummarises}
              filteredSubjects={filteredSubjects}
              subjectType={subjectType}
              activeTab={activeTab}
            />
          )}

          {activeTab === 'subjects' && (
            <Subjects
              filteredSummarises={filteredSummarises}
              filteredSubjects={filteredSubjects}
              subjectType={subjectType}
              activeTab={activeTab}
            />
          )}

          {activeTab === 'score' && filteredSubjects && (
            <Score
              filteredSubjects={filteredSubjects}
              subjectType={subjectType}
            />
          )}
        </Row>
      </Container>

      {/* Footer */}
      <Footer />
    </ContentWrapper>
  )
}

export default Dashboard
