import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  width: 100%;
  height: ${({ theme: { spacing } }) => spacing.largest};
  background-color: ${({ theme: { colors } }) => colors.brand.tertiary.medium};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: flex-end;

  ${defaultProps}
`

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ withSidebar, theme: { spacing } }) =>
      withSidebar && `
        width: calc(100% - 28px);
        padding: 0 ${spacing.medium};
      `}

  @media (min-width: 768px) {
    ${({ withSidebar }) =>
      withSidebar
        ? 'width: calc(100% - 48px);'
        : 'width: 100%;'}
  }

  @media (min-width: 1200px) {
    ${({ withSidebar, isSidebarOpen }) =>
      withSidebar
        ? isSidebarOpen ? 'width: calc(100% - 280px);' : 'width: calc(100% - 48px);'
        : 'width: 100%;'}
  }
`

export const LogoWrapper = styled.div`
  @media (max-width: 767px) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};

    svg {
      width: 32px;

      g.SVGLogoWhiteLetters {
        display: none;
      }
    }
  }

  @media (min-width: 768px) {
    margin-right: ${({ theme: { spacing } }) => spacing.large};
  }
`

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Nav = styled.div`
  display: flex;
  align-items: flex-end;

  @media (min-width: 576px) {
    align-items: center;
  }

  @media (max-width: 575px) {
    flex-direction: column;
  }
`

export const NavItem = styled.button.attrs(() => ({
  type: 'button'
}))`
  color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  font-size: 0.875rem;

  &:not(:first-child) {
    @media (min-width: 576px) {
      margin-left: ${({ theme: { spacing } }) => spacing.large};
    }
  }
`

export const NavWrapperDivider = styled.div`
  width: 1px;
  height: ${({ theme: { spacing } }) => spacing.large};
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  margin-left: ${({ theme: { spacing } }) => spacing.medium};

  @media (min-width: 768px) {
    margin-left: ${({ theme: { spacing } }) => spacing.xlarge};
  }
`

export const UserNav = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 64px;
  padding-left: ${({ theme: { spacing } }) => spacing.small};

  @media (min-width: 768px) {
    padding-left: ${({ theme: { spacing } }) => spacing.xlarge};
  }
`

export const Username = styled.div`
  color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  font-size: 0.875rem;

  @media (max-width: 767px) {
    display: none;
  }
`

export const DropdownBtn = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};

  &:hover {
    color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
    transition: color .3s;
  }
`

export const DropdownBtnSpan = styled.span`
  transform: translateY(2px);

  @media (max-width: 767px) {
    display: none;
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
