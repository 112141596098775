/* React */
import React, { useState } from 'react'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  Icon,
  CustomText,
  CustomBlock,
  Flex,
  FlexAlignCenter,
  theme
} from '@zeta/ui/src'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

/* Styled */
import { CheckboxWrapper, CheckboxSelect, CheckboxLabel } from './styled'

/* Constants */
import { visibleOccurrenceClass } from 'constants/dictionary'

/* Helpers */
import { getPathPrefix, logAmplitudeEvent } from '@zeta/helpers'

export const Checkbox = ({ isSelected, onClickFn, label }) => {
  return (
    <CheckboxWrapper isSelected={isSelected} onClick={onClickFn}>
      <CheckboxSelect>
        <Icon icon="checkmark-outline" size={12} fill="transparent" />
      </CheckboxSelect>

      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrapper>
  )
}

const DossierModal = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectId,
  subjectType,
  isModalVisible,
  setIsModalVisible
}) => {
  const [config, setConfig] = useState({
    includeNonElegible: false,
    includePastCollections: false,
    withRelates: false,
    occurrenceClasses: {
      beneficio_social: true,
      midia: true,
      relacao_societaria: true,
      financeiro: true,
      publico_exposta: true,
      lista_restritiva: true,
      processos: true
    },
    includeNotes: false,
    includeObservations: false,
    includeScore: false,
    includeRelatesScore: false,
    includeRiskRating: false
  })

  const [customMsg, setCustomMsg] = useState('')

  return (
    <Modal
      isVisible={isModalVisible}
      closeFn={() => setIsModalVisible(false)}
      title="Geração de Dossiê"
    >
      <ModalContent>
        <FlexAlignCenter
          override={`
            > *:not(:first-child) {
              margin-left: ${theme.spacing.large};
            }
          `}
        >
          <Checkbox
            isSelected={config.includeNonElegible}
            onClickFn={() =>
              setConfig({
                ...config,
                includeNonElegible: !config.includeNonElegible
              })
            }
            label='Incluir "Não Elegíveis"'
          />

          <Checkbox
            isSelected={config.includePastCollections}
            onClickFn={() =>
              setConfig({
                ...config,
                includePastCollections: !config.includePastCollections
              })
            }
            label="Incluir ocorrências de coletas anteriores"
          />

          {subjectType === 'company' && (
            <Checkbox
              isSelected={config.withRelates}
              onClickFn={() =>
                setConfig({ ...config, withRelates: !config.withRelates })
              }
              label="Incluir PF's"
            />
          )}
        </FlexAlignCenter>

        <CustomText size="0.875rem" weight="700" my="medium">
          Categorias
        </CustomText>

        <Flex>
          <div>
            <Checkbox
              isSelected={config.occurrenceClasses.beneficio_social}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    beneficio_social: !config.occurrenceClasses.beneficio_social
                  }
                })
              }
              label={visibleOccurrenceClass.beneficio_social}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.midia}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    midia: !config.occurrenceClasses.midia
                  }
                })
              }
              label={visibleOccurrenceClass.midia}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.relacao_societaria}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    relacao_societaria: !config.occurrenceClasses
                      .relacao_societaria
                  }
                })
              }
              label={visibleOccurrenceClass.relacao_societaria}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.financeiro}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    financeiro: !config.occurrenceClasses.financeiro
                  }
                })
              }
              label={visibleOccurrenceClass.financeiro}
            />
          </div>

          <CustomBlock ml="xlarge">
            <Checkbox
              isSelected={config.occurrenceClasses.publico_exposta}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    publico_exposta: !config.occurrenceClasses.publico_exposta
                  }
                })
              }
              label={visibleOccurrenceClass.publico_exposta}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.lista_restritiva}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    lista_restritiva: !config.occurrenceClasses.lista_restritiva
                  }
                })
              }
              label={visibleOccurrenceClass.lista_restritiva}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.processos}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    processos: !config.occurrenceClasses.processos
                  }
                })
              }
              label={visibleOccurrenceClass.processos}
            />
          </CustomBlock>
        </Flex>

        <CustomText size="0.875rem" weight="700" my="medium">
          Campos
        </CustomText>
        <Checkbox
          isSelected={config.includeNotes}
          onClickFn={() =>
            setConfig({ ...config, includeNotes: !config.includeNotes })
          }
          label="Comentário Analista"
        />

        <Checkbox
          isSelected={config.includeObservations}
          onClickFn={() =>
            setConfig({
              ...config,
              includeObservations: !config.includeObservations
            })
          }
          label="Observações"
        />

        <Checkbox
          isSelected={config.includeScore}
          onClickFn={() =>
            setConfig({ ...config, includeScore: !config.includeScore })
          }
          label="Score"
        />

        {subjectType === 'company' && (
          <Checkbox
            isSelected={config.includeRelatesScore}
            onClickFn={() =>
              setConfig({
                ...config,
                includeRelatesScore: !config.includeRelatesScore
              })
            }
            label="Score PF's"
          />
        )}

        <Checkbox
          isSelected={config.includeRiskRating}
          onClickFn={() =>
            setConfig({
              ...config,
              includeRiskRating: !config.includeRiskRating
            })
          }
          label="Classificação de Risco"
        />

        <CustomText size="0.875rem" weight="700" mt="xlarge" mb="medium">
          Mensagem customizada (exibida após mensagem padrão)
        </CustomText>
        <ReactQuill
          theme="snow"
          value={customMsg}
          onChange={setCustomMsg}
          modules={{
            toolbar: [['bold', 'italic', 'underline'], ['link']]
          }}
        />
      </ModalContent>

      <ModalActions>
        <Button red onClick={() => setIsModalVisible(false)}>
          <span>Cancelar</span>
        </Button>

        <Button
          onClick={() => {
            logAmplitudeEvent({
              event: 'Dossiê gerado',
              eventProperties: {
                'Incluir não elegíveis': config.includeNonElegible,
                'Incluir PFs': config.withRelates,
                Categorias: Object.keys(config.occurrenceClasses).filter(
                  (occurrenceClass) => config.occurrenceClasses[occurrenceClass]
                ),
                'Comentário Analista': config.includeNotes,
                Observações: config.includeObservations,
                Score: config.includeScore,
                "Score PF's": config.includeRelatesScore,
                'Classificação de Risco': config.includeRiskRating,
                'Tipo do subject': subjectType
              }
            })

            history.push({
              pathname: `${getPathPrefix(
                computedMatch
              )}/${subjectType}/individual/dossie/${subjectId}`,
              state: {
                config,
                customMsg
              }
            })
          }}
          isDisabled={Object.keys(config.occurrenceClasses).every(
            (occurrenceClass) => !config.occurrenceClasses[occurrenceClass]
          )}
        >
          <span>Gerar dossiê</span>
          <Icon icon="folder-outline" />
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default DossierModal
