/* React */
import React from 'react'

/* Components */
import { Col } from 'styled-bootstrap-grid'
import { FlexCentered, LoadingSpinner } from '@zeta/ui/src'
import StatisticsCard from './StatisticsCard'

/* Helpers */
import {
  filterSummarisesByStatus,
  filterSummarisesByExcludingStatus
} from 'components/pages/Dashboard/helpers'

const Statistics = ({
  /* props */
  filteredSummarises,
  filteredSubjects,
  subjectType
}) => {
  return filteredSummarises && filteredSubjects ? (
    <Col>
      <StatisticsCard
        subjectType={subjectType}
        totalListed={filteredSubjects.length}
        totalOccurrences={
          filterSummarisesByExcludingStatus(filteredSummarises, [7]).length
        }
        totalNonElegible={
          filterSummarisesByStatus(filteredSummarises, 7).length
        }
        totalConcluded={filterSummarisesByStatus(filteredSummarises, 5).length}
        showFilters={true}
        mb="xlarge"
      />
    </Col>
  ) : (
    <Col>
      <FlexCentered>
        <LoadingSpinner size="40" mb="large" />
      </FlexCentered>
    </Col>
  )
}

export default Statistics
