import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
// import { composeWithDevTools } from 'redux-devtools-extension'

import reducers from './reducers'

import { getInitialAuthData } from '@zeta/user/src/api'

import {
  signInWatcher,
  updateUserInfoWatcher
} from '@zeta/user/src/store/reducers/user/sagas'
import {
  getAndSetBoardWatcher,
  getAndSetMainDataWatcher,
  getAndSetPeopleWatcher,
  getAndSetCompaniesWatcher,
  createRiskRatingWatcher
} from 'store/reducers/data/sagas'
import {
  getAndSetSubjectWatcher,
  getAndSetPostureWatcher
} from 'store/reducers/individual/sagas'
import {
  editOccurrenceStatusWatcher,
  editPostureWatcher,
  toggleSubjectSituationWatcher,
  createSubjectObservationWatcher,
  editRiskRatingWatcher,
  deleteRiskRatingWatcher,
  editSubjectRiskRatingWatcher
} from 'store/reducers/sagas'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger')
  middlewares.push(createLogger({ diff: false }))
}

const store = createStore(
  reducers,
  getInitialAuthData(),
  applyMiddleware(...middlewares)
  // process.env.NODE_ENV === 'production' ? applyMiddleware(...middlewares) : composeWithDevTools(applyMiddleware(...middlewares))
)

function* rootSaga() {
  yield all([
    // user
    signInWatcher(),
    updateUserInfoWatcher(),
    // data
    getAndSetBoardWatcher(),
    getAndSetMainDataWatcher(),
    getAndSetPeopleWatcher(),
    getAndSetCompaniesWatcher(),
    createRiskRatingWatcher(),
    // individual
    getAndSetSubjectWatcher(),
    getAndSetPostureWatcher(),
    // global
    editOccurrenceStatusWatcher(),
    editPostureWatcher(),
    toggleSubjectSituationWatcher(),
    createSubjectObservationWatcher(),
    editRiskRatingWatcher(),
    deleteRiskRatingWatcher(),
    editSubjectRiskRatingWatcher()
  ])
}

sagaMiddleware.run(rootSaga)

export default store
