/* React */
import React from 'react'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  Card,
  CustomText,
  HorizontalScroll,
  TableInfo,
  ButtonWithIcon,
  FlexAlignCenter,
  theme
} from '@zeta/ui/src'

import Stepline from 'components/pages/Individual/Stepline'

import OccurrenceCard, { headers } from './OccurrenceCard'

/* Parts */
import ClassSummaryTable from './ClassSummaryTable'
import Movements from './Movements'
import CnaeOccurrenceCard from './CnaeOccurrenceCard'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { formatDate, logAmplitudeEvent } from '@zeta/helpers'
import { toCurrency } from 'helpers'
import { formateOccurrenceRows } from 'components/pages/Individual/helpers'

/* Constants */
import {
  visibleOccurrenceClass,
  visibleOccurrenceType,
  visibleInvolvement
} from 'constants/dictionary'

const OccurrenceClassTab = ({
  occurrences,
  occurrenceClass,
  status,
  elegibleType,
  setIsHistoryModalVisible,
  setHistoryModalClass,
  subjectType,
  subjectName,
  subjectThumbnail
}) => {
  return occurrences ? (
    <Row data-testid="row">
      {occurrences.length > 0 && (
        <Col>
          {elegibleType === 'elegible' && (
            <Card mb="xlarge">
              <FlexAlignCenter mb="medium">
                <CustomText weight="700" mr="small">
                  Status de {visibleOccurrenceClass[occurrenceClass]}
                </CustomText>

                <ButtonWithIcon
                  icon="eye-outline"
                  onClick={() => {
                    setHistoryModalClass(occurrenceClass)
                    setIsHistoryModalVisible(true)

                    logAmplitudeEvent({
                      event: 'Clique no histórico de ações no perfil',
                      eventProperties: { Tipo: 'Categoria' }
                    })
                  }}
                >
                  <span>ver detalhes</span>
                </ButtonWithIcon>
              </FlexAlignCenter>

              <Stepline
                items={[
                  { label: 'Nova identificação' },
                  { label: 'Em análise' },
                  { label: 'Aguardando retorno' },
                  { label: 'Comitê' },
                  { label: 'Solucionado' }
                ]}
                activeItem={status}
                itemCheckBg={theme.colors.surface.secondary.lightness}
                orientationBreakpoint={768}
                mb="xlarge"
                data-testid="categoryStepline"
              />
            </Card>
          )}

          <ClassSummaryTable
            occurrences={occurrences}
            subjectType={subjectType}
          />
        </Col>
      )}

      {occurrenceClass !== 'processos' &&
        occurrences.map((occurrence) => (
          <Col md={6} key={uuidv4()}>
            {occurrence.occurrence_type === 'cnae' ? (
              <CnaeOccurrenceCard
                occurrence={occurrence}
                subjectName={subjectName}
                subjectThumbnail={subjectThumbnail}
              />
            ) : (
              <OccurrenceCard
                title={
                  occurrence.occurrence_class === 'relacao_societaria'
                    ? occurrence.occurrence_type === 'cnae'
                      ? `${occurrence.content.Razao_social} - ID: ${occurrence.id}`
                      : `${occurrence.content.Nome_Razao_Social_Socio} - ID: ${occurrence.id}`
                    : `${
                        visibleOccurrenceType[occurrence.occurrence_type]
                      } - ID: ${occurrence.id}`
                }
                occurrence={occurrence}
                data-testid="occurrenceCard"
              >
                <HorizontalScroll>
                  <TableInfo
                    headers={headers}
                    rows={formateOccurrenceRows(
                      occurrence.occurrence_type,
                      occurrence.occurrence_class,
                      occurrence.content,
                      subjectType
                    )}
                  />
                </HorizontalScroll>
              </OccurrenceCard>
            )}
          </Col>
        ))}

      {occurrenceClass === 'processos' &&
        occurrences.map((occurrence) => (
          <Col key={uuidv4()}>
            <OccurrenceCard
              title={`${visibleOccurrenceType[occurrence.occurrence_type]}${
                occurrence.content.involvement === 'AUTHOR' ||
                occurrence.content.involvement === 'DEFENDANT'
                  ? ` (${visibleInvolvement[occurrence.content.involvement]})`
                  : ''
              } - ID: ${occurrence.id}`}
              occurrence={occurrence}
              data-testid="occurrenceCard"
            >
              <HorizontalScroll>
                <TableInfo
                  headers={[
                    { text: 'Número' },
                    { text: 'Status' },
                    { text: 'Envolvimento' },
                    { text: 'Assunto Principal' },
                    { text: 'Tipo' }
                  ]}
                  rows={[
                    {
                      cells: [
                        { text: occurrence.content['Numero'] },
                        { text: occurrence.content['Status'] },
                        {
                          text:
                            visibleInvolvement[
                              occurrence.content['involvement']
                            ]
                        },
                        { text: occurrence.content['Assunto Principal'] },
                        { text: occurrence.content['Tipo'] }
                      ]
                    }
                  ]}
                  mb="xlarge"
                />

                <TableInfo
                  headers={[
                    { text: 'Nome da Corte' },
                    { text: 'Nível da Corte' },
                    { text: 'Estado' },
                    { text: 'Valor da Causa' },
                    { text: 'Data de Publicação' },
                    // { text: 'Data de Notificação' },
                    { text: 'Data de Captura' }
                  ]}
                  rows={[
                    {
                      cells: [
                        { text: occurrence.content['Nome da Corte'] },
                        { text: occurrence.content['Nivel da Corte'] },
                        { text: occurrence.content['Estado'] },
                        {
                          text: toCurrency(occurrence.content['Valor da Causa'])
                        },
                        {
                          text: formatDate(
                            occurrence.content['Data de Publicacao']
                          )
                        },
                        // { text: formatDate(occurrence.content['Data de Notificacao']) },
                        {
                          text: formatDate(
                            occurrence.content['Data de Captura']
                          )
                        }
                      ]
                    }
                  ]}
                />
              </HorizontalScroll>

              <Movements occurrenceId={occurrence.id} />
            </OccurrenceCard>
          </Col>
        ))}
    </Row>
  ) : null
}

export default OccurrenceClassTab
