/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Components */
import {
  CustomText,
  Chip,
  Button,
  FlexSpaceBetween,
  Icon,
  SectionHeadline,
  theme
} from '@zeta/ui/src'

import BoardStatusBadge from 'components/BoardStatusBadge'

/* Styled */
import {
  Wrapper,
  EditButton,
  SubjectName,
  ItemWrapper,
  ButtonsWrapper
} from './styled'

const visibleStatus = {
  1: 'EM INVESTIGAÇÃO',
  2: 'FINALIZADO'
}

const SidebarHeader = ({
  subjectType,
  boardStatus,
  subjectId,
  subjectName,
  updateDate,
  deleteFn,
  editFn,
  saveFn,
  filteredSubjects,
  ...rest
}) => {
  const subjects = useSelector((state) => state.data.subjects)

  return (
    <>
      <SectionHeadline mt="medium">
        <Icon
          icon={'eye-outline'}
          size="medium"
          fill={theme.colors.brand.primary.medium}
        />
        <span>Exibindo</span>
      </SectionHeadline>

      <Wrapper {...rest}>
        {boardStatus && (
          <BoardStatusBadge status={boardStatus} mb="medium">
            {visibleStatus[boardStatus]}
          </BoardStatusBadge>
        )}

        {subjectId && (
          <FlexSpaceBetween>
            <CustomText
              size="0.75rem"
              weight="700"
              color={theme.colors.brand.primary.medium}
            >
              #{subjectId}
            </CustomText>

            <ButtonsWrapper>
              {deleteFn && (
                <EditButton type="button" onClick={deleteFn}>
                  <Icon
                    icon="trash-2-outline"
                    size="medium"
                    fill={theme.colors.brand.primary.medium}
                  />
                </EditButton>
              )}

              {editFn && (
                <EditButton type="button" onClick={editFn}>
                  <Icon
                    icon="edit-outline"
                    size="medium"
                    fill={theme.colors.brand.primary.medium}
                  />
                </EditButton>
              )}
            </ButtonsWrapper>
          </FlexSpaceBetween>
        )}

        <SubjectName>{subjectName}</SubjectName>

        <ItemWrapper>
          <Icon
            icon="people-outline"
            size="medium"
            fill={theme.colors.brand.primary.medium}
            mr="small"
          />

          <div>
            <CustomText size="0.75rem" mb="smallest">
              Total de {subjectType === 'people' ? 'pessoas' : 'escritórios'}:
            </CustomText>
            <Chip blue>{subjects[subjectType].length}</Chip>
          </div>
        </ItemWrapper>

        <ItemWrapper>
          <Icon
            icon="options-2-outline"
            size="medium"
            fill={theme.colors.brand.primary.medium}
            mr="small"
          />

          <div>
            <CustomText size="0.75rem" mb="smallest">
              Quantidade de{' '}
              {subjectType === 'people' ? 'pessoas' : 'escritórios'} após a
              seleção dos filtros:
            </CustomText>
            <Chip>
              {filteredSubjects
                ? filteredSubjects.length
                : subjects[subjectType].length}
            </Chip>
          </div>
        </ItemWrapper>

        {updateDate && (
          <ItemWrapper>
            <Icon
              icon="clock-outline"
              size="medium"
              fill={theme.colors.brand.primary.medium}
              mr="small"
            />

            <div>
              <CustomText size="0.75rem" mb="smallest">
                Atualizado em:
              </CustomText>
              <Chip blue>{updateDate}</Chip>
            </div>
          </ItemWrapper>
        )}

        {saveFn && (
          <Button type="button" green fullWidth onClick={saveFn}>
            <Icon icon="save-outline" />
            <span>Salvar painel</span>
          </Button>
        )}
      </Wrapper>
    </>
  )
}

export default SidebarHeader
