import { axiosInstance } from '@zeta/user/src/api'

import store from 'store'
import { signOut } from '@zeta/user/src/store/reducers/user/action-creators'

/* Configura o erro de token expirado */
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axiosInstance.defaults.headers.common['Authorization']) {
      if (error.response && error.response.status === 401) {
        alert('Sessão expirada. Faça o login novamente.')

        store.dispatch(signOut())
      } else {
        console.log(error)
      }
    }

    return Promise.reject(error)
  }
)

const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json'
  }
})

/***** BOARDS *****/

/**
 *
 * Retorna o index de boards
 * @return {Promise}
 *
 */
export const getBoardsList = () => axiosInstance.get('/reports', getHeaders())

/**
 *
 * Retorna um board
 * @param {Number} boardId
 * @return {Promise}
 *
 */
export const getBoard = ({ boardId }) =>
  axiosInstance.get(`/reports/${boardId}`, getHeaders())

/**
 *
 * Cria um board
 * @param {String} name
 * @param {Object} configBody {filters, etc.}
 * @return {Promise}
 *
 */
export const createBoard = ({
  name,
  // description,
  configBody
}) => {
  const body = {
    name,
    // description,
    config_body: configBody
  }

  return axiosInstance.post('/reports', body, getHeaders())
}

/**
 *
 * Salva um board
 * @param {Number} boardId
 * @param {Object} configBody {filters, etc.}
 * @return {Promise}
 *
 */
export const saveBoard = ({ boardId, name, configBody }) => {
  const body = {
    name,
    config_body: configBody
  }

  return axiosInstance.put(`/reports/${boardId}`, body, getHeaders())
}

/**
 *
 * Edita o nome de um board
 * @param {Number} boardId
 * @param {String} name
 * @return {Promise}
 *
 */
export const editBoard = ({
  boardId,
  name
  // description
}) => {
  const body = {
    name
    // description
  }

  return axiosInstance.put(`/reports/${boardId}`, body, getHeaders())
}

/**
 *
 * Deleta um board
 * @param {Number} boardId
 * @return {Promise}
 *
 */
export const deleteBoard = ({ boardId }) =>
  axiosInstance.delete(`/reports/${boardId}`, getHeaders())

/***** RISK RATINGS *****/

/**
 *
 * Retorna os risk ratings
 * @return {Promise}
 *
 */
export const getRiskRatings = () =>
  axiosInstance.get('/risk_ratings', getHeaders())

/**
 *
 * Cria um risk rating
 * @param {String} description
 * @param {Number} periodicity // em meses
 * @return {Promise}
 *
 */
export const createRiskRating = ({ description, periodicity }) => {
  const body = {
    risk_rating: {
      description,
      periodicity_in_months: periodicity
    }
  }

  return axiosInstance.post('/risk_ratings', body, getHeaders())
}

/**
 *
 * Edita um risk rating
 * @param {String} description
 * @param {Number} periodicity // em meses
 * @param {Number} riskRatingId
 * @return {Promise}
 *
 */
export const editRiskRating = ({ description, periodicity, riskRatingId }) => {
  const body = {
    risk_rating: {
      description,
      periodicity_in_months: periodicity
    }
  }

  return axiosInstance.patch(
    `/risk_ratings/${riskRatingId}`,
    body,
    getHeaders()
  )
}

/**
 *
 * Deleta um risk rating
 * @param {Number} riskRatingId
 * @return {Promise}
 *
 */
export const deleteRiskRating = ({ riskRatingId }) =>
  axiosInstance.delete(`/risk_ratings/${riskRatingId}`, getHeaders())

/***** DOSSIÊ *****/

/**
 *
 * Retorna os relates de um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const getSubjectRelates = ({ subjectId, collectionId }) =>
  axiosInstance.get(
    `/legal_entities/${subjectId}/private_people_occurrences${
      collectionId ? '?collection_id=' + collectionId : ''
    }`,
    getHeaders()
  )

/**
 *
 * Retorna a mensagem mais recente do dossiê
 * @return {Promise}
 *
 */
export const getDossierMsg = () =>
  axiosInstance.get('/dossier_message_templates', getHeaders())

/**
 *
 * Cria uma nova mensagem para o dossiê
 * @param {String} title
 * @param {String} msg
 * @return {Promise}
 *
 */
export const createDossierMsg = ({ title, msg }) => {
  const body = {
    dossier_message_template: {
      subject: title,
      body: msg
    }
  }

  return axiosInstance.post('/dossier_message_templates', body, getHeaders())
}

/**
 *
 * Envia o dossiê por e-mail
 * @param {Number} postureId
 * @param {Array[String]} include
 * @param {Array[String]} occurrenceClasses
 * @return {Promise}
 *
 */
export const sendDossierEmail = ({
  postureId,
  include,
  occurrenceClasses,
  collection_id,
  customMsg
}) => {
  const body = {
    filter: {
      include,
      occurrence_classes: occurrenceClasses,
      collection_id
    },
    email_message: customMsg
  }

  return axiosInstance.post(
    `/postures/${postureId}/dossiers`,
    body,
    getHeaders()
  )
}

/**
 *
 * Envia o dossiê em massa por e-mail
 * @param {Array[Number]} subjectPostureIds
 * @param {Array[String]} include
 * @param {Array[String]} occurrenceClasses
 * @return {Promise}
 *
 */
export const sendBulkDossierEmail = ({
  subjectPostureIds,
  include,
  occurrenceClasses,
  collection_id,
  customMsg
}) => {
  const body = {
    posture_ids: subjectPostureIds,
    filter: {
      include,
      occurrence_classes: occurrenceClasses,
      collection_id
    },
    email_message: customMsg
  }

  return axiosInstance.post(`/postures/dossiers`, body, getHeaders())
}

/***** SUBJECT *****/

/**
 *
 * Retorna um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @return {Promise}
 *
 */
export const getSubject = ({ subjectId, subjectType }) =>
  axiosInstance.get(
    `/${
      subjectType === 'people' ? 'private_people' : 'legal_entities'
    }/${subjectId}`,
    getHeaders()
  )

/**
 *
 * Retorna as ocorrências de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @return {Promise}
 *
 */
export const getSubjectOccurrences = ({ subjectId, subjectType }) =>
  axiosInstance.get(
    `/${
      subjectType === 'people' ? 'private_people' : 'legal_entities'
    }/${subjectId}/occurrences`,
    getHeaders()
  )

/**
 *
 * Retorna o posture de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @return {Promise}
 *
 */
export const getPosture = ({ subjectId, subjectType }) =>
  axiosInstance.get(
    `/postures/${subjectId}?subject_type=${
      subjectType === 'people' ? 'PrivatePerson' : 'LegalEntity'
    }`,
    getHeaders()
  )

/**
 *
 * Edita o posture de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @param {Object} body
 * @return {Promise}
 *
 */
export const editPosture = ({ subjectId, subjectType, body }) =>
  axiosInstance.put(
    `/postures/${subjectId}?subject_type=${
      subjectType === 'people' ? 'PrivatePerson' : 'LegalEntity'
    }`,
    body,
    getHeaders()
  )

/**
 *
 * Edita o risk rating de um subject
 * @param {Number} postureId
 * @param {Number | ''} riskRatingId
 * @return {Promise}
 *
 */
export const editSubjectRiskRating = ({ postureId, riskRatingId }) => {
  const body = {
    risk_rating_id: riskRatingId
  }

  return axiosInstance.patch(
    `/postures/${postureId}/risk_ratings`,
    body,
    getHeaders()
  )
}

/**
 *
 * Retorna a timeline de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @param {Number} collectionId
 * @return {Promise}
 *
 */
export const getSubjectTimeline = ({ subjectId, subjectType, collectionId }) =>
  axiosInstance.get(
    `/${
      subjectType === 'people' ? 'private_people' : 'legal_entities'
    }/${subjectId}/timeline${
      collectionId ? '?collection_id=' + collectionId : ''
    }`,
    getHeaders()
  )

/**
 *
 * Retorna as collections de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @return {Promise}
 *
 */
export const getSubjectCollections = ({ subjectId, subjectType }) =>
  axiosInstance.get(
    `/subject_collections?subject_type=${
      subjectType === 'people' ? 'PrivatePerson' : 'LegalEntity'
    }&subject_id=${subjectId}`,
    getHeaders()
  )

/**
 *
 * Edita o situation de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @param {Boolean} situation
 * @return {Promise}
 *
 */
export const editSubjectSituation = ({ subjectId, subjectType, situation }) => {
  const body = {
    [subjectType === 'people' ? 'private_person' : 'legal_entity']: {
      situation
    }
  }

  return axiosInstance.put(
    `/${
      subjectType === 'people' ? 'private_people' : 'legal_entities'
    }/${subjectId}`,
    body,
    getHeaders()
  )
}

/**
 *
 * Retorna as observations de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @return {Promise}
 *
 */
export const getSubjectObservations = ({ subjectId, subjectType }) =>
  axiosInstance.get(
    `/${
      subjectType === 'people' ? 'private_people' : 'legal_entities'
    }/${subjectId}/observations`,
    getHeaders()
  )

/**
 *
 * Cria uma observation de um subject
 * @param {Number} subjectId
 * @param {String} subjectType 'people' / 'company'
 * @param {String} observation
 * @return {Promise}
 *
 */
export const createSubjectObservation = ({
  subjectId,
  subjectType,
  observation
}) => {
  const body = {
    annotation: observation,
    // risk: 'none_risk_defined',
    observable_id: subjectId,
    observable_type: subjectType === 'people' ? 'PrivatePerson' : 'LegalEntity'
  }

  return axiosInstance.post('/observations', body, getHeaders())
}

/**
 *
 * Edita o status de uma ocorrência
 * @param {Number} occurrenceId
 * @param {Number} status
 * @param {String} notes
 * @return {Promise}
 *
 */
export const editOccurrence = ({ occurrenceId, status, notes }) => {
  const body = {
    occurrence: {
      status,
      notes
    }
  }

  return axiosInstance.put(
    `/occurrences/${occurrenceId}/status`,
    body,
    getHeaders()
  )
}

/**
 *
 * Retorna as partes de um processo
 * @param {Number} occurrenceId
 * @param {Number} page
 * @param {Number} perPage
 * @return {Promise}
 *
 */
export const getLawsuitParts = ({ occurrenceId, page, perPage }) =>
  axiosInstance.get(
    `/occurrences/${occurrenceId}/processes_parts?page=${page || 1}&per_page=${
      perPage || 3
    }`,
    getHeaders()
  )

/**
 *
 * Retorna as movimentações de um processo
 * @param {Number} occurrenceId
 * @param {Number} page
 * @param {Number} perPage
 * @return {Promise}
 *
 */
export const getLawsuitMovements = ({ occurrenceId, page, perPage }) =>
  axiosInstance.get(
    `/occurrences/${occurrenceId}/processes_movements?page=${
      page || 1
    }&per_page=${perPage || 1}`,
    getHeaders()
  )

/**
 *
 * Retorna as decisões de um processo
 * @param {Number} occurrenceId
 * @param {Number} page
 * @param {Number} perPage
 * @return {Promise}
 *
 */
export const getLawsuitDecisions = ({ occurrenceId, page, perPage }) =>
  axiosInstance.get(
    `/occurrences/${occurrenceId}/processes_decisions?page=${
      page || 1
    }&per_page=${perPage || 3}`,
    getHeaders()
  )

/**
 *
 * Retorna os CNAE's de uma ocorrência do tipo "cnae"
 * @param {Number} occurrenceId
 * @return {Promise}
 *
 */
export const getOccurrenceCnaes = ({ occurrenceId }) =>
  axiosInstance.get(`/occurrence/${occurrenceId}/cnaes`, getHeaders())

/**
 *
 * Retorna as filiais de uma ocorrência do tipo "cnae"
 * @param {Number} occurrenceId
 * @return {Promise}
 *
 */
export const getOccurrenceSubsidiaries = ({ occurrenceId }) =>
  axiosInstance.get(`/occurrence/${occurrenceId}/subsidiaries`, getHeaders())

/***** SUMMARISES / SUBJECTS *****/

/**
 *
 * Retorna os summarises
 * @param {Array[Number]} subjectsIds
 * @param {String} subjectType PrivatePerson | LegalEntity
 * @return {Promise}
 *
 */
export const getSummarises = (subjectsIds, subjectType) =>
  axiosInstance.get(
    `/summarises?per_page=999999999999999${
      subjectType ? `&subject_type=${subjectType}` : ''
    }${subjectsIds ? `&subject_ids=${subjectsIds}` : ''}`,
    getHeaders()
  )

/**
 *
 * Retorna a lista de AAIs
 * @param {Array[Number]} subjectsIds
 * @return {Promise}
 *
 */
export const getPeople = (subjectsIds) =>
  axiosInstance.get(
    `/private_people${subjectsIds ? `?subject_ids=${subjectsIds}` : ''}`,
    getHeaders()
  )

/**
 *
 * Retorna a lista de escritórios
 * @param {Array[Number]} subjectsIds
 * @return {Promise}
 *
 */
export const getCompanies = (subjectsIds) =>
  axiosInstance.get(
    `/legal_entities${subjectsIds ? `?subject_ids=${subjectsIds}` : ''}`,
    getHeaders()
  )

/***** EXPORT *****/

/**
 *
 * Exporta a listagem de subjects
 * @param {String} subjectType 'people' | 'company'
 * @return {Promise}
 *
 */
export const exportSubjectsList = ({ subjectType }) =>
  axiosInstance({
    method: 'get',
    url: `/${
      subjectType === 'people' ? 'private_people' : 'legal_entities'
    }/exports`,
    responseType: 'blob',
    APIContentType: 'text/csv'
  })
