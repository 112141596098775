import styled from 'styled-components'

import { Card } from '@zeta/ui/src'

export const Wrapper = styled.div`
  width: 280px;
  height: calc(100vh - ${({ theme: { spacing } }) => spacing.largest});
  padding: ${({ theme: { spacing } }) => `${spacing.large} ${spacing.medium}`};
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border-right: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  position: fixed;
  z-index: 10;
  top: ${({ theme: { spacing } }) => spacing.largest};
  left: -252px;

  @media (min-width: 768px) {
    left: -232px;
  }

  ${({ isSidebarOpen }) => isSidebarOpen && `
    left: 0 !important;

    @media (max-width: 1199px) {
      box-shadow: 2px 2px 4px 2px rgba(34, 36, 38, 0.1);
    }
  `};

  ${({ isSidebarOpen }) => !isSidebarOpen && `
    > * {
      visibility: hidden;
    }
  `};
`

export const ButtonCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    margin-left: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const ToggleSidebarBtn = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.darkness};
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: ${({ theme: { spacing } }) => spacing.large};
  visibility: visible !important;
`
