/* React */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import {
  Modal,
  ModalContent,
  HorizontalScroll,
  TableInfo,
  Card,
  FlexSpaceBetween,
  Icon,
  CustomText,
  CustomBlock,
  LoadingSpinner,
  theme
} from '@zeta/ui/src'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import ReactHtmlParser from 'react-html-parser'
import { sanitize } from 'dompurify'
import { formatDate } from '@zeta/helpers'
import { formatTimeline } from '../../helpers'
import { getSubjectTimeline } from 'api'

/* Constants */
import {
  visibleOccurrenceClass,
  visibleOccurrenceType,
  visibleStatus
} from 'constants/dictionary'

export const HistoryModal = ({
  isHistoryModalVisible,
  setIsHistoryModalVisible,
  historyModalClass,
  setHistoryModalClass,
  subjectId,
  subjectType
}) => {
  const occurrencesByClass = useSelector(
    (state) => state.individual.occurrencesByClass
  )

  const posture = useSelector((state) => state.individual.posture)

  const [timeline, setTimeline] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)

    getSubjectTimeline({
      subjectId,
      subjectType,
      collectionId: posture.collection_id
    }).then((res) => {
      if (mounted) {
        setTimeline(res.data)
        setIsLoading(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  const [openedClassTables, setOpenedClassTables] = useState({
    observations: false,
    cadastrais: historyModalClass === 'cadastrais',
    financeiro: historyModalClass === 'financeiro',
    lista_restritiva: historyModalClass === 'lista_restritiva',
    processos: historyModalClass === 'processos',
    publico_exposta: historyModalClass === 'publico_exposta',
    relacao_societaria: historyModalClass === 'relacao_societaria'
  })

  const toggleOpenedClassTables = (occurrenceClass) =>
    setOpenedClassTables({
      ...openedClassTables,
      [occurrenceClass]: !openedClassTables[occurrenceClass]
    })

  useEffect(
    () => () => {
      setHistoryModalClass(null)
    },
    []
  )

  return (
    <Modal
      isVisible={isHistoryModalVisible}
      closeFn={() => setIsHistoryModalVisible(false)}
      title="Histórico de ações"
      bodyWidth={832}
    >
      <ModalContent>
        {timeline &&
          timeline.length > 0 &&
          Object.keys(formatTimeline(timeline))
            .filter((occurrenceClass) => occurrenceClass === historyModalClass)
            .map((occurrenceClass) => (
              <Card mb="small" key={uuidv4()}>
                <FlexSpaceBetween>
                  <CustomText
                    weight="700"
                    color={theme.colors.brand.primary.medium}
                  >
                    {visibleOccurrenceClass[occurrenceClass]}
                  </CustomText>

                  <button
                    type="button"
                    onClick={() => toggleOpenedClassTables(occurrenceClass)}
                  >
                    <Icon
                      icon={
                        openedClassTables[occurrenceClass]
                          ? 'arrowhead-up-outline'
                          : 'arrowhead-down-outline'
                      }
                      fill={theme.colors.brand.primary.medium}
                      size="medium"
                      ml="medium"
                    />
                  </button>
                </FlexSpaceBetween>

                {openedClassTables[occurrenceClass] && (
                  <>
                    <HorizontalScroll mt="medium">
                      <TableInfo
                        headers={[
                          { text: 'Responsável' },
                          { text: 'Classificação' },
                          { text: 'Comentário' },
                          { text: 'Data' }
                        ]}
                        rows={formatTimeline(timeline)
                          [occurrenceClass].map((occurrenceLogs) => [
                            {
                              cells: [
                                {
                                  text: (
                                    <strong>
                                      {`${
                                        occurrenceLogs[0].occurrence_class ===
                                        'relacao_societaria'
                                          ? occurrenceLogs[0]
                                              .occurrence_type === 'cnae'
                                            ? occurrencesByClass.relacao_societaria.find(
                                                (occurrence) =>
                                                  occurrence.id ===
                                                  occurrenceLogs[0]
                                                    .occurrence_id
                                              ).content.Razao_social
                                            : occurrencesByClass.relacao_societaria.find(
                                                (occurrence) =>
                                                  occurrence.id ===
                                                  occurrenceLogs[0]
                                                    .occurrence_id
                                              ).content.Nome_Razao_Social_Socio
                                          : visibleOccurrenceType[
                                              occurrenceLogs[0].occurrence_type
                                            ]
                                      } - ID: ${
                                        occurrenceLogs[0].occurrence_id
                                      }`}
                                    </strong>
                                  ),
                                  colSpan: 4
                                }
                              ]
                            },
                            ...occurrenceLogs.map((occurrenceLog) => ({
                              cells: [
                                { text: occurrenceLog.who_changed },
                                {
                                  text:
                                    visibleStatus[
                                      occurrenceLog.occurrence_status
                                    ]
                                },
                                {
                                  text: ReactHtmlParser(
                                    sanitize(occurrenceLog.notes)
                                  )
                                },
                                { text: formatDate(occurrenceLog.updated_at) }
                              ]
                            }))
                          ])
                          .reduce((acc, cur) => acc.concat(cur), [])}
                      />
                    </HorizontalScroll>
                  </>
                )}
              </Card>
            ))}

        {timeline &&
          timeline.filter(
            (timelineItem) =>
              timelineItem.occurrence_class === historyModalClass
          ).length === 0 && (
            <CustomText size="0.875rem">Nenhum dado a ser exibido.</CustomText>
          )}

        {isLoading && (
          <CustomBlock override="width: 100%; display: flex; justify-content: center;">
            <LoadingSpinner size="xlarge" />
          </CustomBlock>
        )}
      </ModalContent>
    </Modal>
  )
}

export default HistoryModal
