/* React */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  FlexCentered,
  Tabs,
  LoadingSpinner,
  CollapseAlt,
  UnitSwitcher,
  CustomText,
  theme
} from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Parts */
import ActionsBar from './ActionsBar'
import ProfileHeader from './ProfileHeader'
import ProfileInfo from './ProfileInfo'
import EmployeesTable from './EmployeesTable'
import Posture from './Posture'
import OccurrencesTabs from './OccurrencesTabs'
import SelectedFilters from './SelectedFilters'

/* Helpers */
import { path } from 'ramda'
import { getPathPrefix, logAmplitudeEvent } from '@zeta/helpers'
import { clearFiltersObj } from 'helpers'
import { formatEmployees } from './helpers'

/* Action Creators */
import {
  getAndSetSubject,
  setViewFilteredPublications
} from 'store/reducers/individual/action-creators'

const Individual = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType,
  subjectId,
  filteredSummarises
}) => {
  const dispatch = useDispatch()

  const subjects = useSelector((state) => state.data.subjects)
  const filters = useSelector((state) => state.data.filters)
  const individual = useSelector((state) => state.individual.individual)
  const occurrencesByClass = useSelector(
    (state) => state.individual.occurrencesByClass
  )
  const isLoadingIndividual = useSelector(
    (state) => state.individual.isLoadingIndividual
  )
  const viewFilteredPublications = useSelector(
    (state) => state.individual.viewFilteredPublications
  )

  const routerProps = { history, computedMatch }
  const subjectProps = { subjectType, subjectId }

  useEffect(() => {
    if (!path(['location', 'state', 'preventGetSubject'], history)) {
      dispatch(getAndSetSubject({ subjectId, subjectType }))
    }

    if (Object.keys(filters).length > 0) {
      logAmplitudeEvent({
        event: 'Acessou um perfil com filtros ativos'
      })
    }
  }, [subjectType, subjectId])

  return (
    <>
      <ContentWrapper withSidebar>
        <Container>
          <Row>
            <Col>
              <Tabs
                items={[
                  {
                    text: 'Análise de risco',
                    onClickFn: () =>
                      history.push(
                        `${getPathPrefix(
                          computedMatch
                        )}/${subjectType}/dashboard`
                      )
                  },
                  {
                    text: 'Listagem',
                    onClickFn: () =>
                      history.push(
                        `${getPathPrefix(
                          computedMatch
                        )}/${subjectType}/individual`
                      ),
                    isActive: true
                  }
                ]}
              />

              {isLoadingIndividual && (
                <FlexCentered>
                  <LoadingSpinner size="40" my="large" />
                </FlexCentered>
              )}
            </Col>

            {individual &&
              occurrencesByClass &&
              !isLoadingIndividual &&
              (subjectType === 'people' ||
                (subjectType === 'company' && individual.relates_id)) && (
                <>
                  <Col>
                    <ActionsBar individual={individual} {...routerProps} />

                    <ProfileHeader
                      individual={individual}
                      subjectType={subjectType}
                      {...routerProps}
                    />

                    <ProfileInfo
                      individual={individual}
                      subjectType={subjectType}
                    />

                    {subjectType === 'company' &&
                      individual.relates_id.length > 0 && (
                        <CollapseAlt
                          title="Pessoas vinculadas a esta empresa"
                          override={`margin-top: ${theme.spacing.xsmall}`}
                        >
                          <EmployeesTable
                            employees={formatEmployees(
                              individual.relates_id,
                              subjects.people
                            )}
                            onClickFn={(id) => {
                              history.push(
                                `${getPathPrefix(
                                  computedMatch
                                )}/people/individual/${id}`
                              )

                              logAmplitudeEvent({
                                event:
                                  'Clique em perfil vinculado a um escritório'
                              })
                            }}
                            computedMatch={computedMatch}
                          />
                        </CollapseAlt>
                      )}

                    <Posture
                      individual={individual}
                      {...subjectProps}
                      {...routerProps}
                    />

                    {filters &&
                      Object.keys(clearFiltersObj(filters, 'summarises'))
                        .length > 0 && (
                        <>
                          <UnitSwitcher
                            items={[
                              {
                                key: true,
                                text: 'Visualização com filtros',
                                onClickFn: () =>
                                  dispatch(setViewFilteredPublications(true))
                              },
                              {
                                key: false,
                                text: 'Visualização sem filtros',
                                onClickFn: () => {
                                  dispatch(setViewFilteredPublications(false))

                                  logAmplitudeEvent({
                                    event:
                                      'Ativou a visualização sem filtros no perfil'
                                  })
                                }
                              }
                            ]}
                            activeUnit={viewFilteredPublications}
                            mt="xlarge"
                            mb="large"
                          />

                          <CustomText size="0.75rem" mb="small">
                            Filtros aplicados:
                          </CustomText>
                          <SelectedFilters />
                        </>
                      )}

                    <OccurrencesTabs
                      individual={individual}
                      {...subjectProps}
                      filteredSummarises={filteredSummarises}
                    />
                  </Col>
                </>
              )}
          </Row>
        </Container>

        {/* Footer */}
        <Footer />
      </ContentWrapper>
    </>
  )
}

export default Individual
