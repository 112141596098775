/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  Card,
  CustomText,
  Divider,
  theme
} from '@zeta/ui/src'

/* Styled */
import {
  SteplineWrapper,
  ItemsWrapper,
  Item,
  ItemLabel
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

/* Constants */
const visibleStatusKey = {
  underAnalysis: 'em análise',
  waiting: 'aguardando retorno',
  committee: 'comitê',
  solved: 'solucionado'
}

const TotalText = ({ children }) => <CustomText mb='medium' size='0.875rem' override='@media print { margin-bottom: 8px; }'>{children}</CustomText>

const OccurrenceCategoryCard = ({
  /* props */
  title,
  totals,
  status,
  onClickFn,
  subjectType,
  activeTab
}) => {
  return (
    <Card
      title={title}
      as={onClickFn ? 'button' : 'div'}
      onClick={() => onClickFn && onClickFn()}
      mb='large'
    >
      <TotalText><strong>{totals.totalListed}</strong> {subjectType === 'people' ? 'pessoas' : 'escritórios'} com ocorrências identificadas</TotalText>

      {totals.total >= 0 && <TotalText><strong>{totals.total}</strong> ocorrências elegíveis</TotalText>}

      {totals.totalNonElegible >= 0 && <TotalText><strong>{totals.totalNonElegible}</strong> ocorrências não elegíveis</TotalText>}

      <TotalText>
        <strong>{totals.totalUnclassified}</strong> {activeTab === 'subjects' ? `${subjectType === 'people' ? 'pessoas' : 'escritórios'} com` : ''} ocorrências <strong>não analisadas</strong>
      </TotalText>

      {totals.totalFalse >= 0 &&
        <TotalText>
          <strong>{totals.totalFalse}</strong> {activeTab === 'subjects' ? `${subjectType === 'people' ? 'pessoas' : 'escritórios'} com` : ''} ocorrências <strong>improcedentes</strong>
        </TotalText>}

      <Divider mt='small' />

      <CustomText
        size='0.875rem'
        weight='700'
        color={subjectType === 'people' ? theme.colors.brand.primary.dark : theme.colors.brand.secondary.dark}
        mb='medium'
      >
        Status atuais das análises de {title}
      </CustomText>

      <SteplineWrapper>
        <ItemsWrapper>
          {Object.keys(status.totals).map((statusKey, i) => (
            <Item isActive={status.current >= i + 1} subjectType={subjectType} key={uuidv4()}>
              <ItemLabel subjectType={subjectType}><strong>{status.totals[statusKey]}</strong> {visibleStatusKey[statusKey]}</ItemLabel>
            </Item>
          ))}
        </ItemsWrapper>
      </SteplineWrapper>
    </Card>
  )
}

OccurrenceCategoryCard.propTypes = {
  totals: PropTypes.shape({
    totalListed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    totalUnclassified: PropTypes.number.isRequired,
    totalFalse: PropTypes.number.isRequired
  }).isRequired,
  status: PropTypes.shape({
    totals: PropTypes.shape({
      underAnalysis: PropTypes.number.isRequired,
      waiting: PropTypes.number.isRequired,
      solved: PropTypes.number.isRequired,
      committee: PropTypes.number.isRequired,
      cancellation: PropTypes.number.isRequired,
      concluded: PropTypes.number.isRequired
    }),
    current: PropTypes.oneOf([1, 2, 3, 4, 5, 6])
  }),
  onClickFn: PropTypes.func.isRequired
}

export default OccurrenceCategoryCard
