/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { CustomText, CustomBlock, Icon, theme } from '@zeta/ui/src'

/* Styled */
import { Wrapper, Label, ToggleButton } from '../styled'
import {
  InputWrapper,
  Input,
  Prefix,
  Item,
  ItemCheckbox,
  ItemLabel
} from './styled'

/* Helpers */
import { prop } from 'ramda'
import { v4 as uuidv4 } from 'uuid'

const Range = ({
  name,
  label,
  prefix,
  sufix,
  initialRange,
  values,
  setFilterValue,
  removeFilterValue,
  items,
  ...rest
}) => {
  const value = values[name]

  const isSelected = (item) =>
    value && value.start === item.start && value.end === item.end

  return (
    <Wrapper {...rest}>
      {label && <Label>{label}</Label>}

      <div>
        <ToggleButton
          type="button"
          onClick={() => removeFilterValue(name)}
          clear
        >
          Limpar
        </ToggleButton>
      </div>

      <InputWrapper first>
        <CustomText size="0.75rem" override={`width: ${theme.spacing.xlarge}`}>
          Mín.
        </CustomText>

        {prefix && <Prefix>{prefix}</Prefix>}

        <Input
          type="number"
          value={prop('start', value) || ''}
          onChange={(e) =>
            setFilterValue(name, { ...value, start: e.target.value })
          }
          max={prop('end', value) ? Number(prop('end', value) - 1) : ''}
        />

        {sufix && <Prefix>{sufix}</Prefix>}
      </InputWrapper>

      <InputWrapper>
        <CustomText size="0.75rem" override={`width: ${theme.spacing.xlarge}`}>
          Máx.
        </CustomText>

        {prefix && <Prefix>{prefix}</Prefix>}

        <Input
          type="number"
          value={prop('end', value) || ''}
          onChange={(e) =>
            setFilterValue(name, { ...value, end: e.target.value })
          }
          min={prop('start', value) ? Number(prop('start', value)) + 1 : ''}
        />

        {sufix && <Prefix>{sufix}</Prefix>}
      </InputWrapper>

      {initialRange && (
        <CustomText
          size="0.75rem"
          color={theme.colors.brand.tertiary.lightness}
          mt="small"
        >
          mín.: {initialRange[0]} - máx.: {initialRange[1]}
        </CustomText>
      )}

      {items && (
        <CustomBlock mt="medium">
          {items.map((item) => (
            <Item
              key={uuidv4()}
              selected={isSelected(item)}
              onClick={() =>
                isSelected(item)
                  ? removeFilterValue(name)
                  : setFilterValue(name, {
                      ...value,
                      start: item.start,
                      end: item.end
                    })
              }
              type="button"
            >
              <ItemCheckbox>
                <Icon icon="checkmark-outline" size={12} fill="transparent" />
              </ItemCheckbox>

              <ItemLabel>{item.visibleValue}</ItemLabel>
            </Item>
          ))}
        </CustomBlock>
      )}
    </Wrapper>
  )
}

Range.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefix: PropTypes.string,
  sufix: PropTypes.string,
  initialRange: PropTypes.array,
  values: PropTypes.object.isRequired, // herdado de Filter
  setFilterValue: PropTypes.func.isRequired // herdado de Filter
}

export default Range
