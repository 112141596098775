/* Paths das rotas */
export const paths = {
  initial: process.env.REACT_APP_INITIAL_PATH,
  productConfigurations: '/configuracoes-plataforma'
}

/* API */
export const apiDomain = process.env.REACT_APP_API_DOMAIN

/* Subject Types */
export const subjectTypes = {
  people: process.env.REACT_APP_SUBJECT_TYPE_PEOPLE,
  company: process.env.REACT_APP_SUBJECT_TYPE_COMPANY
}

/* Collections */
export const collectionsIds = process.env.REACT_APP_COLLECTIONS_IDS.split(',')
