export const visibleStatus = {
  0: 'Nova identificação',
  1: 'Improcedente',
  2: 'Em análise',
  3: 'Aguardando retorno',
  4: 'Comitê',
  5: 'Solucionado',
  7: 'Não elegível'
}

export const visibleOccurrenceClass = {
  beneficio_social: 'Benefício Social',
  midia: 'Exposição na Mídia',
  relacao_societaria: 'Relações Societárias',
  financeiro: 'Financeiro',
  publico_exposta: 'PPEs',
  lista_restritiva: 'Listas Restritivas',
  processos: 'Processos'
}

export const visibleOccurrenceType = {
  bolsa_familia: 'Bolsa Família',
  auxilio_emergencial: 'Auxílio Emergencial',
  noticia: 'Notícia',
  cnae: 'CNAE',
  quadro_societario: 'Quadro Societário',
  dividas_e_pendencias: 'Dívidas e Pendências',
  protestos: 'Protestos',
  certidao_de_debito: 'Certidão de Débito',
  ppe: 'PPE',
  cac: 'CAC',
  sancoes: 'Sanções',
  civel: 'Cível',
  tributaria: 'Tributário',
  administrativa: 'Administrativo',
  trabalhista: 'Trabalhista',
  especial_civel: 'Especial Cível',
  criminal: 'Criminal',
  fazenda: 'Fazenda',
  previdenciaria: 'Previdenciário',
  especial_fazenda: 'Especial Fazenda',
  especial_criminal: 'Especial Criminal',
  eleitoral: 'Eleitoral',
  outros: 'Outros',
  cvm: 'CVM',
  null: 'Indefinido'
}

export const visibleInvolvement = {
  CLAIMED: 'Requerido',
  Claimed: 'Requerido',
  CLAIMANT: 'Requerente',
  Claimant: 'Requerente',
  DEFENDANT: 'Réu',
  Defendant: 'Réu',
  AUTHOR: 'Autor',
  Author: 'Autor',
  OTHER: 'Outro',
  Other: 'Outro',
  LAWYER: 'Advogado',
  Lawyer: 'Advogado',
  INMATE: 'Detento',
  Inmate: 'Detento',
  WITNESS: 'Testemunha',
  Witness: 'Testemunha',
  ATTORNEY: 'Procurador',
  Attorney: 'Procurador',
  REPORTER: 'Oficial de Justiça',
  Boarder: 'Oficial de Justiça'
}

export const visiblePolarity = {
  ACTIVE: 'Ativo',
  Active: 'Ativo',
  PASSIVE: 'Passivo',
  Passive: 'Passivo',
  NEUTRAL: 'Neutro',
  Neutral: 'Neutro'
}
