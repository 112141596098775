/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import ReactTooltip from 'react-tooltip'

import {
  Card,
  DropdownBtns,
  CustomText,
  FlexSpaceBetween,
  FlexAlignCenter,
  Icon,
  theme,
  Chip
} from '@zeta/ui/src'

import {
  ContentWrapper,
  OccurrenceCardCol,
  OpenOccurrenceButton,
  Title,
  SubTitle,
  Status,
  ActionsWrapper,
  InnerCardWrapper,
  InnerCardHeader,
  InnerCardTitle,
  InnerCardMore,
  InnerCardContent,
  InnerCardRow,
  InnerCardHeadline,
  InnerCardColumnWrapper,
  InnerCardColumn,
  InnerCardTab,
  ContentModalBg,
  ContentModal,
  ContentModalHeader,
  ContentModalTitle,
  PopUpIconWrapper,
  WarningTagWrapper,
  Table,
  HeaderCell,
  TableCell,
  InnerCardLinkWrapper,
  ProfileCellImg
} from './styled'

/* Helpers */
import { logAmplitudeEvent } from '@zeta/helpers'

/* Constants */
import { visibleStatus } from 'constants/dictionary'

/* Action Creators */
import {
  toggleOpenedOccurrence,
  setOccurrenceToClassify
} from 'store/reducers/individual/action-creators'

export const headers = [
  { text: 'Dado' },
  { text: 'Resultado' },
  { text: 'Fonte', textAlign: 'right' }
]

export {
  OccurrenceCardCol,
  InnerCardRow,
  InnerCardHeadline,
  InnerCardColumnWrapper,
  InnerCardColumn
}

export const TableInfoHeadline = ({ children }) => (
  <CustomText size="0.75rem" weight="700">
    {children}
  </CustomText>
)

export const TooltipIcon = ({ dataTip, dataFor }) => (
  <Icon
    icon="question-mark-circle-outline"
    size="medium"
    fill={theme.colors.surface.primary.darkness}
    override="justify-content: flex-end;"
    data-tip={dataTip}
    data-for={dataFor}
  />
)

export const InnerCard = ({
  title,
  onClickMore,
  moreText,
  disableMoreArrow,
  headerContent,
  onClickBack,
  tabs,
  children,
  ...rest
}) => (
  <InnerCardWrapper {...rest}>
    <InnerCardHeader tabs={tabs}>
      <InnerCardTitle>
        {onClickBack && (
          <button type="button" onClick={onClickBack}>
            <Icon
              icon="arrow-back-outline"
              fill={theme.colors.brand.primary.medium}
              size="medium"
              override={`
                transform: translateY(-1px);
                margin-right: ${theme.spacing.medium};
                ${tabs ? `padding-bottom: ${theme.spacing.medium};` : ''}
              `}
            />
          </button>
        )}

        {title && title}

        {tabs && (
          <FlexAlignCenter>
            {tabs.map((tab) => (
              <InnerCardTab onClick={tab.onClickFn} isActive={tab.isActive}>
                {tab.text}
              </InnerCardTab>
            ))}
          </FlexAlignCenter>
        )}
      </InnerCardTitle>

      {headerContent && headerContent()}

      {onClickMore && (
        <InnerCardMore onClick={onClickMore}>
          {moreText}

          {!disableMoreArrow && (
            <Icon
              icon="arrow-ios-forward-outline"
              fill={theme.colors.brand.primary.medium}
              size="12"
              ml="xsmall"
            />
          )}
        </InnerCardMore>
      )}
    </InnerCardHeader>

    <InnerCardContent>{children}</InnerCardContent>
  </InnerCardWrapper>
)

export const InnerCardTable = ({ headers, rows }) => (
  <Table>
    <tr>
      {headers.map((header) => (
        <HeaderCell>{header}</HeaderCell>
      ))}
    </tr>

    {rows.map((row) => (
      <tr>
        {row.map((cell) => (
          <TableCell>{cell}</TableCell>
        ))}
      </tr>
    ))}
  </Table>
)

export const ProfileCell = ({ image, name, onClickFn }) => (
  <FlexAlignCenter override="text-align: left;">
    <ProfileCellImg src={image} />
    <InnerCardLink onClick={onClickFn}>{name}</InnerCardLink>
  </FlexAlignCenter>
)

export const InnerCardLink = ({ onClickFn, children, ...rest }) => (
  <InnerCardLinkWrapper onClick={onClickFn} {...rest}>
    {children}
  </InnerCardLinkWrapper>
)

export const OccurrenceCardContentModal = ({
  isOpen,
  title,
  closeFn,
  children
}) =>
  isOpen && (
    <ContentModalBg>
      <ContentModal>
        <ContentModalHeader>
          <ContentModalTitle>{title}</ContentModalTitle>

          <button type="button" onClick={closeFn}>
            <Icon
              icon="close-outline"
              fill={theme.colors.type.medium}
              size="12"
              ml="xsmall"
            />
          </button>
        </ContentModalHeader>

        {children}
      </ContentModal>
    </ContentModalBg>
  )

export const PopUpIcon = ({ icon, onClickFn }) => (
  <PopUpIconWrapper onClick={onClickFn}>
    <Icon icon={icon} size="medium" fill={theme.colors.brand.primary.medium} />
  </PopUpIconWrapper>
)

export const WarningTag = ({ text, qty, icon }) => (
  <WarningTagWrapper>
    <span>{text}</span>
    {icon && icon()}
    {qty && <span>({qty})</span>}
  </WarningTagWrapper>
)

export const OccurrenceCard = ({
  occurrence,
  contentAfterStatus,
  title,
  subtitle,
  children,
  ...rest
}) => {
  const dispatch = useDispatch()

  const openedOccurrences = useSelector(
    (state) => state.individual.openedOccurrences
  )

  const [isActionsOpen, setIsActionsOpen] = useState(false)

  return (
    <Card
      override={`
        position: relative;
        ${openedOccurrences[occurrence.id] ? '' : 'padding-bottom: 0;'}
      `}
      mb="xlarge"
      {...rest}
    >
      <FlexSpaceBetween mb="medium">
        <div>
          {occurrence.id !== 'registrationInfo' && (
            <Chip mb="small">ID da coleta: {occurrence.collection_id}</Chip>
          )}

          <Title>{title}</Title>

          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </div>

        <FlexAlignCenter>
          <OpenOccurrenceButton
            onClick={() => {
              dispatch(toggleOpenedOccurrence(occurrence.id))

              if (occurrence.occurrence_class === 'processos')
                logAmplitudeEvent({
                  event: 'Expandiu uma ocorrência da categoria Processos'
                })
            }}
            data-testid="openOccurrenceBtn"
          >
            <Icon
              icon={
                openedOccurrences[occurrence.id]
                  ? 'arrowhead-up-outline'
                  : 'arrowhead-down-outline'
              }
              fill={theme.colors.brand.primary.medium}
              size="medium"
            />
          </OpenOccurrenceButton>

          {occurrence.id !== 'registrationInfo' && (
            <ActionsWrapper>
              <button
                type="button"
                onClick={() => setIsActionsOpen(!isActionsOpen)}
              >
                <Icon
                  icon="more-vertical-outline"
                  fill="#666666"
                  size="large"
                />
              </button>

              {isActionsOpen && (
                <DropdownBtns
                  items={[
                    {
                      text: 'Alterar Status',
                      onClickFn: () => {
                        dispatch(
                          setOccurrenceToClassify({
                            id: occurrence.id,
                            occurrenceClass: occurrence.occurrence_class,
                            occurrenceType: occurrence.occurrence_type,
                            status: occurrence.occurrence_status.status,
                            notes: occurrence.occurrence_status.notes
                          })
                        )

                        setIsActionsOpen(false)
                      }
                    }
                  ]}
                  override="right: 0; top: 24px;"
                />
              )}
            </ActionsWrapper>
          )}
        </FlexAlignCenter>
      </FlexSpaceBetween>

      {occurrence.id !== 'registrationInfo' && (
        <>
          <FlexAlignCenter override="flex-wrap: wrap;">
            <Status>
              {visibleStatus[occurrence.occurrence_status.status]}
            </Status>

            {contentAfterStatus && contentAfterStatus()}
          </FlexAlignCenter>
        </>
      )}

      {openedOccurrences[occurrence.id] && (
        <>
          <ContentWrapper>{children}</ContentWrapper>

          <ReactTooltip id="occurrenceTableTooltip" />
        </>
      )}

      <div></div>
    </Card>
  )
}

export default OccurrenceCard
