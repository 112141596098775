/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  LoadingSpinner,
  Icon,
  CustomBlock
} from '@zeta/ui/src'

import { Label, Input } from '../styled'

/* Action Creators */
import { editRiskRating } from 'store/reducers/action-creators'

const EditModal = ({ setModal, riskRatingToEdit }) => {
  const dispatch = useDispatch()

  const riskRatings = useSelector(state => state.data.riskRatings)
  const isEditingRiskRating = useSelector(state => state.data.isEditingRiskRating)

  const [fields, setFields] = useState({
    description: riskRatingToEdit.description,
    periodicity: riskRatingToEdit.periodicity_in_months
  })

  return (
    <Modal
      title='Editar classificação de risco'
      isVisible={true}
      closeFn={() => setModal(null)}
    >
      <form
        onSubmit={e => {
          e.preventDefault()

          dispatch(
            editRiskRating({
              description: fields.description.trim(),
              periodicity: fields.periodicity,
              riskRatingId: riskRatingToEdit.id,
              onSuccess: () => setModal(null)
            })
          )
        }}
      >
        <ModalContent>
          <CustomBlock mb='medium'>
            <Label for='description'>Risco</Label>
            <Input
              id='description'
              type='text'
              value={fields.description}
              onChange={e => setFields({ ...fields, description: e.target.value })}
            />
          </CustomBlock>

          <CustomBlock>
            <Label for='periodicity'>Intervalo de análise (em meses)</Label>
            <Input
              id='periodicity'
              type='number'
              value={fields.periodicity}
              onChange={e => setFields({ ...fields, periodicity: e.target.value })}
            />
          </CustomBlock>
        </ModalContent>

        <ModalActions>
          <Button onClick={() => setModal(null)} red>
            <Icon icon='close-outline' />
            <span>Cancelar</span>
          </Button>

          <Button
            type='submit'
            isDisabled={
              !fields.description ||
              !fields.periodicity ||
              (fields.description.trim() === riskRatingToEdit.description && Number(fields.periodicity) === riskRatingToEdit.periodicity_in_months) ||
              (fields.description.trim() !== riskRatingToEdit.description && riskRatings.some(riskRating => riskRating.description === fields.description.trim())) ||
              isEditingRiskRating
            }
          >
            <Icon icon='checkmark-outline' />
            <span>Confirmar</span>
          </Button>

          {isEditingRiskRating && <LoadingSpinner size='xlarge' ml='small' />}
        </ModalActions>
      </form>
    </Modal>
  )
}

export default EditModal
