/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { UnitSwitcher, Tabs, CustomBlock, defaultAvatar } from '@zeta/ui/src'

/* Parts */
import Registrations from './Registrations'
import OccurrenceClassTab from './OccurrenceClassTab'
import EditOccurenceModal from './EditOccurenceModal'
import HistoryModal from './HistoryModal'

/* Helpers */
import { without } from 'ramda'
import { logAmplitudeEvent } from '@zeta/helpers'
import { getSubjectStatus, getPicture, filterList } from 'helpers'

const OccurrencesTabs = ({ individual, subjectType, subjectId }) => {
  const occurrencesByClass = useSelector(
    (state) => state.individual.occurrencesByClass
  )
  const viewFilteredPublications = useSelector(
    (state) => state.individual.viewFilteredPublications
  )
  const occurrenceToClassify = useSelector(
    (state) => state.individual.occurrenceToClassify
  )
  const summarises = useSelector((state) => state.data.summarises)
  const filters = useSelector((state) => state.data.filters)

  const [activeTab, setActiveTab] = useState('CADASTRAIS')
  const [elegibleType, setElegibleType] = useState('elegible')

  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false)
  const [historyModalClass, setHistoryModalClass] = useState(null)

  const subjectProps = { subjectType, subjectId }
  const historyModalProps = {
    isHistoryModalVisible,
    setIsHistoryModalVisible,
    historyModalClass,
    setHistoryModalClass
  }

  const occurrencesIds = viewFilteredPublications
    ? filterList(summarises[subjectType], filters, 'summarises')
        .filter((summarise) => summarise.subject_id == subjectId)
        .map((summarise) => summarise.occurrence_id)
    : null

  const filteredOccurrencesByClass = viewFilteredPublications
    ? Object.keys(occurrencesByClass).reduce((acc, curKey) => {
        return {
          ...acc,
          [curKey]: {
            elegible: occurrencesByClass[curKey].filter((occurrence) =>
              occurrencesIds.some(
                (occurrencesId) =>
                  occurrencesId == occurrence.id &&
                  occurrence.occurrence_status.status !== 7
              )
            ),
            nonElegible: occurrencesByClass[curKey].filter((occurrence) =>
              occurrencesIds.some(
                (occurrencesId) =>
                  occurrencesId == occurrence.id &&
                  occurrence.occurrence_status.status === 7
              )
            )
          }
        }
      }, {})
    : Object.keys(occurrencesByClass).reduce((acc, curKey) => {
        return {
          ...acc,
          [curKey]: {
            elegible: occurrencesByClass[curKey].filter(
              (occurrence) => occurrence.occurrence_status.status !== 7
            ),
            nonElegible: occurrencesByClass[curKey].filter(
              (occurrence) => occurrence.occurrence_status.status === 7
            )
          }
        }
      }, {})

  const getOccurrencesCount = (elegibleType) =>
    Object.keys(occurrencesByClass).reduce(
      (acc, curKey) =>
        acc + filteredOccurrencesByClass[curKey][elegibleType].length,
      0
    )

  const getCategoryStatus = (occurrences) =>
    without(
      [1],
      occurrences
        .map((occurrence) => occurrence.occurrence_status.status)
        .sort((a, b) => a - b)
    )[0]

  return (
    <>
      <UnitSwitcher
        items={[
          {
            key: 'elegible',
            text: `Elegíveis (${getOccurrencesCount('elegible')})`,
            onClickFn: () => setElegibleType('elegible')
          },
          {
            key: 'nonElegible',
            text: `Não elegíveis (${getOccurrencesCount('nonElegible')})`,
            onClickFn: () => {
              setElegibleType('nonElegible')

              logAmplitudeEvent({
                event: 'Ativou a visualização de "não elegíveis" no perfil'
              })
            }
          }
        ]}
        activeUnit={elegibleType}
        my="large"
      />

      <Tabs
        items={[
          {
            text: 'Cadastrais',
            onClickFn: () => setActiveTab('CADASTRAIS'),
            isActive: activeTab === 'CADASTRAIS',
            count: false
          },
          {
            text: 'Benefício Social',
            onClickFn: () => setActiveTab('BENEFICIO_SOCIAL'),
            isActive: activeTab === 'BENEFICIO_SOCIAL',
            count:
              filteredOccurrencesByClass.beneficio_social[elegibleType].length >
              0
                ? filteredOccurrencesByClass.beneficio_social[elegibleType]
                    .length
                : false
          },
          {
            text: 'Exposição na Mídia',
            onClickFn: () => setActiveTab('MIDIA'),
            isActive: activeTab === 'MIDIA',
            count:
              filteredOccurrencesByClass.midia[elegibleType].length > 0
                ? filteredOccurrencesByClass.midia[elegibleType].length
                : false
          },
          {
            text: 'Relações Societárias',
            onClickFn: () => setActiveTab('SOCIETARIAS'),
            isActive: activeTab === 'SOCIETARIAS',
            count:
              filteredOccurrencesByClass.relacao_societaria[elegibleType]
                .length > 0
                ? filteredOccurrencesByClass.relacao_societaria[elegibleType]
                    .length
                : false
          },
          {
            text: 'Financeiro',
            onClickFn: () => setActiveTab('FINANCEIRO'),
            isActive: activeTab === 'FINANCEIRO',
            count:
              filteredOccurrencesByClass.financeiro[elegibleType].length > 0
                ? filteredOccurrencesByClass.financeiro[elegibleType].length
                : false
          },
          {
            text: 'PPEs',
            onClickFn: () => setActiveTab('PPES'),
            isActive: activeTab === 'PPES',
            count:
              filteredOccurrencesByClass.publico_exposta[elegibleType].length >
              0
                ? filteredOccurrencesByClass.publico_exposta[elegibleType]
                    .length
                : false
          },
          {
            text: 'Listas Restritivas',
            onClickFn: () => setActiveTab('RESTRITIVAS'),
            isActive: activeTab === 'RESTRITIVAS',
            count:
              filteredOccurrencesByClass.lista_restritiva[elegibleType].length >
              0
                ? filteredOccurrencesByClass.lista_restritiva[elegibleType]
                    .length
                : false
          },
          {
            text: 'Processos',
            onClickFn: () => setActiveTab('PROCESSOS'),
            isActive: activeTab === 'PROCESSOS',
            count:
              filteredOccurrencesByClass.processos[elegibleType].length > 0
                ? filteredOccurrencesByClass.processos[elegibleType].length
                : false
          }
        ]}
        my="xlarge"
        data-testid="categoriesTabs"
      />

      <CustomBlock mb="xlarge">
        {activeTab === 'CADASTRAIS' && (
          <>
            <Registrations individual={individual} subjectType={subjectType} />

            <OccurrenceClassTab
              occurrences={[]}
              occurrenceClass="cadastrais"
              subjectType={subjectType}
              {...historyModalProps}
            />
          </>
        )}

        {activeTab === 'BENEFICIO_SOCIAL' && (
          <OccurrenceClassTab
            occurrences={filteredOccurrencesByClass.beneficio_social[
              elegibleType
            ].sort((a, b) => b.collection_id - a.collection_id)}
            occurrenceClass="beneficio_social"
            status={getCategoryStatus(
              filteredOccurrencesByClass.beneficio_social['elegible']
            )}
            elegibleType={elegibleType}
            subjectType={subjectType}
            {...historyModalProps}
          />
        )}

        {activeTab === 'MIDIA' && (
          <OccurrenceClassTab
            occurrences={filteredOccurrencesByClass.midia[elegibleType].sort(
              (a, b) => b.collection_id - a.collection_id
            )}
            occurrenceClass="midia"
            status={getCategoryStatus(
              filteredOccurrencesByClass.midia['elegible']
            )}
            elegibleType={elegibleType}
            subjectType={subjectType}
            {...historyModalProps}
          />
        )}

        {activeTab === 'SOCIETARIAS' && (
          <OccurrenceClassTab
            occurrences={filteredOccurrencesByClass.relacao_societaria[
              elegibleType
            ].sort((a, b) => b.collection_id - a.collection_id)}
            occurrenceClass="relacao_societaria"
            status={getCategoryStatus(
              filteredOccurrencesByClass.relacao_societaria['elegible']
            )}
            elegibleType={elegibleType}
            subjectType={subjectType}
            subjectName={individual.name}
            subjectThumbnail={
              !individual.social_midia
                ? defaultAvatar
                : getPicture(individual.social_midia.linkedin_image) ||
                  getPicture(individual.social_midia.twitter_image) ||
                  defaultAvatar
            }
            {...historyModalProps}
          />
        )}

        {activeTab === 'FINANCEIRO' && (
          <OccurrenceClassTab
            occurrences={filteredOccurrencesByClass.financeiro[
              elegibleType
            ].sort((a, b) => b.collection_id - a.collection_id)}
            occurrenceClass="financeiro"
            status={getCategoryStatus(
              filteredOccurrencesByClass.financeiro['elegible']
            )}
            elegibleType={elegibleType}
            subjectType={subjectType}
            {...historyModalProps}
          />
        )}

        {activeTab === 'PPES' && (
          <OccurrenceClassTab
            occurrences={filteredOccurrencesByClass.publico_exposta[
              elegibleType
            ].sort((a, b) => b.collection_id - a.collection_id)}
            occurrenceClass="publico_exposta"
            status={getCategoryStatus(
              filteredOccurrencesByClass.publico_exposta['elegible']
            )}
            elegibleType={elegibleType}
            subjectType={subjectType}
            {...historyModalProps}
          />
        )}

        {activeTab === 'RESTRITIVAS' && (
          <OccurrenceClassTab
            occurrences={filteredOccurrencesByClass.lista_restritiva[
              elegibleType
            ].sort((a, b) => b.collection_id - a.collection_id)}
            occurrenceClass="lista_restritiva"
            status={getCategoryStatus(
              filteredOccurrencesByClass.lista_restritiva['elegible']
            )}
            elegibleType={elegibleType}
            subjectType={subjectType}
            {...historyModalProps}
          />
        )}

        {activeTab === 'PROCESSOS' && (
          <OccurrenceClassTab
            occurrences={filteredOccurrencesByClass.processos[
              elegibleType
            ].sort((a, b) => b.collection_id - a.collection_id)}
            occurrenceClass="processos"
            status={getCategoryStatus(
              filteredOccurrencesByClass.processos['elegible']
            )}
            elegibleType={elegibleType}
            subjectType={subjectType}
            {...historyModalProps}
          />
        )}
      </CustomBlock>

      {occurrenceToClassify && (
        <EditOccurenceModal
          {...subjectProps}
          subjectStatus={getSubjectStatus(occurrencesByClass)}
        />
      )}

      {isHistoryModalVisible && (
        <HistoryModal {...subjectProps} {...historyModalProps} />
      )}
    </>
  )
}

export default OccurrencesTabs
