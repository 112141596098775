import { collectionsIds } from './config'

/* Opções dos filtros SELECTION */
export const selectionOptions = {
  occurrence_class: [
    { visibleValue: 'Benefício Social', dbValue: 'beneficio_social' },
    { visibleValue: 'Exposição na Mídia', dbValue: 'midia' },
    { visibleValue: 'Relações societárias', dbValue: 'relacao_societaria' },
    { visibleValue: 'Financeiro', dbValue: 'financeiro' },
    { visibleValue: 'PPEs', dbValue: 'publico_exposta' },
    { visibleValue: 'Listas restritivas', dbValue: 'lista_restritiva' },
    { visibleValue: 'Processos', dbValue: 'processos' }
  ],
  occurrence_type: [
    {
      visibleValue: 'Bolsa Família (Benefício Social)',
      dbValue: 'bolsa_familia'
    },
    {
      visibleValue: 'Auxílio Emergencial (Benefício Social)',
      dbValue: 'auxilio_emergencial'
    },
    { visibleValue: 'Notícia (Exposição na Mídia)', dbValue: 'noticia' },
    { visibleValue: 'CNAE (Relações Societárias)', dbValue: 'cnae' },
    {
      visibleValue: 'Quadro Societário (Relações Societárias)',
      dbValue: 'quadro_societario'
    },
    {
      visibleValue: 'Dívidas e Pendências (Financeiro)',
      dbValue: 'dividas_e_pendencias'
    },
    { visibleValue: 'Protestos (Financeiro)', dbValue: 'protestos' },
    {
      visibleValue: 'Certidão de Débito (Financeiro)',
      dbValue: 'certidao_de_debito'
    },
    { visibleValue: 'PPE (PPEs)', dbValue: 'ppe' },
    { visibleValue: 'CAC (Listas Restritivas)', dbValue: 'cac' },
    { visibleValue: 'Sanções (Listas Restritivas)', dbValue: 'sancoes' },
    { visibleValue: 'Administrativo (Processos)', dbValue: 'administrativa' },
    { visibleValue: 'Cível (Processos)', dbValue: 'civel' },
    { visibleValue: 'Criminal (Processos)', dbValue: 'criminal' },
    { visibleValue: 'CVM (Processos)', dbValue: 'cvm' },
    { visibleValue: 'Eleitoral (Processos)', dbValue: 'eleitoral' },
    { visibleValue: 'Especial Cível (Processos)', dbValue: 'especial_civel' },
    {
      visibleValue: 'Especial Criminal (Processos)',
      dbValue: 'especial_criminal'
    },
    {
      visibleValue: 'Especial Fazenda (Processos)',
      dbValue: 'especial_fazenda'
    },
    { visibleValue: 'Fazenda (Processos)', dbValue: 'fazenda' },
    { visibleValue: 'Outros (Processos)', dbValue: 'outros' },
    { visibleValue: 'Previdenciário (Processos)', dbValue: 'previdenciaria' },
    { visibleValue: 'Trabalhista (Processos)', dbValue: 'trabalhista' },
    { visibleValue: 'Tributário (Processos)', dbValue: 'tributaria' }
  ],
  situation: [
    { visibleValue: 'Ativo', dbValue: true },
    { visibleValue: 'Inativo', dbValue: false }
  ],
  gender: [
    { visibleValue: 'Masculino', dbValue: 'M' },
    { visibleValue: 'Feminino', dbValue: 'F' },
    { visibleValue: 'Não identificado', dbValue: null }
  ],
  state: [
    { visibleValue: 'Acre', dbValue: 'AC' },
    { visibleValue: 'Alagoas', dbValue: 'AL' },
    { visibleValue: 'Amapá', dbValue: 'AP' },
    { visibleValue: 'Amazonas', dbValue: 'AM' },
    { visibleValue: 'Bahia', dbValue: 'BA' },
    { visibleValue: 'Ceará', dbValue: 'CE' },
    { visibleValue: 'Distrito Federal', dbValue: 'DF' },
    { visibleValue: 'Espírito Santo', dbValue: 'ES' },
    { visibleValue: 'Goiás', dbValue: 'GO' },
    { visibleValue: 'Maranhão', dbValue: 'MA' },
    { visibleValue: 'Mato Grosso', dbValue: 'MT' },
    { visibleValue: 'Mato Grosso do Sul', dbValue: 'MS' },
    { visibleValue: 'Minas Gerais', dbValue: 'MG' },
    { visibleValue: 'Pará', dbValue: 'PA' },
    { visibleValue: 'Paraíba', dbValue: 'PB' },
    { visibleValue: 'Paraná', dbValue: 'PR' },
    { visibleValue: 'Pernambuco', dbValue: 'PE' },
    { visibleValue: 'Piauí', dbValue: 'PI' },
    { visibleValue: 'Rio de Janeiro', dbValue: 'RJ' },
    { visibleValue: 'Rio Grande do Norte', dbValue: 'RN' },
    { visibleValue: 'Rio Grande do Sul', dbValue: 'RS' },
    { visibleValue: 'Rondônia', dbValue: 'RO' },
    { visibleValue: 'Roraima', dbValue: 'RR' },
    { visibleValue: 'Santa Catarina', dbValue: 'SC' },
    { visibleValue: 'São Paulo', dbValue: 'SP' },
    { visibleValue: 'Sergipe', dbValue: 'SE' },
    { visibleValue: 'Tocantins', dbValue: 'TO' },
    { visibleValue: 'Exterior', dbValue: 'EX' },
    { visibleValue: 'Não identificado', dbValue: null }
  ],
  // alert: [
  //   { visibleValue: 'Sim', dbValue: true },
  //   { visibleValue: 'Não', dbValue: false }
  // ],
  pendent_contact: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  conflit_term: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  has_observation: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  occurrence_status: [
    { visibleValue: 'Nova identificação', dbValue: 0 },
    { visibleValue: 'Improcedente', dbValue: 1 },
    { visibleValue: 'Procedente (em análise)', dbValue: 2 },
    { visibleValue: 'Procedente (aguardando retorno)', dbValue: 3 },
    { visibleValue: 'Procedente (comitê)', dbValue: 4 },
    { visibleValue: 'Procedente (solucionado)', dbValue: 5 },
    { visibleValue: 'Não elegível', dbValue: 7 }
  ],
  collection_id: collectionsIds.map((id) => ({
    visibleValue: id,
    dbValue: Number(id)
  })),
  cnae_type: [
    { visibleValue: 'HOT', dbValue: 'H' },
    { visibleValue: 'COLD', dbValue: 'C' }
  ],
  similarity_cnae: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  similarity_address: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  // clt: [
  //   { visibleValue: 'Sim', dbValue: true },
  //   { visibleValue: 'Não', dbValue: false }
  // ],
  finantial_pendency: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  // pendency_value: [
  //   { visibleValue: 'Acima de 30K', dbValue: 'Acima de 30K' },
  //   { visibleValue: 'Abaixo de 30K', dbValue: 'Abaixo de 30K' }
  // ],
  debts: [
    { visibleValue: 'Positiva', dbValue: true },
    { visibleValue: 'Negativa', dbValue: false }
  ],
  ppe_type: [
    // { visibleValue: 'Direto', dbValue: 0 },
    { visibleValue: 'Direto', dbValue: 1 },
    { visibleValue: 'Indireto', dbValue: 2 }
  ],
  restrictive_list: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  processes_involvement: [
    { visibleValue: 'Autor', dbValue: 'AUTHOR' },
    { visibleValue: 'Réu', dbValue: 'DEFENDANT' }
  ],
  actual_status: [
    { visibleValue: 'Nova identificação', dbValue: 0 },
    { visibleValue: 'Em análise', dbValue: 2 },
    { visibleValue: 'Aguardando retorno', dbValue: 3 },
    { visibleValue: 'Comitê', dbValue: 4 },
    { visibleValue: 'Solucionado', dbValue: 5 }
  ]
}

/* Nomes visíveis dos labels */
export const visibleLabels = {
  occurrence_class: 'Categoria da ocorrência',
  occurrence_type: 'Tipo da ocorrência',
  bond_time: 'Tempo de vínculo',
  situation: 'Situação',
  gender: 'Gênero',
  state: 'Estado (UF)',
  age: 'Idade',
  alert: 'Gerou alerta',
  score: 'Score',
  responsable: 'Responsável pela tratativa',
  pendent_contact: 'Contato pendente',
  conflit_term: 'Termo de ausência de conflito',
  occurrence_status: 'Status da ocorrência',
  collection_id: 'ID da coleta',
  analyse_start: 'Data de início da análise',
  resolution_date: 'Prazo de resolução',
  has_observation: 'Possui observações',
  risk_rating_id: 'Classificação de risco',
  next_risk_rating_review: 'Data de próxima análise',
  cnae_type: 'Tipo do CNAE',
  similarity_cnae: 'Similaridade de CNAEs (Empresas por CPF)',
  similarity_address: 'Similaridade de endereços',
  company_age: 'Tempo de empresa (relações societárias)',
  clt: 'Empresa com funcionários CLT',
  billing: 'Faturamento',
  finantial_pendency: 'Possui pendências financeiras',
  pendency_value: 'Valor da pendência',
  debts: 'Certidão de débitos',
  ppe_type: 'Tipo do PPE',
  restrictive_list: 'Tem o nome em listas restritivas',
  processes_involvement: 'Envolvimento no processo',
  office: 'Escritório',
  actual_status: 'Status de análise da pessoa ou escritório'
}

export const dynamicFilters = ['responsable', 'office']

export const listsFilters = {
  summarises: [
    'responsable',
    'pendent_contact',
    'conflit_term',
    'occurrence_status',
    'collection_id',
    'analyse_start',
    'resolution_date',
    'occurrence_class',
    'occurrence_type',
    'cnae_type',
    'similarity_cnae',
    'similarity_address',
    'company_age',
    'finantial_pendency',
    'pendency_value',
    'debts',
    'processes_involvement',
    'ppe_type',
    'restrictive_list'
  ],
  subjects: [
    'score',
    'office',
    'situation',
    'gender',
    'state',
    'age',
    'has_observation',
    'risk_rating_id',
    'next_risk_rating_review',
    'actual_status'
  ]
}
