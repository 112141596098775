/* React */
import React from 'react'

/* Components */
import {
  Collapse,
  Divider,
  CustomText
} from '@zeta/ui/src'

/* Parts */
import ProfileFilters from './ProfileFilters'
import AnalysisFilters from './AnalysisFilters'
import CategoriesFilters from './CategoriesFilters'
import CompaniesFilters from './CompaniesFilters'
import CorporateFilters from './CorporateFilters'
import FinancialFilters from './FinancialFilters'
import PPEFilters from './PPEFilters'
import RestrictiveListsFilters from './RestrictiveListsFilters'
import LawsuitFilters from './LawsuitFilters'

const Content = ({
  /* props */
  filterProps
}) => {
  return (
    <>
      <Collapse
        title={<CustomText size='0.875rem' weight='700'>PERFIL / CADASTRAIS</CustomText>}
        noBorder
      >
        <ProfileFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>ANÁLISES</CustomText>}
        noBorder
      >
        <AnalysisFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>CATEGORIAS</CustomText>}
        noBorder
      >
        <CategoriesFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>ESCRITÓRIOS</CustomText>}
        noBorder
      >
        <CompaniesFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>RELAÇÕES SOCIETÁRIAS</CustomText>}
        noBorder
      >
        <CorporateFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>FINANCEIRO</CustomText>}
        noBorder
      >
        <FinancialFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>PPEs</CustomText>}
        noBorder
      >
        <PPEFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>LISTAS RESTRITIVAS</CustomText>}
        noBorder
      >
        <RestrictiveListsFilters {...filterProps} />
      </Collapse>

      <Divider my={'medium'} />

      <Collapse
        title={<CustomText size='0.875rem' weight='700'>PROCESSOS</CustomText>}
        noBorder
      >
        <LawsuitFilters {...filterProps} />
      </Collapse>
    </>
  )
}

export default Content
