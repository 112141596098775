import { includes, equals, uniq } from 'ramda'
import { getPathPrefix } from '@zeta/helpers'
import currency from 'currency.js'

import { listsFilters } from 'constants/filters'

const helpers = {
  /**
   *
   * Retorna o tipo do dataset (people ou company) baseado na url
   * @param {String} url url atual
   * @return {String} 'people' / 'company'
   *
   */
  getSubjectType: (url) => {
    if (/\/people/.test(url)) return 'people'
    if (/\/company/.test(url)) return 'company'
  },

  /**
   *
   * Retorna os itens das tabs de navegação entre subjectTypes (pessoas / escritórios)
   * @param {Object} subjectTypes {people, company}
   * @param {Object} history
   * @param {String} screenPath 'dashboard' / 'individual' / ...
   * @return {Array[Object]}
   *
   */
  getDataSetTabsItems: ({
    subjectTypes,
    history,
    computedMatch,
    screenPath
  }) => {
    if (subjectTypes.people && subjectTypes.company) {
      return [
        {
          text: 'Pessoas',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/people/${screenPath}`
            ),
          isActive: includes('people', computedMatch.path.split('/'))
        },
        {
          text: 'Escritórios',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/company/${screenPath}`
            ),
          isActive: includes('company', computedMatch.path.split('/'))
        }
      ]
    }

    if (subjectTypes.people) {
      return [
        {
          text: 'Pessoas',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/people/${screenPath}`
            ),
          isActive: includes('people', computedMatch.path.split('/'))
        }
      ]
    }

    if (subjectTypes.company) {
      return [
        {
          text: 'Escritórios',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/company/${screenPath}`
            ),
          isActive: includes('company', computedMatch.path.split('/'))
        }
      ]
    }
  },

  /**
   *
   * Verifica se o valor informado possui o regex de url, e em caso positivo, retorna a url
   * @param {String} url
   * @return {String | null}
   *
   */
  getPicture: (url) => (url && /^http/.test(url) ? url : null),

  /**
   *
   * Retorna a cor de feedback de acordo com o score
   * @param {Number} score
   * @return {String} 'red' / 'yellow' / 'green
   *
   */
  getScoreColor: (score) => {
    if (score <= 333.33) return 'green'
    if (score > 333.33 && score <= 666.66) return 'yellow'
    if (score > 666.66) return 'red'
  },

  /**
   *
   * Formata o array de summarises que é salvo na store
   * @param {Array[Object]} summarises
   * @param {String} subjectType 'PrivatePerson' / 'LegalEntity'
   * @return {Array[Object]}
   *
   */
  formatSummarisesArray: (summarises, subjectType) =>
    summarises
      .filter((summarise) => summarise.subject_type === subjectType)
      .map((summarise) => ({
        // ...summarise,
        subject_id: summarise.subject_id,
        subject_type: summarise.subject_type,
        collection_id: summarise.collection_id,
        occurrence_id: summarise.occurrence_id,
        occurrence_status: summarise.occurrence_status,
        occurrence_class: summarise.occurrence_class,
        occurrence_type: summarise.occurrence_type,
        cnae_type: summarise.cnae_type,
        similarity_cnae: summarise.similarity_cnae,
        similarity_address: summarise.similarity_address,
        company_age: summarise.company_age,
        finantial_pendency: summarise.finantial_pendency,
        pendency_value: summarise.pendency_value,
        debts: summarise.debts,
        processes_involvement: summarise.processes_involvement,
        ppe_type: summarise.ppe_type,
        restrictive_list: summarise.restrictive_list,
        responsable: summarise.responsable,
        pendent_contact: summarise.pendent_contact,
        conflit_term: summarise.conflit_term,
        analyse_start:
          summarise.analyse_start && summarise.analyse_start.slice(0, 10),
        resolution_date:
          summarise.resolution_date && summarise.resolution_date.slice(0, 10)
      })),

  /**
   *
   * Formata o array de subjects que é salvo na store
   * @param {Array[Object]} subjects
   * @param {String} subjectType 'PrivatePerson' / 'LegalEntity'
   * @return {Array[Object]}
   *
   */
  formatSubjectsArray: (subjects, subjectType) => {
    if (subjectType === 'PrivatePerson') {
      return subjects.map((subject) => ({
        id: subject.id,
        name: subject.name,
        score: subject.score,
        cpf: subject.cpf,
        formatted_cpf: subject.formatted_cpf,
        code: subject.code,
        situation: subject.situation,
        legal_entity_id: subject.legal_entity_id,
        age: subject.profile.idade,
        gender: subject.profile.sexo,
        state:
          subject.address && subject.address[0] ? subject.address[0].uf : null,
        has_observation: subject.has_observation,
        risk_rating_id: subject.posture.risk_rating_id,
        next_risk_rating_review: subject.posture.next_risk_rating_review,
        posture_id: subject.posture.id,
        ...subject.misc
      }))
    }
    if (subjectType === 'LegalEntity') {
      return subjects.map((subject) => ({
        id: subject.id,
        fantasy_name: subject.fantasy_name,
        company_name: subject.company_name,
        score: subject.score,
        cnpj: subject.cnpj,
        situation: subject.situation,
        state:
          subject.address && subject.address[0] ? subject.address[0].uf : null,
        has_observation: subject.has_observation,
        risk_rating_id: subject.posture.risk_rating_id,
        next_risk_rating_review: subject.posture.next_risk_rating_review,
        posture_id: subject.posture.id,
        ...subject.misc
      }))
    }
  },

  /**
   *
   * Filtra as summarises baseado no objeto filters
   * @param {Array[Object]} summarises
   * @param {Object} filters
   * @return {Array[Object]}
   *
   */
  // filterSummarises: (summarises, filters) => {
  //   if (!filters || equals(filters, {})) return summarises

  //   const filteredProps = Object.keys(filters)

  //   return summarises.filter(summarise =>
  //     filteredProps
  //       .map(
  //         prop => Array.isArray(filters[prop])
  //           ? filters[prop].some(filterValue => filterValue === summarise[prop])
  //           : (filters[prop].start
  //             ? /\d{4}-\d{2}-\d{2}/.test(summarise[prop])
  //               ? summarise[prop] >= filters[prop].start
  //               : Number(summarise[prop]) >= Number(filters[prop].start)
  //             : true
  //           ) &&
  //             (filters[prop].end
  //               ? /\d{4}-\d{2}-\d{2}/.test(summarise[prop])
  //                 ? summarise[prop] <= filters[prop].end
  //                 : Number(summarise[prop]) <= Number(filters[prop].end)
  //               : true
  //             )
  //       )
  //       .every(exp => exp))
  // },

  /**
   *
   * Retorna um array contendo apenas os itens existentes em "list1" e "list2"
   * @param {Object} list1
   * @param {Object} list2
   *
   */
  getListsIntersections: (list1, list2) =>
    uniq(
      [...list1, ...list2].filter(
        (item) => list1.includes(item) && list2.includes(item)
      )
    ),

  /**
   *
   * Retorna um boolean indicando se em "filters" há alguma propriedade contida em listsFilters["listToFilter"]
   * @param {Object} filters
   * @param {String} listToFilter 'summarises' | 'subjects'
   *
   */
  hasListFilter: (filters, listToFilter) =>
    Object.keys(filters).some((key) =>
      listsFilters[listToFilter].some((filterKey) => filterKey === key)
    ),

  /**
   *
   * Formata o objeto "filters", mantendo apenas as propriedades contidas em listsFilters["listToFilter"]
   * @param {Object} filters
   * @param {String} listToFilter 'summarises' | 'subjects'
   *
   */
  clearFiltersObj: (filters, listToFilter) =>
    Object.keys(filters).reduce(
      (acc, curKey) =>
        listsFilters[listToFilter].some((filterKey) => filterKey === curKey)
          ? {
              ...acc,
              [curKey]: filters[curKey]
            }
          : acc,
      {}
    ),

  /**
   *
   * Filtra "list" baseado no objeto "filters"
   * @param {Array[Object]} list
   * @param {Object} filters
   * @param {String} listToFilter 'summarises' | 'subjects'
   * @return {Array[Object]}
   *
   */
  filterList: (list, filters, listToFilter) => {
    const cleanFilters = clearFiltersObj(filters, listToFilter)

    if (!cleanFilters || equals(cleanFilters, {})) return list

    const filteredProps = Object.keys(cleanFilters)

    return list.filter((listItem) =>
      filteredProps
        .map((prop) =>
          Array.isArray(cleanFilters[prop])
            ? cleanFilters[prop].some(
                (filterValue) => filterValue === listItem[prop]
              )
            : (cleanFilters[prop].start
                ? /\d{4}-\d{2}-\d{2}/.test(listItem[prop])
                  ? listItem[prop] >= cleanFilters[prop].start
                  : Number(listItem[prop]) >= Number(cleanFilters[prop].start)
                : true) &&
              (cleanFilters[prop].end
                ? /\d{4}-\d{2}-\d{2}/.test(listItem[prop])
                  ? listItem[prop] <= cleanFilters[prop].end
                  : Number(listItem[prop]) <= Number(cleanFilters[prop].end)
                : true)
        )
        .every((exp) => exp)
    )
  },

  /**
   *
   * Retorna a quantidade de subjects (filtrados ou não)
   * @param {Object} filters
   * @param {Object} summarises summarises de um subject type específico
   * @param {Object} subjects subjects de um type específico
   * @return {Number}
   *
   */
  // getSubjectsLength: (filters, summarises, subjects, skipFiltersVerification) =>
  //   (filters && !equals(filters, {})) || skipFiltersVerification
  //     ? uniq(filterSummarises(summarises, filters).map(summarise => summarise.subject_id)).length
  //     : subjects.length,

  /**
   *
   * Pagina um array
   * @param {Array} items
   * @param {Number} page
   * @param {Number} perPage
   * @return {Array}
   *
   */
  paginateItems: (items, page, perPage) =>
    items.slice(perPage * page - perPage, perPage * page),

  /**
   *
   * Retorna o status geral do subject
   * @param {Object} occurrencesByClass
   * @return {Number}
   *
   */
  getSubjectStatus: (occurrencesByClass) => {
    const sortedOccurrences = Object.keys(occurrencesByClass)
      .reduce((acc, curKey) => acc.concat(occurrencesByClass[curKey]), [])
      .filter(
        (occurrence) =>
          occurrence.occurrence_status.status != 1 &&
          occurrence.occurrence_status.status != 7
      )
      .sort((a, b) => a.occurrence_status.status - b.occurrence_status.status)

    return sortedOccurrences.length > 0
      ? sortedOccurrences[0].occurrence_status.status
      : false
  },

  /**
   *
   * Formata um número para valor monetário (real)
   * @param {Number|String} value
   * @return {String}
   *
   */
  toCurrency: (value) => {
    if (!value || value === 'NA') return 'NA'

    return currency(value).format({
      separator: '.',
      decimal: ',',
      symbol: 'R$'
    })
  },

  /**
   *
   * Encontra o objeto risk rating de acordo com o id informado
   * @param {Array[Object]} riskRatings
   * @param {Number} id
   * @return {Object}
   *
   */
  getRiskRating: (riskRatings, id) =>
    riskRatings.find((riskRating) => riskRating.id === id)
}

export const {
  getSubjectType,
  getDataSetTabsItems,
  getPicture,
  getScoreColor,
  formatSummarisesArray,
  formatSubjectsArray,
  // filterSummarises,
  getListsIntersections,
  hasListFilter,
  clearFiltersObj,
  filterList,
  // getSubjectsLength,
  paginateItems,
  getSubjectStatus,
  toCurrency,
  getRiskRating
} = helpers

export default helpers
