/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Icon, CustomBlock, CustomText, theme } from '@zeta/ui/src'

import BoardStatusBadge from 'components/BoardStatusBadge'

/* Styled */
import {
  Wrapper,
  Info,
  IconWrapper,
  InfoTextWrapper,
  InfoLabel,
  InfoText,
  DatasetGroup,
  DatasetGroupLabel,
  DatasetGroupInfo,
  DatasetGroupInfoTextWrapper,
  DatasetGroupInfoTotal,
  DatasetGroupInfoText
} from './styled'

const visibleStatus = {
  1: 'EM INVESTIGAÇÃO',
  2: 'FINALIZADO'
}

const BoardCard = ({
  status,
  id,
  name,
  createdAt,
  owner,
  people,
  company,
  ...props
}) => {
  return (
    <Wrapper {...props} type="button">
      <BoardStatusBadge status={status} data-testid="boardStatusBadge">
        {visibleStatus[status]}
      </BoardStatusBadge>

      <CustomBlock my="large">
        <CustomText
          size="0.75rem"
          weight="700"
          color={theme.colors.brand.primary.medium}
        >
          #{id}
        </CustomText>

        <CustomText weight="700">{name}</CustomText>
      </CustomBlock>

      <Info>
        <IconWrapper blue>
          <Icon icon="calendar-outline" />
        </IconWrapper>

        <InfoTextWrapper>
          <InfoLabel>Criado em</InfoLabel>
          <InfoText>{createdAt}</InfoText>
        </InfoTextWrapper>
      </Info>

      <Info>
        <IconWrapper blue>
          <Icon icon="person-outline" />
        </IconWrapper>

        <InfoTextWrapper>
          <InfoLabel>Criado por</InfoLabel>
          <InfoText>{owner}</InfoText>
        </InfoTextWrapper>
      </Info>

      {people && (
        <DatasetGroup blue data-testid="datasetGroupPeople">
          <DatasetGroupLabel>Pessoas</DatasetGroupLabel>

          <DatasetGroupInfo>
            <IconWrapper blue>
              <Icon icon="people-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal blue>
                {people.totalListed}{' '}
              </DatasetGroupInfoTotal>
              <DatasetGroupInfoText>
                pessoas com ocorrências identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>

          <DatasetGroupInfo>
            <IconWrapper blue>
              <Icon icon="share-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal blue>
                {people.totalOccurrences}{' '}
              </DatasetGroupInfoTotal>
              <DatasetGroupInfoText>
                ocorrências identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>

          <DatasetGroupInfo>
            <IconWrapper blue>
              <Icon icon="edit-2-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal blue>
                {people.totalClassified}{' '}
              </DatasetGroupInfoTotal>
              <DatasetGroupInfoText>
                ocorrências classificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>
        </DatasetGroup>
      )}

      {company && (
        <DatasetGroup purple data-testid="datasetGroupCompany">
          <DatasetGroupLabel>Escritórios</DatasetGroupLabel>

          <DatasetGroupInfo>
            <IconWrapper purple>
              <Icon icon="briefcase-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal purple>
                {company.totalListed}{' '}
              </DatasetGroupInfoTotal>
              <DatasetGroupInfoText>
                escritórios com ocorrências identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>

          <DatasetGroupInfo>
            <IconWrapper purple>
              <Icon icon="share-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal purple>
                {company.totalOccurrences}{' '}
              </DatasetGroupInfoTotal>
              <DatasetGroupInfoText>
                ocorrências identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>

          <DatasetGroupInfo>
            <IconWrapper purple>
              <Icon icon="edit-2-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal purple>
                {company.totalClassified}{' '}
              </DatasetGroupInfoTotal>
              <DatasetGroupInfoText>
                ocorrências classificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>
        </DatasetGroup>
      )}
    </Wrapper>
  )
}

BoardCard.propTypes = {
  status: PropTypes.oneOf([1, 2]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  people: PropTypes.shape({
    totalListed: PropTypes.number,
    totalOccurrences: PropTypes.number,
    totalClassified: PropTypes.number
  }),
  company: PropTypes.shape({
    totalListed: PropTypes.number,
    totalOccurrences: PropTypes.number,
    totalClassified: PropTypes.number
  })
}

export default BoardCard
