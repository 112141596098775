/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { Col } from 'styled-bootstrap-grid'

import {
  CustomScrollbars,
  Table,
  Button,
  Icon,
  Pagination,
  FlexAlignCenter,
  theme
} from '@zeta/ui/src'

/* Styled */
import { Wrapper, Header, Footer } from './styled'

/* Parts */
import ListSearch from './ListSearch'
import DossierModal from '../DossierModal'

/* Helpers */
import { getPathPrefix, formatDate, logAmplitudeEvent } from '@zeta/helpers'
import { paginateItems } from 'helpers'
import { filterSubjectsBySearchQuery, orderList } from '../helpers'
import { exportSubjectsList } from 'api'

/* Constants */
import { visibleStatus } from 'constants/dictionary'

/* Action Creators */
import {
  setListPage,
  setListPerPage,
  setListOrder
} from 'store/reducers/data/action-creators'

const calculateDays = (date) => {
  if (!date || date === 'NA') return 'NA'

  const oneDay = 24 * 60 * 60 * 1000

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const splitted = date.split('/')
  const resolutionDate = new Date(
    Number(splitted[2]),
    Number(splitted[1]) - 1,
    Number(splitted[0])
  )

  return today > resolutionDate
    ? 0
    : Math.round(Math.abs((today - resolutionDate) / oneDay))
}

export const ListTable = ({
  /* router */
  history,
  computedMatch,
  /* props */
  filteredSubjects,
  subjectType
}) => {
  const dispatch = useDispatch()

  const subjects = useSelector((state) => state.data.subjects)
  const listPage = useSelector((state) => state.data.listPage)
  const listPerPage = useSelector((state) => state.data.listPerPage)
  const listOrder = useSelector((state) => state.data.listOrder)
  const searchQuery = useSelector((state) => state.data.searchQuery)

  const { orderBy, dscOrder, orderValueType } = listOrder[subjectType]

  const toggleOrdenation = (orderBy, orderValueType) => {
    dispatch(setListOrder(orderBy, orderValueType, subjectType))

    logAmplitudeEvent({
      event: 'Ordenação na listagem',
      eventProperties: { Variável: orderBy }
    })
  }

  const headers = {
    people: [
      {
        text: 'Nome',
        onClickFn: () => toggleOrdenation('name', 'string'),
        id: 'name'
      },
      { text: 'CPF', id: null },
      {
        text: 'Score',
        onClickFn: () => toggleOrdenation('score', 'number'),
        id: 'score'
      },
      { text: 'Código "A"', id: null },
      {
        text: 'Escritório',
        onClickFn: () => toggleOrdenation('office', 'string'),
        id: 'office'
      },
      { text: 'CNPJ', id: null },
      {
        text: 'Status análise',
        onClickFn: () => toggleOrdenation('actual_status', 'number'),
        id: 'actual_status'
      },
      {
        text: 'Número de casos',
        onClickFn: () => toggleOrdenation('total_occurrences', 'number'),
        id: 'total_occurrences'
      },
      {
        text: 'Benefício Social',
        onClickFn: () => toggleOrdenation('beneficio_social', 'number'),
        id: 'beneficio_social'
      },
      {
        text: 'Mídia',
        onClickFn: () => toggleOrdenation('midias', 'number'),
        id: 'midias'
      },
      {
        text: 'Relações Societárias',
        onClickFn: () => toggleOrdenation('relacao_societaria', 'number'),
        id: 'relacao_societaria'
      },
      {
        text: 'Financeiro',
        onClickFn: () => toggleOrdenation('financeiro', 'number'),
        id: 'financeiro'
      },
      {
        text: 'PPE',
        onClickFn: () => toggleOrdenation('ppe', 'number'),
        id: 'ppe'
      },
      {
        text: 'Listas Restritivas',
        onClickFn: () => toggleOrdenation('lista_restritiva', 'number'),
        id: 'lista_restritiva'
      },
      {
        text: 'Processos',
        onClickFn: () => toggleOrdenation('processos', 'number'),
        id: 'processos'
      },
      {
        text: 'Prazo de resolução (dias)',
        onClickFn: () => toggleOrdenation('data_resolucao', 'string'),
        id: 'data_resolucao'
      },
      {
        text: 'Data de abertura',
        onClickFn: () => toggleOrdenation('analise_start', 'string'),
        id: 'analise_start'
      },
      {
        text: 'Data de atualização',
        onClickFn: () => toggleOrdenation('analise_update', 'string'),
        id: 'analise_update'
      },
      {
        text: 'Data de encerramento',
        onClickFn: () => toggleOrdenation('analise_update', 'string'),
        id: 'analise_update'
      }
    ],
    company: [
      {
        text: 'Escritório',
        onClickFn: () => toggleOrdenation('company_name', 'string'),
        id: 'company_name'
      },
      { text: 'CNPJ', id: null },
      {
        text: 'Score',
        onClickFn: () => toggleOrdenation('score', 'number'),
        id: 'score'
      },
      {
        text: 'Número de funcionários',
        onClickFn: () => toggleOrdenation('employee_count', 'number'),
        id: 'employee_count'
      },
      {
        text: 'Status análise',
        onClickFn: () => toggleOrdenation('actual_status', 'number'),
        id: 'actual_status'
      },
      {
        text: 'Número de casos',
        onClickFn: () => toggleOrdenation('total_occurrences', 'number'),
        id: 'total_occurrences'
      },
      {
        text: 'Benefício Social',
        onClickFn: () => toggleOrdenation('beneficio_social', 'number'),
        id: 'beneficio_social'
      },
      {
        text: 'Mídia',
        onClickFn: () => toggleOrdenation('midias', 'number'),
        id: 'midias'
      },
      {
        text: 'Relações societárias',
        onClickFn: () => toggleOrdenation('relacao_societaria', 'number'),
        id: 'relacao_societaria'
      },
      {
        text: 'Financeiro',
        onClickFn: () => toggleOrdenation('financeiro', 'number'),
        id: 'financeiro'
      },
      {
        text: 'PPE',
        onClickFn: () => toggleOrdenation('ppe', 'number'),
        id: 'ppe'
      },
      {
        text: 'Listas restritivas',
        onClickFn: () => toggleOrdenation('lista_restritiva', 'number'),
        id: 'lista_restritiva'
      },
      {
        text: 'Processos',
        onClickFn: () => toggleOrdenation('processos', 'number'),
        id: 'processos'
      },
      {
        text: 'Prazo de resolução (dias)',
        onClickFn: () => toggleOrdenation('data_resolucao', 'string'),
        id: 'data_resolucao'
      },
      {
        text: 'Data de abertura',
        onClickFn: () => toggleOrdenation('analise_start', 'string'),
        id: 'analise_start'
      },
      {
        text: 'Data de atualização',
        onClickFn: () => toggleOrdenation('analise_update', 'string'),
        id: 'analise_update'
      },
      {
        text: 'Data de encerramento',
        onClickFn: () => toggleOrdenation('analise_update', 'string'),
        id: 'analise_update'
      }
    ]
  }

  const rows = searchQuery[subjectType]
    ? filterSubjectsBySearchQuery(
        subjects[subjectType],
        searchQuery[subjectType]
      )
    : filteredSubjects

  const [isExporting, setIsExporting] = useState(false)

  const [isDossierModalVisible, setIsDossierModalVisible] = useState(false)
  const [isSendingDossier, setIsSendingDossier] = useState(false)

  return rows ? (
    <>
      <Col>
        <Wrapper>
          <Header>
            <FlexAlignCenter
              override={`
                width: 100%;
                justify-content: space-between;
                margin-right: ${theme.spacing.medium};
                margin-bottom: ${theme.spacing.medium};

                @media (max-width: 1199px) {
                  flex-direction: column;
                  width: 100%;
                  align-items: flex-start;
                }
              `}
            >
              <ListSearch subjectType={subjectType} />

              <FlexAlignCenter mt="small">
                <Button
                  onClick={() => {
                    setIsExporting(true)

                    exportSubjectsList({ subjectType }).then((res) => {
                      setIsExporting(false)

                      const downloadUrl = window.URL.createObjectURL(res.data)
                      const tempLink = document.createElement('a')
                      tempLink.href = downloadUrl
                      tempLink.setAttribute('download', 'export.csv')
                      tempLink.click()
                    })
                  }}
                  small
                  isDisabled={isExporting}
                >
                  <span>Exportar .CSV</span>
                  <Icon icon="download-outline" />
                </Button>

                <Button
                  onClick={() => setIsDossierModalVisible(true)}
                  small
                  isDisabled={isSendingDossier}
                  ml="small"
                >
                  <span>Enviar Dossiê</span>
                  <Icon icon="email-outline" />
                </Button>
              </FlexAlignCenter>
            </FlexAlignCenter>
          </Header>

          {rows.length > 0 && (
            <CustomScrollbars
              style={{
                height:
                  rows.length >= 12
                    ? `${12 * 33 + 55}px`
                    : `${rows.length * 33 + 55}px`,
                width: '100%'
              }}
              renderViewStyle={{ paddingBottom: '6px', paddingRight: '6px' }}
              containerId="listTableContainer"
            >
              <Table
                headers={headers[subjectType]}
                rows={
                  subjectType === 'people'
                    ? paginateItems(
                        orderList(
                          rows,
                          orderBy,
                          dscOrder[orderBy],
                          orderValueType
                        ),
                        listPage[subjectType],
                        listPerPage[subjectType]
                      ).map((row) => ({
                        cells: [
                          row ? row.name : '',
                          row ? row.formatted_cpf : '',
                          row ? row.score : '',
                          row ? row.code : '',
                          row ? row.office : '',
                          row ? row.office_cnpj : '',
                          row ? visibleStatus[row.actual_status] : '',
                          row ? row.total_occurrences : '',
                          row ? row.beneficio_social : '',
                          row ? row.midias : '',
                          row ? row.relacao_societaria : '',
                          row ? row.financeiro : '',
                          row ? row.ppe : '',
                          row ? row.lista_restritiva : '',
                          row ? row.processos : '',
                          row
                            ? calculateDays(formatDate(row.data_resolucao))
                            : '',
                          row ? formatDate(row.analise_start) : '',
                          row ? formatDate(row.analise_update) : '',
                          row
                            ? row.actual_status === 5
                              ? formatDate(row.analise_update)
                              : ''
                            : ''
                        ],
                        onClickFn: () =>
                          history.push(
                            `${getPathPrefix(
                              computedMatch
                            )}/${subjectType}/individual/${row ? row.id : ''}`
                          )
                      }))
                    : paginateItems(
                        orderList(
                          rows,
                          orderBy,
                          dscOrder[orderBy],
                          orderValueType
                        ),
                        listPage[subjectType],
                        listPerPage[subjectType]
                      ).map((row) => ({
                        cells: [
                          row ? row.company_name : '',
                          row ? row.cnpj : '',
                          row ? row.score : '',
                          row ? row.employee_count : '',
                          row ? visibleStatus[row.actual_status] : '',
                          row ? row.total_occurrences : '',
                          row ? row.beneficio_social : '',
                          row ? row.midias : '',
                          row ? row.relacao_societaria : '',
                          row ? row.financeiro : '',
                          row ? row.ppe : '',
                          row ? row.lista_restritiva : '',
                          row ? row.processos : '',
                          row
                            ? calculateDays(formatDate(row.data_resolucao))
                            : '',
                          row ? formatDate(row.analise_start) : '',
                          row ? formatDate(row.analise_update) : '',
                          row
                            ? row.actual_status === 5
                              ? formatDate(row.analise_update)
                              : ''
                            : ''
                        ],
                        onClickFn: () =>
                          history.push(
                            `${getPathPrefix(
                              computedMatch
                            )}/${subjectType}/individual/${row ? row.id : ''}`
                          )
                      }))
                }
                initialActiveColumn={headers[subjectType].findIndex(
                  (header) => header.id === orderBy
                )}
                activeColumnOrder={dscOrder[orderBy] ? 'DESC' : 'ASC'}
                initialOrder="DESC"
                noWrap
                stickyHeader
                stickyFirstCell
                id="listTable"
              />
            </CustomScrollbars>
          )}

          <Footer>
            {rows.length > 0 && (
              <Pagination
                page={listPage[subjectType]}
                setPageFn={(page) => dispatch(setListPage(page, subjectType))}
                perPage={listPerPage[subjectType]}
                setPerPageFn={(perPage) => {
                  dispatch(setListPerPage(perPage, subjectType))

                  logAmplitudeEvent({
                    event:
                      'Alterada quantidade de perfis por página na listagem',
                    eventProperties: { 'Perfis por página': perPage }
                  })
                }}
                perPageItems={[25, 50, 100]}
                totalItems={rows.length}
                scrollAnchor="listTable"
                containerId="listTableContainer"
              />
            )}
          </Footer>
        </Wrapper>
      </Col>

      {isDossierModalVisible && (
        <DossierModal
          subjectType={subjectType}
          isModalVisible={isDossierModalVisible}
          setIsModalVisible={setIsDossierModalVisible}
          isSendingDossier={isSendingDossier}
          setIsSendingDossier={setIsSendingDossier}
          rows={rows}
        />
      )}
    </>
  ) : null
}

export default ListTable
