/* React */
import React from 'react'

import { CustomText, theme } from '@zeta/ui/src'

/* Helpers */
import { formatDate } from '@zeta/helpers'
import { toCurrency } from 'helpers'

export const filterOccurrences = (
  occurrencesByClass,
  includeNonElegible,
  occurrenceClasses,
  currentCollectionId,
  includePastCollections
) =>
  Object.keys(occurrencesByClass).reduce((acc, occurrenceClass) => {
    return occurrenceClasses[occurrenceClass]
      ? {
          ...acc,
          [occurrenceClass]: occurrencesByClass[occurrenceClass]
            .filter((occurrence) =>
              includeNonElegible
                ? occurrence.occurrence_status.status !== 1
                : occurrence.occurrence_status.status !== 1 &&
                  occurrence.occurrence_status.status !== 7
            )
            .filter((occurrence) =>
              includePastCollections
                ? occurrence
                : occurrence.collection_id === currentCollectionId
            )
        }
      : acc
  }, {})

export const hasOccurrencesToShow = (occurrencesByClass) =>
  Object.keys(occurrencesByClass).reduce(
    (acc, occurrenceClass) => acc.concat(occurrencesByClass[occurrenceClass]),
    []
  ).length > 0

export const filterRelates = (
  relates,
  includeNonElegible,
  occurrenceClasses,
  currentCollectionId,
  includePastCollections
) =>
  relates.filter((relate) =>
    hasOccurrencesToShow(
      filterOccurrences(
        relate.occurrences,
        includeNonElegible,
        occurrenceClasses,
        currentCollectionId,
        includePastCollections
      )
    )
  )

export const getHeaders = (occurrenceClass, occurrenceType, subjectType) => {
  switch (occurrenceClass) {
    case 'beneficio_social':
      return ['Benefício', 'Valor', 'Parcelas']

    case 'midia':
      return ['Categoria', 'Link']

    case 'relacao_societaria':
      if (occurrenceType === 'cnae') {
        return ['Razão Social', 'CNPJ', 'CNAE']
      }
      if (occurrenceType === 'quadro_societario') {
        return [
          'Nome / Razão Social',
          'CPF / CNPJ',
          'Qualificação',
          'Data de Entrada',
          'Data de Saída'
        ]
      }
      break

    case 'financeiro':
      if (occurrenceType === 'dividas_e_pendencias') {
        return ['Credor', 'Valor']
      }
      if (occurrenceType === 'protestos') {
        return ['Valor', 'Data', 'Cidade', 'UF']
      }
      if (occurrenceType === 'certidao_de_debito') {
        return ['Status da Certidão', 'Fonte', 'Data da Consulta']
      }
      break

    case 'publico_exposta':
      if (subjectType === 'people') {
        return [
          'Direto / Indireto',
          // 'CPF',
          'Grau de parentesco',
          'Cargo',
          'CPF'
          // 'Localidade'
        ]
      }
      // if (subjectType === 'company') {
      //   return [
      //   ]
      // }
      break

    case 'lista_restritiva':
      if (occurrenceType === 'sancoes') {
        return ['Tipo', 'Órgão', 'Apontamento']
      }
      if (occurrenceType === 'cac') {
        return ['Tipo', 'Órgão', 'Apontamento', 'Data de Emissão']
      }
      break

    case 'processos':
      return ['Número', 'Assunto Principal', 'Tipo', 'Nome da Corte']
  }
}

export const getCells = (occurrence, subjectType) => {
  switch (occurrence.occurrence_class) {
    case 'beneficio_social':
      return [
        occurrence.content.Nome_do_Beneficio,
        toCurrency(occurrence.content.Valo_Beneficio),
        occurrence.content.Numero_Parcelas
      ]

    case 'midia':
      return [
        occurrence.content.Categorias,
        <CustomText
          as="a"
          href={occurrence.content.URL}
          size="0.875rem"
          color={theme.colors.brand.primary.medium}
          target="_blank"
        >
          acessar notícia
        </CustomText>
      ]

    case 'relacao_societaria':
      if (occurrence.occurrence_type === 'cnae') {
        return [
          occurrence.content.Razao_social,
          occurrence.content.CNPJ,
          occurrence.content.Classificacao
        ]
      }
      if (occurrence.occurrence_type === 'quadro_societario') {
        return [
          occurrence.content.Nome_Razao_Social_Socio,
          occurrence.content.CPF_CNPJ_Socio,
          occurrence.content.Qualificacao_Socio,
          formatDate(occurrence.content.Data_Entrada),
          formatDate(occurrence.content.Data_Saida)
        ]
      }
      break

    case 'financeiro':
      if (occurrence.occurrence_type === 'dividas_e_pendencias') {
        return [occurrence.content.Credor, toCurrency(occurrence.content.Valor)]
      }
      if (occurrence.occurrence_type === 'protestos') {
        return [
          toCurrency(occurrence.content.Valor_Protesto),
          formatDate(occurrence.content.Data_Protesto),
          occurrence.content.Cidade,
          occurrence.content.UF
        ]
      }
      if (occurrence.occurrence_type === 'certidao_de_debito') {
        return [
          occurrence.content.Status,
          occurrence.content.Fonte,
          formatDate(occurrence.content.Data_Consulta)
        ]
      }
      break

    case 'publico_exposta':
      if (subjectType === 'people') {
        if (occurrence.content.Nivel == 1) {
          return ['Direto', '', occurrence.content.Cargo, '']
        }
        if (occurrence.content.Nivel == 2) {
          return [
            'Indireto',
            occurrence.content.Motivo,
            '',
            occurrence.content.CPF_Relacionado
          ]
        }
      }
      // if (subjectType === 'company') {
      //   return [
      //   ]
      // }
      break

    case 'lista_restritiva':
      if (occurrence.occurrence_type === 'sancoes') {
        return ['Sanções', occurrence.content.Fonte, occurrence.content.Tipo]
      }
      if (occurrence.occurrence_type === 'cac') {
        return [
          'CAC',
          'Polícia Federal',
          occurrence.content.Status,
          occurrence.content.Data_Emissao
        ]
      }
      break

    case 'processos':
      return [
        occurrence.content['Numero'],
        occurrence.content['Assunto Principal'],
        occurrence.content['Tipo'],
        occurrence.content['Nome da Corte']
      ]
  }
}
