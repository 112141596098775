import { update } from 'ramda'

const helpers = {
  /**
   *
   * Filtra um array de summarises, retornando apenas as summarises do status especificado
   * @param {Array} summarises array de summarises de um risco específico
   * @param {Number} status 0 a 6
   * @return {Array}
   *
   */
  filterSummarisesByStatus: (summarises, status) => summarises.filter(summarise => summarise.occurrence_status == status),

  /**
   *
   * Filtra um array de summarises, excluindo as summarises dos status especificados
   * @param {Array[Object]} summarises
   * @param {Array[Number]} statusToExclude
   * @return {Array}
   *
   */
  filterSummarisesByExcludingStatus: (summarises, statusToExclude) =>
    summarises.filter(summarise => statusToExclude.every(status => status != summarise.occurrence_status)),

  /**
   *
   * Cria um objeto com a contagem de uma prop das summarises (ex: contar quantas vezes cada termo apareceu)
   * @param {Array} summarises array de summarises
   * @return {Object} { '123': 10, '456': 30, '789': 20 }
   *
   */
  countSummarisesProp: (summarises, prop) =>
    summarises.reduce((acc, summarise) => {
      return {
        ...acc,
        [summarise[prop]]: (acc[summarise[prop]] || 0) + 1
      }
    }, {}),

  /**
   *
   * Filtra as summarises e retorna apenas as summarises da categoria especificada
   * @param {Array[Object]} summarises
   * @param {String} category
   * @return {Array[Object]}
   *
   */
  filterSummarisesByCategory: (summarises, category) =>
    summarises.filter(summarise => summarise.occurrence_class === category),

  /**
   *
   * Retorna um array de subjects com seus respectivos status atuais
   * @param {Array[Object]} summarises
   * @return {Array[Object]}
   *
   */
  getSubjectsStatus: summarises =>
    summarises.reduce((acc, cur) => {
      const subjectIndex = acc.findIndex(subject => subject.id === cur.subject_id)

      if (cur.occurrence_status == 1 || cur.occurrence_status == 7) return acc

      return subjectIndex === -1
        ? acc.concat({ id: cur.subject_id, status: cur.occurrence_status })
        : cur.occurrence_status < acc[subjectIndex].status ? update(subjectIndex, { id: cur.subject_id, status: cur.occurrence_status }, acc) : acc
    }, []),

  /**
   *
   * Filtra um array de subjects a partir da função passada como parâmetro e retorna o length desse array
   * @param {Array[Object]} subjects
   * @param {Function} fn
   * @return {Number}
   *
   */
  countSubjects: (subjects, fn) => subjects.filter(fn).length,

  /**
   *
   * Retorna o percentual de "value" em relação ao "total" com um decimal (caso haja)
   * @param {Number} value
   * @param {Number} total
   * @return {Number}
   *
   */
  getPercentage: (value, total) => Number(((value * 100) / total).toFixed(1).replace(/\.0/, ''))
}

export const {
  filterSummarisesByStatus,
  filterSummarisesByExcludingStatus,
  countSummarisesProp,
  filterSummarisesByCategory,
  getSubjectsStatus,
  countSubjects,
  getPercentage
} = helpers

export default helpers
