/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Styled */
import { Wrapper } from './styled'

/* Parts */
import SelectedFilter from './SelectedFilter'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { formatDate } from '@zeta/helpers'
import { clearFiltersObj } from 'helpers'

/* Constants */
import { visibleLabels } from 'constants/filters'

const SelectedFilters = () => {
  const filters = useSelector((state) => state.data.filters)
  const selectionOptions = useSelector((state) => state.data.selectionOptions)

  const summarisesFilters = clearFiltersObj(filters, 'summarises')

  return (
    <Wrapper>
      {Object.keys(summarisesFilters).map((key) => {
        if (Array.isArray(summarisesFilters[key])) {
          return summarisesFilters[key].map((filterValue) => (
            <SelectedFilter
              name={visibleLabels[key]}
              value={
                selectionOptions[key]
                  ? selectionOptions[key].find(
                      (filterItem) => filterItem.dbValue == filterValue
                    ).visibleValue
                  : filterValue
              }
              key={uuidv4()}
            />
          ))
        }

        return (
          <SelectedFilter
            name={visibleLabels[key]}
            value={`${
              summarisesFilters[key].start
                ? 'maior que ' + formatDate(summarisesFilters[key].start)
                : ''
            }${
              summarisesFilters[key].start && summarisesFilters[key].end
                ? ' e '
                : ''
            }${
              summarisesFilters[key].end
                ? 'menor que ' + formatDate(summarisesFilters[key].end)
                : ''
            }`}
            key={uuidv4()}
          />
        )
      })}
    </Wrapper>
  )
}

export default SelectedFilters
