/* Types */
import {
  BOARD_REQUESTED,
  BOARD_SUCCEEDED,
  SET_BOARD_INFO,
  CREATE_RISK_RATING_REQ,
  SET_FILTERS,
  SET_SAVE_REPORT_MODAL,
  SET_SIDEBAR_OPEN,
  MAIN_DATA_REQUESTED,
  PEOPLE_REQUESTED,
  COMPANIES_REQUESTED,
  EDIT_OCCURRENCE_STATUS,
  UPDATE_SUMMARISES,
  UPDATE_SUBJECTS,
  SET_LIST_PAGE,
  SET_LIST_PER_PAGE,
  SET_LIST_ORDER,
  SET_SEARCH_QUERY,
  RESET_PROPS,
  RESET_STATE
} from './action-types'

export const getAndSetBoard = ({ boardType, boardId }) => ({
  type: BOARD_REQUESTED,
  boardType,
  boardId
})

export const setBoard = ({ boardType, payload }) => ({
  type: BOARD_SUCCEEDED,
  boardType,
  payload
})

export const setBoardInfo = ({ payload }) => ({
  type: SET_BOARD_INFO,
  payload
})

export const getAndSetMainData = () => ({
  type: MAIN_DATA_REQUESTED
})

export const getAndSetPeople = (subjectsIds) => ({
  type: PEOPLE_REQUESTED,
  subjectsIds
})

export const getAndSetCompanies = (subjectsIds) => ({
  type: COMPANIES_REQUESTED,
  subjectsIds
})

export const createRiskRating = ({ description, periodicity, onSuccess }) => ({
  type: CREATE_RISK_RATING_REQ,
  description,
  periodicity,
  onSuccess
})

export const editOccurrenceStatus = ({
  subjectType,
  summariseIndex,
  updatedSummarise
}) => ({
  type: EDIT_OCCURRENCE_STATUS,
  subjectType,
  summariseIndex,
  updatedSummarise
})

export const updateSummarises = ({ subjectType, updatedSummarises }) => ({
  type: UPDATE_SUMMARISES,
  subjectType,
  updatedSummarises
})

export const updateSubjects = ({ subjectType, updatedSubjects }) => ({
  type: UPDATE_SUBJECTS,
  subjectType,
  updatedSubjects
})

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  filters
})

export const setListPage = (page, subjectType) => ({
  type: SET_LIST_PAGE,
  page,
  subjectType
})

export const setListPerPage = (perPage, subjectType) => ({
  type: SET_LIST_PER_PAGE,
  perPage,
  subjectType
})

export const setListOrder = (orderBy, orderValueType, subjectType) => ({
  type: SET_LIST_ORDER,
  orderBy,
  orderValueType,
  subjectType
})

export const setSearchQuery = (searchQuery, subjectType) => ({
  type: SET_SEARCH_QUERY,
  searchQuery,
  subjectType
})

export const setSaveBoardModal = ({
  isOpen,
  proceedFn,
  enableProceedWithoutSaving
}) => ({
  type: SET_SAVE_REPORT_MODAL,
  isOpen,
  proceedFn,
  enableProceedWithoutSaving
})

export const setSidebarOpen = (isOpen) => ({
  type: SET_SIDEBAR_OPEN,
  isOpen
})

/* Reseta uma ou mais prop's > array de string's */
export const resetProps = (props) => ({
  type: RESET_PROPS,
  props
})

/* Reseta tudo para o state inicial */
export const resetState = () => ({ type: RESET_STATE })
