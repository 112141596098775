/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import {
  Card,
  CustomText,
  TableInfo,
  ButtonWithIcon,
  CustomBlock,
  LoadingSpinner
} from '@zeta/ui/src'

import HistoryModal from './HistoryModal'

/* Constants */
// import { visibleStatus } from 'constants/dictionary'

/* Helpers */
import { getSubjectCollections } from 'api'
import { formatDate, logAmplitudeEvent } from '@zeta/helpers'

const HistoryTab = ({ subjectId, subjectType }) => {
  const [dataCollections, setDataCollections] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false)
  const [collection, setCollection] = useState(null)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)

    getSubjectCollections({
      subjectId,
      subjectType
    }).then((res) => {
      if (mounted) {
        setDataCollections(res.data)
        setIsLoading(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      <Card>
        <CustomText weight="700" mb="medium">
          Histórico de análise
        </CustomText>

        {dataCollections && (
          <TableInfo
            headers={[
              { text: 'ID da coleta' },
              { text: 'Score' },
              { text: 'Responsável' },
              { text: 'Atualização da base' },
              { text: 'Início da análise' },
              // { text: 'Status' },
              { text: 'Data de resolução' },
              { text: '' }
            ]}
            rows={dataCollections.map((dataCollection) => ({
              cells: [
                { text: dataCollection.collection_id },
                { text: dataCollection.score },
                { text: dataCollection.responsible },
                { text: formatDate(dataCollection.collection_updated) },
                { text: formatDate(dataCollection.start_analysis) },
                // { text: visibleStatus[dataCollection.status] },
                { text: formatDate(dataCollection.end_analysis) },
                {
                  text: (
                    <ButtonWithIcon
                      icon="eye-outline"
                      onClick={() => {
                        setCollection(dataCollection)

                        setIsHistoryModalVisible(true)

                        logAmplitudeEvent({
                          event: 'Clique no histórico de ações no perfil',
                          eventProperties: { Tipo: 'Histórico' }
                        })
                      }}
                    >
                      <span>ver detalhes</span>
                    </ButtonWithIcon>
                  ),
                  textAlign: 'right'
                }
              ]
            }))}
          />
        )}

        {isLoading && (
          <CustomBlock override="width: 100%; display: flex; justify-content: center;">
            <LoadingSpinner size="xlarge" />
          </CustomBlock>
        )}
      </Card>

      {isHistoryModalVisible && (
        <HistoryModal
          subjectId={subjectId}
          subjectType={subjectType}
          isHistoryModalVisible={isHistoryModalVisible}
          setIsHistoryModalVisible={setIsHistoryModalVisible}
          collection={collection}
          setCollection={setCollection}
        />
      )}
    </>
  )
}

export default HistoryTab
