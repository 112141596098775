/* React */
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

/* Components */
import {
  Card,
  TableStriped,
  CustomText,
  theme
} from '@zeta/ui/src'

/* Helpers */
import { countSubjects } from '../../helpers'

/* Constants */
import { states } from '../../constants'

const Score = ({
  /* router */
  history,
  /* props */
  filteredSubjects,
  subjectType
}) => {
  const subjectsByScore = {
    'Alto': filteredSubjects.filter(s => s.score > 666.66),
    'Médio': filteredSubjects.filter(s => s.score > 333.33 && s.score <= 666.66),
    'Baixo': filteredSubjects.filter(s => s.score <= 333.33)
  }

  const scoreTotals = {
    'Alto': subjectsByScore['Alto'].length,
    'Médio': subjectsByScore['Médio'].length,
    'Baixo': subjectsByScore['Baixo'].length
  }

  const scoreByState = states
    .map(state => ({
      name: state,
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.state === state),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.state === state),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.state === state),
      'Total': countSubjects(filteredSubjects, s => s.state === state)
    }))
    .filter(state => state['Total'] > 0)
    .sort((a, b) => b['Total'] - a['Total'])

  const scoreByGender = [
    {
      name: 'Masculino',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.gender === 'M'),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.gender === 'M'),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.gender === 'M'),
      'Total': countSubjects(filteredSubjects, s => s.gender === 'M')
    },
    {
      name: 'Feminino',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.gender === 'F'),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.gender === 'F'),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.gender === 'F'),
      'Total': countSubjects(filteredSubjects, s => s.gender === 'F')
    },
    {
      name: 'Não identificado',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.gender === null),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.gender === null),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.gender === null),
      'Total': countSubjects(filteredSubjects, s => s.gender === null)
    }
  ].sort((a, b) => b['Total'] - a['Total'])

  const scoreByAge = [
    {
      name: '18 - 35 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 18 && s.age <= 35),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 18 && s.age <= 35),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 18 && s.age <= 35),
      'Total': countSubjects(filteredSubjects, s => s.age >= 18 && s.age <= 35)
    },
    {
      name: '36 - 45 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 36 && s.age <= 45),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 36 && s.age <= 45),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 36 && s.age <= 45),
      'Total': countSubjects(filteredSubjects, s => s.age >= 36 && s.age <= 45)
    },
    {
      name: '45 - 60 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 46 && s.age <= 60),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 46 && s.age <= 60),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 46 && s.age <= 60),
      'Total': countSubjects(filteredSubjects, s => s.age >= 46 && s.age <= 60)
    },
    {
      name: 'Acima de 60 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 61),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 61),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 61),
      'Total': countSubjects(filteredSubjects, s => s.age >= 61)
    },
    {
      name: 'Não identificado',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age === null),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age === null),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age === null),
      'Total': countSubjects(filteredSubjects, s => s.age === null)
    }
  ]

  useEffect(() => {
    window.print()
    history.goBack()
  }, [])

  const TableStripedNumber = ({ children }) =>
    <CustomText
      weight='700'
      color={subjectType === 'people' ? theme.colors.brand.primary.dark : theme.colors.brand.secondary.dark}
    >
      {children}
    </CustomText>

  if (filteredSubjects.length === 0) return <CustomText size='0.875rem'>Nenhum dado a ser exibido.</CustomText>

  return (
    <div style={{ padding: '16px' }}>
      <Card title={'NÍVEL DE RISCO'} mb='xlarge'>
        <TableStriped
          headers={[
            { text: <strong>Risco</strong> },
            { text: <strong>Total</strong>, textAlign: 'right' }
          ]}
          rows={[
            {
              cells: [
                { text: 'Alto' },
                { text: <TableStripedNumber>{scoreTotals['Alto']}</TableStripedNumber>, textAlign: 'right' }
              ]
            },
            {
              cells: [
                { text: 'Médio' },
                { text: <TableStripedNumber>{scoreTotals['Médio']}</TableStripedNumber>, textAlign: 'right' }
              ]
            },
            {
              cells: [
                { text: 'Baixo' },
                { text: <TableStripedNumber>{scoreTotals['Baixo']}</TableStripedNumber>, textAlign: 'right' }
              ]
            }
          ]}
        />
      </Card>

      <Card title={'NÍVEL DE RISCO / ESTADO'} mb='xlarge'>
        <TableStriped
          headers={[
            { text: <strong>Estado</strong> },
            { text: 'Alto', textAlign: 'center' },
            { text: 'Médio', textAlign: 'center' },
            { text: 'Baixo', textAlign: 'center' },
            { text: <strong>Total</strong>, textAlign: 'center' }
          ]}
          rows={
            scoreByState.map(state => ({
              cells: [
                { text: state.name === null ? 'Não identificado' : state.name },
                { text: <TableStripedNumber>{state['Alto']}</TableStripedNumber>, textAlign: 'center' },
                { text: <TableStripedNumber>{state['Médio']}</TableStripedNumber>, textAlign: 'center' },
                { text: <TableStripedNumber>{state['Baixo']}</TableStripedNumber>, textAlign: 'center' },
                { text: <TableStripedNumber>{state['Total']}</TableStripedNumber>, textAlign: 'center' }
              ]
            }))
          }
        />
      </Card>

      {subjectType === 'people' &&
        <>
          <Card title={'NÍVEL DE RISCO / SEXO'} mb='xlarge'>
            <TableStriped
              headers={[
                { text: <strong>Sexo</strong> },
                { text: 'Alto' },
                { text: 'Médio' },
                { text: 'Baixo' },
                { text: <strong>Total</strong>, textAlign: 'right' }
              ]}
              rows={
                scoreByGender.map(gender => ({
                  cells: [
                    { text: gender.name === null ? 'Não identificado' : gender.name },
                    { text: <TableStripedNumber>{gender['Alto']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{gender['Médio']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{gender['Baixo']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{gender['Total']}</TableStripedNumber>, textAlign: 'right' }
                  ]
                }))
              }
            />
          </Card>

          <Card title={'NÍVEL DE RISCO / IDADE'} mb='xlarge'>
            <TableStriped
              headers={[
                { text: <strong>Faixa etária</strong> },
                { text: 'Alto' },
                { text: 'Médio' },
                { text: 'Baixo' },
                { text: <strong>Total</strong>, textAlign: 'right' }
              ]}
              rows={
                scoreByAge.map(age => ({
                  cells: [
                    { text: age.name === null ? 'Não identificado' : age.name },
                    { text: <TableStripedNumber>{age['Alto']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{age['Médio']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{age['Baixo']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{age['Total']}</TableStripedNumber>, textAlign: 'right' }
                  ]
                }))
              }
            />
          </Card>
        </>}
    </div>
  )
}

export default withRouter(Score)
