export const getDateString = (date) =>
  `${date.getFullYear()}-${
    String(date.getMonth() + 1).length === 1 ? '0' : ''
  }${date.getMonth() + 1}-${
    String(date.getDate()).length === 1 ? '0' : ''
  }${date.getDate()}`

export const getSubjectStatus = (occurrencesByClass) => {
  const sortedOccurrences = Object.keys(occurrencesByClass)
    .reduce((acc, curKey) => acc.concat(occurrencesByClass[curKey]), [])
    .filter((occurrence) => occurrence.occurrence_status.status != 1)
    .sort((a, b) => a.occurrence_status.status - b.occurrence_status.status)

  return sortedOccurrences.length > 0
    ? sortedOccurrences[0].occurrence_status.status
    : false
}

export const getMiscOccurrenceClass = (occurrenceClass) => {
  if (occurrenceClass === 'publico_exposta') return 'ppe'
  if (occurrenceClass === 'midia') return 'midias'
  return occurrenceClass
}
