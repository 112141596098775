import styled from 'styled-components'

import { Row } from 'styled-bootstrap-grid'
import { defaultProps } from '@zeta/ui/src'

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: ${({ theme: { spacing } }) => spacing.medium};
  border-top: 1px dashed #B7B7B7;
`

export const OccurrenceCardCol = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    width: 50%;

    &:first-child:not(:last-child) {
      padding-right: ${({ theme: { spacing } }) => spacing.small};
    }
    &:last-child:not(:first-child) {
      padding-left: ${({ theme: { spacing } }) => spacing.small};
    }
  }
`

// export const Button = styled.button.attrs(() => ({
//   type: 'button'
// }))`
//   display: flex;
//   align-items: center;
//   padding: ${({ theme: { spacing } }) => `${spacing.small} 0`};
//   color: ${({ theme: { colors } }) => colors.brand.primary.medium};
//   font-size: 0.875rem;
// `

// export const ButtonIcon = styled(Icon).attrs(() => ({
//   size: 'medium',
//   fill: theme.colors.brand.primary.medium
// }))`
//   transform: translateY(-1px);

//   &:first-child {
//     margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
//   }
//   &:last-child {
//     margin-left: ${({ theme: { spacing } }) => spacing.xsmall};
//   }
// `

// export const StatusCol = styled.div`
//   width: 100%;
//   padding: ${({ theme: { spacing } }) => `${spacing.medium} ${spacing.small} 0 ${spacing.small}`};
//   border-top: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
//   display: flex;
//   align-items: center;

//   ${defaultProps}
// `

// export const StatusLabel = styled.div`
//   font-size: 0.875rem;
//   padding: ${({ theme: { spacing } }) => `${spacing.small} 0`};
//   margin-right: ${({ theme: { spacing } }) => spacing.small};
// `

export const OpenOccurrenceButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme: { spacing } }) => spacing.medium};
  width: ${({ theme: { spacing } }) => spacing.xlarge};
  height: ${({ theme: { spacing } }) => spacing.xlarge};
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.surface.primary.light};
`

export const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  font-weight: 700;
`

export const SubTitle = styled.div`
  font-size: 0.875rem;
`

export const Status = styled.div`
  font-size: 0.75rem;
  padding: ${({ theme: { spacing } }) => `${spacing.small} ${spacing.medium}`};
  background-color: #EEEEEE;
  border-radius: 100px;
  margin-right: ${({ theme: { spacing } }) => spacing.xlarge};
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
  white-space: nowrap;
`

export const ActionsWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: ${({ theme: { spacing } }) => spacing.large};
  margin-left: ${({ theme: { spacing } }) => spacing.small};
`

export const InnerCardWrapper = styled.div`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme: { radius } }) => radius.small};
  padding: ${({ theme: { spacing } }) => spacing.medium};

  ${defaultProps}
`

export const InnerCardHeader = styled.div`
  padding-bottom: ${({ theme: { spacing } }) => spacing.medium};
  ${({ tabs }) => tabs && 'padding-bottom: 0;'}
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InnerCardTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
  display: flex;
  align-items: center;
`

export const InnerCardMore = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  text-align: left;
  white-space: nowrap;

  svg {
    transform: translateY(-1px);
  }
`

export const InnerCardContent = styled.div`

`

export const InnerCardHeadline = styled.div`
  font-size: 0.625rem;
  font-weight: 700;
  color: #B7B7B7;
  text-transform: uppercase;

  ${defaultProps}
`

export const InnerCardRow = styled(Row)`
  width: calc(100% + 32px);
`

export const InnerCardColumnWrapper = styled.div`
  display: flex;
  align-items: stretch;
`

export const InnerCardColumn = styled.div`
  width: 50%;
  text-align: center;

  &:first-child {
    border-right: 1px solid #EEEEEE;
  }
  &:last-child {
    border-left: 1px solid #EEEEEE;
  }
`

export const InnerCardTab = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding-bottom: ${({ theme: { spacing } }) => spacing.medium};
  border-bottom: 1px solid ${({ theme: { colors }, isActive }) => isActive ? colors.brand.primary.medium : 'transparent'};
  ${({ isActive }) => isActive && 'font-weight: 700;'}

  &:not(:first-child) {
    margin-left: ${({ theme: { spacing } }) => spacing.large};
  }
`

export const ContentModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: ${({ theme: { radius } }) => radius.smallest};
`

export const ContentModal = styled.div`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border-radius: ${({ theme: { radius } }) => radius.small};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 300px;
  max-width: calc(100% - ${({ theme: { spacing } }) => spacing.xlarge});
`

export const ContentModalHeader = styled.div`
  padding-bottom: ${({ theme: { spacing } }) => spacing.medium};
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContentModalTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const PopUpIconWrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme: { spacing } }) => spacing.large};
  height: ${({ theme: { spacing } }) => spacing.large};
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.surface.primary.light};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.medium};

  &:not(:first-child) {
    margin-left: ${({ theme: { spacing } }) => spacing.small};
  }
`

export const WarningTagWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  padding: ${({ theme: { spacing } }) => `${spacing.small} ${spacing.medium}`};
  background-color: #FEF0E9;
  color: #F4753D;
  border-radius: 100px;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
  white-space: nowrap;

  > img,
  svg {
    width: 12px;
    margin: ${({ theme: { spacing } }) => `0 ${spacing.xsmall}`};
    transform: translateY(-1px);
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const HeaderCell = styled.th`
  &:first-child {
    text-align: left;
    padding-right: ${({ theme: { spacing } }) => spacing.small};
  }
  &:last-child {
    text-align: right;
    padding-left: ${({ theme: { spacing } }) => spacing.small};
  }
`

export const TableCell = styled.td`
  padding-top: ${({ theme: { spacing } }) => spacing.small};

  &:first-child {
    text-align: left;
    padding-right: ${({ theme: { spacing } }) => spacing.small};
  }
  &:last-child {
    text-align: right;
    padding-left: ${({ theme: { spacing } }) => spacing.small};
  }
`

export const ProfileCellImg = styled.img`
  width: ${({ theme: { spacing } }) => spacing.large};
  height: ${({ theme: { spacing } }) => spacing.large};
  border-radius: 50%;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const InnerCardLinkWrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  font-size: 0.625rem;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  text-align: left;

  ${defaultProps}
`
