/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Container } from 'styled-bootstrap-grid'

import {
  LoadingSpinner,
  theme
} from '@zeta/ui/src'

import Menu from 'components/Menu'
import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

export const UserPageWrapper = ({ children }) => {
  const isLoadingMainData = useSelector(state => state.data.isLoadingMainData)

  return isLoadingMainData
    ? <LoadingSpinner size='40' override={`margin: ${theme.spacing.xlarge} auto 0 auto;`} />
    : (
      <>
        <Menu />

        <ContentWrapper>
          <Container>
            {children}
          </Container>

          <Footer />
        </ContentWrapper >
      </>
    )
}

export default UserPageWrapper
