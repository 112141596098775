/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  LoadingSpinner,
  CustomText,
  Icon
} from '@zeta/ui/src'

/* Action Creators */
import { toggleSubjectSituation } from 'store/reducers/action-creators'

const ArchiveModal = ({
  isArchiveModalVisible,
  setIsArchiveModalVisible,
  individual
}) => {
  const dispatch = useDispatch()

  const isEditingSituation = useSelector(state => state.individual.isEditingSituation)

  return (
    <Modal
      isVisible={isArchiveModalVisible}
      closeFn={() => setIsArchiveModalVisible(false)}
      title={individual.situation ? 'Inativar perfil' : 'Ativar perfil'}
    >
      <>
        <ModalContent>
          <CustomText size='0.875rem' mb='large'>
            Você está certo(a) de que deseja {individual.situation ? 'inativar' : 'ativar'} esse perfil?
          </CustomText>
        </ModalContent>

        <ModalActions>
          <Button red onClick={() => setIsArchiveModalVisible(false)}>
            <Icon icon='close-outline' />
            <span>Cancelar</span>
          </Button>

          <Button onClick={() => dispatch(toggleSubjectSituation(() => setIsArchiveModalVisible(false)))} isDisabled={isEditingSituation}>
            <Icon icon='save-outline' />
            <span>Confirmar</span>
          </Button>

          {isEditingSituation && <LoadingSpinner size={'xlarge'} ml={'small'} />}
        </ModalActions>
      </>
    </Modal>
  )
}

export default ArchiveModal
