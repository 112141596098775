/* React */
import React from 'react'
// import PropTypes from 'prop-types'

/* Components */
import ReactTooltip from 'react-tooltip'

import {
  CollapseAlt,
  TableInfo,
  CustomText,
  Icon,
  HorizontalScroll,
  theme
} from '@zeta/ui/src'

/* Styled */
import { ContentWrapper, Col } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

/* Constants */
const headers = [
  { text: 'Dado' },
  { text: 'Resultado' },
  { text: 'Fonte', textAlign: 'right' }
]

const Headline = ({ children }) => (
  <CustomText size="0.75rem" weight="700">
    {children}
  </CustomText>
)

const ResultText = ({ children }) => (
  <CustomText size="0.875rem" weight="700" override="white-space: nowrap;">
    {children}
  </CustomText>
)

const TooltipIcon = ({ dataTip }) => (
  <Icon
    icon="question-mark-circle-outline"
    size="medium"
    fill={theme.colors.surface.primary.darkness}
    override="justify-content: flex-end;"
    data-tip={dataTip}
    data-for="profileInfoTooltip"
  />
)

const ProfileInfo = ({ individual, subjectType }) => {
  const phones = {
    mobile:
      individual.phone.cellphone && individual.phone.cellphone.length > 0
        ? individual.phone.cellphone.map((cellphone) => cellphone.number)
        : null,
    landline:
      individual.phone.phone && individual.phone.phone.length > 0
        ? individual.phone.phone.map((phone) => phone.number)
        : null
  }

  const emails =
    Object.keys(individual.email || {}).length > 0
      ? Object.keys(individual.email).map((key) => individual.email[key])
      : null

  return (
    <CollapseAlt title="Ver mais">
      <ContentWrapper>
        {(phones.mobile || phones.landline) && (
          <Col>
            <HorizontalScroll>
              <TableInfo
                headers={headers}
                rows={[
                  {
                    cells: [
                      { text: <Headline>TELEFONES</Headline>, colSpan: 3 }
                    ]
                  },
                  {
                    cells: phones.mobile
                      ? [
                          { text: 'Telefone móvel' },
                          {
                            text: phones.mobile.map((phone) => (
                              <ResultText key={uuidv4()}>{phone}</ResultText>
                            ))
                          },
                          { text: <TooltipIcon dataTip="Assertiva" /> }
                        ]
                      : []
                  },
                  {
                    cells: phones.landline
                      ? [
                          { text: 'Telefone fixo' },
                          {
                            text: phones.landline.map((phone) => (
                              <ResultText key={uuidv4()}>{phone}</ResultText>
                            ))
                          },
                          { text: <TooltipIcon dataTip="Assertiva" /> }
                        ]
                      : []
                  }
                ]}
              />
            </HorizontalScroll>
          </Col>
        )}

        {subjectType === 'people' && (
          /* (individual.main_email || emails) && */
          <Col>
            <HorizontalScroll>
              <TableInfo
                headers={[{ text: 'Dado' }, { text: 'Resultado' }]}
                rows={[
                  {
                    cells: [{ text: <Headline>E-MAILS</Headline>, colSpan: 3 }]
                  },
                  {
                    cells: [
                      { text: 'Principal' },
                      {
                        text: individual.main_email && (
                          <ResultText>{individual.main_email}</ResultText>
                        )
                      }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Outros' },
                      {
                        text:
                          emails &&
                          emails.map((email) => (
                            <ResultText key={uuidv4()}>{email}</ResultText>
                          ))
                      }
                    ]
                  }
                ]}
              />
            </HorizontalScroll>
          </Col>
        )}

        {subjectType === 'company' && (
          <Col>
            <HorizontalScroll>
              <TableInfo
                headers={[{ text: 'Dado' }, { text: 'Resultado' }]}
                rows={[
                  {
                    cells: [{ text: <Headline>E-MAILS</Headline>, colSpan: 3 }]
                  },
                  {
                    cells: [
                      { text: 'Responsável pelo Escritório' },
                      {
                        text: individual.email_office_responsible && (
                          <ResultText>
                            {individual.email_office_responsible}
                          </ResultText>
                        )
                      }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Responsável pelo Compliance' },
                      {
                        text: individual.email_compliance_responsible && (
                          <ResultText>
                            {individual.email_compliance_responsible}
                          </ResultText>
                        )
                      }
                    ]
                  }
                ]}
              />
            </HorizontalScroll>
          </Col>
        )}

        <ReactTooltip id="profileInfoTooltip" />
      </ContentWrapper>
    </CollapseAlt>
  )
}

ProfileInfo.propTypes = {}

export default ProfileInfo
