/* Constants */
import {
  SUBJECT_REQUESTED,
  // POSTURE_REQUESTED,
  SET_OCCURRENCES_BY_CLASS,
  SET_OCCURRENCE_TO_CLASSIFY,
  TOGGLE_OPENED_OCCURRENCE,
  SET_VIEW_FILTERED_PUBLICATIONS,
  RESET_INDIVIDUAL
} from './action-types'

export const getAndSetSubject = ({
  subjectId, subjectType
}) => ({
  type: SUBJECT_REQUESTED,
  subjectId,
  subjectType
})

// export const getAndSetPosture = ({
//   subjectId, subjectType
// }) => ({
//   type: POSTURE_REQUESTED,
//   subjectId,
//   subjectType
// })

export const setOccurrencesByClass = occurrencesByClass => ({
  type: SET_OCCURRENCES_BY_CLASS,
  occurrencesByClass
})

export const setOccurrenceToClassify = occurrenceToClassify => ({
  type: SET_OCCURRENCE_TO_CLASSIFY,
  occurrenceToClassify
})

export const toggleOpenedOccurrence = occurrenceId => ({
  type: TOGGLE_OPENED_OCCURRENCE,
  occurrenceId
})

export const setViewFilteredPublications = bool => ({
  type: SET_VIEW_FILTERED_PUBLICATIONS,
  bool
})

export const resetIndividual = () => ({
  type: RESET_INDIVIDUAL
})
