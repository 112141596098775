import styled from 'styled-components'

export const DropdownBtnsWrapper = styled.div`
  display: inline-block;
  position: relative;
`

export const FlatpickrWrapper = styled.span`
  .flatpickr-input,
  .flatpickr-input + .form-control.input {
    font-size: 0.875rem;
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors.type.medium};
    width: 75px;
    height: auto;
    line-height: 1.15;
    border: 0;
    padding-right: 0;
    padding-left: ${({ theme: { spacing } }) => spacing.xsmall};
  }
`
