import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-size: 0.875rem;
  font-weight: 700;
`

export const Input = styled.input`
  border: 1px solid #ccc;
  width: 100%;
  outline: none;
  padding: 12px 15px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: ${({ theme: { colors } }) => colors.type.medium};
  margin-bottom: ${({ theme: { spacing } }) => spacing.xlarge};
`
