import styled from 'styled-components'

export const SteplineWrapper = styled.div`
  position: relative;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
  }

  @media print {
    &:not(:last-child) {
      margin-bottom: ${({ theme: { spacing } }) => spacing.small};
    }
  }
`

export const ItemLabel = styled.div`
  font-size: 0.875rem;
  color: ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' ? colors.brand.primary.dark : colors.brand.secondary.dark};
`
