/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  Card,
  Button,
  Icon,
  CustomText,
  FlexCentered,
  LoadingSpinner,
  theme,
  FlexAlignCenter
} from '@zeta/ui/src'

import { RiskRating, RemoveBtn } from './styled'

import ExcludeModal from './ExcludeModal'
import AddModal from './AddModal'
import EditModal from './EditModal'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

/* Constants */
const EXCLUDE_MODAL = 'EXCLUDE_MODAL'
const ADD_MODAL = 'ADD_MODAL'
const EDIT_MODAL = 'EDIT_MODAL'

export const ManageRiskRating = () => {
  const riskRatings = useSelector(state => state.data.riskRatings)
  const isLoadingMainData = useSelector(state => state.data.isLoadingMainData)

  const [modal, setModal] = useState(null)
  const [riskRatingToExclude, setRiskRatingToExclude] = useState(null)
  const [riskRatingToEdit, setRiskRatingToEdit] = useState(null)

  return (
    <>
      <Row>
        <Col md={6} mdOffset={3}>
          <Card mb='xlarge'>
            <div>
              {isLoadingMainData &&
                <FlexCentered>
                  <LoadingSpinner size='40' />
                </FlexCentered>}

              {riskRatings && riskRatings.map(riskRating => (
                <RiskRating key={uuidv4()}>
                  <div>
                    <CustomText size='0.875rem' weight='700' mb='smallest'>{riskRating.description}</CustomText>
                    <CustomText size='0.875rem'>análises a cada {riskRating.periodicity_in_months} {riskRating.periodicity_in_months === 1 ? 'mês' : 'meses'}</CustomText>
                  </div>

                  <FlexAlignCenter>
                    <Icon
                      icon='edit-2-outline'
                      as='button'
                      type='button'
                      onClick={() => {
                        setModal(EDIT_MODAL)
                        setRiskRatingToEdit(riskRating)
                      }}
                      size='medium'
                      fill={theme.colors.brand.primary.medium}
                      mr='small'
                    />

                    <RemoveBtn>
                      <Icon
                        icon='close-outline'
                        size='12'
                        fill={theme.colors.feedback.red.medium}
                        onClick={() => {
                          setModal(EXCLUDE_MODAL)
                          setRiskRatingToExclude(riskRating)
                        }}
                      />
                    </RemoveBtn>
                  </FlexAlignCenter>
                </RiskRating>
              ))}
            </div>

            {riskRatings &&
              <Button fullWidth mt='medium' onClick={() => setModal(ADD_MODAL)}>
                <span>Adicionar classificação de risco</span>
                <Icon icon='plus-circle-outline' />
              </Button>}
          </Card>
        </Col>
      </Row>

      {modal === EXCLUDE_MODAL && <ExcludeModal setModal={setModal} riskRatingToExclude={riskRatingToExclude} />}

      {modal === ADD_MODAL && <AddModal setModal={setModal} />}

      {modal === EDIT_MODAL && <EditModal setModal={setModal} riskRatingToEdit={riskRatingToEdit} />}
    </>
  )
}

export default ManageRiskRating
