/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Card, Icon, theme } from '@zeta/ui/src'

import SelectedFilters from '../SelectedFilters'

/* Styled */
import {
  Row,
  Col,
  InfosCol,
  InfoGroup,
  TotalListedWrapper,
  TotalListedIconWrapper,
  TotalListedInfoWrapper,
  TotalListedNumber,
  TotalListedText,
  Label,
  Info,
  InfoIconWrapper,
  InfoTextWrapper,
  InfoTotal,
  InfoText
} from './styled'

const InfoIcon = ({ icon, subjectType }) => (
  <Icon
    icon={icon}
    size="medium"
    fill={
      subjectType === 'people'
        ? theme.colors.brand.primary.medium
        : subjectType === 'company'
        ? theme.colors.brand.secondary.medium
        : theme.colors.brand.tertiary.lightness
    }
  />
)

const StatisticsCard = ({
  /* props */
  subjectType,
  totalListed,
  totalOccurrences,
  totalNonElegible,
  totalConcluded,
  showFilters,
  activePreset,
  ...rest
}) => {
  return (
    <Card py="medium" px="small" {...rest}>
      <Row>
        {/* Total */}
        <Col>
          <Label>{subjectType === 'people' ? 'Pessoas' : 'Escritórios'}</Label>

          <TotalListedWrapper>
            <TotalListedIconWrapper subjectType={subjectType}>
              <Icon
                icon={
                  subjectType === 'people'
                    ? 'people-outline'
                    : 'briefcase-outline'
                }
                size="xlarge"
                fill={
                  subjectType === 'people'
                    ? theme.colors.brand.primary.medium
                    : theme.colors.brand.secondary.medium
                }
              />
            </TotalListedIconWrapper>

            <TotalListedInfoWrapper>
              <TotalListedNumber subjectType={subjectType}>
                {totalListed}{' '}
              </TotalListedNumber>
              <TotalListedText>
                {subjectType === 'people' ? 'pessoas' : 'escritórios'}
              </TotalListedText>
            </TotalListedInfoWrapper>
          </TotalListedWrapper>
        </Col>

        {/* Ocorrências */}
        <InfosCol>
          <InfoGroup>
            <Label>Ocorrências</Label>

            <Info>
              <InfoIconWrapper subjectType={subjectType}>
                <InfoIcon icon="share-outline" subjectType={subjectType} />
              </InfoIconWrapper>

              <InfoTextWrapper>
                <InfoTotal subjectType={subjectType}>
                  {totalOccurrences}
                </InfoTotal>
                <InfoText> ocorrências elegíveis</InfoText>
              </InfoTextWrapper>
            </Info>

            <Info>
              <InfoIconWrapper>
                <InfoIcon icon="share-outline" />
              </InfoIconWrapper>

              <InfoTextWrapper>
                <InfoTotal>{totalNonElegible}</InfoTotal>
                <InfoText> ocorrências não elegíveis</InfoText>
              </InfoTextWrapper>
            </Info>

            <Info>
              <InfoIconWrapper subjectType={subjectType}>
                <InfoIcon
                  icon="alert-triangle-outline"
                  subjectType={subjectType}
                />
              </InfoIconWrapper>

              <InfoTextWrapper>
                <InfoTotal subjectType={subjectType}>
                  {totalConcluded}
                </InfoTotal>
                <InfoText> análises concluídas</InfoText>
              </InfoTextWrapper>
            </Info>
          </InfoGroup>

          {showFilters && (
            <InfoGroup>
              <Label>Filtragem</Label>

              {activePreset && (
                <Info>
                  <InfoIconWrapper subjectType={subjectType}>
                    <InfoIcon
                      icon="options-2-outline"
                      subjectType={subjectType}
                    />
                  </InfoIconWrapper>

                  <InfoTextWrapper>
                    <InfoText>
                      {subjectType === 'people' ? 'Pessoas' : 'Escritórios'} com
                      ocorrências de{' '}
                    </InfoText>
                    <InfoTotal subjectType={subjectType}>
                      {activePreset}
                    </InfoTotal>
                  </InfoTextWrapper>
                </Info>
              )}

              <SelectedFilters />
            </InfoGroup>
          )}
        </InfosCol>
      </Row>
    </Card>
  )
}

StatisticsCard.propTypes = {
  subjectType: PropTypes.oneOf(['people', 'company']),
  totalListed: PropTypes.number.isRequired,
  totalOccurrences: PropTypes.number.isRequired,
  totalConcluded: PropTypes.number.isRequired,
  activePreset: PropTypes.string
}

export default StatisticsCard
