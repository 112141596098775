/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { FlexAlignCenter } from '../../index'

/* Styled */
import { Option } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const GroupSelect = ({
  items,
  activeIndex,
  ...rest
}) => {
  return (
    <FlexAlignCenter wrap {...rest}>
      {items.map((item, i) => (
        <Option onClick={item.fn} isActive={activeIndex === i} key={uuidv4()}>{item.text}</Option>
      ))}
    </FlexAlignCenter>
  )
}

GroupSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      fn: PropTypes.func.isRequired
    })
  ),
  activeIndex: PropTypes.number
}

export default GroupSelect
