/* React */
import React from 'react'

/* Components */
import { Col } from 'styled-bootstrap-grid'

import {
  Card,
  HorizontalScroll,
  TableStriped,
  CustomText,
  PieChart,
  StackedVerticalBarChart,
  CustomScrollbars,
  theme
} from '@zeta/ui/src'

/* Helpers */
import { countSubjects, getPercentage } from '../helpers'

/* Constants */
import { states } from '../constants'

const ScrollableWrapper = ({ items, maxItems, height, style, children }) => {
  return items > maxItems
    ? (
      <CustomScrollbars
        style={{ height, ...style }}
        renderViewStyle={{ paddingRight: '8px' }}
      >
        {children}
      </CustomScrollbars>
    )
    : <div style={style}>{children}</div>
}

const Score = ({
  /* props */
  filteredSubjects,
  subjectType
}) => {
  const subjectsByScore = {
    'Alto': filteredSubjects.filter(s => s.score > 666.66),
    'Médio': filteredSubjects.filter(s => s.score > 333.33 && s.score <= 666.66),
    'Baixo': filteredSubjects.filter(s => s.score <= 333.33)
  }

  const scoreTotals = {
    'Alto': subjectsByScore['Alto'].length,
    'Médio': subjectsByScore['Médio'].length,
    'Baixo': subjectsByScore['Baixo'].length
  }

  const scoreByState = states
    .map(state => ({
      name: state,
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.state === state),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.state === state),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.state === state),
      'Total': countSubjects(filteredSubjects, s => s.state === state)
    }))
    .filter(state => state['Total'] > 0)
    .sort((a, b) => b['Total'] - a['Total'])

  const scoreByGender = [
    {
      name: 'Masculino',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.gender === 'M'),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.gender === 'M'),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.gender === 'M'),
      'Total': countSubjects(filteredSubjects, s => s.gender === 'M')
    },
    {
      name: 'Feminino',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.gender === 'F'),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.gender === 'F'),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.gender === 'F'),
      'Total': countSubjects(filteredSubjects, s => s.gender === 'F')
    },
    {
      name: 'Não identificado',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.gender === null),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.gender === null),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.gender === null),
      'Total': countSubjects(filteredSubjects, s => s.gender === null)
    }
  ].sort((a, b) => b['Total'] - a['Total'])

  const subjectsByGender = {
    'Masculino': scoreByGender.find(gender => gender.name === 'Masculino')['Total'],
    'Feminino': scoreByGender.find(gender => gender.name === 'Feminino')['Total'],
    'Não identificado': scoreByGender.find(gender => gender.name === 'Não identificado')['Total']
  }

  const scoreByAge = [
    {
      name: '18 - 35 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 18 && s.age <= 35),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 18 && s.age <= 35),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 18 && s.age <= 35),
      'Total': countSubjects(filteredSubjects, s => s.age >= 18 && s.age <= 35)
    },
    {
      name: '36 - 45 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 36 && s.age <= 45),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 36 && s.age <= 45),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 36 && s.age <= 45),
      'Total': countSubjects(filteredSubjects, s => s.age >= 36 && s.age <= 45)
    },
    {
      name: '45 - 60 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 46 && s.age <= 60),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 46 && s.age <= 60),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 46 && s.age <= 60),
      'Total': countSubjects(filteredSubjects, s => s.age >= 46 && s.age <= 60)
    },
    {
      name: 'Acima de 60 anos',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age >= 61),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age >= 61),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age >= 61),
      'Total': countSubjects(filteredSubjects, s => s.age >= 61)
    },
    {
      name: 'Não identificado',
      'Alto': countSubjects(subjectsByScore['Alto'], s => s.age === null),
      'Médio': countSubjects(subjectsByScore['Médio'], s => s.age === null),
      'Baixo': countSubjects(subjectsByScore['Baixo'], s => s.age === null),
      'Total': countSubjects(filteredSubjects, s => s.age === null)
    }
  ]

  const subjectsByAge = {
    '18 - 35 anos': scoreByAge.find(age => age.name === '18 - 35 anos')['Total'],
    '36 - 45 anos': scoreByAge.find(age => age.name === '36 - 45 anos')['Total'],
    '45 - 60 anos': scoreByAge.find(age => age.name === '45 - 60 anos')['Total'],
    'Acima de 60 anos': scoreByAge.find(age => age.name === 'Acima de 60 anos')['Total'],
    'Não identificado': scoreByAge.find(age => age.name === 'Não identificado')['Total']
  }

  const TableStripedNumber = ({ children }) =>
    <CustomText
      weight='700'
      color={subjectType === 'people' ? theme.colors.brand.primary.dark : theme.colors.brand.secondary.dark}
    >
      {children}
    </CustomText>

  if (filteredSubjects.length === 0) return <Col><CustomText size='0.875rem'>Nenhum dado a ser exibido.</CustomText></Col>

  return (
    <>
      <Col lg={6}>
        <Card title={'NÍVEL DE RISCO'} mb='xlarge'>
          <PieChart
            data={
              [
                { name: 'Alto', quantidade: scoreTotals['Alto'], percentual: getPercentage(scoreTotals['Alto'], filteredSubjects.length) },
                { name: 'Médio', quantidade: scoreTotals['Médio'], percentual: getPercentage(scoreTotals['Médio'], filteredSubjects.length) },
                { name: 'Baixo', quantidade: scoreTotals['Baixo'], percentual: getPercentage(scoreTotals['Baixo'], filteredSubjects.length) }
              ]
            }
            colors={[
              theme.colors.feedback.red.darkness,
              theme.colors.feedback.yellow.darkness,
              theme.colors.feedback.green.darkness
            ]}
            margin={{ top: 24, right: 12, left: -12, bottom: 0 }}
            isResponsive
            barGap={0}
            mb='xlarge'
          />

          <TableStriped
            headers={[
              { text: <strong>Risco</strong> },
              { text: <strong>Total</strong>, textAlign: 'right' }
            ]}
            rows={[
              {
                cells: [
                  { text: 'Alto' },
                  { text: <TableStripedNumber>{scoreTotals['Alto']}</TableStripedNumber>, textAlign: 'right' }
                ]
              },
              {
                cells: [
                  { text: 'Médio' },
                  { text: <TableStripedNumber>{scoreTotals['Médio']}</TableStripedNumber>, textAlign: 'right' }
                ]
              },
              {
                cells: [
                  { text: 'Baixo' },
                  { text: <TableStripedNumber>{scoreTotals['Baixo']}</TableStripedNumber>, textAlign: 'right' }
                ]
              }
            ]}
            oddRowBg={subjectType === 'people' ? theme.colors.brand.primary.lightness : theme.colors.brand.secondary.lightness}
          />
        </Card>

        <Card title={'NÍVEL DE RISCO / ESTADO'} mb='xlarge'>
          <ScrollableWrapper items={scoreByState.length} maxItems={8} height='304px' style={{ marginBottom: theme.spacing.largest }}>
            <TableStriped
              headers={[
                { text: <strong>Estado</strong> },
                { text: 'Alto', textAlign: 'center' },
                { text: 'Médio', textAlign: 'center' },
                { text: 'Baixo', textAlign: 'center' },
                { text: <strong>Total</strong>, textAlign: 'center' }
              ]}
              rows={
                scoreByState.map(state => ({
                  cells: [
                    { text: state.name === null ? 'Não identificado' : state.name },
                    { text: <TableStripedNumber>{state['Alto']}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{state['Médio']}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{state['Baixo']}</TableStripedNumber>, textAlign: 'center' },
                    { text: <TableStripedNumber>{state['Total']}</TableStripedNumber>, textAlign: 'center' }
                  ]
                }))
              }
              oddRowBg={theme.colors.brand.primary.lightness}
            />
          </ScrollableWrapper>

          <ScrollableWrapper items={scoreByState.length} maxItems={8} height='420px'>
            <StackedVerticalBarChart
              data={
                scoreByState.map(state => ({
                  name: state.name === null ? 'Não identificado' : state.name,
                  'Baixo': state['Baixo'],
                  'Médio': state['Médio'],
                  'Alto': state['Alto']
                }))
              }
              colors={[
                theme.colors.feedback.green.darkness,
                theme.colors.feedback.yellow.darkness,
                theme.colors.feedback.red.darkness
              ]}
              chartHeight={scoreByState.length * 50}
              barSize={30}
              allowDecimals={false}
              orientation='top'
            />
          </ScrollableWrapper>
        </Card>
      </Col>

      {subjectType === 'people' &&
        <Col lg={6}>
          <Card title={'NÍVEL DE RISCO / SEXO'} mb='xlarge'>
            <PieChart
              data={
                [
                  { name: 'Masculino', quantidade: subjectsByGender['Masculino'], percentual: getPercentage(subjectsByGender['Masculino'], filteredSubjects.length) },
                  { name: 'Feminino', quantidade: subjectsByGender['Feminino'], percentual: getPercentage(subjectsByGender['Feminino'], filteredSubjects.length) },
                  { name: 'Não identificado', quantidade: subjectsByGender['Não identificado'], percentual: getPercentage(subjectsByGender['Não identificado'], filteredSubjects.length) }
                ]
              }
              colors={[
                theme.colors.brand.primary.darkness,
                theme.colors.brand.primary.medium,
                theme.colors.brand.tertiary.medium
              ]}
              margin={{ top: 24, right: 12, left: -12, bottom: 0 }}
              isResponsive
              barGap={0}
              mb='largest'
            />

            <TableStriped
              headers={[
                { text: <strong>Sexo</strong> },
                { text: 'Alto' },
                { text: 'Médio' },
                { text: 'Baixo' },
                { text: <strong>Total</strong>, textAlign: 'right' }
              ]}
              rows={
                scoreByGender.map(gender => ({
                  cells: [
                    { text: gender.name === null ? 'Não identificado' : gender.name },
                    { text: <TableStripedNumber>{gender['Alto']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{gender['Médio']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{gender['Baixo']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{gender['Total']}</TableStripedNumber>, textAlign: 'right' }
                  ]
                }))
              }
              oddRowBg={theme.colors.brand.primary.lightness}
              mb='largest'
            />

            <StackedVerticalBarChart
              data={
                scoreByGender.map(gender => ({
                  name: gender.name === null ? 'Não identificado' : gender.name,
                  'Baixo': gender['Baixo'],
                  'Médio': gender['Médio'],
                  'Alto': gender['Alto']
                }))
              }
              colors={[
                theme.colors.feedback.green.darkness,
                theme.colors.feedback.yellow.darkness,
                theme.colors.feedback.red.darkness
              ]}
              chartHeight={scoreByGender.length * 50}
              barSize={30}
              allowDecimals={false}
            />
          </Card>

          <Card title={'NÍVEL DE RISCO / IDADE'} mb='xlarge'>
            <PieChart
              data={
                [
                  { name: '18 - 35 anos', quantidade: subjectsByAge['18 - 35 anos'], percentual: getPercentage(subjectsByAge['18 - 35 anos'], filteredSubjects.length) },
                  { name: '36 - 45 anos', quantidade: subjectsByAge['36 - 45 anos'], percentual: getPercentage(subjectsByAge['36 - 45 anos'], filteredSubjects.length) },
                  { name: '45 - 60 anos', quantidade: subjectsByAge['45 - 60 anos'], percentual: getPercentage(subjectsByAge['45 - 60 anos'], filteredSubjects.length) },
                  { name: 'Acima de 60 anos', quantidade: subjectsByAge['Acima de 60 anos'], percentual: getPercentage(subjectsByAge['Acima de 60 anos'], filteredSubjects.length) },
                  { name: 'Não identificado', quantidade: subjectsByAge['Não identificado'], percentual: getPercentage(subjectsByAge['Não identificado'], filteredSubjects.length) }
                ]
              }
              colors={[
                theme.colors.brand.primary.darkness,
                theme.colors.brand.primary.medium,
                theme.colors.brand.tertiary.darkness,
                theme.colors.brand.tertiary.light,
                theme.colors.brand.tertiary.lightness
              ]}
              margin={{ top: 24, right: 12, left: -12, bottom: 0 }}
              isResponsive
              barGap={0}
              mb='largest'
            />

            <TableStriped
              headers={[
                { text: <strong>Faixa etária</strong> },
                { text: 'Alto' },
                { text: 'Médio' },
                { text: 'Baixo' },
                { text: <strong>Total</strong>, textAlign: 'right' }
              ]}
              rows={
                scoreByAge.map(age => ({
                  cells: [
                    { text: age.name === null ? 'Não identificado' : age.name },
                    { text: <TableStripedNumber>{age['Alto']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{age['Médio']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{age['Baixo']}</TableStripedNumber> },
                    { text: <TableStripedNumber>{age['Total']}</TableStripedNumber>, textAlign: 'right' }
                  ]
                }))
              }
              oddRowBg={theme.colors.brand.primary.lightness}
              mb='largest'
            />

            <StackedVerticalBarChart
              data={
                scoreByAge.map(age => ({
                  name: age.name === null ? 'Não identificado' : age.name,
                  'Baixo': age['Baixo'],
                  'Médio': age['Médio'],
                  'Alto': age['Alto']
                }))
              }
              colors={[
                theme.colors.feedback.green.darkness,
                theme.colors.feedback.yellow.darkness,
                theme.colors.feedback.red.darkness
              ]}
              chartHeight={scoreByAge.length * 50}
              barSize={30}
              allowDecimals={false}
            />
          </Card>
        </Col>}
    </>
  )
}

export default Score
