/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Icon, theme } from '@zeta/ui/src'

/* Styled */
import { Wrapper, Display, CloseButton } from './styled'

const SelectedFilter = ({ name, value, closeFn }) => (
  <Wrapper>
    <Display>
      {name}: {value}
    </Display>

    <CloseButton type='button' onClick={closeFn}>
      <Icon
        icon='close-outline'
        size='medium'
        fill={theme.colors.brand.tertiary.lightness}
      />
    </CloseButton>
  </Wrapper>
)

SelectedFilter.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  value: PropTypes.string.isRequired,
  closeFn: PropTypes.func.isRequired
}

export default SelectedFilter
