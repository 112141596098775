/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import LoadingSpinner from '../LoadingSpinner'
import { FlexCentered } from '../helpers/Flex'

/* Styled */
import { Wrapper, HeaderRow, HeaderCell, BodyRow, BodyCell } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const TableStriped = ({ headers, rows, oddRowBg, isLoadingRows, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <thead>
        <HeaderRow>
          {headers &&
            headers.map((header) => (
              <HeaderCell textAlign={header.textAlign} key={uuidv4()}>
                {header.text}
              </HeaderCell>
            ))}
        </HeaderRow>
      </thead>

      <tbody>
        {!isLoadingRows &&
          rows &&
          rows.map((row) => (
            <BodyRow oddRowBg={oddRowBg} key={uuidv4()}>
              {row.cells.map((cell, i) => (
                <BodyCell textAlign={cell.textAlign} key={uuidv4()}>
                  {cell.text}
                </BodyCell>
              ))}
            </BodyRow>
          ))}

        {isLoadingRows && (
          <BodyRow>
            <BodyCell colSpan={headers.length}>
              <FlexCentered>
                <LoadingSpinner size='40' />
              </FlexCentered>
            </BodyCell>
          </BodyRow>
        )}
      </tbody>
    </Wrapper>
  )
}

TableStriped.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      textAlign: PropTypes.oneOf(['left', 'center', 'right'])
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
            .isRequired,
          textAlign: PropTypes.oneOf(['left', 'center', 'right'])
        })
      ).isRequired
    })
  ),
  oddRowBg: PropTypes.string.isRequired,
  isLoadingRows: PropTypes.bool
}

export default TableStriped
