/* React */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
// import Chip from '../Chip'
import Icon from '../Icon'

/* Styled */
import {
  Wrapper,
  Header,
  TitleWrapper,
  // Title,
  IconWrapper,
  Content
} from './styled'

/* Constants */
import theme from '../../theme/theme'

const Collapse = ({
  title,
  // withChip,
  // withChipAfter,
  // chipBg,
  noBorder,
  noContentPadding,
  contentBetween,
  isOpen,
  children,
  ...rest
}) => {
  const [isOpenState, setIsOpenState] = useState(isOpen || false)

  return (
    <Wrapper {...rest}>
      <Header onClick={() => setIsOpenState(!isOpenState)} noBorder={noBorder}>
        {/* {withChip && (
          <TitleWrapper>
            <Chip mr='small'>{withChip}</Chip>
            <Title>{title}</Title>
          </TitleWrapper>
        )}

        {withChipAfter && (
          <TitleWrapper noBorder={noBorder}>
            <Title>{title}</Title>
            <Chip bg={chipBg} ml='small'>
              {withChipAfter}
            </Chip>
          </TitleWrapper>
        )}

        {!withChip && !withChipAfter && ( */}
        <TitleWrapper noBorder={noBorder}>{title}</TitleWrapper>
        {/* )} */}

        <IconWrapper>
          <Icon
            icon={isOpenState ? 'minus-outline' : 'plus-outline'}
            size='medium'
            fill={theme.colors.surface.secondary.lightness}
          />
        </IconWrapper>
      </Header>

      {contentBetween && contentBetween()}

      {isOpenState && (
        <Content noContentPadding={noContentPadding} noBorder={noBorder}>
          {children}
        </Content>
      )}
    </Wrapper>
  )
}

Collapse.propTypes = {
  title: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  noContentPadding: PropTypes.bool,
  contentBetween: PropTypes.func,
  isOpen: PropTypes.bool
}

export default Collapse
