/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  GroupSelect,
  Button,
  CustomText,
  LoadingSpinner
} from '@zeta/ui/src'

/* Action Creators */
import { editSubjectRiskRating } from 'store/reducers/action-creators'

export const RiskRatingModal = ({
  isRiskRatingModalVisible,
  setIsRiskRatingModalVisible,
  riskRating,
  subjectType
}) => {
  const dispatch = useDispatch()

  const riskRatings = useSelector(state => state.data.riskRatings)
  const isEditingSubjectRiskRating = useSelector(state => state.individual.isEditingSubjectRiskRating)

  const [newRiskRating, setNewRiskRating] = useState(riskRating)

  const getNextAnalysisDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() + newRiskRating.periodicity_in_months)
    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
  }

  return (
    <Modal
      isVisible={isRiskRatingModalVisible}
      closeFn={() => setIsRiskRatingModalVisible(false)}
      title='Classificação de risco'
      bodyWidth={661}
    >
      <ModalContent>
        <CustomText size='0.875rem' weight='700' mb='small'>Classificação de risco</CustomText>

        <GroupSelect
          items={riskRatings.map(riskRating => ({
            text: riskRating.description,
            fn: () => setNewRiskRating(riskRating)
          }))}
          activeIndex={newRiskRating ? riskRatings.findIndex(riskRating => riskRating.id === newRiskRating.id) : null}
          mb='small'
        />

        {newRiskRating &&
          <CustomText size='0.875rem'>Data da próxima análise: <strong>{getNextAnalysisDate()}</strong></CustomText>}
      </ModalContent>

      <ModalActions>
        <Button onClick={() => setIsRiskRatingModalVisible(false)} red>
          <Icon icon='close-outline' />
          <span>Cancelar</span>
        </Button>

        <Button
          onClick={() => dispatch(
            editSubjectRiskRating({
              riskRatingId: newRiskRating.id,
              subjectType,
              onSuccess: () => setIsRiskRatingModalVisible(false)
            })
          )}
          disabled={isEditingSubjectRiskRating}
        >
          <Icon icon='save-outline' />
          <span>Confirmar</span>
        </Button>

        {isEditingSubjectRiskRating && <LoadingSpinner size='xlarge' ml='small' />}
      </ModalActions>
    </Modal>
  )
}

export default RiskRatingModal
