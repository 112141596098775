import styled from 'styled-components'

export const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.brand.primary.lightness};
  color: ${({ theme: { colors } }) => colors.brand.primary.darkness};
  font-size: 0.875rem;
  font-weight: 700;
  padding: ${({ theme: { spacing } }) => spacing.xsmall};
  borde-radius: ${({ theme: { radius } }) => radius.xsmall};
`

export const SeeMoreButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  font-size: 0.875rem;
  font-weight: 700;
`
