/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Card, Button, Icon, FlexCentered, LoadingSpinner } from '@zeta/ui/src'

import { Label, Input } from './styled'

/* Helpers */
import { getDossierMsg, createDossierMsg } from 'api'

export const ManageDossier = () => {
  const [dossierTitle, setDossierTitle] = useState('')
  const [dossierMsg, setDossierMsg] = useState('')
  const [isLoadingDossierMsg, setIsLoadingDossierMsg] = useState(false)
  const [isEditingDossierMsg, setIsEditingDossierMsg] = useState(false)

  useEffect(() => {
    let mounted = true

    setIsLoadingDossierMsg(true)

    getDossierMsg().then((res) => {
      if (mounted) {
        setIsLoadingDossierMsg(false)
        setDossierTitle(res.data.result[0].subject)
        setDossierMsg(res.data.result[0].body)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  const updateDossierMsg = () => {
    setIsEditingDossierMsg(true)

    createDossierMsg({
      title: dossierTitle,
      msg: dossierMsg
    }).then(() => {
      setIsEditingDossierMsg(false)
    })
  }

  return (
    <>
      <Row>
        <Col md={6} mdOffset={3}>
          <Card mb="xlarge">
            <div>
              {isLoadingDossierMsg && (
                <FlexCentered>
                  <LoadingSpinner size="40" />
                </FlexCentered>
              )}

              {!isLoadingDossierMsg && (
                <>
                  <Label>Título</Label>
                  <Input
                    value={dossierTitle}
                    onChange={(e) => setDossierTitle(e.target.value)}
                  />

                  <Label>Corpo da mensagem</Label>
                  <ReactQuill
                    theme="snow"
                    value={dossierMsg}
                    onChange={setDossierMsg}
                    modules={{
                      toolbar: [['bold', 'italic', 'underline'], ['link']]
                    }}
                  />
                </>
              )}
            </div>

            {!isLoadingDossierMsg && (
              <Button
                fullWidth
                mt="medium"
                onClick={updateDossierMsg}
                isDisabled={isEditingDossierMsg}
              >
                {isEditingDossierMsg ? (
                  <span>Atualizando...</span>
                ) : (
                  <>
                    <span>Atualizar mensagem</span>
                    <Icon icon="checkmark-outline" />
                  </>
                )}
              </Button>
            )}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ManageDossier
