import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Col = styled.div`
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
  width: 100%;

  @media (min-width: 992px) {
    width: 30%;
  }
`

export const InfosCol = styled.div`
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 991px) {
    margin-top: ${({ theme: { spacing } }) => spacing.medium};
  }

  @media (min-width: 992px) {
    width: 70%;
  }
`

export const InfoGroup = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    &:last-child {
      margin-top: ${({ theme: { spacing } }) => spacing.medium};
    }
  }

  @media (min-width: 768px) {
    &:first-child {
      padding-right: ${({ theme: { spacing } }) => spacing.small};
      width: 35%;
    }
    &:last-child {
      padding-left: ${({ theme: { spacing } }) => spacing.small};
      width: 65%;
    }
  }
`

export const Label = styled.div`
  font-weight: 700;
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
`

export const TotalListedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media print {
    justify-content: flex-start;
  }
`

export const TotalListedIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.medium};

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' &&
    `
      background-color: ${colors.brand.primary.lightness};
    `}

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'company' &&
    `
      background-color: ${colors.brand.secondary.lightness};
    `}

  @media print {
    display: none;
  }
`

export const TotalListedInfoWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.largest});
`

export const TotalListedNumber = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' &&
    `
      color: ${colors.brand.primary.medium};
    `}

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'company' &&
    `
      color: ${colors.brand.secondary.medium};
    `}
`

export const TotalListedText = styled.span`
  line-height: 1;
`

export const Info = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const InfoTextWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.large});
`

export const InfoTotal = styled.span`
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' &&
    `
      color: ${colors.brand.primary.medium};
    `}

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'company' &&
    `
      color: ${colors.brand.secondary.medium};
    `}
`

export const InfoText = styled.span`
  font-size: 0.875rem;
`
