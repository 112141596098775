/* React */
import React, { useState } from 'react'

/* Parts */
// import Menu from './Menu'
import SidebarContent from './SidebarContent'
import SidebarTabs from './SidebarTabs'

const Sidebar = ({ filteredSubjects }) => {
  const [activeTab, setActiveTab] = useState(null)

  return (
    <>
      {/* <Menu
        setActiveTab={setActiveTab}
      /> */}

      <SidebarContent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        filteredSubjects={filteredSubjects}
      />

      <SidebarTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  )
}

export default Sidebar
