/* React */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  Icon,
  CustomText,
  CustomBlock,
  Flex,
  FlexAlignCenter,
  LoadingSpinner,
  theme
} from '@zeta/ui/src'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Checkbox } from '../../Individual/DossierModal'

/* Constants */
import { visibleOccurrenceClass } from 'constants/dictionary'
import { collectionsIds } from 'constants/config'

/* Helpers */
import { sendBulkDossierEmail } from 'api'

/* Action Creators */
import {
  getAndSetPeople,
  getAndSetCompanies
} from 'store/reducers/data/action-creators'

const DossierModal = ({
  subjectType,
  isModalVisible,
  setIsModalVisible,
  isSendingDossier,
  setIsSendingDossier,
  rows
}) => {
  const dispatch = useDispatch()

  const [config, setConfig] = useState({
    non_elegible: false,
    relates: false,
    notes: false,
    observations: false,
    score: false,
    relates_score: false,
    risk_rating: false,
    occurrenceClasses: {
      beneficio_social: true,
      midia: true,
      relacao_societaria: true,
      financeiro: true,
      publico_exposta: true,
      lista_restritiva: true,
      processos: true
    },
    includePastCollections: false
  })

  const [customMsg, setCustomMsg] = useState('')

  return (
    <Modal
      isVisible={isModalVisible}
      closeFn={() => setIsModalVisible(false)}
      title="Enviar Dossiê"
    >
      <ModalContent>
        <FlexAlignCenter
          override={`
            > *:not(:first-child) {
              margin-left: ${theme.spacing.large};
            }
          `}
        >
          <Checkbox
            isSelected={config.non_elegible}
            onClickFn={() =>
              setConfig({ ...config, non_elegible: !config.non_elegible })
            }
            label='Incluir "Não Elegíveis"'
          />

          <Checkbox
            isSelected={config.includePastCollections}
            onClickFn={() =>
              setConfig({
                ...config,
                includePastCollections: !config.includePastCollections
              })
            }
            label="Incluir ocorrências de coletas anteriores"
          />

          {subjectType === 'company' && (
            <Checkbox
              isSelected={config.relates}
              onClickFn={() =>
                setConfig({ ...config, relates: !config.relates })
              }
              label="Incluir PF's"
            />
          )}
        </FlexAlignCenter>

        <CustomText size="0.875rem" weight="700" my="medium">
          Categorias
        </CustomText>

        <Flex>
          <div>
            <Checkbox
              isSelected={config.occurrenceClasses.beneficio_social}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    beneficio_social: !config.occurrenceClasses.beneficio_social
                  }
                })
              }
              label={visibleOccurrenceClass.beneficio_social}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.midia}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    midia: !config.occurrenceClasses.midia
                  }
                })
              }
              label={visibleOccurrenceClass.midia}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.relacao_societaria}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    relacao_societaria: !config.occurrenceClasses
                      .relacao_societaria
                  }
                })
              }
              label={visibleOccurrenceClass.relacao_societaria}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.financeiro}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    financeiro: !config.occurrenceClasses.financeiro
                  }
                })
              }
              label={visibleOccurrenceClass.financeiro}
            />
          </div>

          <CustomBlock ml="xlarge">
            <Checkbox
              isSelected={config.occurrenceClasses.publico_exposta}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    publico_exposta: !config.occurrenceClasses.publico_exposta
                  }
                })
              }
              label={visibleOccurrenceClass.publico_exposta}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.lista_restritiva}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    lista_restritiva: !config.occurrenceClasses.lista_restritiva
                  }
                })
              }
              label={visibleOccurrenceClass.lista_restritiva}
            />

            <Checkbox
              isSelected={config.occurrenceClasses.processos}
              onClickFn={() =>
                setConfig({
                  ...config,
                  occurrenceClasses: {
                    ...config.occurrenceClasses,
                    processos: !config.occurrenceClasses.processos
                  }
                })
              }
              label={visibleOccurrenceClass.processos}
            />
          </CustomBlock>
        </Flex>

        <CustomText size="0.875rem" weight="700" my="medium">
          Campos
        </CustomText>
        <Checkbox
          isSelected={config.notes}
          onClickFn={() => setConfig({ ...config, notes: !config.notes })}
          label="Comentário Analista"
        />

        <Checkbox
          isSelected={config.observations}
          onClickFn={() =>
            setConfig({ ...config, observations: !config.observations })
          }
          label="Observações"
        />

        <Checkbox
          isSelected={config.score}
          onClickFn={() => setConfig({ ...config, score: !config.score })}
          label="Score"
        />

        {subjectType === 'company' && (
          <Checkbox
            isSelected={config.relates_score}
            onClickFn={() =>
              setConfig({ ...config, relates_score: !config.relates_score })
            }
            label="Score PF's"
          />
        )}

        <Checkbox
          isSelected={config.risk_rating}
          onClickFn={() =>
            setConfig({ ...config, risk_rating: !config.risk_rating })
          }
          label="Classificação de Risco"
        />

        <CustomText size="0.875rem" weight="700" mt="xlarge" mb="medium">
          Mensagem customizada (exibida após mensagem padrão)
        </CustomText>
        <ReactQuill
          theme="snow"
          value={customMsg}
          onChange={setCustomMsg}
          modules={{
            toolbar: [['bold', 'italic', 'underline'], ['link']]
          }}
        />
      </ModalContent>

      <ModalActions>
        <Button red onClick={() => setIsModalVisible(false)}>
          <span>Cancelar</span>
        </Button>

        <Button
          onClick={() => {
            setIsSendingDossier(true)

            sendBulkDossierEmail({
              subjectPostureIds: rows.map((row) => row.posture_id),
              include: Object.keys(config).filter(
                (configKey) =>
                  configKey !== 'occurrenceClasses' &&
                  configKey !== 'includePastCollections' &&
                  config[configKey]
              ),
              occurrenceClasses: Object.keys(config.occurrenceClasses).filter(
                (occurrenceClass) => config.occurrenceClasses[occurrenceClass]
              ),
              collection_id:
                !config.includePastCollections &&
                collectionsIds[collectionsIds.length - 1],
              customMsg
            }).then(() => {
              setIsSendingDossier(false)
              setIsModalVisible(false)

              if (subjectType === 'people')
                dispatch(getAndSetPeople(rows.map((row) => row.id)))
              if (subjectType === 'company')
                dispatch(getAndSetCompanies(rows.map((row) => row.id)))
            })
          }}
          isDisabled={
            Object.keys(config.occurrenceClasses).every(
              (occurrenceClass) => !config.occurrenceClasses[occurrenceClass]
            ) || isSendingDossier
          }
        >
          <span>Enviar dossiê</span>
          <Icon icon="email-outline" />
        </Button>

        {isSendingDossier && <LoadingSpinner size="xlarge" ml="small" />}
      </ModalActions>
    </Modal>
  )
}

export default DossierModal
