/* React */
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/* Components */
import Flatpickr from 'react-flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import 'flatpickr/dist/themes/material_blue.css'

import { Row, Col } from 'styled-bootstrap-grid'

import {
  FlexAlignCenter,
  FlexSpaceBetween,
  Card,
  CustomText,
  TextWithIcon,
  Icon,
  DropdownBtns,
  ButtonAction,
  ButtonWithIcon,
  theme
} from '@zeta/ui/src'

import Stepline from 'components/pages/Individual/Stepline'
import DossierModal from 'components/pages/Individual/DossierModal'
import DossierEmailModal from 'components/pages/Individual/DossierEmailModal'
import CreateObservationModal from 'components/pages/Individual/CreateObservationModal'
import RiskRatingModal from './RiskRatingModal'
import DeleteRiskRatingModal from './DeleteRiskRatingModal'
import HistoryModal from './HistoryModal'

/* Styled */
import { DropdownBtnsWrapper, FlatpickrWrapper } from './styled'

/* Helpers */
import { formatDate, logAmplitudeEvent } from '@zeta/helpers'
import { getSubjectStatus, getRiskRating } from 'helpers'

/* Action Creators */
import { editPosture } from 'store/reducers/action-creators'

const EditBtn = ({ onClick }) => (
  <Icon
    icon="edit-2-outline"
    as="button"
    type="button"
    onClick={onClick}
    size="medium"
    fill={theme.colors.brand.primary.medium}
    ml="xsmall"
  />
)

const CurrentTab = ({
  history,
  computedMatch,
  individual,
  subjectId,
  subjectType
}) => {
  const dispatch = useDispatch()

  const users = useSelector((state) => state.data.users)
  const riskRatings = useSelector((state) => state.data.riskRatings)
  const occurrencesByClass = useSelector(
    (state) => state.individual.occurrencesByClass
  )
  const posture = useSelector((state) => state.individual.posture)

  const [openedDropdowns, setOpenedDropdowns] = useState({
    conflictTerm: false,
    contact: false,
    responsible: false
  })

  const [isDossierModalVisible, setIsDossierModalVisible] = useState(false)
  const [isDossierEmailModalVisible, setIsDossierEmailModalVisible] = useState(
    false
  )
  const [isObservationModalVisible, setIsObservationModalVisible] = useState(
    false
  )
  const [isRiskRatingModalVisible, setIsRiskRatingModalVisible] = useState(
    false
  )
  const [
    isDeleteRiskRatingModalVisible,
    setIsDeleteRiskRatingModalVisible
  ] = useState(false)
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false)

  const flatpickrRef = useRef(null)

  const handleEditPosture = (body, key) => {
    if (key) setOpenedDropdowns({ ...openedDropdowns, [key]: false })
    dispatch(editPosture({ body, key, subjectType, subjectId }))
  }

  return (
    <>
      <Card>
        <FlexSpaceBetween mb="medium">
          <FlexAlignCenter>
            <CustomText weight="700" mr="small">
              Status
            </CustomText>

            <ButtonWithIcon
              icon="eye-outline"
              onClick={() => {
                setIsHistoryModalVisible(true)

                logAmplitudeEvent({
                  event: 'Clique no histórico de ações no perfil',
                  eventProperties: { Tipo: 'Geral' }
                })
              }}
            >
              <span>ver detalhes</span>
            </ButtonWithIcon>
          </FlexAlignCenter>

          <div>
            <ButtonAction
              onClick={() => setIsObservationModalVisible(true)}
              ml="medium"
            >
              <span>Adicionar Observação</span>
              <Icon icon="list-outline" />
            </ButtonAction>

            <ButtonAction
              onClick={() => setIsDossierModalVisible(true)}
              ml="medium"
            >
              <span>Gerar Dossiê</span>
              <Icon icon="folder-outline" />
            </ButtonAction>

            <ButtonAction
              onClick={() => setIsDossierEmailModalVisible(true)}
              ml="medium"
            >
              <span>Enviar Dossiê</span>
              <Icon icon="email-outline" />
            </ButtonAction>
          </div>
        </FlexSpaceBetween>

        <Stepline
          items={[
            { label: 'Nova identificação' },
            { label: 'Em análise' },
            { label: 'Aguardando retorno' },
            { label: 'Comitê' },
            { label: 'Solucionado' }
          ]}
          activeItem={getSubjectStatus(occurrencesByClass)}
          itemCheckBg={theme.colors.surface.secondary.lightness}
          orientationBreakpoint={768}
          mb="xlarge"
          data-testid="globalStepline"
        />

        <Row>
          <Col lg={4} md={6}>
            <TextWithIcon my="small">
              <Icon icon="calendar-outline" />
              <span>
                Atualização da base:{' '}
                <strong>{formatDate(posture.collection_updated)} </strong>- ID
                da coleta: {posture.collection_id}
              </span>
            </TextWithIcon>
          </Col>

          <Col lg={4} md={6}>
            <TextWithIcon my="small">
              <Icon icon="calendar-outline" />
              <span>
                Início da análise:{' '}
                <strong data-testid="analyseStartText">
                  {formatDate(posture.analyse_start)}
                </strong>
              </span>
            </TextWithIcon>
          </Col>

          <Col lg={4} md={6}>
            <TextWithIcon my="small">
              <Icon icon="calendar-outline" />
              <span>
                Última atualização:{' '}
                <strong data-testid="analyseUpdateText">
                  {formatDate(posture.analyse_update)}
                </strong>
              </span>
            </TextWithIcon>
          </Col>

          <Col lg={4} md={6}>
            <FlexAlignCenter my="small">
              <TextWithIcon override="> span { width: auto }">
                <Icon icon="clock-outline" />

                {posture.resolution_date ? (
                  <FlexAlignCenter>
                    <span>Prazo de resolução: </span>

                    <FlatpickrWrapper>
                      <Flatpickr
                        value={posture.resolution_date}
                        onChange={(date) => {
                          handleEditPosture(
                            { resolution_date: date[0] },
                            'resolution_date'
                          )

                          logAmplitudeEvent({
                            event: 'Prazo de resolução da análise alterado'
                          })
                        }}
                        options={{
                          dateFormat: 'Y-m-d',
                          altInput: true,
                          altFormat: 'd/m/Y',
                          minDate: posture.analyse_start,
                          locale: Portuguese
                        }}
                        style={{
                          fontWeight: '700',
                          height: '32px',
                          color: '#3D4043'
                        }}
                        data-testid="resolutionDateText"
                        ref={flatpickrRef}
                      />
                    </FlatpickrWrapper>
                  </FlexAlignCenter>
                ) : (
                  <span>
                    Prazo de resolução:{' '}
                    <strong data-testid="resolutionDateText">NA</strong>
                  </span>
                )}
              </TextWithIcon>

              {posture.resolution_date && (
                <EditBtn
                  onClick={() => flatpickrRef.current.flatpickr.open()}
                />
              )}
            </FlexAlignCenter>
          </Col>

          <Col lg={4} md={6}>
            <TextWithIcon my="small">
              <Icon icon="calendar-outline" />
              <span>
                Data de resolução:{' '}
                <strong>{formatDate(posture.end_analysis)}</strong>
              </span>
            </TextWithIcon>
          </Col>

          <Col lg={4} md={6}>
            <FlexAlignCenter my="small">
              <TextWithIcon override="> span { width: auto }">
                <Icon icon="clipboard-outline" />
                <span>
                  Termo de ausência de conflito:{' '}
                  <strong>{posture.conflict_term ? 'Sim' : 'Não'}</strong>
                </span>
              </TextWithIcon>

              {posture.analyse_start && (
                <DropdownBtnsWrapper>
                  <EditBtn
                    onClick={() =>
                      setOpenedDropdowns({
                        ...openedDropdowns,
                        conflictTerm: !openedDropdowns.conflictTerm
                      })
                    }
                  />

                  {openedDropdowns.conflictTerm && (
                    <DropdownBtns
                      items={[
                        {
                          text: 'Sim',
                          onClickFn: () =>
                            handleEditPosture(
                              { conflict_term: true },
                              'conflictTerm'
                            )
                        },
                        {
                          text: 'Não',
                          onClickFn: () =>
                            handleEditPosture(
                              { conflict_term: false },
                              'conflictTerm'
                            )
                        }
                      ]}
                      override="right: 0;"
                    />
                  )}
                </DropdownBtnsWrapper>
              )}
            </FlexAlignCenter>
          </Col>

          {/* <Col lg={4} md={6}>
            <FlexAlignCenter my="small">
              <TextWithIcon override="> span { width: auto }">
                <Icon icon="phone-outline" />
                <span>
                  Contato pendente:{' '}
                  <strong data-testid="pendentContactText">
                    {posture.contact ? 'Sim' : 'Não'}
                  </strong>
                </span>
              </TextWithIcon>

              {posture.analyse_start && (
                <DropdownBtnsWrapper>
                  <EditBtn
                    onClick={() =>
                      setOpenedDropdowns({
                        ...openedDropdowns,
                        contact: !openedDropdowns.contact
                      })
                    }
                  />

                  {openedDropdowns.contact && (
                    <DropdownBtns
                      items={[
                        {
                          text: 'Sim',
                          onClickFn: () =>
                            handleEditPosture({ contact: true }, 'contact')
                        },
                        {
                          text: 'Não',
                          onClickFn: () =>
                            handleEditPosture({ contact: false }, 'contact')
                        }
                      ]}
                      override="right: 0;"
                    />
                  )}
                </DropdownBtnsWrapper>
              )}
            </FlexAlignCenter>
          </Col> */}

          <Col lg={4} md={6}>
            {posture.responsible ? (
              <FlexAlignCenter my="small">
                <TextWithIcon override="> span { width: auto }">
                  <Icon icon="person-outline" />
                  <span>
                    Responsável:{' '}
                    <strong data-testid="analyseResponsibleText">
                      {posture.responsible}
                    </strong>
                  </span>
                </TextWithIcon>

                <DropdownBtnsWrapper>
                  <EditBtn
                    onClick={() =>
                      setOpenedDropdowns({
                        ...openedDropdowns,
                        responsible: !openedDropdowns.responsible
                      })
                    }
                  />

                  {openedDropdowns.responsible && (
                    <DropdownBtns
                      items={users.map((user) => ({
                        text: user,
                        onClickFn: () => {
                          handleEditPosture(
                            { responsible: user },
                            'responsible'
                          )

                          logAmplitudeEvent({
                            event: 'Responsável pela análise alterado'
                          })
                        }
                      }))}
                      override="right: 0;"
                    />
                  )}
                </DropdownBtnsWrapper>
              </FlexAlignCenter>
            ) : (
              <TextWithIcon my="small">
                <Icon icon="person-outline" />
                <span>
                  Responsável:{' '}
                  <strong data-testid="analyseResponsibleText">NA</strong>
                </span>
              </TextWithIcon>
            )}
          </Col>

          <Col lg={4} md={6}>
            <FlexAlignCenter my="small">
              <TextWithIcon override="> span { width: auto }">
                <Icon icon="alert-triangle-outline" />
                <span>
                  Classificação de risco:{' '}
                  <strong>
                    {getRiskRating(riskRatings, posture.risk_rating_id)
                      ? getRiskRating(riskRatings, posture.risk_rating_id)
                          .description
                      : 'NA'}
                  </strong>
                </span>
              </TextWithIcon>

              {posture.analyse_start && (
                <EditBtn onClick={() => setIsRiskRatingModalVisible(true)} />
              )}

              {getRiskRating(riskRatings, posture.risk_rating_id) && (
                <Icon
                  icon="trash-2-outline"
                  as="button"
                  type="button"
                  onClick={() => setIsDeleteRiskRatingModalVisible(true)}
                  size="medium"
                  fill={theme.colors.feedback.red.medium}
                  ml="xsmall"
                />
              )}
            </FlexAlignCenter>
          </Col>

          <Col lg={4} md={6}>
            <TextWithIcon my="small">
              <Icon icon="calendar-outline" />
              <span>
                Próxima análise:{' '}
                <strong>
                  {posture.next_risk_rating_review
                    ? formatDate(posture.next_risk_rating_review)
                    : 'NA'}
                </strong>
              </span>
            </TextWithIcon>
          </Col>
        </Row>
      </Card>

      {isObservationModalVisible && (
        <CreateObservationModal
          isObservationModalVisible={isObservationModalVisible}
          setIsObservationModalVisible={setIsObservationModalVisible}
        />
      )}

      {isDossierModalVisible && (
        <DossierModal
          history={history}
          computedMatch={computedMatch}
          individual={individual}
          subjectId={subjectId}
          subjectType={subjectType}
          isModalVisible={isDossierModalVisible}
          setIsModalVisible={setIsDossierModalVisible}
        />
      )}

      {isDossierEmailModalVisible && (
        <DossierEmailModal
          subjectType={subjectType}
          subjectId={subjectId}
          isModalVisible={isDossierEmailModalVisible}
          setIsModalVisible={setIsDossierEmailModalVisible}
        />
      )}

      {isRiskRatingModalVisible && (
        <RiskRatingModal
          isRiskRatingModalVisible={isRiskRatingModalVisible}
          setIsRiskRatingModalVisible={setIsRiskRatingModalVisible}
          riskRating={getRiskRating(riskRatings, posture.risk_rating_id)}
          subjectType={subjectType}
        />
      )}

      {isDeleteRiskRatingModalVisible && (
        <DeleteRiskRatingModal
          isDeleteRiskRatingModalVisible={isDeleteRiskRatingModalVisible}
          setIsDeleteRiskRatingModalVisible={setIsDeleteRiskRatingModalVisible}
          subjectType={subjectType}
        />
      )}

      {isHistoryModalVisible && (
        <HistoryModal
          subjectId={subjectId}
          subjectType={subjectType}
          isHistoryModalVisible={isHistoryModalVisible}
          setIsHistoryModalVisible={setIsHistoryModalVisible}
        />
      )}
    </>
  )
}

export default CurrentTab
