/* Constants */
import {
  BOARD_SUCCEEDED,
  SET_BOARD_INFO,
  SET_FILTERS,
  SET_SAVE_REPORT_MODAL,
  SET_SIDEBAR_OPEN,
  MAIN_DATA_REQUESTED,
  MAIN_DATA_SUCCEEDED,
  PEOPLE_REQUESTED,
  COMPANIES_REQUESTED,
  PEOPLE_SUCCEEDED,
  COMPANIES_SUCCEEDED,
  SET_USERS,
  CREATE_RISK_RATING_REQ,
  CREATE_RISK_RATING_SUC,
  EDIT_OCCURRENCE_STATUS,
  UPDATE_SUMMARISES,
  UPDATE_SUBJECTS,
  SET_LIST_PAGE,
  SET_LIST_PER_PAGE,
  SET_LIST_ORDER,
  SET_SEARCH_QUERY,
  RESET_PROPS,
  RESET_STATE
} from './action-types'
import types from 'store/reducers/action-types'
import { UPDATE_USER_INFO_SUCCEEDED } from '@zeta/user/src/store/reducers/user/action-types'
import { selectionOptions } from 'constants/filters'

/* Helpers */
import { update } from 'ramda'

const initialDscOrder = {
  name: true,
  cpf: true,
  code: true,
  office: true,
  office_cnpj: true,
  actual_status: true,
  total_occurrences: true,
  score: true,
  beneficio_social: true,
  midias: true,
  relacao_societaria: true,
  financeiro: true,
  ppe: true,
  lista_restritiva: true,
  processos: true,
  data_resolucao: true,
  analise_start: true,
  analise_update: true,
  company_name: true,
  cnpj: true,
  employee_count: true
}

export const initialState = {
  board: null,

  isLoadingMainData: false,
  summarises: null,
  subjects: null,

  filters: {
    situation: [true]
  },
  dynamicFiltersItems: null,
  selectionOptions: {
    ...selectionOptions
  },

  listPage: {
    people: 1,
    company: 1
  },
  listPerPage: {
    people: 25,
    company: 25
  },
  listOrder: {
    people: {
      orderBy: 'score',
      dscOrder: initialDscOrder,
      orderValueType: 'number'
    },
    company: {
      orderBy: 'score',
      dscOrder: initialDscOrder,
      orderValueType: 'number'
    }
  },
  searchQuery: {
    people: null,
    company: null
  },

  saveBoardModal: {
    isOpen: false,
    proceedFn: null,
    enableProceedWithoutSaving: false
  },

  isSidebarOpen: false,

  users: null,

  riskRatings: null,
  isCreatingRiskRating: false,
  isEditingRiskRating: false,
  isDeletingRiskRating: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MAIN_DATA_REQUESTED:
      return {
        ...state,
        isLoadingMainData: true
      }

    case MAIN_DATA_SUCCEEDED:
      return {
        ...state,
        isLoadingMainData: false,
        summarises: action.summarises,
        subjects: action.subjects,
        dynamicFiltersItems: action.dynamicFiltersItems,
        users: action.users,
        riskRatings: action.riskRatings,
        selectionOptions: action.selectionOptions
      }

    case PEOPLE_REQUESTED:
      return {
        ...state,
        isLoadingMainData: true
      }

    case COMPANIES_REQUESTED:
      return {
        ...state,
        isLoadingMainData: true
      }

    case PEOPLE_SUCCEEDED:
      return {
        ...state,
        isLoadingMainData: false,
        subjects: {
          people: action.people,
          company: state.subjects.company
        },
        summarises: action.summarises
      }

    case COMPANIES_SUCCEEDED:
      return {
        ...state,
        isLoadingMainData: false,
        subjects: {
          people: state.subjects.people,
          company: action.companies
        },
        summarises: action.summarises
      }

    case SET_USERS:
      return {
        ...state,
        users: action.users
      }

    case CREATE_RISK_RATING_REQ:
      return {
        ...state,
        isCreatingRiskRating: true
      }

    case CREATE_RISK_RATING_SUC:
      return {
        ...state,
        riskRatings: action.riskRatings,
        selectionOptions: action.selectionOptions,
        isCreatingRiskRating: false
      }

    case types.EDIT_RISK_RATING_REQ:
      return {
        ...state,
        isEditingRiskRating: true
      }

    case types.EDIT_RISK_RATING_SUC:
      return {
        ...state,
        riskRatings: action.riskRatings,
        selectionOptions: action.selectionOptions,
        // subjects: action.subjects,
        isEditingRiskRating: false
      }

    case types.DELETE_RISK_RATING_REQ:
      return {
        ...state,
        isDeletingRiskRating: true
      }

    case types.DELETE_RISK_RATING_SUC:
      return {
        ...state,
        riskRatings: action.riskRatings,
        selectionOptions: action.selectionOptions,
        subjects: action.subjects,
        isDeletingRiskRating: false
      }

    case types.EDIT_SUBJECT_RISK_RATING_SUC:
      return {
        ...state,
        subjects: action.subjects
      }

    case UPDATE_USER_INFO_SUCCEEDED:
      return {
        ...state,
        users: action.users,
        dynamicFiltersItems: {
          ...state.dynamicFiltersItems,
          responsable: action.responsables
        },
        summarises: {
          people: action.summarises.people,
          company: action.summarises.company
        }
      }

    case types.EDIT_OCCURRENCE_SUCCEEDED:
      return {
        ...state,
        summarises: action.summarises,
        subjects: action.subjects,
        dynamicFiltersItems: action.dynamicFiltersItems
      }

    case types.EDIT_POSTURE_SUCCEEDED:
      return {
        ...state,
        summarises: action.summarises,
        subjects: action.subjects,
        dynamicFiltersItems: action.dynamicFiltersItems
      }

    case types.TOGGLE_SUBJECT_SITUATION_SUCCEEDED:
      return {
        ...state,
        subjects: action.subjects
      }

    case types.CREATE_SUBJECT_OBSERVATION_SUCCEEDED:
      return {
        ...state,
        subjects: action.subjects
      }

    case EDIT_OCCURRENCE_STATUS:
      return {
        ...state,
        summarises: {
          ...state.summarises,
          [action.subjectType]: update(
            action.summariseIndex,
            action.updatedSummarise,
            state.summarises[action.subjectType]
          )
        }
      }

    case UPDATE_SUMMARISES:
      return {
        ...state,
        summarises: {
          ...state.summarises,
          [action.subjectType]: action.updatedSummarises
        }
      }

    case UPDATE_SUBJECTS:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          [action.subjectType]: action.updatedSubjects
        }
      }

    case BOARD_SUCCEEDED:
      return {
        ...state,
        board: {
          type: action.boardType,
          ...action.payload
        },
        filters:
          action.boardType === 'board'
            ? action.payload.config_body.filters
            : { ...initialState.filters }
      }

    case SET_BOARD_INFO:
      return {
        ...state,
        board: {
          ...state.data,
          ...action.payload
        }
      }

    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
        listPage: {
          people: 1,
          company: 1
        },
        searchQuery: {
          people: null,
          company: null
        }
      }

    case SET_LIST_PAGE:
      return {
        ...state,
        listPage: {
          ...state.listPage,
          [action.subjectType]: action.page
        }
      }

    case SET_LIST_PER_PAGE:
      return {
        ...state,
        listPerPage: {
          ...state.listPerPage,
          [action.subjectType]: action.perPage
        }
      }

    case SET_LIST_ORDER:
      return {
        ...state,
        listOrder: {
          ...state.listOrder,
          [action.subjectType]: {
            orderBy: action.orderBy,
            orderValueType: action.orderValueType,
            dscOrder: {
              ...initialDscOrder,
              [action.orderBy]: !state.listOrder[action.subjectType].dscOrder[
                action.orderBy
              ]
            }
          }
        }
      }

    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          [action.subjectType]: action.searchQuery
        },
        listPage: {
          ...state.listPage,
          [action.subjectType]: 1
        }
      }

    case SET_SAVE_REPORT_MODAL:
      return {
        ...state,
        saveBoardModal: {
          isOpen: action.isOpen,
          proceedFn: action.proceedFn,
          enableProceedWithoutSaving: action.enableProceedWithoutSaving
        }
      }

    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.isOpen
      }

    case RESET_PROPS:
      return {
        ...state,
        ...action.props.reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: initialState[cur]
          }
        }, {})
      }

    case RESET_STATE:
      return {
        ...initialState,
        isLoadingMainData: state.isLoadingMainData,
        summarises: state.summarises,
        subjects: state.subjects,
        dynamicFiltersItems: state.dynamicFiltersItems,
        users: state.users,
        riskRatings: state.riskRatings,
        selectionOptions: state.selectionOptions
      }

    default:
      return state
  }
}
