import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 118px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
