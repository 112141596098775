/* React */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/* Components */
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  Button,
  LoadingSpinner
} from '@zeta/ui/src'

/* Action Creators */
import { createSubjectObservation } from 'store/reducers/action-creators'

export const CreateObservationModal = ({
  isObservationModalVisible,
  setIsObservationModalVisible
}) => {
  const dispatch = useDispatch()
  const isCreatingObservation = useSelector(state => state.individual.isCreatingObservation)

  const [value, setValue] = useState('')

  return (
    <Modal
      isVisible={isObservationModalVisible}
      closeFn={() => setIsObservationModalVisible(false)}
      title='Adicionar observação'
      bodyWidth={661}
    >
      <ModalContent>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['link']
            ]
          }}
        />
      </ModalContent>

      <ModalActions>
        <Button onClick={() => setIsObservationModalVisible(false)} red>
          <Icon icon='close-outline' />
          <span>Cancelar</span>
        </Button>

        <Button
          onClick={() => dispatch(createSubjectObservation({ observation: value, onSuccess: () => setIsObservationModalVisible(false) }))}
          disabled={isCreatingObservation || value === ''}
        >
          <Icon icon='save-outline' />
          <span>Salvar</span>
        </Button>

        {isCreatingObservation && <LoadingSpinner size='xlarge' ml='small' />}
      </ModalActions>
    </Modal>
  )
}

CreateObservationModal.propTypes = {
}

export default CreateObservationModal
