/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { CustomText } from '@zeta/ui/src'

/* Styled */
import { Wrapper } from './styled'

/* Parts */
import SelectedFilter from './SelectedFilter'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { omit, without } from 'ramda'
import { formatDate } from '@zeta/helpers'

/* Constants */
import { visibleLabels } from 'constants/filters'

/* Action Creators */
import { setFilters } from 'store/reducers/data/action-creators'

const SelectedFilters = () => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state.data.filters)
  const selectionOptions = useSelector(state => state.data.selectionOptions)

  // TESTAR
  const removeFilterValue = (filters, key, value) => {
    if (Array.isArray(filters[key])) {
      if (filters[key].length === 1) {
        return dispatch(setFilters(omit([key], filters)))
      }

      return dispatch(setFilters({ ...filters, [key]: without([value], filters[key]) }))
    }

    dispatch(setFilters(omit([key], filters)))
  }

  const filteredKeys = Object.keys(filters)

  return filteredKeys.length > 0
    ? <Wrapper>
      {filteredKeys
        .map(key => {
          if (Array.isArray(filters[key])) {
            return filters[key].map(filterValue => (
              <SelectedFilter
                name={visibleLabels[key]}
                value={selectionOptions[key] ? selectionOptions[key].find(filterItem => filterItem.dbValue == filterValue).visibleValue : filterValue || 'NA'}
                closeFn={() => removeFilterValue(filters, key, filterValue)}
                key={uuidv4()}
              />
            ))
          }

          return (
            <SelectedFilter
              name={visibleLabels[key]}
              value={`${filters[key].start ? 'Maior ou igual a ' + formatDate(filters[key].start) : ''}${filters[key].start && filters[key].end ? ' e ' : ''}${filters[key].end ? 'Menor ou igual a ' + formatDate(filters[key].end) : ''}`}
              closeFn={() => removeFilterValue(filters, key)}
              key={uuidv4()}
            />
          )
        })}
    </Wrapper>
    : <CustomText size='0.75rem'>Nenhum filtro ativo.</CustomText>
}

export default SelectedFilters
