/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  Button,
  CustomText,
  LoadingSpinner
} from '@zeta/ui/src'

/* Action Creators */
import { editSubjectRiskRating } from 'store/reducers/action-creators'

export const DeleteRiskRatingModal = ({
  isDeleteRiskRatingModalVisible,
  setIsDeleteRiskRatingModalVisible,
  subjectType
}) => {
  const dispatch = useDispatch()

  const isEditingSubjectRiskRating = useSelector(state => state.individual.isEditingSubjectRiskRating)

  return (
    <Modal
      isVisible={isDeleteRiskRatingModalVisible}
      closeFn={() => setIsDeleteRiskRatingModalVisible(false)}
      title='Remover classificação de risco'
      bodyWidth={661}
    >
      <ModalContent>
        <CustomText size='0.875rem'>Deseja remover a classificação de risco?</CustomText>
      </ModalContent>

      <ModalActions>
        <Button onClick={() => setIsDeleteRiskRatingModalVisible(false)} red>
          <Icon icon='close-outline' />
          <span>Cancelar</span>
        </Button>

        <Button
          onClick={() => dispatch(
            editSubjectRiskRating({
              riskRatingId: '',
              subjectType,
              onSuccess: () => setIsDeleteRiskRatingModalVisible(false)
            })
          )}
          disabled={isEditingSubjectRiskRating}
        >
          <Icon icon='save-outline' />
          <span>Confirmar</span>
        </Button>

        {isEditingSubjectRiskRating && <LoadingSpinner size='xlarge' ml='small' />}
      </ModalActions>
    </Modal>
  )
}

export default DeleteRiskRatingModal
