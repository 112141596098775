/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import ReactTooltip from 'react-tooltip'

import {
  Modal,
  ModalContent,
  FlexCentered,
  LoadingSpinner,
  HorizontalScroll,
  TableInfo,
  Pagination
} from '@zeta/ui/src'

import { TooltipIcon } from '../../OccurrenceCard'

/* Helpers */
import { formatDate } from '@zeta/helpers'
import { getLawsuitDecisions, getLawsuitParts, getLawsuitMovements } from 'api'

/* Constants */
import { visibleInvolvement, visiblePolarity } from 'constants/dictionary'

const getTitle = (type) => {
  if (type === 'decisions') return 'Decisões'
  if (type === 'parts') return 'Partes'
  if (type === 'movements') return 'Movimentações'
}

export const MovementsModal = ({
  /* props */
  movementsModal,
  setMovementsModal
}) => {
  const [data, setData] = useState(null)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [page, setPage] = useState(1)

  const { type, occurrenceId } = movementsModal

  useEffect(() => {
    setIsLoadingData(true)

    if (type === 'decisions') {
      getLawsuitDecisions({
        occurrenceId,
        page,
        perPage: 5
      }).then((res) => {
        setData(res.data)
        setIsLoadingData(false)
      })
    }

    if (type === 'parts') {
      getLawsuitParts({
        occurrenceId,
        page,
        perPage: 5
      }).then((res) => {
        setData(res.data)
        setIsLoadingData(false)
      })
    }

    if (type === 'movements') {
      getLawsuitMovements({
        occurrenceId,
        page,
        perPage: 5
      }).then((res) => {
        setData(res.data)
        setIsLoadingData(false)
      })
    }
  }, [page])

  return (
    <Modal
      isVisible={movementsModal}
      closeFn={() => setMovementsModal(null)}
      title={getTitle(type)}
      bodyWidth={832}
    >
      <ModalContent>
        {!isLoadingData && data && type === 'decisions' && (
          <>
            <HorizontalScroll>
              <TableInfo
                headers={[
                  { text: 'Data da Decisão' },
                  { text: 'Conteúdo da Decisão' },
                  { text: 'Fonte' }
                ]}
                rows={data.result.map((decision) => ({
                  cells: [
                    { text: formatDate(decision.decision_date) },
                    { text: decision.content },
                    {
                      text: (
                        <TooltipIcon
                          dataTip="Bigdatacorp"
                          dataFor="movementsModalTooltip"
                        />
                      )
                    }
                  ]
                }))}
                mb="small"
              />
            </HorizontalScroll>

            <ReactTooltip id="movementsModalTooltip" />
          </>
        )}

        {!isLoadingData && data && type === 'parts' && (
          <>
            <HorizontalScroll>
              <TableInfo
                headers={[
                  { text: 'Tipo' },
                  { text: 'Polaridade' },
                  { text: 'Nome' },
                  { text: 'Documento' },
                  { text: 'Fonte' }
                ]}
                rows={data.result.map((part) => ({
                  cells: [
                    {
                      text:
                        visibleInvolvement[part.involvement] || part.involvement
                    },
                    { text: visiblePolarity[part.polarity] || part.polarity },
                    { text: part.name },
                    { text: part.document },
                    {
                      text: (
                        <TooltipIcon
                          dataTip="Bigdatacorp"
                          dataFor="movementsModalTooltip"
                        />
                      )
                    }
                  ]
                }))}
                mb="small"
              />

              <ReactTooltip id="movementsModalTooltip" />
            </HorizontalScroll>

            <ReactTooltip id="movementsModalTooltip" />
          </>
        )}

        {!isLoadingData && data && type === 'movements' && (
          <>
            <HorizontalScroll>
              <TableInfo
                headers={[
                  { text: 'Data da Publicação' },
                  { text: 'Conteúdo' },
                  { text: 'Data da Captura' },
                  { text: 'Fonte' }
                ]}
                rows={data.result.map((movement) => ({
                  cells: [
                    { text: formatDate(movement.publication_date) },
                    { text: movement.content },
                    { text: formatDate(movement.publication_catch) },
                    {
                      text: (
                        <TooltipIcon
                          dataTip="Bigdatacorp"
                          dataFor="movementsModalTooltip"
                        />
                      )
                    }
                  ]
                }))}
                mb="small"
              />

              <ReactTooltip id="movementsModalTooltip" />
            </HorizontalScroll>

            <ReactTooltip id="movementsModalTooltip" />
          </>
        )}

        {!isLoadingData && data && (
          <Pagination
            page={page}
            setPageFn={(page) => setPage(page)}
            perPage={5}
            totalItems={data.total_listed}
          />
        )}

        {isLoadingData && (
          <FlexCentered>
            <LoadingSpinner size="40" my="large" />
          </FlexCentered>
        )}
      </ModalContent>
    </Modal>
  )
}

export default MovementsModal
