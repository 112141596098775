/* React */
import React from 'react'

/* Redux */
import { Provider } from 'react-redux'
import store from 'store'

/* Styles */
import { GridThemeProvider, BaseCSS } from 'styled-bootstrap-grid'
import { Theme, grid } from '@zeta/ui/src'

/* Router */
import { Router, Redirect, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

/* Components */
import UserRoutes from '@zeta/user/src/components/Routes'
import UserPageWrapper from 'components/UserPageWrapper'

import PrivateRoute from '@zeta/user/src/components/PrivateRoute'

import MainDataLoader from 'components/MainDataLoader'

import BoardsList from 'components/pages/BoardsList'

import ProductConfigurations from 'components/pages/ProductConfigurations'

import BoardWrapper from 'components/BoardWrapper'
import Dashboard from 'components/pages/Dashboard'
import DashboardPrint from 'components/pages/Dashboard/DashboardPrint'
import List from 'components/pages/List'
import Individual from 'components/pages/Individual'
import Dossier from 'components/pages/Dossier'

import ScrollToTop from 'components/routeHelpers/ScrollToTop'

/* Styled */
import { Wrapper } from './styled'

/* Constants */
import { paths, subjectTypes } from 'constants/config'

export const history = createBrowserHistory()

const privateRouteDefaultProps = {
  initialPath: paths.initial,
  permissions: ['admin', 'standard']
}

const BoardWrapperRoute = (props) => (
  <PrivateRoute
    component={BoardWrapper}
    {...privateRouteDefaultProps}
    {...props}
  />
)

const App = () => (
  <Theme>
    <GridThemeProvider gridTheme={grid}>
      <Provider store={store}>
        <Router history={history}>
          <MainDataLoader>
            <Wrapper>
              <Switch>
                {UserRoutes({
                  initialPath: paths.initial,
                  UserPageWrapper,
                  history
                })}

                {/* Lista de paineis */}
                <PrivateRoute
                  exact
                  path={'/paineis'}
                  component={BoardsList}
                  {...privateRouteDefaultProps}
                />

                {/* Configurações da Plataforma */}
                <PrivateRoute
                  exact
                  path={paths.productConfigurations}
                  component={ProductConfigurations}
                  {...privateRouteDefaultProps}
                />

                {/* Análise Geral */}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/dashboard'}
                    content={Dashboard}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/dashboard/print/summarises'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="summarises"
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/dashboard/print/subjects'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="subjects"
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/dashboard/print/score'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="score"
                  />
                )}

                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/dashboard'}
                    content={Dashboard}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/dashboard/print/summarises'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="summarises"
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/dashboard/print/subjects'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="subjects"
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/dashboard/print/score'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="score"
                  />
                )}

                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/dashboard'}
                    content={Dashboard}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/dashboard/print/summarises'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="summarises"
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/dashboard/print/subjects'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="subjects"
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/dashboard/print/score'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="score"
                  />
                )}

                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/dashboard'}
                    content={Dashboard}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/dashboard/print/summarises'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="summarises"
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/dashboard/print/subjects'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="subjects"
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/dashboard/print/score'}
                    noLayout
                    content={DashboardPrint}
                    activeTab="score"
                  />
                )}

                {/* Listagem */}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/individual'}
                    content={List}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/individual'}
                    content={List}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/individual'}
                    content={List}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/individual'}
                    content={List}
                  />
                )}

                {/* Individual */}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/individual/:individualID'}
                    content={Individual}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/individual/:individualID'}
                    content={Individual}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/individual/:individualID'}
                    content={Individual}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/individual/:individualID'}
                    content={Individual}
                  />
                )}

                {/* Dossiê */}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={
                      '/painel/:boardId/people/individual/dossie/:individualID'
                    }
                    noLayout
                    content={Dossier}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/individual/dossie/:individualID'}
                    noLayout
                    content={Dossier}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={
                      '/painel/:boardId/company/individual/dossie/:individualID'
                    }
                    noLayout
                    content={Dossier}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/individual/dossie/:individualID'}
                    noLayout
                    content={Dossier}
                  />
                )}

                {/* Redirecionamento padrão (caso nenhuma rota acima dê match) */}
                <Redirect to={paths.initial} />
              </Switch>
            </Wrapper>
          </MainDataLoader>

          <ScrollToTop />
        </Router>
        <BaseCSS />
      </Provider>
    </GridThemeProvider>
  </Theme>
)

export default App
