/* React */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import { LoadingSpinner, theme } from '@zeta/ui/src'

/* Parts */
import Menu from 'components/Menu'
import Sidebar from 'components/sidebar/Sidebar'
import SaveBoardModal from './SaveBoardModal'

/* Helpers */
import {
  getSubjectType,
  filterList,
  hasListFilter,
  getListsIntersections
} from 'helpers'

/* Constants */
import { subjectTypes } from 'constants/config'

/* Action Creators */
import {
  getAndSetBoard,
  setBoard,
  setSaveBoardModal,
  resetState
} from 'store/reducers/data/action-creators'

const BoardWrapper = ({
  content: Content,
  noLayout,
  computedMatch,
  ...props
}) => {
  const dispatch = useDispatch()

  const board = useSelector((state) => state.data.board)
  const isLoadingMainData = useSelector((state) => state.data.isLoadingMainData)
  const isSaveBoardModalOpen = useSelector(
    (state) => state.data.saveBoardModal.isOpen
  )
  const proceedFn = useSelector((state) => state.data.saveBoardModal.proceedFn)
  const enableProceedWithoutSaving = useSelector(
    (state) => state.data.saveBoardModal.enableProceedWithoutSaving
  )
  const summarises = useSelector((state) => state.data.summarises)
  const subjects = useSelector((state) => state.data.subjects)
  const filters = useSelector((state) => state.data.filters)

  const subjectType = getSubjectType(computedMatch.url)
  const subjectId = computedMatch.params.individualID
  const subjectProps = { subjectType, subjectId }

  useEffect(() => {
    return () => dispatch(resetState())
  }, [])

  useEffect(() => {
    if (!board) {
      const { boardId } = computedMatch.params

      if (boardId) {
        dispatch(getAndSetBoard({ boardType: 'board', boardId: boardId }))
        return
      }

      dispatch(setBoard({ boardType: 'dataSet' }))
    }
  }, [board])

  const [filteredSummarises, setFilteredSummarises] = useState(null)
  const [filteredSubjects, setFilteredSubjects] = useState(null)

  useEffect(() => {
    if (summarises && subjects) {
      const filteredSummarises = filterList(
        summarises[subjectType],
        filters,
        'summarises'
      )
      const filteredSubjects = filterList(
        subjects[subjectType],
        filters,
        'subjects'
      )

      const summarisesSubjectsIds = filteredSummarises.map(
        (summarise) => summarise.subject_id
      )
      const subjectsIds = filteredSubjects.map((subject) => subject.id)

      if (
        hasListFilter(filters, 'summarises') &&
        hasListFilter(filters, 'subjects')
      ) {
        const intersections = getListsIntersections(
          summarisesSubjectsIds,
          subjectsIds
        )

        setFilteredSummarises(
          filteredSummarises.filter((summarise) =>
            intersections.includes(summarise.subject_id)
          )
        )
        setFilteredSubjects(
          filteredSubjects.filter((subject) =>
            intersections.includes(subject.id)
          )
        )
        return
      }

      if (
        hasListFilter(filters, 'summarises') &&
        !hasListFilter(filters, 'subjects')
      ) {
        setFilteredSummarises(filteredSummarises)
        setFilteredSubjects(
          filteredSubjects.filter((subject) =>
            summarisesSubjectsIds.includes(subject.id)
          )
        )
        return
      }

      if (
        !hasListFilter(filters, 'summarises') &&
        hasListFilter(filters, 'subjects')
      ) {
        setFilteredSummarises(
          filteredSummarises.filter((summarise) =>
            subjectsIds.includes(summarise.subject_id)
          )
        )
        setFilteredSubjects(filteredSubjects)
        return
      }

      if (
        !hasListFilter(filters, 'summarises') &&
        !hasListFilter(filters, 'subjects')
      ) {
        setFilteredSummarises(summarises[subjectType])
        setFilteredSubjects(subjects[subjectType])
      }
    }
  }, [summarises, subjects, filters, subjectType])

  return board && !isLoadingMainData ? (
    <>
      {!noLayout && (
        <>
          <Menu
            withSidebar
            withBoardWrapperLinks
            computedMatch={computedMatch}
          />

          <Sidebar filteredSubjects={filteredSubjects} />
        </>
      )}

      <Content
        {...props}
        key={subjectTypes[subjectType]}
        filteredSummarises={filteredSummarises}
        filteredSubjects={filteredSubjects}
        {...subjectProps}
        computedMatch={computedMatch}
      />

      {!noLayout && (
        <SaveBoardModal
          isModalOpen={isSaveBoardModalOpen}
          closeModal={() =>
            dispatch(
              setSaveBoardModal({
                isOpen: false,
                proceedFn: null,
                enableProceedWithoutSaving: false
              })
            )
          }
          isBoard={() => board.type === 'board'}
          proceedFn={(res) => proceedFn(res)}
          enableProceedWithoutSaving={enableProceedWithoutSaving}
        />
      )}
    </>
  ) : (
    <LoadingSpinner
      size="40"
      override={`margin: ${theme.spacing.xlarge} auto 0 auto;`}
    />
  )
}

export default withRouter(BoardWrapper)
