/* React */
import React from 'react'

/* Parts */
import Statistics from 'components/Statistics'
import Occurrences from './Occurrences'
import Subjects from './Subjects'
import Score from './Score'

const DashboardPrint = ({
  filteredSummarises,
  filteredSubjects,
  subjectType,
  activeTab
}) => {
  return (
    <div
      style={{ width: '100%' }}
    >
      <Statistics filteredSummarises={filteredSummarises} filteredSubjects={filteredSubjects} subjectType={subjectType} />

      {activeTab === 'summarises' &&
        <Occurrences filteredSummarises={filteredSummarises} filteredSubjects={filteredSubjects} subjectType={subjectType} activeTab={activeTab} />}

      {activeTab === 'subjects' &&
        <Subjects filteredSummarises={filteredSummarises} filteredSubjects={filteredSubjects} subjectType={subjectType} activeTab={activeTab} />}

      {activeTab === 'score' && filteredSubjects &&
        <Score filteredSubjects={filteredSubjects} subjectType={subjectType} />}
    </div>
  )
}

export default DashboardPrint
