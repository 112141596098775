/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  DropdownSelect,
  Button,
  Warning,
  LoadingSpinner,
  Icon
} from '@zeta/ui/src'

/* Helpers */
import { logAmplitudeEvent } from '@zeta/helpers'
import { saveBoard, createBoard } from 'api'

const SaveBoardModal = ({
  isModalOpen,
  closeModal,
  isBoard,
  enableProceedWithoutSaving,
  proceedFn
}) => {
  const board = useSelector((state) => state.data.board)
  const filters = useSelector((state) => state.data.filters)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitThen = (res) => {
    setIsSubmitting(false)
    closeModal()
    proceedFn(res)
  }

  const submitFn = (values) => {
    setIsSubmitting(true)

    const configBody = {
      filters,
      status: values.status
    }

    if (isBoard()) {
      saveBoard({
        boardId: board.id,
        name: values.name,
        configBody
      }).then((res) => submitThen(res))

      logAmplitudeEvent({
        event: 'Salvou um painel',
        eventProperties: {
          'Status do painel': 'Painel existente'
        }
      })
    } else {
      createBoard({
        name: values.name,
        configBody
      }).then((res) => submitThen(res))

      logAmplitudeEvent({
        event: 'Salvou um painel',
        eventProperties: {
          'Status do painel': 'Painel novo'
        }
      })
    }
  }

  return (
    <Modal
      title={'Salvar painel'}
      isVisible={isModalOpen}
      closeFn={() => closeModal()}
    >
      <Formik
        initialValues={
          isBoard()
            ? { name: board.name, status: board.config_body.status || 1 }
            : { name: '', status: 1 }
        }
        onSubmit={(values) => submitFn(values)}
      >
        {(props) => {
          const { values, setFieldValue, handleSubmit } = props

          return (
            <Form onSubmit={handleSubmit}>
              <ModalContent>
                {!isBoard() && (
                  <Warning mb={'large'}>
                    Os painéis criados ficarão visíveis para todos os membros do
                    time.
                  </Warning>
                )}

                <Field
                  inputElement={'input'}
                  type={'text'}
                  name={'name'}
                  label={'Nome do painel'}
                  placeholder={'Digite o nome do seu painel'}
                  component={FieldGroup}
                  mb={'large'}
                />

                <DropdownSelect
                  items={[
                    {
                      text: 'Em investigação',
                      fn: () => setFieldValue('status', 1)
                    },
                    {
                      text: 'Finalizado',
                      fn: () => setFieldValue('status', 2)
                    }
                  ]}
                  activeIndex={values.status - 1}
                  label={'Status do painel'}
                  displayText={'Em investigação'}
                />
              </ModalContent>

              <ModalActions>
                {enableProceedWithoutSaving && (
                  <Button type={'button'} onClick={proceedFn} red>
                    <Icon icon={'close-outline'} />
                    <span>Não salvar</span>
                  </Button>
                )}

                <Button type="submit" disabled={isSubmitting || !values.name}>
                  <Icon icon={'save-outline'} />
                  <span>Salvar painel</span>
                </Button>

                {isSubmitting && (
                  <LoadingSpinner size={'xlarge'} ml={'small'} />
                )}
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default SaveBoardModal
