import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.small};
`

export const Col = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.small};
  width: 100%;

  @media (min-width: 992px) {
    width: 50%;
  }
`
