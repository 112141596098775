/* React */
import React from 'react'

/* Components */
import Icon from '../Icon'

/* Styled */
import { Wrapper } from './styled'

/* Constants */
import theme from '../../theme/theme'

const ButtonWithIcon = ({ icon, children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Icon
        icon={icon}
        size="medium"
        fill={theme.colors.brand.primary.medium}
      />
      {children}
    </Wrapper>
  )
}

export default ButtonWithIcon
