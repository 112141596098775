export default {
  EDIT_OCCURRENCE_REQUESTED: 'EDIT_OCCURRENCE_REQUESTED',
  EDIT_OCCURRENCE_SUCCEEDED: 'EDIT_OCCURRENCE_SUCCEEDED',
  EDIT_POSTURE_REQUESTED: 'EDIT_POSTURE_REQUESTED',
  EDIT_POSTURE_SUCCEEDED: 'EDIT_POSTURE_SUCCEEDED',
  RESET_PROPS: 'RESET_PROPS',
  RESET_STATE: 'RESET_STATE',
  TOGGLE_SUBJECT_SITUATION_REQUESTED: 'TOGGLE_SUBJECT_SITUATION_REQUESTED',
  TOGGLE_SUBJECT_SITUATION_SUCCEEDED: 'TOGGLE_SUBJECT_SITUATION_SUCCEEDED',
  CREATE_SUBJECT_OBSERVATION_REQUESTED: 'CREATE_SUBJECT_OBSERVATION_REQUESTED',
  CREATE_SUBJECT_OBSERVATION_SUCCEEDED: 'CREATE_SUBJECT_OBSERVATION_SUCCEEDED',
  EDIT_RISK_RATING_REQ: 'EDIT_RISK_RATING_REQ',
  EDIT_RISK_RATING_SUC: 'EDIT_RISK_RATING_SUC',
  DELETE_RISK_RATING_REQ: 'DELETE_RISK_RATING_REQ',
  DELETE_RISK_RATING_SUC: 'DELETE_RISK_RATING_SUC',
  EDIT_SUBJECT_RISK_RATING_REQ: 'EDIT_SUBJECT_RISK_RATING_REQ',
  EDIT_SUBJECT_RISK_RATING_SUC: 'EDIT_SUBJECT_RISK_RATING_SUC'
}
