/* React */
import React from 'react'

/* Components */
import {
  Card,
  TableStriped,
  CustomText,
  HorizontalScroll,
  CustomScrollbars,
  theme
} from '@zeta/ui/src'

/* Constants */
import { visibleStatus, visibleOccurrenceType } from 'constants/dictionary'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { update, insert, uniq } from 'ramda'
import { formatDate } from '@zeta/helpers'
import { toCurrency } from 'helpers'

const getHeaders = (occurrences, subjectType) => {
  switch (occurrences[0].occurrence_class) {
    case 'beneficio_social':
      return [
        { text: <strong>Benefício</strong> },
        { text: <strong>Valor</strong> },
        { text: <strong>Parcelas</strong> }
      ]

    case 'midia':
      return [
        { text: <strong>Categoria</strong> },
        { text: <strong>Link</strong> }
      ]

    case 'relacao_societaria':
      if (occurrences[0].occurrence_type === 'cnae') {
        return [
          { text: <strong>Razão Social</strong> },
          { text: <strong>CNPJ</strong> },
          { text: <strong>CNAE</strong> }
        ]
      }
      if (occurrences[0].occurrence_type === 'quadro_societario') {
        return [
          { text: <strong>Nome / Razão Social</strong> },
          { text: <strong>CPF / CNPJ</strong> },
          { text: <strong>Qualificação</strong> }
        ]
      }
      break

    case 'financeiro':
      if (occurrences[0].occurrence_type === 'dividas_e_pendencias') {
        return [
          { text: <strong>Credor</strong> },
          { text: <strong>Valor</strong> }
        ]
      }
      if (occurrences[0].occurrence_type === 'protestos') {
        return [
          { text: <strong>Valor</strong> },
          { text: <strong>Data</strong> },
          { text: <strong>Cidade</strong> },
          { text: <strong>UF</strong> }
        ]
      }
      if (occurrences[0].occurrence_type === 'certidao_de_debito') {
        return [
          { text: <strong>Status da Certidão</strong> },
          { text: <strong>Fonte</strong> },
          { text: <strong>Data da Consulta</strong> }
        ]
      }
      break

    case 'publico_exposta':
      if (subjectType === 'people') {
        return [
          { text: <strong>Direto / Indireto</strong> },
          // { text: <strong>CPF</strong> },
          { text: <strong>Grau de parentesco</strong> },
          { text: <strong>Cargo</strong> },
          { text: <strong>CPF</strong> }
          // { text: <strong>Localidade</strong> }
        ]
      }
      // if (subjectType === 'company') {
      //   return [
      //   ]
      // }
      break

    case 'lista_restritiva':
      if (occurrences[0].occurrence_type === 'sancoes') {
        return [
          { text: <strong>Tipo</strong> },
          { text: <strong>Órgão</strong> },
          { text: <strong>Apontamento</strong> }
        ]
      }
      if (occurrences[0].occurrence_type === 'cac') {
        return [
          { text: <strong>Tipo</strong> },
          { text: <strong>Órgão</strong> },
          { text: <strong>Apontamento</strong> },
          { text: <strong>Data de Emissão</strong> }
        ]
      }
  }
}

const getCells = (occurrence, subjectType) => {
  switch (occurrence.occurrence_class) {
    case 'beneficio_social':
      return [
        { text: occurrence.content.Nome_do_Beneficio },
        { text: toCurrency(occurrence.content.Valo_Beneficio) },
        { text: occurrence.content.Numero_Parcelas }
      ]

    case 'midia':
      return [
        { text: occurrence.content.Categorias },
        {
          text: (
            <CustomText
              as="a"
              href={occurrence.content.URL}
              size="0.875rem"
              color={theme.colors.brand.primary.medium}
              target="_blank"
            >
              acessar notícia
            </CustomText>
          )
        }
      ]

    case 'relacao_societaria':
      if (occurrence.occurrence_type === 'cnae') {
        return [
          { text: occurrence.content.Razao_social },
          { text: occurrence.content.CNPJ },
          { text: occurrence.content.Classificacao }
        ]
      }
      if (occurrence.occurrence_type === 'quadro_societario') {
        return [
          { text: occurrence.content.Nome_Razao_Social_Socio },
          { text: occurrence.content.CPF_CNPJ_Socio },
          { text: occurrence.content.Qualificacao_Socio }
        ]
      }
      break

    case 'financeiro':
      if (occurrence.occurrence_type === 'dividas_e_pendencias') {
        return [
          { text: occurrence.content.Credor },
          { text: toCurrency(occurrence.content.Valor) }
        ]
      }
      if (occurrence.occurrence_type === 'protestos') {
        return [
          { text: toCurrency(occurrence.content.Valor_Protesto) },
          { text: formatDate(occurrence.content.Data_Protesto) },
          { text: occurrence.content.Cidade },
          { text: occurrence.content.UF }
        ]
      }
      if (occurrence.occurrence_type === 'certidao_de_debito') {
        return [
          { text: occurrence.content.Status },
          { text: occurrence.content.Fonte },
          { text: formatDate(occurrence.content.Data_Consulta) }
        ]
      }
      break

    case 'publico_exposta':
      if (subjectType === 'people') {
        if (occurrence.content.Nivel == 1) {
          return [
            { text: 'Direto' },
            { text: '' },
            { text: occurrence.content.Cargo },
            { text: '' }
          ]
        }
        if (occurrence.content.Nivel == 2) {
          return [
            { text: 'Indireto' },
            { text: occurrence.content.Motivo },
            { text: '' },
            { text: occurrence.content.CPF_Relacionado }
          ]
        }
      }
      // if (subjectType === 'company') {
      //   return [
      //   ]
      // }
      break

    case 'lista_restritiva':
      if (occurrence.occurrence_type === 'sancoes') {
        return [
          { text: 'Sanções' },
          { text: occurrence.content.Fonte },
          { text: occurrence.content.Tipo }
        ]
      }
      if (occurrence.occurrence_type === 'cac') {
        return [
          { text: 'CAC' },
          { text: 'Polícia Federal' },
          { text: occurrence.content.Status },
          { text: occurrence.content.Data_Emissao }
        ]
      }
  }
}

export const getLawsuitRows = (occurrences) => {
  const rows = occurrences
    .reduce((acc, cur) => {
      const occTypeIndex =
        acc.length > 0
          ? acc.findIndex(
              (row) =>
                row.cells[0].text === visibleOccurrenceType[cur.occurrence_type]
            )
          : -1

      const getColumnIndex = () => {
        if (cur.polarity === 'ACTIVE') return 1
        if (cur.polarity === 'PASSIVE') return 2
        return 3
      }

      /* caso já exista uma row para o tipo do Processo */
      if (occTypeIndex !== -1) {
        return update(
          occTypeIndex,
          {
            cells: update(
              4,
              { text: acc[occTypeIndex].cells[4].text + 1 },
              update(
                getColumnIndex(),
                { text: acc[occTypeIndex].cells[getColumnIndex()].text + 1 },
                acc[occTypeIndex].cells
              )
            )
          },
          acc
        )
      }

      /* caso ainda não exista uma row para o tipo do Processo */
      const cells = [
        { text: visibleOccurrenceType[cur.occurrence_type] },
        { text: 0 },
        { text: 0 },
        { text: 0 },
        { text: 1 }
      ]

      return acc.concat({
        cells: update(getColumnIndex(), { text: 1 }, cells)
      })
    }, [])
    .sort((a, b) => a.cells[0].text.localeCompare(b.cells[0].text))

  const sumColumn = (i) => rows.reduce((acc, row) => acc + row.cells[i].text, 0)

  return insert(
    0,
    {
      cells: [
        { text: 'Todos os Processos' },
        { text: sumColumn(1) },
        { text: sumColumn(2) },
        { text: sumColumn(3) },
        { text: sumColumn(4) }
      ]
    },
    rows
  )
}

const ClassSummaryTable = ({
  /* props */
  occurrences,
  subjectType
}) => {
  if (
    occurrences.length > 0 &&
    occurrences[0].occurrence_class === 'processos'
  ) {
    return (
      <Card title="Resumo dos Processos" mb="xlarge">
        <HorizontalScroll>
          <TableStriped
            headers={[
              { text: <strong>Tipo</strong> },
              { text: <strong>Parte Ativa</strong> },
              { text: <strong>Parte Passiva</strong> },
              { text: <strong>Outra Partes</strong> },
              { text: <strong>Total</strong> }
            ]}
            rows={getLawsuitRows(occurrences)}
            oddRowBg={theme.colors.brand.primary.lightness}
          />
        </HorizontalScroll>
      </Card>
    )
  }

  if (occurrences.length > 0) {
    return uniq(
      occurrences.map((occurrence) => occurrence.occurrence_type)
    ).map((occurrenceType) => {
      const typeOccurrences = occurrences.filter(
        (occurrence) => occurrence.occurrence_type === occurrenceType
      )

      return (occurrenceType === 'ppe' && subjectType === 'company') ||
        occurrenceType === 'quadro_societario' ? null : (
        <Card
          title={`Resumo das ocorrências (${visibleOccurrenceType[occurrenceType]})`}
          mb="xlarge"
          key={uuidv4()}
        >
          <CustomScrollbars
            style={{
              height:
                typeOccurrences.length >= 6
                  ? '224px'
                  : typeOccurrences.length * 32 + 44 + 'px'
            }}
          >
            <TableStriped
              headers={[
                ...getHeaders(typeOccurrences, subjectType),
                { text: <strong>Status</strong> },
                { text: <strong>ID</strong> }
              ]}
              rows={typeOccurrences.map((occurrence) => ({
                cells: [
                  ...getCells(occurrence, subjectType),
                  { text: visibleStatus[occurrence.occurrence_status.status] },
                  { text: occurrence.id }
                ]
              }))}
              oddRowBg={theme.colors.brand.primary.lightness}
            />
          </CustomScrollbars>
        </Card>
      )
    })
  }

  return null
}

export default ClassSummaryTable
