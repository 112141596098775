/* React */
import React from 'react'

/* Components */
import ReactTooltip from 'react-tooltip'

import {
  HorizontalScroll,
  TableInfo
} from '@zeta/ui/src'

import OccurrenceCard, { TableInfoHeadline, TooltipIcon, OccurrenceCardCol, headers } from '../OccurrenceClassTab/OccurrenceCard'

const Registrations = ({
  /* props */
  individual,
  subjectType
}) => {
  const occurrence = {
    id: 'registrationInfo'
  }

  if (subjectType === 'people') {
    return (
      <>
        <OccurrenceCard title='Cadastrais' occurrence={occurrence} isOpenState>
          <OccurrenceCardCol>
            <HorizontalScroll>
              <TableInfo
                headers={headers}
                rows={[
                  {
                    cells: [
                      { text: <TableInfoHeadline>PERFIL</TableInfoHeadline>, colSpan: 3 }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Data de nascimento' },
                      { text: individual.profile.data_nascimento },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Idade' },
                      { text: individual.profile.idade },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Sexo' },
                      { text: individual.profile.sexo },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Escolaridade' },
                      { text: individual.profile.grau_instrucao },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Estado civil' },
                      { text: individual.profile.estado_civil },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  }
                // {
                //   cells: [
                //     { text: 'Cidade de nascimento' },
                //     { text: individual.profile.data_nascimento },
                //     { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                //   ]
                // },
                // {
                //   cells: [
                //     { text: 'UF de nascimento' },
                //     { text: individual.profile.data_nascimento },
                //     { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                //   ]
                // },
                // {
                //   cells: [
                //     { text: 'País de nascimento' },
                //     { text: individual.profile.data_nascimento },
                //     { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                //   ]
                // }
                ]}
              />
            </HorizontalScroll>
          </OccurrenceCardCol>

          {/* <HorizontalScroll>
          <TableInfo
            headers={[
              { text: 'Dado' },
              { text: 'Resultado' },
              { text: 'Fonte', textAlign: 'right' }
            ]}
            rows={[
              {
                cells: [
                  { text: <TableInfoHeadline>VÍNCULOS</TableInfoHeadline>, colSpan: 3 }
                ]
              },
              {
                cells: [
                  { text: 'Nome pai' },
                  { text: individual.profile.data_nascimento },
                  { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                ]
              },
              {
                cells: [
                  { text: 'Nome mãe' },
                  { text: individual.profile.idade },
                  { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                ]
              },
              {
                cells: [
                  { text: 'Nome cônjuge' },
                  { text: individual.profile.sexo },
                  { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                ]
              }
            ]}
          />
        </HorizontalScroll> */}

          {individual.address &&
            <OccurrenceCardCol>
              <HorizontalScroll>
                <TableInfo
                  headers={headers}
                  rows={[
                    {
                      cells: [
                        { text: <TableInfoHeadline>MORADIA</TableInfoHeadline>, colSpan: 3 }
                      ]
                    },
                    ...(individual.address || []).map((address, i) => ({
                      cells: [
                        { text: `Endereço ${i + 1}` },
                        {
                          text: (address.tipo_logradouro + ' ' + address.logradouro + ', ' + (Number(address.numero)).toFixed(0) + ', ' + address.complemento + ', ' + address.bairro + ', ' + address.cidade + ', ' + address.uf + ', ' + address.cep).replace(/, null/g, '').replace(/null/g, '').trim()
                        },
                        { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                      ]
                    }))
                  ]}
                />
              </HorizontalScroll>
            </OccurrenceCardCol>}
        </OccurrenceCard>

        <ReactTooltip id='registrationsTooltip' />
      </>
    )
  }

  if (subjectType === 'company') {
    return (
      <>
        <OccurrenceCard title='Cadastrais' occurrence={occurrence}>
          <OccurrenceCardCol>
            <HorizontalScroll>
              <TableInfo
                headers={headers}
                rows={[
                  {
                    cells: [
                      { text: <TableInfoHeadline>PERFIL</TableInfoHeadline>, colSpan: 3 }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Razão social' },
                      { text: individual.company_name },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Nome fantasia' },
                      { text: individual.fantasy_name },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Porte' },
                      { text: individual.profile.company_size },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Status Receita' },
                      { text: individual.profile.incoming_status },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Tipo de empresa' },
                      { text: individual.profile.company_type },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Data de abertura' },
                      { text: individual.profile.opening_date },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Faixa idade' },
                      { text: individual.profile.employee_age_range },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Faixa de funcionários' },
                      { text: individual.profile.employee_range },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: <TableInfoHeadline>CNAE</TableInfoHeadline>, colSpan: 3 }
                    ]
                  },
                  {
                    cells: [
                      { text: 'CNAE' },
                      { text: (Number(individual.cnae.number)).toFixed(0) },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Descrição CNAE' },
                      { text: individual.cnae.description },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Grupo CNAE' },
                      { text: individual.cnae.group },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  },
                  {
                    cells: [
                      { text: 'Subgrupo CNAE' },
                      { text: individual.cnae.subgroup },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  }
                ]}
              />
            </HorizontalScroll>
          </OccurrenceCardCol>

          <OccurrenceCardCol>
            <HorizontalScroll>
              <TableInfo
                headers={headers}
                rows={[
                  {
                    cells: [
                      { text: <TableInfoHeadline>ENDEREÇOS</TableInfoHeadline>, colSpan: 3 }
                    ]
                  },
                  ...(individual.address || []).map((address, i) => ({
                    cells: [
                      { text: `Endereço ${i + 1}` },
                      {
                        text: (address.tipo_logradouro + ' ' + address.logradouro + ', ' + (Number(address.numero)).toFixed(0) + ', ' + address.complemento + ', ' + address.bairro + ', ' + address.cidade + ', ' + address.uf + ', ' + address.cep).replace(/, null/g, '').replace(/null/g, '').trim()
                      },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  })),
                  individual.phone.phone && individual.phone.phone.length > 0
                    ? {
                      cells: [
                        { text: <TableInfoHeadline>TELEFONES FIXOS</TableInfoHeadline>, colSpan: 3 }
                      ]
                    }
                    : null,
                  ...(individual.phone.phone || []).map((phone, i) => ({
                    cells: [
                      { text: `Telefone fixo ${i + 1}` },
                      { text: phone.number },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  })),
                  individual.phone.cellphone && individual.phone.cellphone.length > 0
                    ? {
                      cells: [
                        { text: <TableInfoHeadline>TELEFONES MÓVEIS</TableInfoHeadline>, colSpan: 3 }
                      ]
                    }
                    : null,
                  ...(individual.phone.cellphone || []).map((cellphone, i) => ({
                    cells: [
                      { text: `Telefone móvel ${i + 1}` },
                      { text: cellphone.number },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  })),
                  Object.keys(individual.email || {}).length > 0
                    ? {
                      cells: [
                        { text: <TableInfoHeadline>E-MAILS</TableInfoHeadline>, colSpan: 3 }
                      ]
                    }
                    : null,
                  ...Object.keys(individual.email || {}).map((key, i) => ({
                    cells: [
                      { text: `E-mail ${i + 1}` },
                      { text: individual.email[key] },
                      { text: <TooltipIcon dataTip='Assertiva' dataFor='registrationsTooltip' /> }
                    ]
                  }))
                ]}
              />
            </HorizontalScroll>
          </OccurrenceCardCol>
        </OccurrenceCard>

        <ReactTooltip id='registrationsTooltip' />
      </>
    )
  }
}

export default Registrations
